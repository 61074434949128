import React, { Fragment, useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import Navbar from "../Navbar";
import Footer from "../Footer";
import Select2Wrapper from '../Select2Wrapper';
import Cookies from 'js-cookie';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const { config } = require('../api/api');

Modal.setAppElement('#root');
const Makepayment = () => {
  const base_url = config.baseURL;
  const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';

  const { t } = useTranslation();
  const [pdata, setPdata] = useState([]);


  const [searchData, setSearchData] = useState(false);
  useEffect(() => {
    const storedSearchString = sessionStorage.getItem('searchData');
    debugger
    if (storedSearchString !== 'undefined') {
      const storedSearch = JSON.parse(storedSearchString);
      setSearchData(storedSearch);
    }
  }, []);
  const [orderData, setOrderData] = useState(false);
  useEffect(() => {
    debugger;
    const storedOrderString = sessionStorage.getItem('event_data');
    if (storedOrderString !== "undefined" && storedOrderString!== undefined && storedOrderString !== null) {
        const storedOrder = JSON.parse(storedOrderString);
        setOrderData(storedOrder);
    }
}, []);


  const [userData, setUserData] = useState(false);

  useEffect(() => {
    const storedUserString = Cookies.get('userData'); //sessionStorage.getItem('userData')
    debugger
    if (storedUserString !== undefined && storedUserString!==null) {
      const storedUser = JSON.parse(storedUserString);
      setUserData(storedUser);
    }
    else {
      alert("login session expired");
      window.location.href = '/';
    }
  }, []);

  const usrtok = userData.access_token;
  const [order_data, setorderdata] = useState(false);
  useEffect(() => {
    const order_data = sessionStorage.getItem('order_data');
    if (order_data !== "undefined" && order_data!== undefined && order_data !== null) {
      const storedResult1 = JSON.parse(order_data);
      debugger
      setorderdata(storedResult1);
    }
  }, []);


  const [searchResult, setSearchResult] = useState(false);
  useEffect(() => {
    const storedResultString = sessionStorage.getItem('searchResult');
    if (storedResultString !== "undefined" && storedResultString!== undefined && storedResultString !== null) {
      const storedResult = JSON.parse(storedResultString);
      debugger
      setSearchResult(storedResult);
    }
  }, []);





  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    dob: '',
    birthCountry: '',
    citizenshipCountry: '',
    sex: '',
    email: '',
    phoneA: '',
    phoneP: '',
    livingCountry: '',
    passportNumber: '',
    passportIssuingCountry: '',
    passportIssuingDate: '',
    passportExpiringDate: '',
    transit: 'Yes',
    applicantEmployed: ''
  });

  const [dob, setDob] = useState('');
  const [birthcountry, setBirth] = useState('');
  const [passportNumber, setPnumber] = useState('');
  const [passportIssuingDate, setIdate] = useState('');
  const [passportExpiringDate, setEdate] = useState('');

  function generateRandomValue() {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let randomValue = '';
    for (let i = 0; i < 10; i++) {
      randomValue += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return randomValue;
  }
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'dob') {
      setDob(value);
    }
    if (name === 'birthCountry') {
      setBirth(value);
    }
    if (name === 'passportNumber') {
      setPnumber(value);
    }
    if (name === 'passportIssuingDate') {
      setIdate(value);
    }
    if (name === 'passportExpiringDate') {
      setEdate(value);
    }

    setFormData({ ...formData, [name]: value });
  };




  const [loading, setLoading] = useState(false);



  const [vendorKey, setVendorKey] = useState('');

  useEffect(() => {

    const postvData = { organization_name: "Superjet" };

    if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {


      fetch(base_url+'/create_organization', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postvData),
      })
        .then(response => response.json())
        .then(data => {
          setVendorKey(data.result[0].vendor_key);
        })
        .catch(error => {
          console.error('Error posting data:', error);
        });


    }
  }, [vendorKey]); // Dependency array



  
  

 


  const [pkey, setpKey] = useState('');

  const vkdata = { vendor_key: vendorKey };

  // if (vendorKey !== '' && pkey === '') {

  //   fetch(base_url+'/generate_payment_token', {
  //     method: 'POST',
  //     headers: {
  //       'Authorization': `Bearer ${usrtok}`,
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify(vkdata),
  //   })
  //     .then(response => response.json())
  //     .then(data => {
  //       debugger

  //       setpKey(data.result.payment_token);


  //     })
  //     .catch(error => {
  //       console.error('Error posting data:', error);
  //     });

  // }



  const handleSubmit = async (e) => {
    debugger
    setLoading(true);
    e.preventDefault();
    console.log(userData);
    console.log(searchResult);
    console.log(searchData);
    const rand = Math.floor(Math.random() * 90000000) + 10000000;
    const rnd = rand.toString();
   const data = {
        // "user_token":usrtok,
       
        "order_id":orderData.orderId,
        "amount_cents": searchResult.fee,
        "reference_id": rnd,
        "is_live": "true",
        "first_name": order_data.first_name,
        "last_name":order_data.last_name,
        "phone_no": order_data.phone_no,
        "apartment": order_data.apartment,
        "floor": order_data.floor,
        "street": order_data.street,
        "city": order_data.city,
        "state": order_data.state,
        "country": order_data.country,
        "email": order_data.email,
        "save_selection": "true",
        "redirection_url": base_url+"/payment_intention_new",
        "currency": searchResult.currency,
        "description": "Payment visa **",
        // "payment_token": pkey,
         "vendor_key":vendorKey
      };
      setPdata(data);
debugger
if (vendorKey !== ''&&usrtok!=='') {

  fetch(base_url+'/create_new_payment_intention_link', {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${usrtok}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then(response => response.json())
    .then(data => {
      setLoading(false);
      debugger
      if(data.message==="success"){
        window.location.href = data.result?.client_url;
      }

      // setpKey(data.result.payment_token);


    })
    .catch(error => {
      console.error('Error posting data:', error);
    });

}
// console.log(pdata);

   
  };

//   const [isChecked, setIsChecked] = useState(false);

//   const handleCheckboxChange = (event) => {
//     setIsChecked(event.target.checked);
//   };

//   const [isModalOpen, setIsModalOpen] = useState(false);

  // Handle link click to open modal
//   const handleOpenModal = (event) => {
//     event.preventDefault(); // Prevent the default link behavior
//     setIsModalOpen(true);
//   };

  // Handle close modal
//   const handleCloseModal = () => {
//     setIsModalOpen(false);
//   };

  return (

    <Fragment>

      <div className="header_section1">

        <Navbar />


        <div className="container-fluid bg-breadcrumb">
          <div className="container text-center py-5">
            {/* <h3 className="abt-txt my-5">{t('Visa')} {t('Application')} {t('Form')}</h3> */}
            <h3 className="abt-txt my-5"> Make Payment</h3>
          </div>
        </div>


      </div>


      <div class="features_section">
        <div class="container">

          <Row>

            <Col md={3}></Col>

            <Col md={6} className="myforms my-5">


              <div className="clformrow mt-1">


                {/* <form onSubmit={handleSubmit}> */}


                  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                      <span className="input-group-text">{t('First')} {t('Name')}</span>
                    </div>
                    <input type="text" name="firstName" className="form-control"
                      value={order_data?.first_name} onChange={handleChange} required readOnly/>
                  </div>

                  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                      <span className="input-group-text">{t('Last')} {t('Name')}</span>
                    </div>
                    <input type="text" name="lastName" className="form-control"
                      value={order_data?.last_name} onChange={handleChange} required readOnly/>
                  </div>

                  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                      <span className="input-group-text">{t('Email')}</span>
                    </div>
                    <input type="text" name="email" className="form-control" value={order_data?.email}
                      onChange={handleChange} required readOnly />
                  </div>


                  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                      <span className="input-group-text">{t('Passport')} {t('Country')}</span>
                    </div>
                    <input type="text" className="form-control" value={searchResult?.citizenship}
                      required readOnly />
                  </div>


                  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                      <span className="input-group-text">{t('Destination')} {t('Country')}</span>
                    </div>
                    <input type="text" className="form-control" value={searchResult?.destination}
                      required readOnly />
                  </div>
                  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                      <span className="input-group-text">{t('Fee')}</span>
                    </div>
                    <input type="text" className="form-control" value={searchResult?.fee}
                      required readOnly />
                  </div>
                  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                      <span className="input-group-text">{t('Currency')}</span>
                    </div>
                    <input type="text" className="form-control" value={searchResult?.currency}
                      required readOnly />
                  </div>
                  <button type="submit" onClick={handleSubmit} className="btn btn-primary rounded-pill text-white my-3 py-1 px-4">{t('Make Payment')}</button>



                  {/* <button type="submit" disabled={!isChecked}
                    className="btn btn-primary rounded-pill text-white my-2 py-1 px-4">{t('Submit')}</button> */}


                {/* </form> */}

              </div>

            </Col>

            <Col md={3}></Col>

          </Row>

          {loading && (
            <div className="overlay">
              <div className="spinner"></div>
            </div>
          )}

        </div>

      </div>



      {/* <Modal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        contentLabel="Example Modal"
        style={{
          overlay: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
          content: {
            position: 'relative',
            padding: '20px',
            maxWidth: '80%', // Set larger width for larger modal
            maxHeight: '80vh',
            overflow: 'auto',
            inset: 'auto',
            width: '100%',
          },
        }}
      >
        <div>
          <button
            onClick={handleCloseModal}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              background: 'transparent',
              border: 'none',
              fontSize: '24px',
              cursor: 'pointer'
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>

          <center>
            <h2 style={{ fontWeight: 600 }}>Form Filling Disclaimers for Visa Processing</h2>
          </center>
        </div>

        <br />
        <br />
        <br />

        <p><b style={{ fontWeight: 600 }}>Accuracy of Information</b>
          <br />
          • I hereby acknowledge that all information provided in this form is accurate and truthful to the best
          of my knowledge. I understand that providing false or misleading information may result in the rejection
          of my visa application and potential legal consequences.
          <br />
          <br />
          <b style={{ fontWeight: 600 }}>Role of SuperJetOM</b>
          <br />
          • I understand and agree that SuperJetOM is a service provider facilitating the submission of my visa
          application. I acknowledge that the decision to grant or deny the visa is solely at the discretion of
          the relevant embassy and government authorities. SuperJetOM has no influence or control over the
          decision-making process of the visa application.
          <br />
          <br />
          <b style={{ fontWeight: 600 }}>Processing Time and Delays</b>
          <br />
          • I acknowledge that visa processing times may vary and that SuperJetOM cannot guarantee any specific
          timeframe for the processing of my application. I understand that delays may occur due to various
          factors beyond the control of SuperJetOM.
          <br />
          <br />
          <b style={{ fontWeight: 600 }}>Non-Refundable Fees</b>
          <br />
          • I understand that all fees paid to SuperJetOM for the visa processing service are non-refundable,
          regardless of the outcome of the visa application.
          <br />
          <br />
          <b style={{ fontWeight: 600 }}>Additional Documentation</b>
          <br />
          • I acknowledge that the embassy or government authorities may request additional documentation or
          information during the processing of my visa application. I agree to promptly provide any such requested
          information to avoid delays in processing.
          <br />
          <br />
          <b style={{ fontWeight: 600 }}>No Guarantees of Approval</b>
          <br />
          • I understand that the submission of a visa application through SuperJetOM does not guarantee approval
          of the visa. The decision rests solely with the embassy and government authorities, and SuperJetOM
          cannot influence this outcome.
          <br />
          <br />
          <b style={{ fontWeight: 600 }}>Legal Compliance</b>
          <br />
          • I agree to comply with all applicable laws and regulations related to the visa application process. I
          understand that any violation of these laws may result in the rejection of my application and potential
          legal consequences.
          <br />
          <br />
          <b style={{ fontWeight: 600 }}>Privacy and Data Protection</b>
          <br />
          • I acknowledge that SuperJetOM will handle my personal information in accordance with its privacy
          policy and applicable data protection laws. I consent to the collection, processing, and storage of my
          personal data for the purpose of visa processing.
          <br />
          <br />
          <b style={{ fontWeight: 600 }}>Changes to Application Requirements</b>
          <br />
          • I understand that visa application requirements and procedures may change without notice. I agree to
          provide any updated information or documentation as required by the embassy or government authorities.
          <br />
          <br />
          <b style={{ fontWeight: 600 }}>Communication and Notifications</b>
          <br />
          • I agree to receive communication and notifications from SuperJetOM regarding my visa application via
          the contact information provided in this form. I understand that it is my responsibility to ensure that
          my contact details are accurate and up to date.
        </p>
      </Modal> */}

      <Footer />

    </Fragment>

  );

};

export default Makepayment;