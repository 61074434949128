import React, { useState, useEffect, useRef } from "react";
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import SignatureCanvas from 'react-signature-canvas';
import { Fragment } from 'react';
import { compareDesc, format } from 'date-fns';
import Selectoption from "../Selectoption";
import Selectoptionocc from "../Selectoptionocc";
import Cookies from 'js-cookie';
import $ from 'jquery';
import { useTranslation } from 'react-i18next';

const { config } = require('../../api/api');
const TravelInbound = () => {
    const { t } = useTranslation();
    const base_url = config.baseURL;
    const redirecturl = config.redirectURL;
    const vendor_key = 'c3VwZXJqZXRfc2FuYWRfcGVyc29uYWxfYWNjaWRlbnRfdmVuZG9yX2tleQ==';
    const api_headers = {
        'Authorization': 'Bearer ' + vendor_key,
        'Content-Type': 'application/json' // Ensure you're sending JSON
    }
    const signatureRef = useRef();
    const [genderList, setGender] = useState([]);
    const [maritalStatusList, setMarital] = useState([]);
    const [relationList, setRelationList] = useState([]);
    const [occupationList, setOccupationList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [questionList, setQuestionList] = useState([]);
    const [age, setAge] = useState(null);
    const [done, setDone] = useState(false);
    const [phoneaValue, setphoneaValue] = useState('');

    const [basicPremium, setBasicPremium] = useState(null);
    const [inboundProductData, setInboundProductData] = useState(null);
    const [selectedInboundCoverageOptionId, setSelectedInboundCoverageOptionId] = useState(null);
    const [selectedInboundCoverageOption, setSelectedInboundCoverageOption] = useState(null);
    const [selectedInboundNumberOfDays, setSelectedInboundNumberOfDays] = useState(null);
    const [selectedInboundFromCountryOption, setSelectedInboundFromCountryOption] = useState(null);
    const [selectedInboundFromCountryOptionid, setSelectedInboundFromCountryOptionid] = useState(null);
    const [selectedInboundFromCountry, setSelectedInboundFromCountry] = useState(null);
  
    const [selectedInboundNationalityOption, setSelectedInboundNationalityOption] = useState(null);
    const [selectedInboundNationalityid, setSelectedInboundNationalityid] = useState(null);
    const [selectedInboundNationality, setSelectedInboundNationality] = useState(null);

    const [isInboundPersonalDeclarationChecked, setIsInboundPersonalDeclarationChecked] = React.useState(false);
    const [isInboundfinalChecked, setfinalDeclarationChecked] = React.useState(false);
    const [selectedgenderOption, setSelectedgenderOption] = useState(null);
    const [selectedgenderOptionid, setSelectedgenderOptionid] = useState(null);
    const [selectedMaritelOption, setSelectedMaritalOption] = useState(null);
    const [selectedMaritalOptionid, setSelectedMaritalOptionid] = useState(null);
  
    const today1 = new Date();
    const tomorrow = new Date(today1);
    tomorrow.setDate(today1.getDate() + 1);
    const formattedDate = tomorrow.toISOString().split('T')[0];
    const [personalInfo, setPersonalInfo] = React.useState({
        firstName: '',
        lastName: '',
        dob: '',
        email: '',
        nationality: '',
        phone: '',
        gender: '',
        maritalStatus: '',
        passportNumber: '',
      });
      const handleChange = (field, value) => {
        setPersonalInfo((prevState) => ({
          ...prevState,
          [field]: value,
        }));
      };
  
    const [selectedstartdOption, setSelectedstartdOption] = useState(formattedDate);
    const [selectedGender, setSelectedGender] = useState('Select Gender');
    const [selectedMaritalStatus, setSelectedMaritalStatus] = useState('Select Status');
    const [selectedDateofBirth, setSelectedDateofBirth] = useState(null);
    const [quotationData, setQuotationData] = useState(null);
    const [personalPolicyData, setPersonalPolicyData] = useState(null);
    const [personalPolicyPaymentLink, setPersonalPolicyPaymentLink] = useState(null);
    const [selectedOccupationCode, setSelectedOccupationCode] = useState('');
    const [selectednationality, setSelectednationality] = useState('');

    const [genderError, setGenderError] = useState("");
    const [marriedError, setMarriedError] = useState("");
    const [dobError, setDobError] = useState("");
    const [occupationError, setOccupationError] = useState("");
    const [nationalityError, setNationalityError] = useState("");
    const [totpercentage, settotpercentage] = useState();
    const [selectedCode, setSelectedCode] = useState('');// New state to store occupation CODE

    const [sanadRequesteeName, setSanadRequesteename] = useState('');
    const [sanadUserName, setSanadUserName] = useState('');
    const [sanadUserMobileNumber, setSanadUserMobileNumber] = useState('');
    const [sanadReferenceNumber, setSanadReferenceNumber] = useState('');
    const [sanadCivilIDNumber, setSanadCivilIDNumber] = useState('');
    const [sanadServiceRequestId, setSanadServiceRequestId] = useState('');
    const [sanadTransDate, setSanadTransDate] = useState('');
    const [sanadReturnUrl, setSanadReturnUrl] = useState('');
    const [sanadErrorUrl, setSanadErrorUrl] = useState('');
    const [sanadBackUrl, setSanadBackUrl] = useState('');
    const [sanadLanguage, setSanadLanguage] = useState('');
    const [isSignatureEmpty, setIsSignatureEmpty] = useState(true);
    const [beneficiarycheck, setbeneficiarycheck] = useState(false);
    const vatin_regex = /^[a-zA-Z0-9]{0,15}$/;
    const selectOpt1 = useRef(null);
    const selectnationalty = useRef(null);
    const fromcountry = useRef(null);
    const [selectedfromcountry, setSelectedromcountry] = useState('');
    const [selectedfromcountryid, setSelectedromcountryid] = useState('');
    const [selectedoccupation, setSelectedoccupation] = useState('');
    const [bmi, setBmi] = useState('0');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [modalMessageno, setModalMessageNO] = useState("");
    const [modalMessagepolicy, setModalMessagePolicy] = useState("");
    
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        mobileNumber: '',
        dob: '',
        civilIDno: '',
        passportNumber: '',
        height: '',
        weight: '',
        resident_location: '',
        nationality: '',
        vat_in: '',
        gender: '',
        is_married: '',
        expiryDateof: ''

    });

    //reset value
    const resetForm = () => {
        setIsChecked(false);
        setAge(null);
        setBasicPremium(null);
        setSelectedGender('Select Gender');
        setSelectedMaritalStatus('Select Status');
        setSelectedDateofBirth(null);
        setQuotationData(null);
        setPersonalPolicyData(null);
        // setPersonalPolicyPaymentLinkuseState(null);
        // setFormData({
        //     fullName: '',
        //     email: '',
        //     mobileNumber: '',
        //     dob: '',
        //     civilIDno: '',
        //     passportNumber: '',
        //     height: '',
        //     weight: '',
        //     occupation: '',
        //     resident_location: '',
        //     nationality: '',
        //     vat_in: '',
        //     gender: '',
        //     is_married: '',
        //     expiryDateof: ''
        // });
    };

    // new add code
    const [CoverageError, setCoverageError] = useState('');
    const [DaysError, setDaysError] = useState('');
    const [fromCountryError, setFromcountryError] = useState('');
    const [StartdateError, setStartdateError] = useState('');


    const [emailError, setEmailError] = useState('');

    const handleEmailBlur = (e) => {
        const { value } = e.target;

        // Regular expression to validate email format
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // Check if the entered email is valid
        if (!emailPattern.test(value)) {
            setEmailError('Please enter valid e-mail address');
        } else {
            setEmailError('');
        }
    };
    const [loading, setLoading] = useState(false);
    const [nameError, setNameError] = useState('');

    const handleNameBlur = (e) => {
        const { value } = e.target;

        // Regular expression for English letters and spaces
        const englishLetterPattern = /^[a-zA-Z\s]*$/;

        // Check if the name is empty or contains invalid characters
        if (value === '') {
            setNameError('Please enter First Name');
        } else if (!englishLetterPattern.test(value)) {
            setNameError('Please enter First Name, accept english letters only.');
        } else {
            setNameError('');
        }
    };
    const [lastnameError, setLastNameError] = useState('');
    const handleLastNameBlur = (e) => {
        const { value } = e.target;

        // Regular expression for English letters and spaces
        const englishLetterPattern = /^[a-zA-Z\s]*$/;

        // Check if the name is empty or contains invalid characters
        if (value === '') {
            setLastNameError('Please enter Last Name');
        } else if (!englishLetterPattern.test(value)) {
            setLastNameError('Please enter Last Name, accept english letters only.');
        } else {
            setLastNameError('');
        }
    };
    const [mobileError, setMobileError] = useState('');

    const handleMobileBlur = (e) => {
        const { value } = e.target;

        // Check if the mobile number has exactly 8 digits
        if (value.length !== 8 || !/^\d+$/.test(value)) {
            setMobileError('Please enter 8 digit phone number');
        } else {
            setMobileError('');
        }
    };

   

   
    const handleDOBBlur = (e) => {
        const { value } = e.target;
       if(age<18){
        // document.getElementsByName("dob")[0].focus();
       
       } 
    //    setDobError('');
       
        // Regular expression to allow only numbers and check length between 4 to 9 digits
      

        // Check if the Civil ID matches the pattern
       
    };

    const [passportError, setPassportError] = useState('');

    const handlePassportBlur = (e) => {
        const { value } = e.target;

        // Regular expression to allow only letters and numbers
        const passportPattern = /^[a-zA-Z0-9]*$/;

        // Check if the Passport Number matches the pattern
        if (!passportPattern.test(value) || value == '') {
            setPassportError('Please enter valid passport number, it accept numbers & letters only.');
        } else {
            setPassportError('');
        }
    };

    const [heightError, setHeightError] = useState('');

    const handleHeightBlur = (e) => {
        const { value } = e.target;

        // Convert the value to a number and check if it's a valid number and within the range
        const heightValue = Number(value);

        if (isNaN(heightValue) || heightValue < 0 || heightValue > 999 || value == '') {
            setHeightError('Please enter valid height, accept max 3 digits.');
        } else {
            setHeightError('');
        }
    };

    const [weightError, setWeightError] = useState('');

    const handleWeightBlur = (e) => {
        const { value } = e.target;

        // Convert the value to a number and check if it's a valid number and within the range
        const weightValue = Number(value);

        if (isNaN(weightValue) || weightValue < 0 || weightValue > 999 || value == '') {
            setWeightError('Please enter valid weight, accept max 3 digits.');
        } else {
            setWeightError('');
        }
    };

    const [locationError, setLocationError] = useState('');

    const handleLocationBlur = (e) => {
        const { value } = e.target;

        // Optional: Regular expression to allow only letters, spaces, commas, and hyphens
        const locationPattern = /^[a-zA-Z\s,-]+$/;

        // Check if the Residential Location is empty or invalid
        if (value === '') {
            setLocationError('Please enter your Residential Location');
        } else if (!locationPattern.test(value)) {
            setLocationError('Please enter a valid Residential Location (letters, spaces, commas, hyphens only)');
        } else {
            setLocationError('');
        }
    };

    const [vatError, setVatError] = useState('');

    const handleVatBlur = (e) => {
        const { value } = e.target;

        // Regular expression to allow only letters and numbers
        const vatPattern = /^[a-zA-Z0-9]{15}$/;

        // Check if the VATIN is exactly 15 characters long and matches the pattern
        if (!vatPattern.test(value)) {
            setVatError('Please enter a valid VATIN (15 characters, letters, and numbers only)');
        } else {
            setVatError('');
        }
    };
    const [expriydateofError, setExpriydateError] = useState('');

    const handleExpriydateBlur = (e) => {
        const { value } = e.target;


        // Check if the Residential Location is empty or invalid
        if (value === '') {
            setExpriydateError('Please Select a Expiry Date of Civil ID / Passport');
        } else {
            const selectedDate = new Date(value);
        const currentDate = new Date();

        if (selectedDate < currentDate) {
            document.getElementsByName("expiryDateof")[0].focus();
            setExpriydateError('Expiry date cannot be in the future');
        } else if (isNaN(selectedDate)) {
            document.getElementsByName("expiryDateof")[0].focus();
            setExpriydateError('Invalid Expiry Date');
        } else {
            setExpriydateError('');
        }
        }
    };



    const [isChecked, setIsChecked] = useState(false);
    const [accessTokenKey, setAccessTokenKey] = useState('');
    const [isHidePage, setIsHidePage] = useState('');

    const isPremiumVisible = selectedGender !== 'Select Gender' && selectedMaritalStatus !== 'Select Status' && selectedDateofBirth !== null;
    // const isProceedVisible = formData.fullName !== '' && formData.email !== '' && formData.mobileNumber !== '' && formData.dob !== '' && formData.civilIDno !== '' && formData.passportNumber !== '' && formData.height !== '' && formData.weight !== '' && formData.occupation !== '' && formData.resident_location !== '' && formData.nationality !== '' && formData.gender !== '' && formData.is_married !== '';
    var isProceedVisible = false;

    const handleChangedate = (e) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Remove time for accurate comparison
        const selectedDate = new Date(e.target.value);
        setSelectedstartdOption(selectedDate);
        // Only allow dates after today
        if (selectedDate > today) {
           
          
        } else {
            setDaysError("Please select a future date.");
          console.log("Selected date must be after today.");
        }
      };
      
    const [startDate, setStartDate] = useState("");

    const [minDate, setMinDate] = useState("");

  useEffect(() => {
    // Calculate tomorrow's date
    const today = new Date();
    today.setDate(today.getDate() + 1);
    const formattedDate = today.toISOString().split("T")[0]; // Format as YYYY-MM-DD
    setMinDate(formattedDate);
  }, []);

    // const handleChange1 = (name, value) => {

    //     setFormData({ ...formData, [name]: value });
    //     console.log(formData);
    // };
    const handleChange2 = (name, englishValue, codeValue) => {
        debugger
        // setFormData({ ...formData, [name]: englishValue });  // Store the English value in formData
        // setSelectedCode(codeValue);  // Store the CODE in a separate variable
        // console.log('Form Data:', formData);
        // console.log('Selected Code:', codeValue);

        setFormData({ ...formData, [name]: englishValue });  // Store the English value in formData
        setSelectedCode(codeValue);
    };
    //   const handleChange2 = (e) => {

    //     setFormData({ ...formData, [e.target.name]:formatDate( e.target.value )});
    //     console.log(formData)

    // };
    const formatDate = (date) => {
        if (!date) return '';
        const d = new Date(date);
        let day = d.getDate().toString().padStart(2, '0');
        let month = (d.getMonth() + 1).toString().padStart(2, '0');
        let year = d.getFullYear();
        return `${day}-${month}-${year}`;
    };

    // const handleChange = (e) => {
    //     const { name, value } = e.target;

    //     // Regular expression to allow only English letters and spaces
    //     const englishLetterPattern = /^[a-zA-Z\s]*$/;
    //     // Email validation pattern
    //     const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    //     // Apply the pattern only for the 'fullName' field
    //     if (name === 'fullName' && !englishLetterPattern.test(value)) {
    //         // If invalid, return early without updating the formData
    //         return;
    //     }
    //     if (name === 'email' && !emailPattern.test(value)) {

    //         console.log("Invalid email format");
    //         // Optionally, set an error state here if you want to show a message to the user
    //     }

    //     // Update formData for valid input
    //     setFormData({ ...formData, [name]: value });
    // };
    useEffect(() => {
        if (isModalOpen) {
            // Prevent background scroll
            document.body.style.overflow = "hidden";
        } else {
            // Restore background scroll
            document.body.style.overflow = "auto";
        }
        return () => {
            // Cleanup in case of unmounting
            document.body.style.overflow = "auto";
        };
    }, [isModalOpen]);

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);
    };
    const today = new Date();
    const maxDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate() + 1).toISOString().split('T')[0];


    const [dobValue, setdobValue] = useState('');

    const handledobChange = (event) => {
        const selectedDob = event.target.value;
        setdobValue(event.target.value);

        const dobDate = new Date(selectedDob);
        const today = new Date();
        let calculatedAge = today.getFullYear() - dobDate.getFullYear();
        const monthDiff = today.getMonth() - dobDate.getMonth();

        // Adjust age if the current date is before the user's birthday in the current year
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dobDate.getDate())) {
            calculatedAge--;
        }

        setAge(calculatedAge);
    };
    const validateQuestions = () => {
        const uncheckedQuestions = questionsData.filter((question) => question.isChecked === undefined);
        return uncheckedQuestions;
    };

    // const [formRows, setFormRows] = useState([{
    //     first_name: '',
    //     last_name: '',

    // }]);
    const [beneficiaryData, setBeneficiaryData] = useState([{
        name: '',
        relation: '',
        benefit_percentage: '',
    }]);
    //new add code
    const handleAddRownew = () => {
        let allFieldsFilled = true;

        // Loop through the beneficiaryData to check if all required fields are filled
        for (let i = 0; i < beneficiaryData.length; i++) {
            const { name, relation, benefit_percentage } = beneficiaryData[i];

            // Check if the name or relation is empty
            if (!name || !relation) {
                allFieldsFilled = false;
                alert(`Please fill all fields for Beneficiary ${i + 1}`);
                break;
            }

            // If benefit_percentage field exists, check if it is empty
            if (beneficiaryData.length > 1 && !benefit_percentage) {
                allFieldsFilled = false;
                alert(`Please fill the Benefit Percentage for Beneficiary ${i + 1}`);
                break;
            }
        }

        // If all fields are filled, allow adding a new beneficiary
        if (allFieldsFilled) {
            // Logic to add a new row
            const newBeneficiary = { name: '', relation: '', benefit_percentage: '' };
            setBeneficiaryData([...beneficiaryData, newBeneficiary]);
        }
    };

    const [questionsData, setQuestionsData] = useState([{
        english: '',
        arabic: '',
        key: '',
        accepted_answer: '',
        isChecked: '',
        isChecked_answer: '',
        details: ''
    }]);
    // const handleRemoveRow = (index) => {
    //     // const rows = [...formRows];
    //     // rows.splice(index, 1);
    //     // setFormRows(rows);
    //     const rows = [...beneficiaryData];
    //     rows.splice(index, 1);
    //     setBeneficiaryData(rows);
    // };
    const handleRemoveRow = (index) => {
        debugger
        let totalBenefitPercentage = 0;
        const rows = [...beneficiaryData];
        rows.splice(index, 1);
        for (let i = 0; i < rows.length; i++) {
            if (rows[i].benefit_percentage !== '') {
                totalBenefitPercentage += parseInt(rows[i].benefit_percentage);
            }
        }
        settotpercentage(totalBenefitPercentage);
        console.log("Total Benefit Percentage:", totalBenefitPercentage);
        setBeneficiaryData(rows);
    };

    const handleAddRow = () => {
        debugger
        // setFormRows([...formRows, {
        //     first_name: '',
        //     last_name: '',

        // }]);
        setBeneficiaryData([...beneficiaryData, {
            name: '',
            relation: '',
            benefit_percentage: '',

        }]);
    };
    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const [selectedValue, setSelectedValue] = useState('Yes');

    const handleChanges = (e) => {
        setSelectedValue(e.target.value);
    };
    function handleRadioChange(index, isChecked) {
        const updatedQuestionsData = [...questionsData];
        updatedQuestionsData[index] = {
            ...updatedQuestionsData[index],
            isChecked,
            isChecked_answer: isChecked ? "Yes" : "No"
        };
        setQuestionsData(updatedQuestionsData);
    }

    
    useEffect(async () => {
        debugger
        // update sanad data in superjet
        const urlSearchString = window.location.search;
        const uparams = new URLSearchParams(urlSearchString);
        const sanadUserName = uparams.get('SANADUSERNAME');
        const sanadRequesteename = uparams.get('REQUESTEENAME');
        const sanadUserMobileNumber = uparams.get('REQUESTEEMOBILENO');
        const sanadReferenceNumber = uparams.get('SANADREFNUMBER');
        const sanadCivilIDNumber = uparams.get('REQUESTEECIVILNO');
        const sanadAccessToken = uparams.get('SANADACCESSTOKEN');
        const sanad_ServiceRequestId = uparams.get('SERVICEREQUESTID');
        const sanad_TransDate = uparams.get('TRANSDATE');
        const sanad_ReturnUrl = uparams.get('RETURNURL');
        const sanad_ErrorUrl = uparams.get('ERRORURL');
        const sanad_BackUrl = uparams.get('BACKURL');
        const sanad_Language = uparams.get('LANGUAGE');
        setSanadUserName(sanadUserName);
        setSanadRequesteename(sanadRequesteename); 
        setSanadUserMobileNumber(sanadUserMobileNumber);
        setSanadReferenceNumber(sanadReferenceNumber);
        setSanadCivilIDNumber(sanadCivilIDNumber);
        setSanadServiceRequestId(sanad_ServiceRequestId);
        setSanadTransDate(sanad_TransDate);
        setSanadReturnUrl(sanad_ReturnUrl);
        setSanadErrorUrl(sanad_ErrorUrl);
        setSanadBackUrl(sanad_BackUrl);
        setSanadLanguage(sanad_Language);
        // const response = await fetch(base_url + '/verify_sanad_access_token', {
        //     method: 'POST',
        //     headers: api_headers,
        //     body: JSON.stringify({
        //         sanad_access_token: sanadAccessToken // Serializing the body as JSON
        //     })
        // });
        // const data = await response.ok ? await response.json() : Promise.reject('Network response was not ok');

        // debugger
        // if (data.message === 'success') {
        //     setIsHidePage('success');
        // } else {
        //     setIsHidePage('fail');
        // }

        if (sanadAccessToken === "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImFkbWluQHNhbmFkLmNvbSIsInBhc3N3b3JkIjoiMTIzNCIsImlhdCI6MTcyODA1MjA0NywiZXhwIjoxNzI4MDUyOTQ3fQ.ucyIy6q6ovSDstXZL1zO-9swTAGYFeypV0uo8_rQJJc") {
            setIsHidePage('success');
        } else {
            setIsHidePage('fail');
        }
        var sanad_urls_data = {
            'back_url': sanad_BackUrl,
            'return_url': sanad_ReturnUrl,
            'error_url': sanad_ErrorUrl,
        }
        //    sessionStorage.setItem("sanadUrlData", JSON.stringify(sanad_urls_data));
        const expiryDate = new Date();
        expiryDate.setTime(expiryDate.getTime() + (1 * 60 * 60 * 1000));
        Cookies.set('sanadUrlData', JSON.stringify(sanad_urls_data), { expires: expiryDate });
       setPersonalInfo({
            firstName: sanadRequesteename,
            lastName: '',
            dob: '',
            email: '',
            nationality: '',
            phone: sanadUserMobileNumber,
            gender: '',
            maritalStatus: '',
            passportNumber: '',
          });
        // setFormData({
        //     fullName: sanadRequesteename,
        //     email: '',
        //     mobileNumber: sanadUserMobileNumber,
        //     dob: '',
        //     civilIDno: sanadCivilIDNumber,
        //     passportNumber: '',
        //     height: '',
        //     weight: '',
        //     resident_location: '',
        //     nationality: '',
        //     vat_in: '',
        //     gender: '',
        //     is_married: '',
        //     expiryDateof: ''
        // });
    }, []);

    // useEffect(() => {
    //     //debugger
    //     // Initialize Select2
    //     $(selectOpt1.current).select2();

    //     // Handle Select2 change event
    //     $(selectOpt1.current).on('select2:select', (e) => {
    //         //debugger
    //         // Find the selected option by matching the code (value)
    //         const selectedOption = occupationList.find(option => option.CODE === e.params.data.id);
    //         debugger
    //         setOccupationError(""); // Clear the error if valid

    //         setSelectedCode(e.params?.data?.id?.toString());
    //         setSelectedoccupation(e.params?.data?.text?.split('-')[0]?.trim());
    //         // setFormData({ ...formData, ['occupation']: e.params?.data?.text?.split('-')[0]?.trim()});
    //         // setFormData({ ...formData, [name]: value });

    //         // Call onChange with both the English name and the code
    //         // if (selectedOption) {
    //         //   //debugger
    //         //   onChange(name, selectedOption.ENGLISH, selectedOption.CODE);  // Pass both ENGLISH and CODE
    //         // }
    //     });
        

    //     // Clean up Select2 instance on unmount
    //     // return () => {
    //     //     $(selectOpt1.current).select2('destroy');
    //     // };
    // }, [occupationList]);
    useEffect(() => {
        //debugger
        // Initialize Select2
        $(selectnationalty.current).select2();

        // Handle Select2 change event
        $(selectnationalty.current).on('select2:select', (e) => {
            //debugger
            // Find the selected option by matching the code (value)
            const selectedOption_nat = countryList.find(option => option===e.params.data.id);
            debugger
         setNationalityError("");
            
        setFormData(prevFormData => ({
            ...prevFormData,
            nationality: selectedOption_nat,
        }));
            
        });
    
    }, [countryList]);
    useEffect(() => {
        //debugger
        // Initialize Select2
        $(fromcountry.current).select2();

        // Handle Select2 change event
        $(fromcountry.current).on('select2:select', async (e) => {
            //debugger
            // Find the selected option by matching the code (value)
            const value = countryList.find(option => option===e.params.data.id);
            const [description, id] = value.split("#");
            setSelectedInboundFromCountryOptionid(id);
            setSelectedInboundFromCountry(description);
            setSelectedInboundFromCountryOption(value);
            handleChange('nationality', description);
            setSelectedInboundNationalityid(id);
            setSelectedInboundNationalityOption(value);
            setSelectedInboundNationality(description);
            // setDone(false);
            // SetshowPlan(false);
            setFromcountryError("");
            const cnData = { country: description };

            await fetch(base_url + '/get_country_code', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(cnData),
            })
              .then(response => response.json())
              .then(data => {
                //  

                const cde = data.result.replace(/\+/g, '');

                setphoneaValue(cde);

              })
              .catch(error => {
                console.error('Error posting data:', error);
              });
            
        });
    
    }, [countryList]);
    const parseDate = (dateString) => {
        const [day, month, year] = dateString.split('-').map(Number);
        return new Date(year, month - 1, day); // month is 0-indexed
    };
    const calculateAge = (dob) => {
        const today = new Date();
        const birthDate = parseDate(dob);

        // Check if birthDate is valid
        if (isNaN(birthDate.getTime())) {
            throw new Error('Invalid date');
        }

        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();

        // Adjust age if the birth date hasn't occurred yet this year
        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        return age;
    };
    useEffect(async () => {

        const fetchData = async () => {
            try {
                var accessTokenData = await createAccessToken();
                var accessToken = accessTokenData?.result?.data?.token;
                if (accessToken != null && accessToken != undefined) {
                    document.getElementsByName("travelplan")[0].focus();
                    setAccessTokenKey(accessToken);
                    await getProductLists(accessToken);
                    await getBasicPremiumPlan(accessToken);
                    await getOccupationList(accessToken);
                    await getCountryList(accessToken);
                    await getQuestionList(accessToken);
                    // debugger
                    // console.log('accessTokenData', accessTokenData)
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchData();
    }, []);


    const createAccessToken = async () => {
        try {
            const response = await fetch(base_url + '/create_sanad_personal_accident_token', {
                method: 'GET',
                headers: api_headers
            });
            const data = await response.ok ? await response.json() : Promise.reject('Network response was not ok');
            if (data.message === 'success') {
                return data;
            } else {
                return null;
            }
        } catch (error) {
            return null;
        }
    };
    const getProductLists = async (accessToken) => {
        try {
            // debugger
            const response = await fetch(base_url + '/getProducts', {
                method: 'POST',
                headers: api_headers,
                body: JSON.stringify({
                    token: accessToken // Serializing the body as JSON
                })
            });
            const inbound_product_response_data = await response.ok ? await response.json() : Promise.reject('Network response was not ok');
            if (inbound_product_response_data.message === 'success') {
                if (inbound_product_response_data?.result?.data[0]) {
                    setInboundProductData(inbound_product_response_data.result.data[0]);
                  }
            } else {
                setInboundProductData('');
            }
        } catch (error) {
            setInboundProductData('');
        }
    };
    const getBasicPremiumPlan = async (accessToken) => {
        try {
            const response = await fetch(base_url + '/getGender', {
                method: 'POST',
                headers: api_headers,
                body: JSON.stringify({
                    token: accessToken // Serializing the body as JSON
                })
            });
            const inbound_gender_response_data = await response.ok ? await response.json() : Promise.reject('Network response was not ok');
            if (inbound_gender_response_data?.result?.data.response_data) {
                setGender(inbound_gender_response_data.result.data.response_data);
              }else {
                setGender([]);
            }
        } catch (error) {
            setGender([]);
            // return null;
        }
    };
    const getOccupationList = async (accessToken) => {
        try {

            const response = await fetch(base_url + '/getMaritalStatus', {
                method: 'POST',
                headers: api_headers,
                body: JSON.stringify({
                    token: accessToken // Serializing the body as JSON
                })
            });
            const inbound_marital_response_data = await response.ok ? await response.json() : Promise.reject('Network response was not ok');
            if (inbound_marital_response_data?.result?.data.response_data) {
                setMarital(inbound_marital_response_data.result.data.response_data);
              } else {
                setMarital([]);
            }
        } catch (error) {
            setMarital([]);
            // return null;
        }
    };
    const getCountryList = async (accessToken) => {
        try {
            const response = await fetch(base_url + '/get_sanad_personal_accident_countries', {
                method: 'POST',
                headers: api_headers,
                body: JSON.stringify({
                    token: accessToken // Serializing the body as JSON
                })
            });
            const data = await response.ok ? await response.json() : Promise.reject('Network response was not ok');
            if (data.message === 'success') {
                setCountryList(data.result.data);
                // setRelationList(data?.result?.data);
            } else {
                setCountryList(null);
            }
        } catch (error) {
            setCountryList(null);
            // return null;
        }
    };
    const getQuestionList = async (accessToken) => {
        try {
            const response = await fetch(base_url + '/get_sanad_personal_accident_questions', {
                method: 'POST',
                headers: api_headers,
                body: JSON.stringify({
                    token: accessToken // Serializing the body as JSON
                })
            });
            const data = await response.ok ? await response.json() : Promise.reject('Network response was not ok');
            if (data.message === 'success') {
                setQuestionList(data.result.data);
                var questionsLists = data.result.data;

                questionsLists = questionsLists.map(question => ({
                    english: question.English,
                    arabic: question.Arabic,
                    key: question.key,
                    accepted_answer: question.accepted_answer,
                    isChecked: '',
                    details: '',
                    isChecked_answer: ''
                }));
                setQuestionsData(questionsLists);
                // setRelationList(data?.result?.data);
            } else {
                setQuestionList(null);
            }
        } catch (error) {
            setQuestionList(null);
            // return null;
        }
    };
    const getQuotation = async (accessToken) => {
        try {
            debugger
            setLoading(true);
            if (beneficiaryData.length == 1) {
                beneficiaryData[0].benefit_percentage = '100';
                setBeneficiaryData(beneficiaryData);
            }

            var quotation_data =
            {
                "token": accessToken,
                "cover_type": "Personal Accident",
                "application_type": "self",
                "plan": "Personal Accident",
                "nationality": formData.nationality,
                "civil_id": formData.civilIDno,
                "sum_assured": basicPremium.sum_assured,
                "personalInformation": {
                    "fullName": formData.fullName,
                    "email": formData.email,
                    "mobileNumber": formData.mobileNumber,
                    "dob": formData.dob,
                    "resident_location": formData.resident_location,
                    "gender": formData.gender.toLowerCase(),
                    "is_married": formData.is_married.toLowerCase(),
                    "height": formData.height.toString(),
                    "weight": formData.weight.toString(),
                    "occupation": selectedoccupation,
                    "passport": formData.passportNumber,
                    "vat_in": formData.vat_in,
                },
                "beneficiaries": beneficiaryData
            };
            const response = await fetch(base_url + '/get_sanad_personal_accident_quotation', {
                method: 'POST',
                headers: api_headers,
                body: JSON.stringify(quotation_data)
            });
            const data = await response.ok ? await response.json() : Promise.reject('Network response was not ok');
            if (data.message === 'success') {
                setQuotationData(data?.result?.data?.amount);
                await addPersonalPolicy(accessToken, data?.result?.data?.amount);
                // setCountryList(data.result.data);
                // setRelationList(data?.result?.data);
            } else {
                setQuotationData(null);
            }
        } catch (error) {
            setQuotationData(null);
            // return null;
        }
    };
    const addPersonalPolicy = async (accessToken, amount_details) => {
        try {
            var policy_data =
            {
                "token": accessToken,
                "sanad_username": sanadUserName,
                "sanad_requesteename": sanadRequesteeName,
                "sanad_reference_no": sanadReferenceNumber,
                "sanad_civil_no": sanadCivilIDNumber,
                "sanad_passport_no": formData.passportNumber,
                "sanad_service_requestid":sanadServiceRequestId,
                "sanad_data": {
                    "sanadUserName":sanadUserName,
                    "sanad_requesteename": sanadRequesteeName,
                    "sanadUserMobileNumber": sanadUserMobileNumber,
                    "sanadReferenceNumber": sanadReferenceNumber,
                    "sanadCivilIDNumber": sanadCivilIDNumber,
                    "sanad_ServiceRequestId": sanadServiceRequestId,
                    "sanad_TransDate": sanadTransDate,
                    "sanad_ReturnUrl": sanadReturnUrl,
                    "sanad_ErrorUrl":sanadErrorUrl,
                    "sanad_BackUrl":sanadBackUrl,
                    "sanad_Language": sanadLanguage,
                    "sanad_passport_no": formData.passportNumber
                },
                "policy_data": {
                    "cover_type": "Personal Accident",
                    "application_type": "self",
                    "plan": "Personal Accident",
                    "nationality": formData.nationality,
                    "civil_id": formData.civilIDno,
                    "sum_assured": basicPremium.sum_assured,
                    "personalInformation": {
                        "fullName": formData.fullName,
                        "email": formData.email,
                        "mobileNumber": formData.mobileNumber,
                        "dob": formData.dob,
                        "resident_location": formData.resident_location,
                        "gender": formData.gender.toLowerCase(),
                        "is_married": formData.is_married.toLowerCase(),
                        "height": formData.height.toString(),
                        "weight": formData.weight.toString(),
                        "occupation": selectedoccupation,
                        "occupation_code": selectedCode,
                        "id_expiry_date": formatDate(formData.expiryDateof),
                        // "id_expiry_date":formData.expiryDateof,
                        "passport": formData.passportNumber,
                        "vat_in": formData.vat_in,
                    },
                    "beneficiaries": beneficiaryData,
                    "amount": amount_details,
                    "suffer_from_impairment_of_sight": "No",
                    "is_healthy": "Yes",
                    "practise_acitivty": "No",
                    "have_intention_leaving_country": "No",
                    "have_hazardous_cricumstances": "No",
                    "have_any_proposal_declined": "No",
                    "have_pa_declined": "No",
                    "have_pa_policy": "No",
                    "have_claimed_compensation": "No",
                    "signature": signatureRef.current.toDataURL()
                }
            };
            debugger
            const response = await fetch(base_url + '/get_sanad_personal_accident_add_policy', {
                method: 'POST',
                headers: api_headers,
                body: JSON.stringify(policy_data)
            });
            const data = await response.ok ? await response.json() : Promise.reject('Network response was not ok');
            if(data.message === 'success'&&data.result==='sanad reference number already exist'){
                setLoading(false);
                alert('Sanad reference number already exists. Please create a new reference number.\nالرقم المرجعي لسند موجود بالفعل. الرجاء إنشاء رقم مرجعي جديد.');
            }else if (data.message === 'success') {
                setLoading(false);
                if (data?.result?.policy_number != null && data?.result?.policy_number != undefined) {
                    setDone(true);
                    setPersonalPolicyData(data?.result?.policy_number);
                    await createPolicyPaymentLink(accessToken, data?.result?.policy_number);
                } else {
                    if (data?.result?.data?.decision_reasons != undefined && data?.result?.data?.decision_reasons[0] != undefined) {
                        // alert(data?.result?.data?.decision_reasons[0]);
                        setDone(true);
                        setModalMessage(data?.result?.data?.decision_reasons[0]);
                        setModalMessageNO(data?.result?.data?.referal_quote_id);
                        setModalMessagePolicy(data?.result?.message);
                         setIsModalOpen(true);
                    }

                }

                // setCountryList(data.result.data);
                // setRelationList(data?.result?.data);
            } else {
                setPersonalPolicyData(null);
            }
        } catch (error) {
            setPersonalPolicyData(null);
            // return null;
        }
    };
    const createPolicyPaymentLink = async (accessToken, policy_no) => {
        try {
            var policy_data =
            {
                "token": accessToken,
                "policy_no": policy_no,
                "redirect_url":  redirecturl+"/download-sanad-policy?policyno="+policy_no
               
                
            }
            const response = await fetch(base_url + '/create_sanad_personal_accident_payment_link', {
                method: 'POST',
                headers: api_headers,
                body: JSON.stringify(policy_data)
            });
            const data = await response.ok ? await response.json() : Promise.reject('Network response was not ok');
            if (data.message === 'success') {
                setPersonalPolicyPaymentLink(data?.result?.data.payment_url);
                // setCountryList(data.result.data);
                // setRelationList(data?.result?.data);
            } else {
                setPersonalPolicyPaymentLink(null);
            }
        } catch (error) {
            setPersonalPolicyPaymentLink(null);
            // return null;
        }
    };
    const clearSignature = () => {
        signatureRef.current.clear();
        setIsSignatureEmpty(true); // Disable button after clearing
    };
    const handleSignature = () => {
        if (!signatureRef.current.isEmpty()) {
            setIsSignatureEmpty(false); // Enable button when signature is drawn
        }
    };

    const calculateBmi = (weight, height) => {
        const w = parseFloat(weight);
        const h = parseFloat(height) / 100; // Convert height from cm to meters
    
        if (isNaN(w) || isNaN(h) || h <= 0 || w <= 0) {
        //   setError('Please enter valid height and weight.');
          setBmi('');
          return false;
        }
    
        const bmiValue = (w / (h ** 2)).toFixed(2);
    
        // Adjust the conditions to properly check the BMI ranges
        debugger
        if (bmiValue > 19 && bmiValue < 30) {
          setBmi(bmiValue);
        //   setError('');
        return true
        } else {
            setBmi(bmiValue);
            return false
        //   setError('BMI is within the normal range (19-30).');
          
        }
      };
    //   const handleKeyDown = (event) => {
    //     if (event.key === 'Enter') {
    //       event.preventDefault(); // Prevent default behavior
    //       alert('The Enter key is disabled for this field. Please complete your input and use Tab or Submit.');
    //     }
    //   };

    // const handleFocus = () => {
    //     if (age < 18 && age!==null) {
    //       alert("Age is less than 18");
    //       setAge(null);
    //     }
    //   };

    // var today = new Date();

    // // Add one day
    // const tomorrow = new Date(today);
    // tomorrow.setDate(today.getDate() + 1);

    // // Format the date as YYYY-MM-DD
    // const formattedDate = tomorrow.toISOString().split('T')[0];
     
    return (
        isHidePage === 'success' ? <Fragment>
            <form onSubmit={handleSubmit} onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    e.preventDefault(); // Prevent Enter key from submitting the form
                }
            }} className="visa-form1">
                    <div style={{ display: 'flex', paddingBottom: '20px' }}>
                        <button style={{ width: '100px', fontWeight: 'bold' }} onClick={() => {
                            const regex = /^(ftp|http|https):\/\/[^ "]+$/;
                            var isValidUrl = regex.test(sanadBackUrl);
                            if (isValidUrl) {
                                window.location.href = sanadBackUrl; // Open in the same tab
                            } else {
                                window.location.reload();
                            }

                        }} 
                        // onKeyDown={(e) => {
                        //     if (e.key === 'Enter') {
                        //         e.preventDefault(); // Prevent default Enter key action
                        //     }
                        // }}
                        >Back</button>
                    </div>

                <div className="">
                    <div className='row mb-2'>
                        <div className='col-md-6 text-start'>
                            <h2>Inbound Travel</h2>
                        </div>
                        <div className='col-md-6 text-right'>
                            <h2>السفر الداخلي</h2>
                        </div>
                    </div>

                    <div className='inside_visa-form'>
                        <div className='row mb-4'>
                            <div className='col-md-4'>
                                {/* <label>Full Name  الاسم الكامل <span style={{ color: 'red' }}>*</span></label> */}
                                <label style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <span>
                                    Product<span style={{ color: "red" }}>*</span>
                                    </span>
                                    <span style={{ textAlign: "right", direction: "rtl" }}>
                                    حدد المنتج
                                    </span>
                                </label>
                                <input
                                    type='text'
                                    className='form-control'
                                    value='DOI - Oman Inbound Product'
                                    name='travelplan'
                                    readOnly
                                    />
                                 </div>


                                 <div className="col-md-4">
                                <label
                                    style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    }}
                                >
                                    <span>
                                    Coverage Selection<span style={{ color: "red" }}>*</span>
                                    </span>
                                    <span style={{ textAlign: "right", direction: "rtl" }}>
                                    اختيار التغطية
                                    </span>
                                </label>
                                <select
                                    className="form-control"
                                    onChange={(event) => {
                                        debugger
                                    const selectedOption = inboundProductData?.packages.find(
                                        (option) =>
                                        option.coverageArea.id.toString() === event.target.value.toString()
                                    );

                                    if (selectedOption) {
                                        setSelectedInboundCoverageOptionId(selectedOption.coverageArea.id);
                                        setSelectedInboundCoverageOption(selectedOption.coverageArea.name);
                                        // Clear error if a valid option is selected
                                        // setCoverageError(""); 
                                    } else {
                                        setSelectedInboundCoverageOptionId(null);
                                        setSelectedInboundCoverageOption("");
                                    }
                                    }}
                                    value={selectedInboundCoverageOptionId}
                                    name="coverageselection"
                                >
                                    <option value="">{'-- Select Coverage --'}</option>
                                    {inboundProductData?.packages.map((option) => (
                                    <option key={option.coverageArea.id} value={option.coverageArea.id}>
                                        {option.coverageArea.name}
                                    </option>
                                    ))}
                                </select>
                                {CoverageError && <p style={{ color: "red" }}>{CoverageError}</p>} {/* Show error message */}
                                </div>



                            <div className='col-md-4'>
                                {/* <label>Full Name  الاسم الكامل <span style={{ color: 'red' }}>*</span></label> */}
                                <label style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <span>
                                    Number Of Days<span style={{ color: "red" }}>*</span>
                                    </span>
                                    <span style={{ textAlign: "right", direction: "rtl" }}>
                                    عدد الأيام
                                    </span>
                                </label>
                               { selectedInboundCoverageOption!='Single Entry'?<select value={selectedInboundNumberOfDays}
                    // disabled={isFormFrozen}
                    onChange={(event) => {
                      setSelectedInboundNumberOfDays(event.target.value);
                     
                    }}
                    name="numberofdays" className="form-control">
                                    <option>--select--</option>
                                    <option value='30'>30</option>
                                    <option value='60'>60</option>
                                    <option value='90'>90</option>
                                    <option value='120'>120</option>
                                </select>:<select  className="form-control">
                                    <option>--select--</option>
                                    <option value='30'>30</option>
                                    <option value='60'>60</option>
                                    <option value='90'>90</option>
                                </select>}
                                {DaysError && <p style={{ color: 'red' }}>{DaysError}</p>} {/* Show error message */}
                            </div>
                        </div>
                        <div className="row mb-4">
                        <div className='col-md-4'>
                                {/* <label>Nationality <span style={{ color: 'red' }}>*</span></label> */}
                                {/* <Selectoption name="nationality" options={countryList} value={formData.nationality} onChange={handleChange1} className='form-control ' required /> */}
                                <label style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <span>
                                    Select From Country <span style={{ color: "red" }}>*</span>
                                    </span>
                                    <span style={{ textAlign: "right", direction: "rtl" }}>
                                    جنسية
                                    </span>
                                </label>
                                <select
                                    ref={fromcountry}
                                    className="form-control"
                                    style={{ borderRadius: '5px' }}
                                // onChange={handleChange2}
                                // Add your desired height and border-radius here
                                >
                                    <option>{`${t('Select From Country')}`}</option>
                                    {
                                countryList
                                    .filter((option) => option.toLowerCase() !== 'oman') // Exclude "Oman"
                                    .map((option) => (
                                    <option key={option} value={option}>
                                        {option.charAt(0).toUpperCase() + option.slice(1)}
                                    </option>
                                    ))
                                }


                                </select>
                                { fromCountryError&& <p style={{ color: 'red' }}>{fromCountryError}</p>} {/* Show error message */}


                            </div>

                                <div className='col-md-4'>
                                {/* <label>Full Name  الاسم الكامل <span style={{ color: 'red' }}>*</span></label> */}
                                <label style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <span>
                                    Start Date<span style={{ color: "red" }}>*</span>
                                    </span>
                                    <span style={{ textAlign: "right", direction: "rtl" }}>
                                    تاريخ البدء
                                    </span>
                                </label>
                                
                                <input
                                type="date"
                                name="startdate"
                                className="form-control"
                                onChange={handleChangedate}
                                style={{ padding: 7 }}
                                min={minDate} // Dynamically set the min date
                                value={selectedstartdOption}
                                />
                                {StartdateError && <p style={{ color: 'red' }}>{StartdateError}</p>} {/* Show error message */}
                            </div>
                        </div>    
                    </div>
                </div>


                <div className="">
                    {/* <h2>Personal Details</h2> */}
                    <div className='row mb-3 mt-4'>
                        <div className='col-md-6 text-start'>
                            <h2>Personal Details</h2>
                        </div>
                        <div className='col-md-6 text-right'>
                            <h2>التفاصيل الشخصية    </h2>
                        </div>
                    </div>

                    <div className='inside_visa-form'>
                        {/* Row 1: Full Name, Email ID, Mobile Number, and Civil ID Number */}
                        <div className='row mb-4'>
                            <div className='col-md-4'>
                                {/* <label>Full Name  الاسم الكامل <span style={{ color: 'red' }}>*</span></label> */}
                                <label style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <span>
                                    First Name<span style={{ color: "red" }}>*</span>
                                    </span>
                                    <span style={{ textAlign: "right", direction: "rtl" }}>
                                    الاسم الأول
                                    </span>
                                </label>
                                <input
                                    type='text'
                                    className='form-control'
                                    value={personalInfo.firstName}
                                    name='firstName'
                                    onChange={(e)=>{handleChange('firstName', e.target.value)}}
                                    onBlur={handleNameBlur}
                                    onInput={(e) => {
                                        e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, '');
                                      }} // Validate on blur (when user leaves the field)
                                />
                                {nameError && <p style={{ color: 'red' }}>{nameError}</p>} {/* Show error message */}
                            </div>
                            {/* <div className='col-md-3'>
                            <label>Full Name:</label>
                            <input
                                type='text'
                                className='form-control'
                                value={formData.fullName}
                                name='fullName'
                                onChange={handleChange}
                            />
                        </div> */}
                            <div className='col-md-4'>
                                {/* <label>Email ID <span style={{ color: 'red' }}>*</span></label> */}
                                <label style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <span>
                                    Last Name <span style={{ color: "red" }}>*</span>
                                    </span>
                                    <span style={{ textAlign: "right", direction: "rtl" }}>
                                    اسم العائلة
                                    </span>
                                </label>
                                <input
                                    type='text'
                                    className='form-control'
                                    name='lastName'
                                    value={personalInfo.lastName}
                                    onChange={(e)=>{handleChange('lastName', e.target.value)}}
                                    onBlur={handleLastNameBlur} // Validate on blur (when user leaves the field)
                                />
                                {lastnameError && <p style={{ color: 'red' }}>{lastnameError}</p>} {/* Show error message */}
                            </div>

                            <div className='col-md-4'>
                                {/* <label>Mobile Number <span style={{ color: 'red' }}>*</span></label> */}
                                <label style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <span>
                                    DOB <span style={{ color: "red" }}>*</span>
                                    </span>
                                    <span style={{ textAlign: "right", direction: "rtl" }}>
                                    تاريخ الميلاد
                                    </span>
                                </label>
                                <input
                                    type='date'
                                    className='form-control'
                                    name='dob'
                                    value={personalInfo.dob}
                                    onChange={(e)=>{handleChange('dob', e.target.value)}}
                                    maxLength={8} // Enforce max length in the UI
                                    onBlur={handleDOBBlur} // Validate on blur (when user leaves the field)
                                />
                                {dobError && <p style={{ color: 'red' }}>{dobError}</p>} {/* Show error message */}
                            </div>
                        </div>

                        {/* Row 2: Passport Number, Height, Weight, and Nationality */}
                        <div className='row mb-4'>

                            <div className='col-md-4'>
                                {/* <label>Civil ID Number <span style={{ color: 'red' }}>*</span></label> */}
                                <label style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <span>
                                    Email<span style={{ color: "red" }}>*</span>
                                    </span>
                                    <span style={{ textAlign: "right", direction: "rtl" }}>
                                    بريد إلكتروني
                                    </span>
                                </label>
                                <input
                                    type='text'
                                    className='form-control'
                                    name='email'
                                    value={personalInfo.email}
                                    onChange={(e)=>{handleChange('email', e.target.value)}}
                                    onBlur={handleEmailBlur} // Validate on blur (when user leaves the field)
                                />
                                {emailError && <p style={{ color: 'red' }}>{emailError}</p>} {/* Show error message */}
                            </div>

                            <div className='col-md-4'>
                                {/* <label>Nationality <span style={{ color: 'red' }}>*</span></label> */}
                                {/* <Selectoption name="nationality" options={countryList} value={formData.nationality} onChange={handleChange1} className='form-control ' required /> */}
                                <label style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <span>
                                    Nationality <span style={{ color: "red" }}>*</span>
                                    </span>
                                    <span style={{ textAlign: "right", direction: "rtl" }}>
                                    جنسية
                                    </span>
                                </label>
                                <select
                                    ref={selectnationalty}
                                    className="form-control"
                                    style={{ borderRadius: '5px' }}
                                // onChange={handleChange2}
                                // Add your desired height and border-radius here
                                >
                                    <option>{`${t('Select Nationality')}`}</option>
                                    {
                                          countryList.map((option) => (
                                            <option key={option} value={option}>
                                              {option.charAt(0).toUpperCase() + option.slice(1)}
                                            </option>
                                          ))
                                    }

                                </select>
                                {nationalityError && <p style={{ color: 'red' }}>{nationalityError}</p>} {/* Show error message */}


                            </div>

                            <div className='col-md-4'>
                                {/* <label>Height (in cms) <span style={{ color: 'red' }}>*</span></label> */}
                                <label style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <span>
                                    Phone <span style={{ color: "red" }}>*</span>
                                    </span>
                                    <span style={{ textAlign: "right", direction: "rtl" }}>
                                    هاتف
                                    </span>
                                </label>
                                <input
                                    type='number'
                                    className='form-control'
                                    name='phone'
                                    value={personalInfo.phone}
                                    // onChange={handleChange}
                                    onChange={(e)=>{handleChange('phone', e.target.value)}}
                                    onBlur={handleMobileBlur} // Validate on blur (when user leaves the field)
                                 

                                />
                                {mobileError && <p style={{ color: 'red' }}>{mobileError}</p>} {/* Show error message */}
                            </div>

                            
                        </div>

                        {/* Row 3: Residential Location, Expiry Date, VATIN, and an Empty Placeholder */}
                        <div className='row mb-4'>
                            <div className='col-md-4'>
                                {/* <label>Weight (in kgs) <span style={{ color: 'red' }}>*</span></label> */}
                                <label style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <span>
                                    Gender <span style={{ color: "red" }}>*</span>
                                    </span>
                                    <span style={{ textAlign: "right", direction: "rtl" }}>
                                    جنس
                                    </span>
                                </label>
                                <select name="gender_value" className="form-control " style={{ direction: 'ltr' }}
                                            onChange={(e) => {
                                                debugger
                                                setGenderError("");
                                                setSelectedgenderOptionid(e.target.options[e.target.selectedIndex].id);
                                                setSelectedgenderOption(e.target.value);
                                                handleChange('gender', e.target.value);
                                            }}
                                            value={selectedGender}
                                            required>
                                             <option value="">{'-- Select --'}</option>
                                            {genderList && genderList.map((opt) =>
                                                <option key={opt.id} id={opt.id} value={opt.description}>{t(opt.description)}</option>
                                            )}

                                        </select>
                                        {genderError && <p style={{ color: 'red' }}>{genderError}</p>} {/* Show error message */}
                            </div>

                            <div className='col-md-4'>
                                {/* <label>Nationality <span style={{ color: 'red' }}>*</span></label> */}
                                {/* <Selectoption name="nationality" options={countryList} value={formData.nationality} onChange={handleChange1} className='form-control ' required /> */}
                                <label style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <span>
                                    Marital Status <span style={{ color: "red" }}>*</span>
                                    </span>
                                    <span style={{ textAlign: "right", direction: "rtl" }}>
                                    الحالة الاجتماعية
                                    </span>
                                </label>
                                <select name="marital_status" className="form-control " style={{ direction: 'ltr' }}
                                            onChange={(e) => {
                                                debugger
                                                setMarriedError("");
                                                setSelectedMaritalOptionid(e.target.options[e.target.selectedIndex].id);
                                                setSelectedMaritalOption(e.target.value);
                                                handleChange('maritalStatus', e.target.value);
                                            }}
                                            value={selectedMaritalStatus}
                                            required>
                                             <option value="">{'-- Select --'}</option>
                      {maritalStatusList && maritalStatusList.map((mopt) =>
                        <option key={mopt.id} id={mopt.id}
                          value={mopt.description}>{t(mopt.description)}</option>
                      )}

                                        </select>
                                        {marriedError && <p style={{ color: 'red' }}>{marriedError}</p>} {/* Show error message */}
                            </div>
                            
                            <div className='col-md-4'>
                                {/* <label>Residential Location <span style={{ color: 'red' }}>*</span></label> */}
                                <label style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <span>
                                    Passport Number <span style={{ color: "red" }}>*</span>
                                    </span>
                                    <span style={{ textAlign: "right", direction: "rtl" }}>
                                    رقم جواز السفر
                                    </span>
                                </label>
                                <input
                                    type='text'
                                    className='form-control'
                                    name='passport'
                                    value={personalInfo.passportNumber}
                                    onChange={(e)=>{handleChange('passport', e.target.value)}}
                                    onBlur={handlePassportBlur} // Validate on blur (when user leaves the field)
                                />
                                {passportError && <p style={{ color: 'red' }}>{passportError}</p>} {/* Show error message */}
                            </div>
                            <div className='col-md-3'>
                                {/* Empty placeholder for alignment */}
                            </div>
                        </div>

                        <p>We use this to create your insurance and send you updates about your application</p>
                        <p ><input type="checkbox" checked={isChecked}
                            onChange={handleCheckboxChange} name="note" />
                            {"  I want to receive insurance updates, product launches and personalized offers. I can opt out anytime."}
                        </p>
                    </div>
                </div>


                <button type="button"
                                style={{ backgroundColor:  '#1D3561', borderColor: '#1D3561' }}
                                className="rounded-pill text-white my-3 py-1 "
                                onClick={async () => {
                                    console.log(personalInfo);
                                    if (!selectedInboundCoverageOptionId) {
                                        setCoverageError( "Please select a coverage option.");
                                        document.getElementsByName("coverageselection")[0].focus();
                                       
                                      } else if (!selectedInboundNumberOfDays) {
                                        setDaysError( "Please select the number of days.");
                                        document.getElementsByName("numberofdays")[0].focus();
                                        
                                      } else if (!selectedInboundFromCountryOption) {
                                        alert("Please select a country.");
                                        setFromcountryError("Please select a country.");
                                        // document.getElementsByName("insurancefromcountry")[0].focus();
                
                                      } else if (!selectedstartdOption) {
                                        document.getElementsByName("insurancestartdate")[0].focus();
                                        
                                        setStartdateError("Please select a Start Date.");
                
                                      } else if (selectedstartdOption < formattedDate) {
                                        document.getElementsByName("insurancestartdate")[0].focus();
                                        
                                        setStartdateError("Please select a Valid Start Date.");
                                      } else if (personalInfo.firstName == '') {
                                        document.getElementsByName("firstName")[0].focus();
                                        
                                        setNameError("Please enter the First name");
                                      } else if (personalInfo.lastName == '') {
                                        document.getElementsByName("lastName")[0].focus();
                                       
                                        setLastNameError("Please enter the last name");
                
                                      } else if (personalInfo.dob == '') {
                                        document.getElementsByName("dob")[0].focus();
                                        setDobError("Please enter the Date of Birth");
                
                                      }else{
                                        console.log('done');
                                      }


                                }}
                                >
                                Get Premium
                            </button>


                <div className="">
                    {/* <h2>Please answer the following</h2> */}
                    <div className='row mb-3 mt-4'>
                        <div className='col-md-6 text-start'>
                            <h2>Please answer the following</h2>
                        </div>
                        <div className='col-md-6 text-right'>
                            <h2>الرجاء الإجابة على ما يلي</h2>
                        </div>
                    </div>

                    <div class='inside_visa-form'>


                        <h4 className="px-0 py-2">Question</h4>
                        
                                      {/* <p>{ques}</p> */}
                                      <p>All persons to be insured are in good health and free from any physical defect at the time of effecting this insurance</p>
                        
                        
                                      <Row style={{ direction: 'ltr' }}>
                                        <Col md={2}>
                                          <div class="align-items-center travel-selection-option">
                                             YES
                                            <input type="radio" name="questionans" value="Yes" onChange={handleRadioChange} className="d-inline-block option-circle ml-auto" />
                                          </div>
                                        </Col>
                        
                                        <Col md={2}>
                                          <div class="align-items-center travel-selection-option">
                                            NO
                                            <input type="radio" name="questionans" value="No" onChange={handleRadioChange} className="d-inline-block option-circle ml-auto" />
                                          </div>
                                        </Col>
                                      </Row>

                        <Col md={12}>
                            <br />

                            <b>Please put your signature here</b><br />

                            <SignatureCanvas
                                ref={signatureRef}
                                penColor="black"
                                canvasProps={{ width: 340, height: 200, className: 'sigCanvas' }}
                                style={{ border: '1px solid #ccc', backgroundColor: 'white' }} // Ensure background is white
                                onEnd={handleSignature} // Detect when drawing ends
                            />
                            <div>
                                <button type="button"
                                    style={{ backgroundColor: !isSignatureEmpty ? '#1D3561' : 'grey', borderColor: '#1D3561' }}
                                    className="rounded-pill text-white my-3 py-1 " onClick={async () => {
                                        clearSignature();
                                    }}
                                    disabled={isSignatureEmpty}
                                >
                                    Clear Signature
                                </button>
                            </div>

                        </Col>


                        <p ><input type="checkbox" checked={isChecked}
                            onChange={handleCheckboxChange} name="note" />
                            {" I/We hereby declare that to the best of my/our knowledge and belief, the above statements and particulars are complete and true and that I/We have not misstated or suppressed any material facts. (A material fact is one which is likely to influence Superjetom company acceptance or assessment of this proposal. If in any doubt whether facts are material, they should be disclosed). Submitting this form does not bind the Proposer to complete the Insurance, nor Superjetom company to accept, but it is agreed that this form shall be the basis of the contract should a policy be issued."}
                        </p>
                        <div className='col-md-2'>
                            {!done&&(<button type="button"
                                style={{ backgroundColor: isChecked ? '#1D3561' : "grey", borderColor: '#1D3561' }}
                                className="rounded-pill text-white my-3 py-1 "
                                // disabled={isChecked ? false : true}
                                onClick={async () => {
                                   
                                    let beneficiarydel= false;
                                    const englishLetterPattern = /^[a-zA-Z\s]*$/;
                                    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                    const civilIDPattern = /^\d{4,9}$/;
                                    const passportPattern = /^[a-zA-Z0-9]*$/;
                                    const locationPattern = /^[a-zA-Z\s,-]+$/;
                                    const heightValue = Number(formData.height);
                                    const weightValue = Number(formData.weight);
                                    setGenderError("");
                                    setMarriedError("");
                                    setExpriydateError("");
                                    setDobError("");
                                    setOccupationError(""); // Clear the error if valid
                                    setNationalityError("");
                                    if (beneficiaryData.length > 0) {
                                        debugger
                                        if ((beneficiaryData.length === 1 && beneficiaryData[0].name === '') || (beneficiaryData.length === 1 && beneficiaryData[0].relation === '')||(beneficiaryData.length === 1 && beneficiaryData[0].relation === 'relation')) {
                                            setbeneficiarycheck(true);
                                            beneficiarydel= true;
                                            // alert('Please ensure the Beneficiary details');

                                        } else if (beneficiaryData.length > 1) {
                                            if ((totpercentage < 100 || totpercentage > 100) && beneficiaryData.length > 1) {
                                                debugger
                                                alert(" Beneficiary Benefit Percentage total is invalid");
                                                return
                                            }
                                            for (let i = 0; i < beneficiaryData.length; i++) {
                                                if (beneficiaryData[i].name === '' || beneficiaryData[i].relation === '' || beneficiaryData[i].benefit_percentage === ''||beneficiaryData[i].relation === 'relation') {
                                                    setbeneficiarycheck(true);
                                                    beneficiarydel= true;
                                                    // alert('Please ensure the Beneficiary details');

                                                }
                                                

                                            }

                                        }


                                    }
                                    const bmi= calculateBmi(formData.weight,formData.height)

                                    var question_valid = !questionsData.some(q => q.isChecked_answer === '');
                                    var personal_details_valid = formData.fullName !== '' && formData.email !== '' && formData.mobileNumber !== '' && formData.dob !== '' && formData.civilIDno !== '' && formData.passportNumber !== '' && formData.height !== '' && formData.weight !== '' && selectedoccupation !== '' && formData.resident_location !== '' && formData.nationality !== '' && formData.gender !== '' && formData.is_married !== ''&&formData.expiryDateof!=='';
                                    debugger
                                    if(formData.gender === ''){
                                        setGenderError("Please select a Gender.");
                                        alert('Please select the Gender\n الرجاء تحديد الجنس');
                                        document.getElementsByName("gender_value")[0].focus();

                                    }else if(formData.is_married===''){
                                        alert('Please select the Married status\nالرجاء تحديد الحالة متزوج');
                                        setMarriedError("Please select a Married status.");
                                        document.getElementsByName("marital_status")[0].focus();
                                     }else if(formData.dob ===''){
                                        alert('Please select the Date of Birth.\nالرجاء تحديد تاريخ الميلاد.');
                                        setDobError("Please select a Date of Birth.");
                                        document.getElementsByName("dob")[0].focus();
                                    }else if (beneficiarydel) {
                                        let isFormValid = true;

                                        beneficiaryData.forEach((row, index) => {
                                            if (!row.name || !row.relation || row.relation === 'Select Relation' || row.relation === 'relation'|| (beneficiaryData.length > 1 && (!row.benefit_percentage || row.benefit_percentage < 1 || row.benefit_percentage > 100))) {
                                                isFormValid = false;

                                                // Focus on the first empty field
                                                const element = document.querySelector(`[name="${!row.name ? 'name' : (!row.relation || row.relation === 'relation'|| row.relation === 'Select Relation' ? 'relation' : 'benefit_percentage')}"][data-index="${index}"]`);
                                                if (element) {
                                                    element.focus();
                                                }
                                                return; // Exit the loop after finding the first invalid field
                                            }
                                        });
                                        alert('Please ensure the Add beneficiary Details\nيرجى التأكد من تفاصيل إضافة المستفيد');
                                    } else if (!personal_details_valid) {
                                       

                                        if (formData.fullName === '' || !englishLetterPattern.test(formData.fullName)) {
                                            document.getElementsByName("fullName")[0].focus();
                                           
                                            if (formData.fullName === '') {
                                                setNameError('Please enter Full Name');
                                                alert('Please enter Full Name\n الرجاء إدخال الاسم الكامل');
                                            } else if (!englishLetterPattern.test(formData.fullName)) {
                                                setNameError('Please enter Full Name, accept english letters only.');
                                                alert('Please enter Full Name, accept english letters only.\n الرجاء إدخال الاسم الكامل، وقبول الحروف الإنجليزية فقط.');
                                            }

                                        } else if (formData.email === '' || !emailPattern.test(formData.email)) {
                                           

                                            document.getElementsByName("email")[0].focus();
                                            // Check if the entered email is valid
                                            if (!emailPattern.test(formData.email)) {
                                                setEmailError('Please enter valid e-mail address');
                                                alert('Please enter a valid e-mail address\nيرجى إدخال عنوان بريد إلكتروني صالح');
                                            } else {
                                                setEmailError('Please enter e-mail address');
                                                alert('Please enter e-mail address\n الرجاء إدخال عنوان البريد الإلكتروني');
                                            }
                                        } else if (formData.mobileNumber === '' || formData.mobileNumber.length !== 8 || !/^\d+$/.test(formData.mobileNumber)) {
                                            // Check if the mobile number has exactly 8 digits
                                            document.getElementsByName("mobileNumber")[0].focus();
                                            setMobileError('Please enter 8 digit phone number');
                                            alert('Please enter 8 digit phone number\n الرجاء إدخال رقم الهاتف المكون من 8 أرقام ');

                                        } else if (formData.civilIDno === '' || !civilIDPattern.test(formData.civilIDno)) {

                                           
                                        } else if (formData.passportNumber === '' || !passportPattern.test(formData.passportNumber)) {

                                            document.getElementsByName("passportNumber")[0].focus();
                                            // Check if the Passport Number matches the pattern
                                            if (!passportPattern.test(formData.passportNumber)) {
                                                setPassportError('Please enter valid passport number, it accept numbers & letters only.');
                                                alert('Please enter valid passport number, it accept numbers & letters only.\nالرجاء إدخال رقم جواز السفر الصحيح، فهو يقبل الأرقام والحروف فقط.');
                                            } else {
                                                alert('Please enter passport number.\n الرجاء إدخال رقم جواز السفر.');
                                                setPassportError('Please enter passport number.');
                                            }
                                        } else if (isNaN(heightValue) || heightValue < 0 || heightValue > 999 || formData.height === '') {

                                            document.getElementsByName("height")[0].focus();
                                            if (isNaN(heightValue) || heightValue < 0 || heightValue > 999) {
                                                setHeightError('Please enter valid height, accept max 3 digits.');
                                                alert('Please enter valid height, accept max 3 digits.\nالرجاء إدخال ارتفاع صحيح، قبول 3 أرقام كحد أقصى.');
                                            } else {
                                                setHeightError('Please enter valid height.');
                                                alert('Please enter valid height.\n الرجاء إدخال الارتفاع الصحيح.');
                                            }
                                        } else if (isNaN(weightValue) || weightValue < 0 || weightValue > 999 || formData.weight === '') {
                                            // Convert the value to a number and check if it's a valid number and within the range

                                            document.getElementsByName("weight")[0].focus();
                                            if (isNaN(weightValue) || weightValue < 0 || weightValue > 999) {
                                                setWeightError('Please enter valid weight, accept max 3 digits.');
                                                alert('Please enter valid weight, accept max 3 digits.\nالرجاء إدخال وزن صحيح، يرجى قبول 3 أرقام كحد أقصى.');
                                            } else {
                                                setWeightError('Please enter weight.');
                                                alert('Please enter weight.\nالرجاء إدخال الوزن.');
                                            }
                                         
                                        // } else if (!bmi) { 
                                        //     alert("BMI is outside the normal range (19-30). Please enter valid Height and Weight.\n مؤشر كتلة الجسم خارج النطاق الطبيعي (19-30). الرجاء إدخال الطول والوزن الصحيحين.");

                                         }else if(formData.nationality ===''){
                                            setNationalityError('Please Select a Nationality')
                                            alert('Please Select the Nationality\n الرجاء تحديد الجنسية');
                                            selectnationalty.current.focus();
                                            // document.getElementsByName("expiryDateof")[0].focus();
                                            // setExpriydateError('Please Select a Expiry Date of Civil ID / Passport')
                                        }else if(selectedoccupation ===''){
                                            setOccupationError('Please Select a Occupation')
                                            alert('Please Select a Occupation\n الرجاء تحديد المهنة');
                                            selectOpt1.current.focus();
                                            // document.getElementsByName("expiryDateof")[0].focus();
                                            // setExpriydateError('Please Select a Expiry Date of Civil ID / Passport')
                                       }else if (formData.resident_location === '' || !locationPattern.test(formData.resident_location)) {
                                            
                                            document.getElementsByName("resident_location")[0].focus();
                                            // Check if the Residential Location is empty or invalid
                                            if (formData.resident_location === '') {
                                                setLocationError('Please enter your Residential Location');
                                                alert('Please enter your Residential Location\n الرجاء إدخال موقع السكن الخاص بك');
                                            } else if (!locationPattern.test(formData.resident_location)) {
                                                setLocationError('Please enter a valid Residential Location (letters, spaces, commas, hyphens only)');
                                                alert('Please enter a valid Residential Location (letters, spaces, commas, hyphens only)\n الرجاء إدخال موقع سكن صالح (الحروف والمسافات والفواصل والواصلات فقط)');
                                            } else {

                                            }
                                       }else if(formData.expiryDateof===''){
                                            document.getElementsByName("expiryDateof")[0].focus();
                                            setExpriydateError('Please Select a Expiry Date of Civil ID / Passport')
                                            alert('Please Select a Expiry Date of Civil ID / Passport\n الرجاء تحديد تاريخ انتهاء البطاقة المدنية / جواز السفر')
                                         } else {
                                            setExpriydateError('');
                                            setLocationError('');
                                            setWeightError('');
                                            setHeightError('');
                                            setPassportError('');
                                           
                                            setMobileError('');
                                            setEmailError('');
                                            setNameError('');
                                            alert('Please ensure the personal details\n يرجى التأكد من التفاصيل الشخصية');
                                        }

                                    } else if (!question_valid) {
                                       
                                        alert('Please ensure the questions and answer\n يرجى التأكد من الأسئلة والإجابة');
                                    } else if (signatureRef.current.isEmpty()) {
                                       
                                           
                                            // signatureRef.current.canvas.current.focus(); 
                                        // const canvas = signatureRef.current.getCanvas();
                                        // canvas.focus(); // This focuses the actual canvas
                                        alert('Please put the signature\n من فضلك ضع التوقيع');
                                    } else if (!isChecked) {

                                        alert('Please ensure the declaration \nيرجى التأكد من الإعلان');
                                    } else {
                                        
                                        debugger
                                        setPersonalPolicyData(null);
                                        setPersonalPolicyPaymentLink(null);
                                        await getQuotation(accessTokenKey);
                                    }
                                }
                            }
                            >
                                Proceed
                            </button>)}
                        </div>

                        

                    </div>

                </div>
                {/* {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>Decision Reason</h2>
            <p>{modalMessage}</p>
            <button onClick={() => setIsModalOpen(false)}>Close</button>
          </div>
        </div>
      )} */}
      {isModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={() => setIsModalOpen(false)}>&times;</span>
                        <h1>Thank you for contacting</h1>
                        <h1>Dhofar Insurance Company</h1>
                        <p></p>
                        {/* {bmi != 0 && <p style={{
                                    color:  '#3fd2f2',
                                }}>Your Body Mass Index is {bmi}</p>} */}
                        {/* <p>BMI is outside the normal range (19-30).</p> */}
                       <p>We have received your request for </p>
                       <p>{modalMessageno}</p>
                        <p>Please call us @ 8000 7777 for further assistance.</p>
                    </div>
                </div>
            )}

                <div className="">
                    {/* <h2>Payment Details</h2> */}
                    <div className='row mb-3 mt-4'>
                        <div className='col-md-6 text-start'>
                            <h2>Payment Details</h2>
                        </div>
                        <div className='col-md-6 text-right'>
                            <h2>تفاصيل الدفع</h2>
                        </div>
                    </div>

                    <div class='inside_visa-form'>

                        <div className="pl-1">
                            <ul className="list-unstyled">
                                <li><strong>Basic premium : </strong> {quotationData != null && quotationData != undefined ? quotationData.base_price + " OMR" : ''}</li>
                                <li><strong>Policy Fee : </strong> {quotationData != null && quotationData != undefined ? quotationData.policy_fee + " OMR" : ''}</li>
                                <li><strong>Death Cover : </strong> {quotationData != null && quotationData != undefined ? quotationData.death_cover + " OMR" : ''}</li>
                                <li><strong>Government Fee : </strong> {quotationData != null && quotationData != undefined ? quotationData.government_fee + " OMR" : ''}</li>
                                <li><strong>Emergency Fund Fee : </strong>{quotationData != null && quotationData != undefined ? quotationData.emergency_fund_fee + " OMR" : ''}</li>
                                <li><strong>Total : </strong>{quotationData != null && quotationData != undefined ? quotationData.total + " OMR" : ''}</li><br />
                                <li><h2>Reference Number : </h2>{personalPolicyData != null && personalPolicyData != undefined ? personalPolicyData : ''}</li>

                            </ul>


                        </div>



                    </div>

                </div>

                {/* <button type="submit">Submit</button> */}
                <div className='row'>
                    <div className='col-md-2 text-center'>
                        <button type="button"
                            style={{ backgroundColor: personalPolicyPaymentLink != null ? '#1D3561' : 'grey', borderColor: '#1D3561' }}
                            className="rounded-pill text-white my-3 py-1 "
                            onClick={() => {

                                // resetForm();
                                window.location.href = personalPolicyPaymentLink;
                                // window.open(personalPolicyPaymentLink, '_blank')
                                // Open link in a new tab
                                // window.open(personalPolicyPaymentLink, '_blank')

                                // Reload the current page (form tab)
                                // window.location.reload();
                            }}
                            disabled={personalPolicyPaymentLink != null ? false : true}
                        >
                            Make Payment
                        </button>
                    </div>
                    <div className='col-md-4'></div>
                    <div className='col-md-4'></div>

                </div>
            </form>
            {loading && (
                <div className="overlay">
                    <div className="spinner"></div>
                </div>
            )}

        </Fragment> : <div style={{
            width: '100vw',
            height: '100vh',
            border: 'none',
            textAlign: 'center',
            marginTop: '25%',
            fontSize: '20px',
            fontWeight: 'bold'
        }}>  {isHidePage == '' ? "Loading" : "Token Invalid"}</div>

    );
};
export default TravelInbound;
