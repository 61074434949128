import React, { Fragment , useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useTranslation } from 'react-i18next';
import travelimg from './tr.png';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Select2Wrapper from './Select2Wrapper';
import Cookies from 'js-cookie';
const { config } = require('../src/api/api');

function Outboundtraveldetails() {
  const base_url = config.baseURL;
    const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';
    const [error, setError] =useState('');
    const [error1, setError1] = useState('');
    const [error2, setError2] = useState('');
    const [error3, setError3] = useState('');


    const [dhofarData, setDhofarData] = useState(false);  
    useEffect(() => {
    const storedDhofarString = Cookies.get('dhofarDataoutbound'); //sessionStorage.getItem('userData')
      if (storedDhofarString!==undefined) {
        const storedDhofar = JSON.parse(storedDhofarString);
        setDhofarData(storedDhofar);
      }
      else
      {
        // alert("token expired");
        // window.location.href='/';
      }
    }, []);
    const [responseData, setResponseData] = useState(null);

//getproducts

  const postData = { token: dhofarData };


  const [vendorKey, setVendorKey] = useState('');
  
    useEffect(() => {

      const postvData = { organization_name: "Superjet" };

      if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {
        
        
        fetch(base_url+'/create_organization', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(postvData),
          })
          .then(response => response.json())
          .then(data => {
            setVendorKey(data.result[0].vendor_key);
          })
          .catch(error => {
              console.error('Error posting data:', error);
          });


      }
    }, [vendorKey]); // Dependency array


  if(responseData===null && vendorKey!=='')
  {

  fetch(base_url+'/get_outbound_individual_products', {
  method: 'POST',
  headers: {
      'Authorization': `Bearer ${vendorKey}`,
      'Content-Type': 'application/json',
  },
  body: JSON.stringify(postData),
  })
  .then(response => response.json())
  .then(data => {
    
      setResponseData(data.result);
    
  })
  .catch(error => {
      console.error('Error posting data:', error);
  });

  }


    const { t } = useTranslation();

    const [selectedcOption, setSelectedcOption] = useState(null);
    const [selectedcOptionid, setSelectedcOptionid] = useState(null);
    const [selectednOption, setSelectednOption] = useState(null);

    const [showSection, setShowSection] = useState(false);

    const [packageData, setPackageData] = useState(null);

    const handlecOptionChange = (event, extraValue) => {
      setSelectedcOptionid(extraValue);
      setSelectedcOption(event.target.value);
      setError1('')

      const parsedInt = parseInt(event.target.id);

      // debugger

      setPackageData(responseData.data[parsedInt].packages);

      setShowSection(true);

    };

    const [selectedcaOption, setSelectedcaOption] = useState(null);
    const [selectedcaOptionid, setSelectedcaOptionid] = useState(null);

    const [selectedcnaOption, setSelectedcnaOption] = useState(null);
    const [selectedcnaOptionid, setSelectedcnaOptionid] = useState(null);

    const [selectedperOption, setSelectedperOption] = useState(null);

    

    const handleperOptionChange = (event) => {
      setSelectedperOption(event.target.value);
      setError('')
    };

    const handlecaOptionChange = (event, extraValue1, extraValue2) => {
      setSelectedcaOptionid(event.target.id);
      setSelectedcaOption(event.target.value);
      setError2('')

      const exstr = extraValue1.toString();
// debugger
      setSelectedcnaOptionid(exstr);
      setSelectedcnaOption(extraValue2);
    };

    const handlenOptionChange = (event) => {
      setSelectednOption(event.target.value);
      setError3('')
    };

    const today = new Date();

    // Add one day
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
  
    // Format the date as YYYY-MM-DD
    const formattedDate = tomorrow.toISOString().split('T')[0];

    const [selectedstartdOption, setSelectedstartdOption] = useState(formattedDate);
    const handlestOptionChange = (event) => {
      setSelectedstartdOption(event.target.value);
    };

    const handleclick = () => {

        if(selectedperOption==null)
        {
          // alert('Please choose traveller type');
          setError("Please choose traveller type");
          return false;
        }
        else if(selectedcOption==null)
        {
          // alert('Please choose product');
          setError1('Please choose product');
          return false;
        }
        else if(selectedcaOption==null)
        {
          // alert('Please choose coverage');
          setError2("Please choose coverage");
          return false;
        }
        else if(selectednOption==null)
        {
          // alert('Please select number of days');
          setError3('Please select number of days');
          return false;
        }
        else if(selectedstartdOption==null)
        {
          alert('Please select start date');
          return false;
        }
        else
        {


      var data   = {

        "persontype": selectedperOption,
        "outbound_product_type_id": "2",
        "outbound_product_id": selectedcOptionid,
        "outbound_product_name": selectedcOption,
        "outbound_coverage_id": selectedcaOptionid,
        "outbound_coverage_name": selectedcaOption,
        "outbound_travel_destinations" : [
            {
                "countryID" : selectedcnaOptionid,
                "countryName" : selectedcnaOption
            }
        ],
        "outbound_period_type_id": 1,
        "outbound_period_value_id": 4,
        "cumulative_days": selectednOption,
        "outbound_travel_start_date": selectedstartdOption        

      }

      sessionStorage.setItem("outboundtraveldetails", JSON.stringify(data));
      
      if(selectedcOption==='Standard')
      {
      window.location.href = `/outbound-travel-info`;
      }
      else
      {
      
      // if(Cookies.get('userData'))
      // {
      window.location.href = `/outbound-traveller-info`;
      // }
      // else
      // {
      // sessionStorage.setItem("searchData", "");
      // alert("Login required.");
      // window.location.href = '/Login?red=Outboundtravel';
      // }

      }

      }
      
    };

    

  return (
    <Fragment>

    <div className="header_section1">

    <Navbar />


    <div className="container-fluid bg-breadcrumb">
          <div className="container text-center py-5">            
              <h3 className="abt-txt my-5">{t('Outbound')} {t('Travel')}</h3>
          </div>
      </div>


    </div>
       
 
        <div className="features_section my-1">
        <div className="container">
            
            <div className="row">

                <Col md={3}></Col>

                    <Col md={6} className="myforms my-5"> 


                        <div>

{responseData && (
                
                <div>

                            <h4 className="px-0 py-2">{(lnstr === 'en') ? 'Choose type of travellers' : 'اختر نوع المسافرين'}<span style={{color:'red', marginLeft:'4px'}}>*</span></h4>

                            <div class="align-items-center travel-selection-option">
                                Individual
                                <input type="radio" name="travelplanperson" value="Individual" className="d-inline-block option-circle ml-auto" onChange={handleperOptionChange} />
                            </div>
                            <div class="align-items-center travel-selection-option mt-2">
                                Family
                                <input type="radio" name="travelplanperson" value="Family" className="d-inline-block option-circle ml-auto" onChange={handleperOptionChange} />
                            </div>
                            {error && <div><span style={{color:'red'}}>{error}</span></div>}


                            <h4 className="px-0 py-2 mt-3">{(lnstr === 'en') ? 'Select Product' : 'اختيار المنتج'}<span style={{color:'red', marginLeft:'4px'}}>*</span></h4>

                            {responseData && responseData.data.map((option, index) =>

                            <div class="align-items-center travel-selection-option mt-2">
                                {option.productDescription}
                                <input type="radio" name="productselection" id={index} value={option.productDescription} className="d-inline-block option-circle ml-auto" onChange={(e) => handlecOptionChange(e, option.productId)}  />
                            </div>

                            )}
                            {error1 && <div><span style={{color:'red'}}>{error1}</span></div>}




{showSection && (
                              <div>
                                <h4 className="px-0 py-2 mt-3">{(lnstr === 'en') ? 'Coverage Selection' : 'اختيار التغطية'}<span style={{color:'red', marginLeft:'4px'}}>*</span></h4>

                                {packageData && packageData.map((poption) => 

                                <div class="align-items-center travel-selection-option mt-2">
                                    {poption.coverageArea.name}
                                    <input type="radio" name="coverageselection" id={poption.coverageArea.id} value={poption.coverageArea.name} className="d-inline-block option-circle ml-auto" onChange={(e) => handlecaOptionChange(e, poption.destinations[0].countryID, poption.destinations[0].countryName)} />
                                </div>

                                )}

                                <br />
                              </div>
                            )}
                            {error2 && <span style = {{color:'red'}}>{error2}</span>}




                            <h4 className="px-0 py-2 mt-3">{(lnstr === 'en') ? 'Number Of Days' : 'عدد الأيام'}<span style={{color:'red', marginLeft:'4px'}}>*</span></h4>

                              {(selectedcOption==='Standard') ? 

                                <select className="form-control" onChange={handlenOptionChange} name="numberofdays" style={{ padding: 8, direction:'ltr' }}>
                                    <option value="">{(lnstr === 'en') ? '-- Select --' : '--اختار--'}</option>


                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="30">30</option>
                                    <option value="60">60</option>                                    
                                    <option value="90">90</option>
                                    {/* <option value="120">120</option>
                                    <option value="1 Year">1 Year</option>
                                    <option value="2 Years">2 Years</option> */}

                                    

                                </select>

                                :

                                  <select className="form-control" onChange={handlenOptionChange} name="numberofdays"
                                          style={{padding: 8, direction: 'ltr'}}>
                                      <option value="">{(lnstr === 'en') ? '-- Select --' : '--اختار--'}</option>


                                      <option value={15}>15</option>
                                      <option value={25}>25</option>
                                      <option value={45}>45</option>


                                  </select>

                              }
                            {error3 && <span style={{color:'red'}}>{error3}</span>}


                    <h4 className="px-0 py-2 mt-3">{(lnstr === 'en') ? 'Start Date' : 'تاريخ البدء'}</h4>


                                <input type="date" min={formattedDate} value={selectedstartdOption} name="insurancestartdate" className="form-control" onChange={handlestOptionChange} style={{ padding: 7 }} />


                            <button type="button" onClick={handleclick} className="btn btn-primary rounded-pill text-white my-3 py-1 px-4">{t('Submit')}</button>
                            
                            </div>

                          )}

{!responseData && (
  <div className="overlay">
    <div className="spinner"></div>
  </div>
)}

                            </div>




                    </Col>

<Col md={3}></Col>

</div>
            
            </div>
          </div>

    <Footer />

    

    </Fragment>
  );
}

export default Outboundtraveldetails;