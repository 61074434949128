import React, { Fragment , useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useTranslation } from 'react-i18next';
import travelimg from './tr.png';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Select2Wrapper from './Select2Wrapperins';
import Cookies from 'js-cookie';
import './selectstyle.css';
const { config } = require('../src/api/api');

function Inboundtravelinfo() {
    const base_url = config.baseURL;
    const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';

    const [dhofarData, setDhofarData] = useState(false);
    useEffect(() => {
        const storedDhofarString = Cookies.get('dhofarData'); //sessionStorage.getItem('userData')
        if (storedDhofarString!==undefined) {
            const storedDhofar = JSON.parse(storedDhofarString);
            setDhofarData(storedDhofar);
        }
        else
        {
            // alert("token expired");
            // window.location.href='/';
        }
    }, []);


    const [vendorKey, setVendorKey] = useState('');

    useEffect(() => {

        const postvData = { organization_name: "Superjet" };

        if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {


            fetch(base_url+'/create_organization', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(postvData),
            })
                .then(response => response.json())
                .then(data => {
                    setVendorKey(data.result[0].vendor_key);
                })
                .catch(error => {
                    console.error('Error posting data:', error);
                });


        }
    }, [vendorKey]); // Dependency array


    const postData = { token: dhofarData };

    //getcountries

    const [options, setCountries] = useState(null);
    const [error, setError] = useState('')

    useEffect(() => {

        if(options===null && vendorKey!=='')
        {


            fetch(base_url+'/getCountries', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${vendorKey}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(postData),
            })
                .then(response => response.json())
                .then(data => {

                    setCountries(data.result.data.response_data);

                })
                .catch(error => {
                    console.error('Error posting data:', error);
                });

        }


    }, [options, postData, vendorKey]);



    const { t } = useTranslation();

    const [selectedfrmOption, setSelectedfrmOption] = useState(null);
    const [selectedfromOption, setSelectedfromOption] = useState(null);
    const [selectedfromOptionid, setSelectedfromOptionid] = useState(null);
    const handlefromcountryChange = (value) => {

        const [description, id] = value.split("#");

        // alert(description);

        setSelectedfromOptionid(id);
        setSelectedfromOption(description);
        setSelectedfrmOption(value);
        setError('');
    };

    const today = new Date();

    // Add one day
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    // Format the date as YYYY-MM-DD
    const formattedDate = tomorrow.toISOString().split('T')[0];

    const [selectedstartdOption, setSelectedstartdOption] = useState(formattedDate);
    const handlestOptionChange = (event) => {
        setSelectedstartdOption(event.target.value);
    };

    const togglestartVisibility = () => {

        if(selectedfromOption==null)
        {
            // alert('Please from country');
            setError('Please from country')
            return false;
        }
        else if(selectedstartdOption==null)
        {
            alert('Please select start date');
            return false;
        }
        else
        {
debugger
            var data   = {

                "inbound_country_id": selectedfromOptionid,
                "inbound_country_name": selectedfromOption,
                "inbound_travel_start_date": selectedstartdOption

            }

            // debugger

            sessionStorage.setItem("inboundtravelinfo", JSON.stringify(data));

            // if(Cookies.get('userData'))
            // {
            window.location.href = `/inbound-traveller-info`;
            // }
            // else
            // {
            //     sessionStorage.setItem("searchData", "");
            //     alert("Login required.");
            //     window.location.href = '/Login?red=Inboundtravel';
            // }


        }

    };

    return (
        <Fragment>

            <div className="header_section1">

                <Navbar />


                <div className="container-fluid bg-breadcrumb">
                    <div className="container text-center py-5">
                        <h3 className="abt-txt my-5">{(lnstr === 'en') ? 'Travel Info' : 'معلومات السفر'}</h3>
                    </div>
                </div>


            </div>


            <div className="features_section">
                <div className="container">

                    <div className="row">

                        <Col md={3}></Col>

                        <Col md={6} className="myforms my-5">


                            <div>


                                <h4 className="px-0 py-2 mt-3">{(lnstr === 'en') ? 'Select From Country' : 'اختر من البلد'}<span style={{color:'red' , padding:'5px'}}>*</span></h4>

                                <Select2Wrapper name="insurancefromcountry" value={selectedfrmOption} options={options} className='form-control' onChange={handlefromcountryChange} required style={{ padding: 15 }} />
                                {error&& <div><span style ={{color:'red'}}>{error}</span></div>}

                                {/*<select name="insurancefromcountry" className="form-control" onChange={handlefromcountryChange} required>*/}
                                {/*  <option value="">-- {t('Select')} --</option>*/}
                                {/*  {options && options.map((option) => */}
                                {/*    <option key={option.id} id={option.id} value={option.description}>{t(option.description)}</option>*/}
                                {/*  )}*/}
                                {/*</select>*/}

                                <h4 className="px-0 py-2 mt-3">{(lnstr === 'en') ? 'Start Date' : 'تاريخ البدء'}</h4>

                                <input type="date" min={formattedDate} value={selectedstartdOption} name="insurancestartdate" className="form-control" onChange={handlestOptionChange} style={{ padding: 7 }} />

                                <button type="button" onClick={togglestartVisibility} className="btn btn-primary rounded-pill text-white my-3 py-1 px-4">{t('Submit')}</button>

                            </div>

                        </Col>

                        <Col md={3}></Col>

                    </div>

                </div>
            </div>

            <Footer />



        </Fragment>
    );
}

export default Inboundtravelinfo;