import React, { useState, useEffect, Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import i18n from '../i18n';
import Navbar from "../Navbar";
import Footer from "../Footer";
import AOS from 'aos';
import 'aos/dist/aos.css';

function DhofarMotorinsuranceoffers() {

    const { t } = useTranslation();

    const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';
    const [loading, setLoading] = useState(false);

  const changeLanguage = (lng) => {

    const expiryDate = new Date();
    expiryDate.setTime(expiryDate.getTime() + (1 * 60 * 60 * 1000));

    Cookies.set('langData', lng, { expires: expiryDate });

    i18n.changeLanguage(lng);
  };

    
    const [insData, setData] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [isHovered1, setIsHovered1] = useState(false);
    const [isHovered2, setIsHovered2] = useState(false);
    
    useEffect(() => {
        // debugger
      const storeddata = sessionStorage.getItem('motorinsurancePlan');
        if (storeddata!==undefined && storeddata!==null) {
          const storeddt = JSON.parse(storeddata);
          setData(storeddt);
        }
        else
        {
            window.location.href = '/motor-insurance';
        }
    }, []);

    
  // Function to update HTML dir attribute based on language
  const updateHtmlDirAttribute = () => {
    document.documentElement.dir = i18n.dir(); // Set document direction
  };

  // Update HTML dir attribute when component mounts and language changes
  useEffect(() => {
    updateHtmlDirAttribute();
  }, [i18n.language]);

  useEffect(()=>{
    AOS.init({duration:600});
  },[]);

  const handleBack= async (event) => {
    event.preventDefault();

    window.location.href = "/dhofar_motorinsurance"

  };


  const handlethird = async (event) => {
    event.preventDefault();

    window.location.href = "/dhofar_motor_thirdparty"

  };

  const handlecomprehensive = async (event) => {
    event.preventDefault();

    window.location.href = '/dhofar_motor_comperhensive'
  }

  return (
    <Fragment>

      <div className="header_section1">

        <Navbar />


        <div className="container-fluid bg-breadcrumb">
          <div className="container text-center py-5">
            <h3 className="abt-txt my-3">{(lnstr === 'en') ? 'Details Page': 'تحميل السياسة\n'}</h3>
          </div>
        </div>


      </div>


      <div className="features_section">
        <div className="container">

          <div className="row">

            <Col md={2}></Col>

            <Col md={8} className="myforms my-5">
            <div class="card" data-aos='fade-up'>
  <div class="card-body">
    <h4><strong>Vehicle Details</strong></h4>
    {/* <div class="d-flex justify-content-between"> */}
      <div className="row">
      <div className="col-lg-3 col-md-12 col-sm-12 col-12">
        <spam>Plate Number</spam>
        <div><h5><strong>5001</strong></h5></div>
      </div>
      <div className="col-lg-3 col-md-12 col-sm-12 col-12">
        <spam>Plate Code</spam>
        <div><h5><strong>LK</strong></h5></div>
      </div>
      <div className="col-lg-3 col-md-12 col-sm-12 col-12">
        <spam>Plate Type</spam>
        <div><h5><strong>Oman</strong></h5></div>
      </div>
      <div className="col-lg-2 col-md-12 col-sm-12 col-12">
        <spam>Expiry Date</spam>
        <div><h5><strong>05/26/2024</strong></h5></div>
      </div>
    </div>
    {/* </div> */}
    <hr/>
    <h4><strong>Personal Details</strong></h4>
    {/* <div class="d-flex justify-content-between"> */}
      <div className="row">
        <div className="col-lg-5 col-md-12 col-sm-12 col-12">
        <spam>Name</spam>
        <div><h5><strong>Amir bin Mustafa bin Mal Allah Al Lawati</strong></h5></div>
      </div>
      <div className="col-lg-3 col-md-12 col-sm-12 col-12">
        <spam>Mobile Number</spam>
        <div><h5><strong>9678 3415</strong></h5></div>
      </div>
      <div className="col-lg-3 col-md-12 col-sm-12 col-12">
        <spam>Expiry Date</spam>
        <div><h5><strong>06/20/2028</strong></h5></div>
      </div>
    {/* </div> */}
  </div>
</div>
</div>

<div className="p-3" data-aos='fade-up'>
  <div className="row" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

<div className="col-lg-7 col-md-7 col-sm-12 col-12">
  <button type ='submit' className=" rounded-pill text-white my-1 py-1 px-4 " onClick={handleBack} style={{
            backgroundColor: isHovered2 ? '#0b8ba8' : '#33d0f2',
            transition: 'background-color 0.3s ease',
          }}  onMouseEnter={() => setIsHovered2(true)} onMouseLeave={() => setIsHovered2(false)}>Back</button>
          </div>

          <div className="col-lg-5 col-md-5 col-sm-12 col-12">
    <button type ='submit' className=" rounded-pill text-white my-1 py-1 px-4" onClick={handlethird} style={{
            backgroundColor: isHovered ? '#0b8ba8' : '#33d0f2',
            transition: 'background-color 0.3s ease',
          }}  onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>Third Party</button>

    <button type ='submit' className="rounded-pill text-white my-1 py-1 px-2" onClick={handlecomprehensive}style={{
            backgroundColor: isHovered1 ? '#0b8ba8' : '#33d0f2',
            transition: 'background-color 0.3s ease',
            marginLeft:'6px' , textAlign:'center'
          }}  onMouseEnter={() => setIsHovered1(true)} onMouseLeave={() => setIsHovered1(false)}>Comprehensive</button>
</div>


</div>
</div>
<div>

</div>



          
        </Col>

            <Col md={2 }></Col>

          </div>


        </div>
      </div>

      <Footer />

      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}

    </Fragment>
  );
}

export default DhofarMotorinsuranceoffers;