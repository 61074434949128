import React, { Fragment, useState, useEffect, useRef } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { useTranslation } from 'react-i18next';
import travelimg from '../tr.png';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';
import Selectoption from "./Selectoptions";
import Selectoptionocc from "./Selectoptionoccuption";
import $ from 'jquery';
const { config } = require('../api/api');


function CyberPersonalInformation() {

  const base_url = config.baseURL;

  const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';


  const { t } = useTranslation();

  debugger



  const [formData, setFormData] = useState({
    occupation: '',
    nationality: '',
  });
  const [loading, setLoading] = useState(false);
  const [Occupations, setOccupation] = useState('');
  const [dhofarData, setDhofarData] = useState(false);
  const [age, setAge] = useState(null);
  const postData = { token: dhofarData };

  useEffect(() => {


    const storedDhofarString = Cookies.get('personalData'); //sessionStorage.getItem('userData')
    if (storedDhofarString !== undefined) {
      const storedDhofar = JSON.parse(storedDhofarString);
      setDhofarData(storedDhofar);
    }
    else {
      // alert("token expired");
      // window.location.href='/';
    }

  }, []);


  const [vendorKey, setVendorkey] = useState('');

  useEffect(() => {
    const vendortoken = Cookies.get('Vendorkey');

    if (vendortoken !== undefined) {


      setVendorkey(vendortoken)


    }
  }, []);

  useEffect(() => {
    debugger
    const personaljson = sessionStorage.getItem('persionalinformationpage');
    if (personaljson != undefined) {
      const personal = JSON.parse(personaljson);
    //   setSelectedGender(value.basicinfo_gender_detail);
    setfnameValue(personal.fullName);
    setemailValue(personal.email);
    setphoneValue(personal.mobilenumber);
    setcivilidValue(personal.civilid);
    setpassportValue(personal.passportnumber);
    setheightValue(personal.height);
    setweightValue(personal.weight);
    setSelectedoccupation(personal.occupation);
    setResidentialLocationValue(personal.Residential_Location);
    setexpirydateValue(personal.expiry_date);
    setSelectednationality(personal.nationality);
    setVATINValue(personal.vat_in);

    









      
    }

  }, []);


  const [occupations, setOccupations] = useState([]);

  useEffect(() => {

    if (vendorKey !== '') {
      const fetchOccupations = async () => {
        try {

          const response = await fetch(base_url + '/get_personal_accident_occupations', {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${vendorKey}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(postData),
          });

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const data = await response.json();

          setOccupations(data.result.data);
        } catch (error) {
          console.error('Error fetching occupations:', error);
        }
      };

      fetchOccupations();
    }
  }, [vendorKey]);



  const [natioalitys, setNationalityValue] = useState([]);

  useEffect(() => {

    debugger

    if (vendorKey !== '') {
      const fetchNationality = async () => {
        try {


          const response = await fetch(base_url +'/get_personal_accident_countries', {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${vendorKey}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(postData),
          });

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const data = await response.json();

          setNationalityValue(data.result.data);
        } catch (error) {
          console.error('Error fetching occupations:', error);
        }
      };

      fetchNationality();
    }
  }, [vendorKey]);







  const [buttonDisabled, setButtonDisabled] = useState(false);

  // const today = new Date().toISOString().split('T')[0];
  const today = new Date();
  const maxDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate() + 1).toISOString().split('T')[0];


  const [dobValue, setdobValue] = useState('');
  const handledobChange = (event) => {
    const selectedDob = event.target.value;
    setdobValue(event.target.value);

    const dobDate = new Date(selectedDob);
    const today = new Date();
    let calculatedAge = today.getFullYear() - dobDate.getFullYear();
    const monthDiff = today.getMonth() - dobDate.getMonth();

    // Adjust age if the current date is before the user's birthday in the current year
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dobDate.getDate())) {
      calculatedAge--;
    }

    setAge(calculatedAge);
  };


  const handleqOptionChange = (event) => {

    // if (event.target.value === 'No') {
    //   alert("The outbound plans are available only for residents of Oman.");
    //   setButtonDisabled(true);
    //   return;
    // }
    // else {
    //   setButtonDisabled(false);
    // }

  };

  const [selectedoccupation, setSelectedoccupation] = useState(null);
  const [selectednationality, setSelectednationality] = useState(null);
  const selectOpt2 = useRef(null);

  const [userData, setUserData] = useState(false);
  const currentdate = new Date().toISOString().split('T')[0];

  const [fnameValue, setfnameValue] = useState('');


  const handleChangenationality = (name, value) => {

    // setFormData({ ...formData, [name]: value });
    setSelectednationality(value)

    // console.log(formData);
  };

  const handlefnameChange = (event) => {
    setfnameValue(event.target.value);
  };

  const [emailValue, setemailValue] = useState('');
  const handleemailChange = (event) => {
    setemailValue(event.target.value);
  };

  const [phoneValue, setphoneValue] = useState('');
  const handlephoneChange = (event) => {
    setphoneValue(event.target.value);
  };

  const [civilidValue, setcivilidValue] = useState('');
  const handlecivilidChange = (event) => {
    setcivilidValue(event.target.value);
  };

  const [passportValue, setpassportValue] = useState('');
  const handlepassportChange = (event) => {
    setpassportValue(event.target.value);
  };

  const [heightValue, setheightValue] = useState('');
  const handleheightChange = (event) => {
    setheightValue(event.target.value);
  };

  const [weightValue, setweightValue] = useState('');
  const handleweightChange = (event) => {

    setweightValue(event.target.value);

    const h = parseFloat(heightValue);
    const w = parseFloat(weightValue);
    if (isNaN(h) || isNaN(w) || h <= 0 || w <= 0) {
      // setError('Please enter valid height and weight.');
      return;
    }
    const bmiValue = (w / ((h / 100) ** 2)).toFixed(2);
    setBmi(bmiValue);
    setError('');
  };

  const [occupationValue, setoccupationValue] = useState('');
  const handleoccupationChange = (event) => {
    setoccupationValue(event.target.value);
  };

  const [ResidentialLocationValue, setResidentialLocationValue] = useState('');
  const handleResidentialLocationChange = (event) => {
    setResidentialLocationValue(event.target.value);
  };

  const [expirydateValue, setexpirydateValue] = useState('');
  const handleexpirydateChange = (event) => {
    setexpirydateValue(event.target.value);
  };

  const [NationalityValue, setNationality] = useState('');
  const handleNationalityChange = (event) => {
    setNationality(event.target.value);
  };

  const [VATINValue, setVATINValue] = useState('');
  const handleVATINChange = (event) => {
    setVATINValue(event.target.value);
  };

  const [isChecked, setIsChecked] = useState(false);

  // const [phoneValue, setphoneValue] = useState('');
  //   const handlephoneChange = (event) => {
  //       setphoneValue(event.target.value);
  //   };
  //   const handlephoneaChange = (event) => {
  //     setphoneaValue(event.target.value);
  // };


  const handleSubmit = async (event) => {

    debugger

    var data1 = {
      'fullName': fnameValue,
      'email': emailValue,
      'mobilenumber': phoneValue,
      'civilid': civilidValue,
      'occupation': formData.occupation,
      'occupation_code': selectedCode,
      'Residential_Location': ResidentialLocationValue,
      'expiry_date': expirydateValue,
      'nationality': formData.nationality,
      'vat_in': VATINValue


    }
    sessionStorage.setItem("persionalinformationpage", JSON.stringify(data1))

    event.preventDefault();
    setLoading(true);

    event.preventDefault();
    window.location.href = '/cyber-questions';



  };


  const [mobileError, setMobileError] = useState('');

  const handleMobileBlur = (e) => {
      const { value } = e.target;

      // Check if the mobile number has exactly 8 digits
      if (value.length !== 8 || !/^\d+$/.test(value)) {
          setMobileError('Please enter 8 digit phone number');
      } else {
          setMobileError('');
      }
  };


  const [civilIDError, setCivilIDError] = useState('');
  const handleCivilIDBlur = (e) => {
    const { value } = e.target;

    // Regular expression to allow only numbers and check length between 4 to 9 digits
    const civilIDPattern = /^\d{4,9}$/;

    // Check if the Civil ID matches the pattern
    if (!civilIDPattern.test(value)) {
        setCivilIDError('Please enter valid civil id number, it accept numbers only, 4 to 9 digit.');
    } else {
        setCivilIDError('');
    }
};


const [passportError, setPassportError] = useState('');

const handlePassportBlur = (e) => {
  const { value } = e.target;

  // Regular expression to allow only letters and numbers
  const passportPattern = /^[a-zA-Z0-9]*$/;

  // Check if the Passport Number matches the pattern
  if (!passportPattern.test(value)||value=='') {
      setPassportError('Please enter valid passport number, it accept numbers & letters only.');
  } else {
      setPassportError('');
  }
};



const [heightError, setHeightError] = useState('');

    const handleHeightBlur = (e) => {
        const { value } = e.target;

        // Convert the value to a number and check if it's a valid number and within the range
        const heightValue = Number(value);

        if (isNaN(heightValue) || heightValue < 0 || heightValue > 999||value=='') {
            setHeightError('Please enter valid height, accept max 3 digits.');
        } else {
            setHeightError('');
        }
    };


    const [weightError, setWeightError] = useState('');

    const handleWeightBlur = (e) => {
        const { value } = e.target;

        // Convert the value to a number and check if it's a valid number and within the range
        const weightValue = Number(value);

        if (isNaN(weightValue) || weightValue < 0 || weightValue > 999||value=='') {
            setWeightError('Please enter valid weight, accept max 3 digits.');
        } else {
            setWeightError('');
        }
    };



    const [locationError, setLocationError] = useState('');

    const handleLocationBlur = (e) => {
        const { value } = e.target;

        // Optional: Regular expression to allow only letters, spaces, commas, and hyphens
        const locationPattern = /^[a-zA-Z\s,-]+$/;

        // Check if the Residential Location is empty or invalid
        if (value === '') {
            setLocationError('Please enter your Residential Location');
        } else if (!locationPattern.test(value)) {
            setLocationError('Please enter a valid Residential Location (letters, spaces, commas, hyphens only)');
        } else {
            setLocationError('');
        }
    };




    const [vatError, setVatError] = useState('');

    const handleVatBlur = (e) => {
        const { value } = e.target;

        // Regular expression to allow only letters and numbers
        const vatPattern = /^[a-zA-Z0-9]{15}$/;

        // Check if the VATIN is exactly 15 characters long and matches the pattern
        if (!vatPattern.test(value)) {
            setVatError('Please enter a valid VATIN (15 characters, letters, and numbers only)');
        } else {
            setVatError('');
        }
    };










  const [selectedgenderOption, setSelectedgenderOption] = useState(null);
  const [selectedgenderOptionid, setSelectedgenderOptionid] = useState(null);
  const handlegenderChange = (event) => {
    setSelectedgenderOptionid(event.target.options[event.target.selectedIndex].id);
    setSelectedgenderOption(event.target.value);
  };

  const [selectedmaritalOption, setSelectedmaritalOption] = useState(null);
  const [selectedmaritalOptionid, setSelectedmaritalOptionid] = useState(null);
  const handlemaritalChange = (event) => {
    setSelectedmaritalOptionid(event.target.options[event.target.selectedIndex].id);
    setSelectedmaritalOption(event.target.value);
  };
  //getgender


  const [selectedCode, setSelectedCode] = useState('');
  // const [selectedoccupation, setSelectedoccupation] = useState('');


  const handleInputChange = (name, englishValue, codeValue) => {

  };

  const handlenationalityChange = async (event) => {

  }




  const [bmi, setBmi] = useState(null);
  const [error, setError] = useState('');




  const handleChange1 = (name, value) => {

    setFormData({ ...formData, [name]: value });
    // console.log(formData);
  };


  const handleChange2 = (name, englishValue, codeValue) => {

    debugger

    setFormData({ ...formData, [name]: englishValue });  // Store the English value in formData
    setSelectedCode(codeValue);
    // setOccupation(englishValue);  // Store the CODE in a separate variable
    // console.log('Form Data:', formData);
    // console.log('Selected Code:', codeValue);
  };



  return (
    <Fragment>

      <div className="header_section1">

        <Navbar />


        <div className="container-fluid bg-breadcrumb">
          <div className="container text-center py-5">
            <h3 className="abt-txt my-5">{(lnstr === 'en') ? 'Enter Personal Information  ' : 'أدخل المعلومات الشخصية\n'}</h3>
          </div>
        </div>


      </div>


      <div className="features_section">
        <div className="container">

          <div className="row">

            <Col md={3}></Col>

            <Col md={6} className="myforms my-5">

              <div className="clformrow mt-1">


                <form onSubmit={handleSubmit}>


                  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                      <span className="input-group-text">{(lnstr == 'en') ? 'Full Name' : 'الاسم الكامل'}<span style={{color:'red', marginLeft: '5px'}}>*</span></span>
                    </div>
                    <input type="text" name="firstName" className="form-control"
                      value={fnameValue} onChange={handlefnameChange} required />
                  </div>


                  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                      <span className="input-group-text">{t('Email')}<span style={{color:'red', marginLeft: '5px'}}>*</span></span>
                    </div>
                    <input type="text" name="email" className="form-control" value={emailValue}
                      onChange={handleemailChange} required />
                  </div>


                  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                      <span className="input-group-text">{(lnstr === 'en') ? 'Mobile Number' : 'رقم الهاتف المحمول'}<span style={{color:'red', marginLeft: '5px'}}>*</span></span>
                    </div>
                    <input type="tel" name="phone" className="form-control" value={phoneValue} maxLength={8}  onBlur={handleMobileBlur}
                      onChange={handlephoneChange} required /> 
                  </div>
                  <div>{mobileError && <p style={{ color: 'red' }}>{mobileError}</p>}</div>


                  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                      <span className="input-group-text">{(lnstr === 'en') ? 'Civil ID Number' : 'رقم البطاقة المدنية'}<span style={{color:'red', marginLeft: '5px'}}>*</span></span>
                    </div>
                    <input type="text" name="civilid" className="form-control" value={civilidValue} onBlur={handleCivilIDBlur}
                      onChange={handlecivilidChange} required />
                  </div>
                  <div>{civilIDError && <p style={{ color: 'red' }}>{civilIDError}</p>}</div>


                  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                      <span className="input-group-text">{(lnstr === 'en') ? 'Occupation' : 'إشغال'}<span style={{color:'red', marginLeft: '5px'}}>*</span></span>
                    </div>

                    <div className="styledata">
                      <Selectoptionocc name="occupation"
                      value={selectedoccupation}
                        options={occupations}
                        className='form-control select2'
                        onChange={handleChange2}
                        required />
                    </div>
                  </div>

                 

                  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                      <span className="input-group-text">{(lnstr === 'en') ? 'Residential Location' : 'إشغال'}<span style={{color:'red', marginLeft: '5px'}}>*</span></span>
                    </div>
                    <input type="text" name="ResidentialLocation" value={ResidentialLocationValue} className="form-control" placeholder={lnstr === 'en' ? 'For example (Muscat)' : 'على سبيل المثال (مسقط)'} onBlur={handleLocationBlur} onChange={handleResidentialLocationChange} required />
                    {locationError && <p style={{ color: 'red' }}>{locationError}</p>}
                  </div>

                  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                      <span className="input-group-text">{(lnstr === 'en') ? 'Expiry Date of Civil ID/Passport' : 'تاريخ انتهاء البطاقة المدنية / جواز السفر'}<span style={{color:'red', marginLeft: '5px'}}>*</span></span>
                    </div>
                    <input type="date" name="expirydate" value={expirydateValue} className="form-control" max='2100-12-31' min={currentdate} onChange={handleexpirydateChange} required />
                  </div>

                  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                      <span className="input-group-text">{t('Nationality')}<span style={{color:'red', marginLeft: '5px'}}>*</span></span>
                    </div>
                    {/*<div className="styledata"><Selectoption name="nationality" options={natioalitys} value={selectednationality} onChange={handleChange1} className='form-control ' required /></div>
*/}                  
                        <div className="styledata">
                      <select
                        ref={selectOpt2}
                        className="form-control"
                        style={{ borderRadius: '5px', height: '30px' , width: '100%' ,maxWidth: '300px' }}
                        onChange={handleChangenationality} required
                        value={selectednationality} // Set the selected value to match session storage or state
                      // Add your desired height and border-radius here
                      >
                        {/*<option >{`-- ${t('Select')} --`}</option> */}
                        {natioalitys != null && natioalitys.map((option) => (
                          <option key={option} value={option}>
                            {option.charAt(0).toUpperCase() + option.slice(1)}
                          </option>
                        ))}
                      </select>
                    </div>
                   

                     
</div>


                  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                      <span
                        className="input-group-text">{(lnstr === 'en') ? 'VATIN (optional)' : 'ضريبة القيمة المضافة (اختياري)'}</span>
                    </div>
                    <input type="text" name="VATIN" value={VATINValue} className="form-control"  maxLength={15}  onBlur={handleVatBlur} onChange={handleVATINChange} required />
                  </div>
                  <div>{vatError && <p style={{ color: 'red' }}>{vatError}</p>}</div>



                  <button type="submit" className="btn btn-primary rounded-pill text-white my-3 py-1 px-4">{(lnstr === 'en') ? 'Proceed' : 'يتابع'}</button>


                </form>

              </div>





            </Col>

            <Col md={3}></Col>

          </div>

        </div>
      </div>

      <Footer />

      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}

    </Fragment>
  );
}

export default CyberPersonalInformation;
