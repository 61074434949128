import React, { Fragment, useState, useEffect } from "react";
import Navbar from "../../Navbar";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import Footer from "../../Footer";
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
// import SessionRemove from "../helper/sessionremove";
// import "./homeinsurance.css";

const { config } = require('../../api/api');


function Tenantpage(){

  
  
  const base_url = config.baseURL;
 const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';


  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    insurancetype: '',
  });

  const [selectedOption, setSelectedOption] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showSection, setShowSection] = useState(false);
  const [section_1_sum_assured , setsection_1_sum_assured] = useState(null);
  const [section_3_sum_assured , setsection_3_sum_assured] = useState(null);
  const [section_5_sum_assured , setsection_5_sum_assured] = useState(null);
  const [sumAssured, setSumAssured] = useState(0);
const [sec3sumAssured, setsec3SumAssured] = useState(0);
const [sec5sumAssured, setsec5SumAssured] = useState(0);
const [errorsec1, setErrorsec1] = useState("");
const [errorsec3, setErrorsec3] = useState("");
const [errorsec5, setErrorsec5] = useState("");

   
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const [dhofarData, setDhofarData] = useState(false);
  useEffect(() => {


    const storedDhofarString = Cookies.get('homeData'); //sessionStorage.getItem('userData')
    debugger
    if (storedDhofarString !== undefined) {
      const storedDhofar = JSON.parse(storedDhofarString);
      setDhofarData(storedDhofar);
    }
    else {
      // alert("token expired");
      // window.location.href='/';
    }

  }, []);


  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    debugger
    setFormData({ ...formData, 'insurancetype': event.target.value });

    if (event.target.value === ' ') {
      setShowSection(true);
      setButtonDisabled(true);
    }
    else {
      setShowSection(false);
      setButtonDisabled(false);
    }

  };
  const [vendorKey, setVendorkey] = useState('');
  useEffect(() => {

      const postvData = { organization_name: "Superjet" };
      debugger
  
      if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {
  
  
        fetch( base_url + '/create_organization', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postvData),
        })
          .then(response => response.json())
          .then(data => {
            setVendorkey(data.result[0].vendor_key);
          })
          .catch(error => {
            console.error('Error posting data:', error);
          });
  
  
      }
    }, [vendorKey]);

    const postData = { token: dhofarData };
    useEffect(() => {
    debugger

      if (vendorKey !== '') {
        fetch(base_url + '/get_home_assured', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${vendorKey}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postData),
  
        })
          .then(response => response.json())
          .then(data => {
            debugger
            setsection_1_sum_assured(data.result.data.section_1_sum_assured);
            setsection_3_sum_assured(data.result.data.section_3_sum_assured);
            setsection_5_sum_assured(data.result.data.section_5_sum_assured);
  
          })
          .catch(error => {
            console.error('Error posting data:', error);
          });
  
      }
    }, [vendorKey]);
  
  



  const handleSubmit = async (event) => {
    event.preventDefault();

    var data ={
      'section_1_sum_assured': sumAssured,
      'section_3_sum_assured': sec3sumAssured,
      'section_5_sum_assured': sec5sumAssured
    }
debugger
    sessionStorage.setItem('homeowner', JSON.stringify(data))

    setLoading(true);
      // Check if the selected option is 'Personal Accident'
        window.location.href ='/ownerpagedetails';

  };
//
const[ischeckedsec1,setIscheckedsec1,]=useState(false);
const [isExpanded, setIsExpanded] = useState(false);
const [isExpandedofsec3, setIsExpandedofsec3] = useState(false);
const [isExpandedofsec5, setIsExpandedofsec5] = useState(false);
  const handleCheckboxChange = () => {
    setIsExpanded(!isExpanded);
    setIscheckedsec1(!ischeckedsec1)
  };


  const handleCheckboxChangeofsection3 = () => {
    setIsExpandedofsec3(!isExpandedofsec3);
  };
  const handleCheckboxChangeofsection5 = () => {
    setIsExpandedofsec5(!isExpandedofsec5);
  };


const isDisabled=(sumAssured <= 0 || sumAssured >=section_1_sum_assured) && sec3sumAssured<=0 || (sec3sumAssured > section_3_sum_assured) && (sec5sumAssured<=0 || sec5sumAssured >= section_5_sum_assured);
  
  // Function to handle increment and decrement
 // const handleChange = (amount) => {
  //  setSumAssured(prevSum => Math.max(0, prevSum + amount)); // Ensures it doesn't go below 0
    
 // };
  
  const handleChange = (amount) => {
    setSumAssured((prevSum) => {
      const newSum =Math.max(0, prevSum + amount);

       if (newSum === 0 || newSum > section_1_sum_assured) {
        setErrorsec1("Please enter sum assured, must not be more than " + section_1_sum_assured);
      } else {
        setErrorsec1("");
      }

      return newSum;
    });
  };
  
  const handleChangeofsection3 = (amount) => {
    //setsec3SumAssured(prevSum => Math.max(0, prevSum + amount)); 
    setsec3SumAssured((prevSum) => {
      const newSum =Math.max(0, prevSum + amount);

       if (newSum === 0 || newSum > section_3_sum_assured) {
        setErrorsec3("Please enter sum assured, must not be more than 1500");
      } else {
        setErrorsec3("");
      }

      return newSum;
    });
    
    // Ensures it doesn't go below 0
  };
  const handleChangeofsection5 = (amount) => {
   // setsec5SumAssured(prevSum => Math.max(0, prevSum + amount)); // Ensures it doesn't go below 0
   setsec5SumAssured((prevSum) => {
    const newSum =Math.max(0, prevSum + amount);

     if (newSum === 0 || newSum > section_5_sum_assured) {
      setErrorsec5("Please enter sum assured, must not be more than" + section_5_sum_assured);
    } else {
      setErrorsec5("");
    }

    return newSum;
  });
  };


return(
  
  <Fragment>


<div className="header_section1">

<Navbar/>
<div className="container-fluid bg-breadcrumb">
  <div className="container text-center py-5">
  <h3 className="abt-txt my-5">{(lnstr === 'en') ? 'Home Insurance' : 'التأمين على المنزل\n'}</h3>
  </div>
</div>
</div>

<div className="features_section">
        <div className="container">

          <div className="row">

            <Col md={3}></Col>

            <Col md={6} className="myforms my-5">


      <div class="card mb-3">
  <div class="card-body">
    <h1 class="card-title" style={{"color":"#f95845"}}>Note:</h1>
    <p>Section 1 or Section 5 are mandatory. You can select either of them or both.</p>
  </div>
</div>

      
  <div className=" card mb-3 border-warning" >
  {/* <Card.Body> */}
    <div className="card-body d-flex justify-content-between align-items-center">
      <div>
        <h1 className=" card-tittle text-muted">Section 1</h1>
        
        <ul className="mb-3" style={{listStyleType:'none'}}>
            <li>Home Contents Cover</li>
            <li>Contents Temporarily Removed</li>
            <li>Replacement of Locks and Keys</li>
            <li>Contents in Open</li>
            <li>Stock in Deep Freezer / Refrigerator</li>
          </ul> 
      </div>
     
      <input type="checkbox" 
      checked={ischeckedsec1}
            onChange={handleCheckboxChange}/>
             </div>
          {isExpanded && (
            <div className="p-3 border rounded">
            <h5>Sum Assured</h5>
            <input
              type="text"
              className="form-control mb-1 text-center font-weight-bold"
              value={sumAssured}
              readOnly
            />
            {errorsec1 && <p style={{ color: "red" }}>{errorsec1}</p>}
            <div className="button-grid d-grid gap-3"
          style={{
            gridTemplateColumns: 'repeat(4, 1fr)',
            gridGap: '10px', 
            textAlign:'center'
          }}>



             <div className="button increase">
              <button className="btn btn-outline-info m-2" 
      style={{ 
                backgroundColor: '#33d0f2',
                color:'white',
                padding: '5px 10px', 
                fontSize: '12px' 
              
              }}onClick={() => handleChange(100)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z"/>
            </svg> 100 </button>
              <button className="btn btn-outline-info m-2"
              
               style={{
                backgroundColor: '#33d0f2',
                color:'white',
                padding: '5px 10px', 
                fontSize: '12px' 
              }}onClick={() => handleChange(500)}><svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" fill="currentColor" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z"/>
            </svg> 500</button>
              <button className="btn btn-outline-info m-2" 
               style={{
                backgroundColor: '#33d0f2',
                color:'white' ,
                padding: '5px 10px', 
                fontSize: '12px'    
              }}onClick={() => handleChange(1000)}><svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" fill="currentColor" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z"/>
            </svg> 1000</button>
              <button className="btn btn-outline-info m-2" 
               style={{
                backgroundColor: '#33d0f2',
                color:'white'  ,
                padding: '5px 10px', 
                fontSize: '12px'   
              }}onClick={() => handleChange(10000)}><svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" fill="currentColor" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z"/>
            </svg> 10000</button>
            </div>
              <button className="btn btn-outline-secondary m-2" 
              
              style={{
                backgroundColor: '#545b62',
                color:'white',padding: '5px 10px', 
                fontSize: '12px'     
              }} onClick={() => handleChange(-100)} disabled={sumAssured < 100}><svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" fill="currentColor" class="bi bi-dash-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1z"/>
            </svg> 100</button>
              <button className="btn btn-outline-secondary m-2"
              
              style={{
                backgroundColor: '#545b62',
                color:'white',padding: '5px 10px', 
                fontSize: '12px'   
              }} onClick={() => handleChange(-500)} disabled={sumAssured < 500}><svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" fill="currentColor" class="bi bi-dash-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1z"/>
            </svg> 500</button>
              <button className="btn btn-outline-secondary m-2"
               style={{
                backgroundColor: '#545b62',
                color:'white' ,
                padding: '5px 10px', 
                fontSize: '12px' 
              }} onClick={() => handleChange(-1000)} disabled={sumAssured < 1000}><svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" fill="currentColor" class="bi bi-dash-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1z"/>
            </svg> 1000</button>
              <button className="btn btn-outline-secondary m-2"
               style={{
                backgroundColor: '#545b62',
                color:'white',padding: '5px 10px', 
                fontSize: '12px'   
              }} onClick={() => handleChange(-10000)} disabled={sumAssured < 10000}><svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" fill="currentColor" class="bi bi-dash-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1z"/>
            </svg> 10000</button>
            </div>
            
          </div>
              )}
        {/* </Card.Body> */}
      </div>

      <div className=" card mb-3">
    {/* <Card.Body> */}
    <div className=" card-body d-flex justify-content-between align-items-center">
      <div>
        <h1 className="card-title text-muted">Section 2</h1>
        Accidental Damage to Contents
      </div>
      <input  type="checkbox" style={{ cursor:'pointer'}}/>
    </div>
  {/* </Card.Body> */}
</div>


      {/* Section 3 */}


      <card className=" card mb-3">
  {/* <Card.Body> */}
    <div className=" card-body d-flex justify-content-between align-items-center">
      <div>
        <h1 className="card-title text-muted">Section 3</h1>
        Personal Belongings, Valuables
      </div>
      <input
            type="checkbox" 
            onChange={handleCheckboxChangeofsection3}/>
            </div>
          {isExpandedofsec3 && (
            <div className="p-3 border rounded">
            <h5>Sum Assured</h5>
            <input
              type="text"
              className="form-control mb-3 text-center font-weight-bold"
              value={sec3sumAssured}
              readOnly
            />
             {errorsec3 && <p style={{ color: "red" }}>{errorsec3}</p>}
            <div className="d-flex justify-content-between flex-wrap">
   
            <div className="button increase">
            <button className="btn btn-outline-info m-2" 
      style={{ 
                backgroundColor: '#33d0f2',
                color:'white',padding: '5px 10px', 
                fontSize: '12px' 
              
              }}onClick={() => handleChangeofsection3(100)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" fill="currentColor" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z"/>
            </svg> 100 </button>
           



            <button className="btn btn-outline-info m-2" 
      style={{ 
                backgroundColor: '#33d0f2',
                color:'white',padding: '5px 10px', 
                fontSize: '12px' 
              
              }}onClick={() => handleChangeofsection3(500)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" fill="currentColor" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z"/>
            </svg> 500 </button>

  </div>
  <div className="button decrease">
            <button className="btn btn-outline-secondary m-2" 
              
              style={{
                backgroundColor: '#545b62',
                color:'white'  ,
                padding: '5px 10px', 
                fontSize: '12px'   
              }} onClick={() => handleChangeofsection3(-100)} disabled={sec3sumAssured < 100}><svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" fill="currentColor" class="bi bi-dash-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1z"/>
            </svg> 100</button>


            

            <button className="btn btn-outline-secondary m-2" 
              
              style={{
                backgroundColor: '#545b62',
                color:'white',padding: '5px 10px', 
                fontSize: '12px'     
              }} onClick={() => handleChangeofsection3(-500)} disabled={sec3sumAssured < 500}><svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" fill="currentColor" class="bi bi-dash-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1z"/>
            </svg> 500</button>
            </div>
              
            </div>
          </div>
              )}
        {/* </Card.Body> */}
      </card>


      {/* Section 4 */}

      <div className="card mb-3">
  {/* <Card.Body> */}
    <div className="card-body d-flex justify-content-between align-items-center">
      <div>
        <h1 className="text-muted">Section 4</h1>
        Personal Documents, Credit Card, Money
      </div>
      <input type="checkbox" />
    </div>
  {/* </Card.Body> */}
</div>

   {/* Section 5 */}
<div className="card mb-3">
  {/* <Card.Body> */}
    <div className=" card-body d-flex justify-content-between align-items-center">
      <div>
        <h1 className="card-title text-muted">Section 5</h1>
        
        Building(s) Cover
      </div>
      <input type="checkbox" 
      checked={isExpandedofsec5}
      onChange={handleCheckboxChangeofsection5}
      />
      
      </div>
      
        { /* <input
            type="checkbox" 
            label=""
            onChange={handleCheckboxChangeofsection5}
          />*/}
         
          {isExpandedofsec5 && (
            <div className="p-3 border rounded">
            <h5>Sum Assured</h5>
            <input
              type="text"
              className="form-control mb-3 text-center font-weight-bold"
              value={sec5sumAssured}
              readOnly
            />
             


{errorsec5 && <p style={{ color: "red" }}>{errorsec5}</p>}
            <div className="button-grid d-grid gap-3"
          style={{
            gridTemplateColumns: 'repeat(4, 1fr)',
            gridGap: '10px', 
            textAlign:'center'
          }}/>



            <div className=" button increase " style={{marginLeft:'25%'}}>
              <button className="btn btn-outline-info m-2 " 
      style={{ 
                backgroundColor: '#33d0f2',
                color:'white',padding: '5px 10px', 
                fontSize: '12px' 
              
              }}onClick={() => handleChangeofsection5(100)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" fill="currentColor" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z"/>
            </svg> 100 </button>

            


            <button className="btn btn-outline-info m-2" 
      style={{ 
                backgroundColor: '#33d0f2',
                color:'white',padding: '5px 10px', 
                fontSize: '12px' 
              
              }}onClick={() => handleChangeofsection5(1000)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" fill="currentColor" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z"/>
            </svg> 1000 </button>
            

            <button className="btn btn-outline-info m-2" 
      style={{ 
                backgroundColor: '#33d0f2',
                color:'white',padding: '5px 10px', 
                fontSize: '12px' 
              
              }}onClick={() => handleChangeofsection5(100000)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" fill="currentColor" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z"/>
            </svg> 10000 </button>
  
              </div>
          
<div className="button increase " style={{marginLeft:'25%'}}>
              <button className="btn btn-outline-secondary m-2"
               style={{
                backgroundColor: '#545b62',
                color:'white',padding: '5px 10px', 
                fontSize: '12px'   
              }} onClick={() => handleChangeofsection5(-100)} disabled={sec5sumAssured < 100}><svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" fill="currentColor" class="bi bi-dash-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1z"/>
            </svg> 100</button>



              <button className="btn btn-outline-secondary m-2"
               style={{
                backgroundColor: '#545b62',
                color:'white' ,padding: '5px 10px', 
                fontSize: '12px'  
              }} onClick={() => handleChangeofsection5(-1000)} disabled={sec5sumAssured < 1000}><svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" fill="currentColor" class="bi bi-dash-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1z"/>
            </svg> 1000</button>



            <button className="btn btn-outline-secondary m-2"
               style={{
                backgroundColor: '#545b62',
                color:'white' ,padding: '5px 10px', 
                fontSize: '12px'  
              }} onClick={() => handleChangeofsection5(-10000)} disabled={sec5sumAssured < 10000}><svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" fill="currentColor" class="bi bi-dash-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1z"/>
            </svg> 10000</button>
   </div> 
   </div>         
          
              )}
        {/* </Card.Body> */}
      </div>




        {/* Section 6 */}

        <div className="card mb-3">
  {/* <Card.Body> */}
    <div className="card-body d-flex justify-content-between align-items-center">
      <div>
        <h1 className="text-muted">Section 6</h1>
         <ul style={{ listStyleType: 'none'}} className="mb-3">
            <li>Liabilities Cover</li>
            <li>Personal Liability</li>
            <li>Liability as Owner of the insured Building</li>
          </ul>
      </div>
      <input type="checkbox" />
    </div>
  {/* </Card.Body> */}
</div>


      {/* Section 7 */}
      
     
      <div className=" card mb-3">
  {/* <Card.Body> */}
    <div className="card-body d-flex justify-content-between align-items-center">
      <div>
        <h1 className="card-tittle text-muted">Section 7</h1>
        Rent and Alternative Accommodation
      </div>
      <input type="checkbox" />
    </div>
  {/* </Card.Body> */}
</div>


      {/* Section 8 */}

      <div className="card mb-3">
  {/* <Card.Body> */}
    <div className="card-body d-flex justify-content-between align-items-center">
      <div>
        <h1 className="card-title text-muted">Section 8</h1>
        Death Cover
      </div>
      <input type="checkbox" />
    </div>
  {/* </Card.Body> */}
</div>


     
      {/* Proceed Button */}
      <button type="button" variant="secondary" onClick={handleSubmit} disabled={isDisabled} className="btn btn-primary rounded-pill text-white my-3 py-1 px-4 "
      style={{ backgroundColor: !isDisabled ? '#1D3561' : 'grey', borderColor: '#1D3561',borderRadius: '5px' }}>
        Proceed
      </button>







    {/* </Container> */}
</Col>
     

<Col md={3}></Col>

</div>

        
</div>
</div>

    <Footer />

    {loading && (
      <div className="overlay">
        <div className="spinner"></div>
      </div>
    )}

  </Fragment>
  
);  
};
export default Tenantpage;