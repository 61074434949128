import React, { Fragment, useState, useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { connect } from 'react-redux';
import { submitForm } from './redux/actions/actions';
import { useTranslation } from 'react-i18next';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';
import { GoogleOAuthProvider, googleLogout } from "@react-oauth/google";
import { useGoogleLogin } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";


import { LoginSocialFacebook } from "reactjs-social-login";
import { FacebookLoginButton } from "react-social-login-buttons";
import FacebookLogin from 'react-facebook-login';
// import {LoginSocialFacebook } from "react-social-login-buttons";
// import { LoginSocialFacebook } from 'react-login-social';
const { config } = require('../api/api');

const Login = ({ onSubmitForm, message, loginresult, error }) => {
  const base_url = config.baseURL;

  const [vendorKey, setVendorKey] = useState('');

  useEffect(() => {

    const postvData = { organization_name: "Superjet" };

    if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {

     
      fetch(base_url+'/create_organization', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postvData),
      })
        .then(response => response.json())
        .then(data => {
          setVendorKey(data.result[0].vendor_key);
        })
        .catch(error => {
          console.error('Error posting data:', error);
        });


    }
  }, [vendorKey]); // Dependency array


  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });



  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    debugger
    console.log(selectedOption);
    e.preventDefault();

    setLoading(false);

    var user_type = selectedOption == "option1" ? 'traveller' : 'agent';
    const data = {
      'email': formData.email,
      'password': formData.password,
      'vendor_key': vendorKey,
      'type': user_type
    };
    // debugger;
    onSubmitForm(data);
    // const userData = user;
    // sessionStorage.clear();
    // sessionStorage.removeItem('userData');
    Cookies.remove('userData');
  };

  const [showModal, setShowModal] = useState(false);
  const [showsuccesModal, setsuccessShowModal] = useState(false);
  const [alertmsg, setShowAlert] = useState('');
  const [resmsg, setResAlert] = useState('');

  const handleForgetPassword = () => {
    // Open modal for password reset
    setShowModal(true);
    debugger
    // window.location.href = '/';
  };

  // Function to close the modal
  const handleCloseModal = () => {
    resetForm();
    setShowModal(false);
    setResAlert('');
  };
 // Toggle the password visibility
 const togglePasswordVisibility1 = () => {
  setPasswordVisible1(!passwordVisible1);
};
// State to toggle password visibility
const [passwordVisible1, setPasswordVisible1] = useState(false);
const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
const toggleConfirmPasswordVisibility = () => {
  setConfirmPasswordVisible(!confirmPasswordVisible);
};

// Form state for input fields
const [email, setEmail] = useState('');
const [password, setPassword] = useState('');
const [confirmPassword, setConfirmPassword] = useState('');

 // Function to clear all input fields
 const resetForm = () => {
  setEmail('');
  setPassword('');
  setConfirmPassword('');
  setPasswordVisible(false);
  setConfirmPasswordVisible(false);
};

const [emailError, setEmailError] = useState('');

const validateEmail = (email) => {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailPattern.test(email);
};

const handleEmailChange = (e) => {
  const value = e.target.value;
  setEmail(value);

  // Check if the email is valid
  if (!validateEmail(value)) {
    setEmailError(t('Please enter a valid email address.'));
  } else {
    setEmailError(''); // Clear the error if valid
  }
};

const modalSubmit = async (e) => {
  if(email===''||emailError!==''){
    setShowAlert('Please enter a valid email address');
//   }else if(password===''){
//     setShowAlert('Please enter a password');
//   }else if(confirmPassword===''){
//     setShowAlert('Please enter a confirmPassword');
//  }else if(confirmPassword!==password){
//   setShowAlert('Password mismatch');
}else{
  setShowAlert('');
  setLoading(false);
debugger
    var user_type = selectedOption == "option1" ? 'traveller' : 'agent';
    const datav = {
      'email': email,
      // 'password': password,
      'vendor_key': vendorKey,
      'type': user_type
    };
    debugger
    console.log(datav);
    await fetch(base_url+'/restpassword', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(datav),
    })
      .then(response => response.json())
      .then(data => {
        debugger
        if(data.message=='success'){
          setResAlert('mail');
          setTimeout(() => {
            handleCloseModal();
          }, 1000);

          // setShowModal(false);
          // setResAlert('');
        }else{
          setResAlert(data.message);
        }


      })
      .catch(error => {
        console.error('Error posting data:', error);
      });
}
}
  // const FacebookLoginButton = () => {
  const [sdkLoaded, setSdkLoaded] = useState(false);

  useEffect(() => {
    // Check if the Facebook SDK script is already loaded
    if (window.FB) {
      setSdkLoaded(true);
      return;
    }

    // Load the Facebook SDK script
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: '1476922139695688', // Replace with your Facebook App ID
        cookie: true,
        xfbml: true,
        version: 'v16.0', // Use the appropriate version of the Facebook API
      });
      setSdkLoaded(true); // Mark SDK as loaded to prevent re-initialization
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        setSdkLoaded(true); // Mark as loaded if script is already present
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }, []);

  const handleFacebookLogin = () => {
    if (!sdkLoaded) {
      console.log('Facebook SDK not loaded yet.');
      return;
    }

    window.FB.login(
      function (response) {
        if (response.authResponse) {
          console.log('Welcome! Fetching your information....');
          window.FB.api('/me', { fields: 'name,email' }, function (response) {
            console.log('Name:', response.name);
            console.log('Email:', response.email);

            // Assuming `SocilaMediaLogInData` is your custom function
            SocilaMediaLogInData({ name: response.name, email: response.email }, 'facebook');
          });
        } else {
          console.log('User cancelled login or did not fully authorize.');
        }
      },
      { scope: 'email' } // You can add more scopes if needed
    );
  };

  const GoogleLoginButton = () => {
    const googleLogin = useGoogleLogin({
      onSuccess: async response => {
        debugger
        // The ID token contains user profile information
        const accessToken = response.access_token;
        if (accessToken) {
          try {
            // Fetch user info from Google's UserInfo endpoint
            const userInfoResponse = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            });
            const userInfo = await userInfoResponse.json();
            const { given_name, family_name, email } = userInfo;
            console.log('Given Name:', given_name);
            console.log('Family Name:', family_name);
            console.log('Email:', email);

            SocilaMediaLogInData({ given_name, family_name, email }, 'google');
          } catch (error) {
            console.error('Error fetching user info:', error);
          }
        } else {
          console.log('Access token is undefined');
        }
      },

      // onSuccess: credentialResponse => {
      //   console.log(credentialResponse)
      //   const decoded = jwtDecode(credentialResponse?.credential);
      //   SocilaMediaLogInData(decoded, 'google');
      // },
      onError: () => {
        console.log('Login Failed');
      },
    });

    return (
      <button
        onClick={() => googleLogin()}
        style={{
          backgroundColor: 'transparent',
          border: 'none',
          cursor: 'pointer',
          padding: 0,
          direction: 'ltr'
        }}
      >
        <img
          src="https://img.icons8.com/color/48/000000/google-logo.png"
          alt="Google Icon"
          style={{ width: '35px', height: '35px' }}
        />
      </button>
    );
  };

  async function SocilaMediaLogInData(data, source) {
    // console.log(data)

    // debugger

    if (source === 'google') {

      var GivenData = {
        'first_name': data.given_name,
        'last_name': data.family_name,
        'email': data.email,
        'password': '',
        'locale': 'en',
        'vendor_key': vendorKey
      }

      var vkData = GivenData;
      // console.log(GivenData)


      await fetch(base_url+'/traveller_google_signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(vkData),
      })
        .then(response => response.json())
        .then(data => {
          // debugger
          // console.log(data);
          // const storedUser = JSON.parse(storedUserString);

          if(data.message.toString().toLowerCase().indexOf('user already exist in this') > -1){
            window.alert(data.message);
          }
          else if (data.result && data.result.length !== 0) {
            const expiryDate = new Date();
            expiryDate.setTime(expiryDate.getTime() + (1 * 60 * 60 * 1000));

            // debugger

            Cookies.set('userData', JSON.stringify(data.result[0]), { expires: expiryDate });
            // localStorage.setItem('userData', JSON.stringify(data.result));
            window.location.href = '/';
          } else {
            //   setUserData(data.result);
          }

          // setCountries(data.result);
        })
        .catch(error => {
          console.error('Error fetching countries:', error);
        });


    } else if (source === 'facebook') {
      // console.log(data)
      var GivenData1 = {
        'first_name': data.first_name,
        'last_name': data.last_name,
        'email': data.email,
        'password': '',
        'locale': 'en',
        'vendor_key': vendorKey
      }


      var vkData1 = GivenData1;
      // console.log(GivenData)


      await fetch(base_url+'/traveller_google_signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(vkData1),
      })
        .then(response => response.json())
        .then(data => {
          // debugger
          // console.log(data);
          // const storedUser = JSON.parse(storedUserString);
          if (data.result && data.result.length !== 0) {
            const expiryDate = new Date();
            expiryDate.setTime(expiryDate.getTime() + (1 * 60 * 60 * 1000));

            // debugger

            Cookies.set('userData', JSON.stringify(data.result[0]), { expires: expiryDate });
            // localStorage.setItem('userData', JSON.stringify(data.result));
            window.location.href = '/';
          } else {
            //   setUserData(data.result);
          }

          // setCountries(data.result);
        })
        .catch(error => {
          console.error('Error fetching countries:', error);
        });

    }




  }

  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const [searchData, setSearchData] = useState(false);
  useEffect(() => {
    const storedSearchString = sessionStorage.getItem('searchData');
    if (storedSearchString) {
      const storedSearch = JSON.parse(storedSearchString);
      setSearchData(storedSearch);
    }
  }, [searchData]);


  const { t } = useTranslation();


  useEffect(() => {

    if (message === 'success') {

      const udata = loginresult;

      const expiryDate = new Date();
      expiryDate.setTime(expiryDate.getTime() + (1 * 60 * 60 * 1000));

      Cookies.set('userData', JSON.stringify(udata), { expires: expiryDate });

      // const urlSearchString = window.location.search;

      // const uparams = new URLSearchParams(urlSearchString);

      // //sessionStorage.setItem("userData", JSON.stringify(udata));

      // if(uparams && uparams.get('red')==='Visatypes')
      // {
      // window.location.href = '/Visatypes';
      // }
      // else if(uparams && uparams.get('red')==='Inboundtravel')
      // {
      // window.location.href = '/Inboundtravellerinfo';
      // }
      // else if(uparams && uparams.get('red')==='Outboundtravel')
      // {
      //   window.location.href = '/Outboundtravellerinfo';
      // }

      if (searchData) {
        window.location.href = '/visatypes';
      }
      else {
        window.location.href = '/';
      }

    }
    else {
      setLoading(false);
    }

  }, [message, loginresult, searchData]);
  const urlSearchString = window.location.search;

    const uparams = new URLSearchParams(urlSearchString);
  const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';
  const selctedtype = uparams.get('type') ? uparams.get('type') : 'option1';
  const [selectedOption, setSelectedOption] = useState(selctedtype);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  return (
    <Fragment>

      <Navbar />


      <div className="features_section layout_padding my-5">
        <div className="container">


          <div className="row">

            <Col md={3}></Col>

            <Col md={6} className="myforms">



              <h1 className={lnstr}>{(lnstr === 'en') ? 'Login' : 'تسجيل الدخول'}</h1>

              <div style={{ display: 'flex', alignContent: 'space-between' }}>
                <label style={{ width: '40%' }}>
                  <input
                    type="radio"
                    value="option1"
                    checked={selectedOption === 'option1'}
                    onChange={handleOptionChange}
                  />&nbsp;
                  User
                </label>

                <label>
                  <input
                    type="radio"
                    value="option2"
                    checked={selectedOption === 'option2'}
                    onChange={handleOptionChange}
                  />&nbsp;
                  Travel Agency
                </label>
              </div>

              <form onSubmit={handleSubmit}>

                <div className="input-group mb-2 mt-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text">{t('Email')}</span>
                  </div>
                  <input type="email" name="email" className="form-control" value={formData.email}
                    onChange={handleChange} required />
                </div>

                <div className="input-group mb-2 mt-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text">{t('Password')}</span>
                  </div>
                  <input type={passwordVisible ? 'text' : 'password'} name="password" className="form-control"
                    value={formData.password} onChange={handleChange} required />
                    <div className="input-group-append">
                  <button type="button" className="btn btn-outline-grey" onClick={togglePasswordVisibility}>
                    {passwordVisible ? <i className="fas fa-eye"></i> : <i className="fas fa-eye-slash"></i>}
                  </button>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <button type="submit" className="btn btn-primary rounded-pill text-white my-2 py-1 px-4">{t('Submit')}</button>
                  <button type="button" className="btn btn-link text-primary p-0" onClick={handleForgetPassword}>
                    {t('Forgot Password?')}
                  </button>
                </div>

                {/* <button type="submit"
                  className="btn btn-primary rounded-pill text-white my-2 py-1 px-4">{t('Submit')}</button> */}
                <span style={{ color: 'red' }}> {(message === 'success') ? '' : message}</span>
              </form>

              <p className="my-2">{(lnstr === 'en') ?
                <span>Don't have an account ? <a href={`/signup?type=${encodeURIComponent(selectedOption)}`}>Register</a></span> :
                <span> ليس لديك حساب؟ <a href={`/signup?type=${encodeURIComponent(selectedOption)}`}>سجل الآن</a></span>} </p>

            </Col>

            <Col md={3}></Col>


          </div>

          <div className="row">
            <Col md={3}></Col>
            <Col md={6} style={{ marginTop: '12px', marginBottom: '12px' }}>
              <div style={{ margin: '0 100px', display: 'flex', alignItems: 'center' }}>
                <div style={{ flexGrow: 1, height: '1px', backgroundColor: 'rgb(204 208 211)' }}></div>
                <span style={{ margin: '0 10px' }}>OR</span>
                <div style={{ flexGrow: 1, height: '1px', backgroundColor: 'rgb(204 208 211)' }}></div>
              </div>
            </Col>
          </div>


          <div className="row" style={{ direction: 'ltr' }}>
            <Col md={3}></Col>
            <Col md={6}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

              {/* <GoogleLogin
        clientId='532083496095-kkq6vev9b1li8bdr57b4m71eat6vv2vm.apps.googleusercontent.com'
        buttonText="Login with Google"
        onSuccess={(res)=>{
debugger;
        }}
        onFailure={(err)=>{
          debugger;
        }}
        cookiePolicy={"single_host_origin"}
      /> */}
                <GoogleOAuthProvider
                  // clientId='532083496095-kkq6vev9b1li8bdr57b4m71eat6vv2vm.apps.googleusercontent.com'>
                  clientId='976243094219-9ktk0lv4l3uej0f397hlf4s382ubme0l.apps.googleusercontent.com'>
                  {/*<FontAwesomeIcon icon={faGoogle} style={{color: "#B197FC", fontSize: "35px"}}/>*/}

                  <GoogleLoginButton />

                  {/*<GoogleLogin*/}
                  {/*    onSuccess={credentialResponse => {*/}
                  {/*      const decoded = jwtDecode(credentialResponse?.credential);*/}
                  {/*      SocilaMediaLogInData(decoded, 'google')*/}
                  {/*    }}*/}
                  {/*    onError={() => {*/}
                  {/*      console.log('Login Failed');*/}
                  {/*    }}*/}
                  {/*/>*/}


                </GoogleOAuthProvider>


                {/* <button

                  onClick={handleFacebookLogin}
                  style={{
                    backgroundColor: 'transparent',
                    border: 'none',
                    cursor: 'pointer',
                    padding: 0,
                    direction: 'ltr',
                  }}
                >
                  <img
                    src="https://img.icons8.com/color/48/000000/facebook-new.png"
                    alt="Facebook Icon"
                    style={{ width: '37px', height: '37px' }}
                  />
                </button> */}




                {/*<LoginSocialFacebook appId='917659810122928'*/}
                {/*                     onResolve={(response) => {*/}
                {/*                       SocilaMediaLogInData(response.data, 'facebook')*/}
                {/*                     }}*/}
                {/*                     onReject={(error) => {*/}
                {/*                       console.log(error)*/}
                {/*                     }}*/}
                {/*>*/}
                {/*  <FacebookLoginButton></FacebookLoginButton>*/}
                {/*</LoginSocialFacebook>*/}
              </div>

            </Col>
          </div>


        </div>
      </div>


      <Footer />

      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}
      {/* {showModal && <div className="modal-backdrop fade show"></div>} */}

<div
  className={`modal fade ${showModal ? 'show' : ''}`}
  tabIndex="-1"
  role="dialog"
  aria-labelledby="forgotPasswordModalLabel"
  aria-hidden={!showModal}
  style={{ display: showModal ? 'block' : 'none' }}
  onClick={handleCloseModal}
>
  <div
    className="modal-dialog"
    role="document"
    onClick={(e) => e.stopPropagation()}
  >
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="forgotPasswordModalLabel">{t('Forgot Password')}</h5>
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={handleCloseModal}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div className="modal-body">
        <p>{t('Please enter your email to reset your password.')}</p>
        <input
          type="email"
          className={`form-control ${emailError ? 'is-invalid' : ''}`}
          placeholder={t('Email')}
          value={email}
         onChange={(e) => handleEmailChange(e)}
          required
        />
        {emailError && <div className="invalid-feedback">{emailError}</div>}
        <p></p>
        {/* <div className="input-group mb-3">
          <input
            type={passwordVisible1 ? "text" : "password"}
            className="form-control"
            placeholder={t('New Password')}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <div className="input-group-append">
            <button
              className="btn btn btn-outline-grey"
              type="button"
              onClick={togglePasswordVisibility1}
            >
              {passwordVisible1 ? <i className="fas fa-eye"></i> : <i className="fas fa-eye-slash"></i>}
            </button>
          </div>
        </div> */}

        {/* <div className="input-group mb-3">
          <input
            type={confirmPasswordVisible ? "text" : "password"}
            className="form-control"
            placeholder={t('Confirm Password')}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          <div className="input-group-append">
            <button
              className="btn btn btn-outline-grey"
              type="button"
              onClick={toggleConfirmPasswordVisibility}
            >
              {confirmPasswordVisible ? <i className="fas fa-eye"></i> : <i className="fas fa-eye-slash"></i>}

            </button>
          </div>
        </div> */}
        <span style={{ color: 'red' }}> {(alertmsg === '') ? '' : alertmsg}</span>
       { resmsg === 'mail'? <span style={{ color: 'green' }}> Reset Password link is send your email </span>:<span style={{ color: 'red' }}> {(resmsg === '') ? '' : resmsg}</span>}

      </div>



            <div className="modal-footer">
              <button type="button"
              className="btn btn-primary"
              onClick={modalSubmit}
              >
                {t('Submit')} </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleCloseModal}
              >
                {t('Close')}
        </button>
      </div>

    </div>
  </div>
</div>

    </Fragment>

  );
};

const mapStateToProps = state => ({
  message: state.login.message,
  loginresult: state.login.logindata,
  error: state.login.error
});

const mapDispatchToProps = (dispatch) => ({
  onSubmitForm: (formData) => dispatch(submitForm(formData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);