import React, { Fragment, useState, useEffect,useRef } from "react";
import Navbar from "../Navbar";
import Cookies from 'js-cookie';
import Footer from "../Footer";

import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { event } from "jquery";
const { config } = require('../api/api');


function Ownerpagedetails(){

  const base_url = config.baseURL;

  const selectOpt1 = useRef(null);
  const selectOpt2 = useRef(null);
  const [building, setBuilding] = useState('');
  
    const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';
    const [loading, setLoading] = useState(false);

    const { t } = useTranslation();

    const [agevalue, setagevalue] = useState('');
    const [addressvalue, setaddressvalue] = useState('');
   const [addressError, setAddressError] = useState('');
   const[selectedfloor,setSelectedfloor]=useState('');
  const[selectedbuilding,setSelectedbuilding]=useState('');
  const [formData, setFormData] = useState([]);


  const [vendorKey, setVendorkey] = useState('');
  useEffect(() => {

      const postvData = { organization_name: "Superjet" };
      debugger
  
      if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {
  
  
        fetch( base_url + '/create_organization', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postvData),
        })
          .then(response => response.json())
          .then(data => {
            setVendorkey(data.result[0].vendor_key);
          })
          .catch(error => {
            console.error('Error posting data:', error);
          });
  
  
      }
    }, [vendorKey]);

  const [dhofarData, setDhofarData] = useState(false);
  useEffect(() => {


    const storedDhofarString = Cookies.get('homeData'); //sessionStorage.getItem('userData')
    debugger
    if (storedDhofarString !== undefined) {
      const storedDhofar = JSON.parse(storedDhofarString);
      setDhofarData(storedDhofar);
    }
    else {
      // alert("token expired");
      // window.location.href='/';
    }

  }, []);

  const postData = { token: dhofarData };
  useEffect(() => {
  debugger

    if (vendorKey !== '') {
      fetch(base_url + '/home_details', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${vendorKey}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),

      })
        .then(response => response.json())
        .then(data => {
          debugger
          setFormData(data.result.data);



        })
        .catch(error => {
          console.error('Error posting data:', error);
        });

    }
  }, [vendorKey]);





const handleSubmit = async (event) => {
  event.preventDefault();
debugger
  var data = {
    'home_details_age_of_building': agebuilding,
    "home_details_type_of_building": buildingtype,
    "home_details_floor_no":floorno,
    "home_details_address":address
  }

  sessionStorage.setItem("homedetails" , JSON.stringify(data));

  setLoading(true);
    // Check if the selected option is 'Personal Accident'
      window.location.href ='/home-insurance-photograph';
}

const [agebuilding, setagebuildingValue] = useState('');

  const handleChangeage = (event) => {
    setagebuildingValue(event.target.value);
  };

const [buildingtype, setBuildingType] = useState('')
  const handleChangeBuildingType = (name, value) => {

    // setFormData({ ...formData, [name]: value });
    setBuildingType(value)

    // console.log(formData);
  };

  const [floorno, setFloorNo] = useState('')
  const handleChangeFloorno = (name, value) => {

    // setFormData({ ...formData, [name]: value });
    setFloorNo(value)

    // console.log(formData);
  };

  const [address, setaAddressValue] = useState('');

  const handleChangeAddress = (event) => {
    setaAddressValue(event.target.value);
  };

  const [Protection , setProtection] = useState('');
  const handleChangeProtection = (event) => {
    setProtection(event.target.value)
  };

  const handleChangefloor=(event) => {
    setSelectedfloor(event.target.value);
  };
   
   const handleagevalue = (event) => {
      setagevalue(event.target.value);
    };

    const  handleaddressvalue = (event) => {
      setaddressvalue(event.target.value);
    };
    
    const handleChangebuilding=(event) => {
      setSelectedbuilding(event.target.value);
    };
    // function handleRadioChange(index, isChecked) {
    //   const updatedQuestionsData = [...questionsData];
    //   updatedQuestionsData[index] = {
    //     ...updatedQuestionsData[index],
    //     isChecked,
    //     isChecked_answer: isChecked ? "Yes" : "No"
    //   };
    //   setQuestionsData(updatedQuestionsData);
    // }

    // function handleDetailsChange(index, details) {

    //   const updatedQuestionsData = [...questionsData];
    //   updatedQuestionsData[index] = {
    //     ...updatedQuestionsData[index],
    //     details
    //   };
    //   setQuestionsData(updatedQuestionsData);
    // }


  const handleaddressBlur = (e) => {
    const { value } = e.target;

    // Optional: Regular expression to allow only letters, spaces, commas, and hyphens
    const addressPattern = /^[a-zA-Z\s,-]+$/;

    // Check if the Residential Location is empty or invalid
    if (value === '') {
      setAddressError('Please enter valid details');
      setaddressvalue('');
    } 
    else if (!addressPattern.test(value)) {
      setAddressError('Please enter valid details ');
      setaddressvalue('');
      
      
    }
    else {
      setAddressError('');
    }
  };

  // if (!Array.isArray(formData) || formData.length === 0) {
  //   return <div>No data available to display</div>;
  // }


  // const handleRadioChange = (value) => {

  //   setFormData((prevItem) => ({
  //     ...prevItem,
  //     isChecked: value,
  //   }));
  // };

  const [responses, setResponses] = useState({});

  // const handleTextChange = (key, value) => {
  //   setResponses(prev => ({
  //     ...prev,
  //     [key]: value
  //   }));
  // };

  const handleRadioChange = (key, value) => {
    debugger
    setFormData(prev => ({
      ...prev,
      key: value
    }));
  };

  function handleDetailsChange(index, details) {

    // const updatedQuestionsData = [...questionsData];
    // updatedQuestionsData[index] = {
    //   ...updatedQuestionsData[index],
    //   details
    // };
    // setQuestionsData(updatedQuestionsData);
  }

 

    return(
        <Fragment>

      <div className="header_section1">
        <Navbar />
      <div className="container-fluid bg-breadcrumb">
      <div className="container text-center py-5">
        <h3 className="abt-txt my-5">{(lnstr === 'en') ? 'Home Insurance' : 'Home Insurance\n'}</h3>
      </div>
      </div>
      </div>


      <div className="features_section">
        <div className="container">

          <div className="row">

            <Col md={3}></Col>

            <Col md={6} className="myforms my-5">


            <div>
    <h1>Property Insurance Form</h1>
    <form>
      {formData?.map(item => (
        <div key={item.key}>
          
          <form onSubmit={" "}>
          {item.type === 'Number' && item.key === 'age_of_building' &&  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                    </div>
                    <input type="number" placeholder = {(lnstr === 'en') ? item.text_english : item.text_arabi} name="registration" className="form-control"  value={agebuilding} onChange={handleChangeage} required/>
                </div>
    }

{item.type === 'Select' && item.key === 'type_of_building' &&  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                    </div>
                    <select className="form-control" name={item.key} value={buildingtype}  onChange={handleChangeBuildingType} required>
      <option value='' disabled selected >{(lnstr === 'en') ? item.text_english : item.text_arabi}</option>
              {item.options.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
                </div>
    }


{item.type === 'Select' && item.key === 'floor_no' &&  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                    </div>
                    <select className="form-control" name={item.key} value={floorno}  onChange={handleChangeFloorno} required>
      <option value='' disabled selected >{(lnstr === 'en') ? item.text_english : item.text_arabi}</option>
              {item.options.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
                </div>
    }


    {item.type === 'Yes-No' && item.key === 'is_property_in_basement' && (<div>
      <p>{(lnstr === 'en') ? item.text_english : item.text_arabi}</p>
      <div className="custom-radio" style={{ color: '#3fd2f2' }}>
  <input
    type="radio"
    id="yes"
    name="question"
    value="Yes"
  />
  <label for="yes">Yes</label>

  <input
    type="radio"
    id="no"
    name="question"
    value="No"
  />
  <label for="no">No</label>
</div> <br></br></div>

          )}

{item.type === 'Yes-No' && item.key === 'is_whole_building_residential' && (<div>
      <p>{(lnstr === 'en') ? item.text_english : item.text_arabi}</p>
            <div className="custom-radio" style={{color:'#3fd2f2'}}>
            <input
    type="radio"
    id="yes1"
    name="question1"
    value="Yes"
  />
  <label for="yes1">Yes</label>

  <input
    type="radio"
    id="no1"
    name="question1"
    value="No"
  />
  <label for="no1">No</label>
          </div><br></br></div>
          )}


{item.type === 'Text' && item.key === 'address' &&  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                    </div>
                    <textarea type="number" placeholder = {(lnstr === 'en') ? item.text_english : item.text_arabi} name="registration" className="form-control" value={address} onChange={handleChangeAddress} required/>
                <br></br>  <h2 class="heading-mt mt-md-4">Type of Fire protection devices installed in premises</h2></div>
    }


{item.type === 'Yes-No' && item.key === 'have_fire_alarm_system' && (<div>
      <p>{(lnstr === 'en') ? item.text_english : item.text_arabi}</p>
            <div className="custom-radio" style={{color:'#3fd2f2'}}>
            <input
    type="radio"
    id="yes2"
    name="question2"
    value="Yes"
  />
  <label for="yes2">Yes</label>

  <input
    type="radio"
    id="no2"
    name="question2"
    value="No"
  />
  <label for="no2">No</label>
          </div><br></br></div>
          )}


{item.type === 'Yes-No' && item.key === 'have_portable_extinguishers' && (<div>
      <p>{(lnstr === 'en') ? item.text_english : item.text_arabi}</p>
            <div className="custom-radio" style={{color:'#3fd2f2'}}>
            <input
    type="radio"
    id="yes3"
    name="question3"
    value="Yes"
  />
  <label for="yes3">Yes</label>

  <input
    type="radio"
    id="no3"
    name="question3"
    value="No"
  />
  <label for="no3">No</label>
          </div><br></br></div>
          )}
{item.type === 'Yes-No' && item.key === 'have_hydrant_system' && (<div>
      <p>{(lnstr === 'en') ? item.text_english : item.text_arabi}</p>
            <div className="custom-radio" style={{color:'#3fd2f2'}}>
            <input
    type="radio"
    id="yes4"
    name="question4"
    value="Yes"
  />
  <label for="yes4">Yes</label>

  <input
    type="radio"
    id="no4"
    name="question4"
    value="No"
  />
  <label for="no4">No</label>
          </div><br></br></div>
          )}

{item.type === 'Text' && item.key === 'other_fire_protection' &&  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                    </div>
                    <input type="text" placeholder = {(lnstr === 'en') ? item.text_english : item.text_arabi} name="registration" className="form-control" value={Protection} onChange={handleChangeProtection}  required/>
                    <br></br></div>
    }


{item.type === 'Yes-No' && item.key === 'have_surveillance_camera' && (<div>
  <br/>
  <h1>Security systems provided in the premises</h1>
 
      <p>{(lnstr === 'en') ? item.text_english : item.text_arabi}</p>
            <div className="custom-radio" style={{color:'#3fd2f2'}}>
            <input
    type="radio"
    id="yes5"
    name="question5"
    value="Yes"
  />
  <label for="yes5">Yes</label>

  <input
    type="radio"
    id="no5"
    name="question5"
    value="No"
  />
  <label for="no5">No</label>
          </div><br></br></div>
          )}


{item.type === 'Yes-No' && item.key === 'have_burglar_alarm_system' && (<div>
      <p>{(lnstr === 'en') ? item.text_english : item.text_arabi}</p>
            <div className="custom-radio" style={{color:'#3fd2f2'}}>
            <input
    type="radio"
    id="yes6"
    name="question6"
    value="Yes"
  />
  <label for="yes6">Yes</label>

  <input
    type="radio"
    id="no6"
    name="question6"
    value="No"
  />
  <label for="no6">No</label>
          </div><br></br></div>
          )}


{item.type === 'Yes-No' && item.key === 'have_grilled_doors' && (<div>
      <p>{(lnstr === 'en') ? item.text_english : item.text_arabi}</p>
            <div className="custom-radio" style={{color:'#3fd2f2'}}>
            <input
    type="radio"
    id="yes7"
    name="question7"
    value="Yes"
  />
  <label for="yes7">Yes</label>

  <input
    type="radio"
    id="no7"
    name="question7"
    value="No"
  />
  <label for="no7">No</label>
          </div><br></br></div>
          )}
    
    {item.type === 'Yes-No' && item.key === 'have_watchman_service' && (<div>
      <p>{(lnstr === 'en') ? item.text_english : item.text_arabi}</p>
            <div className="custom-radio" style={{color:'#3fd2f2'}}>
            <input
    type="radio"
    id="yes8"
    name="question8"
    value="Yes"
  />
  <label for="yes8">Yes</label>

  <input
    type="radio"
    id="no8"
    name="question8"
    value="No"
  />
  <label for="no8">No</label>
          </div><br></br></div>
          )}  

{item.type === 'Yes-No' && item.key === 'have_security_checkpoint' && (<div>
      <p>{(lnstr === 'en') ? item.text_english : item.text_arabi}</p>
            <div className="custom-radio" style={{color:'#3fd2f2'}}>
            <input
    type="radio"
    id="yes9"
    name="question9"
    value="Yes"
  />
  <label for="yes9">Yes</label>

  <input
    type="radio"
    id="no9"
    name="question9"
    value="No"
  />
  <label for="no9">No</label>
          </div><br></br></div>
          )}


{item.type === 'Text' && item.key === 'other_security_system' &&  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                    </div>
                    <input type="text" placeholder = {(lnstr === 'en') ? item.text_english : item.text_arabi} name="registration" className="form-control"  required/>
                    <br></br></div>
    }
    


    </form>
        </div>
      ))}
      <button variant="secondary"  className="btn btn-primary rounded-pill text-white my-3 py-1 px-4" onClick={handleSubmit} >{(lnstr === 'en') ? 'Proceed' : 'يتابع'}</button>
    </form>
  </div>
             
          </Col>
          
            <Col md={3}></Col>
           
          </div>
          
        </div>
      </div>





      
        
       
       <Footer />
       {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>

        
      )}

       </Fragment>
    )
}


export default Ownerpagedetails;