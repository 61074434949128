import React, { Fragment, useState, useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { useTranslation } from 'react-i18next';
import travelimg from '../tr.png';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';
import AOS from 'aos';
import 'aos/dist/aos.css';
const { config } = require('../api/api');



function MotorInsuranceComperhensive() {
  const base_url = config.baseURL;

  const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';


  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    insurancetype: '',
  });

  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showSection, setShowSection] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered1, setIsHovered1] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleOptionChange = (event) => {
    setSelected(event.target.value);
    debugger
    setFormData({ ...formData, 'insurancetype': event.target.value });

    if (event.target.value === ' ') {
      setShowSection(true);
      setButtonDisabled(true);
    }
    else {
      setShowSection(false);
      setButtonDisabled(false);
    }

  };

  const handleclose = async (event) => {
    event.preventDefault();
    window.location.href = '/dhofar_motor_comperhensive'
  }

  useEffect(()=>{
    const planvalue = sessionStorage.getItem('seletedplan');
    if(planvalue != undefined) {
      const plan = JSON.parse(planvalue)
      setSelected(plan.plan)
    }
  },[])

  useEffect(()=>{
    AOS.init({duration:1000});
  },[]);


  const popupStyle = {
    position: 'fixed',
    width:'50%',
    top: '50%',
    left: '48%',
    transform: 'translate(-50%, -50%)',
    padding: '20px',
    background: 'white',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    zIndex: 1000,
    borderRadius: '8px',
};


  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    debugger
      setIsOpen(!isOpen);
  };
  const handleClosePopup = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isOpen]);
  const Popup = ({ onClose }) => {

    return (
      // <div style={popupOverlayStyle} className="popup-overlay">

<div style={popupStyle}  className="popup-container p-3" data-aos='fade-up'>

<button 
        type="button" 
        onClick={handleClosePopup} 
        style={{
          position: 'absolute', 
          top: '1px', 
          right: '10px', 
          fontSize: '24px', 
          background: 'transparent', 
          border: 'none', 
          cursor: 'pointer',
          color:'black'
        }}
      >
        <span>&times;</span>
      </button>

<form>
<div className="center">Comprehensive Insurance</div>

                <div className="d-flex justify-content-between my-3 colmds-4 card m-0">
                  <div className="d-flex justify-content-between my-3">
                    <div>
                      <h4><strong>Plan</strong></h4>
                      <h6>Basic + Medex + Driver + Family (PAB) + UAE</h6>
                    </div>
                  </div>
                </div>
                <div>
                    <ul className="list-unstyled">
                      <li><h6>Coverage</h6></li>
                      <li><p>Basic</p></li>
                      <li><p>Medical Expenses</p></li>
                      <li><p>Personal Accident Benefit for Driver</p></li>
                      <li><p>Personal Accident Benefit for Family</p></li>
                      <li><p>UAE Geographical Cover</p></li>
                    </ul>
                  </div>
              </form>

      </div>
        
      // </div>  
    );
};



const handlesubmit = async (event) => {

  if (selected === null) {
    alert('Please choose a plan');
  }else{

  var data ={
    'plan': selected
  }
    sessionStorage.setItem('seletedplan' , JSON.stringify(data))
    event.preventDefault();
  window.location.href ='/dhofar_motor_comprehensivequote'

  }
}


const handleBack = async (event) => {

  debugger
    event.preventDefault();
  window.location.href ='/dhofar_motoroffers'
 
}
  




  return (
    <Fragment>

      <div className="header_section1">

        <Navbar />


        <div className="container-fluid bg-breadcrumb">
          <div className="container text-center py-5">
            <h3 className="abt-txt my-3">{(lnstr === 'en') ? 'Comprehensive' : 'شامل\n'}</h3>
          </div>
        </div>


      </div>


      <div className="features_section">
        <div className="container">

          <div className="row">

            <Col md={3}></Col>

            <Col md={6} className="myforms my-5" data-aos='fade-up'>



              <h4 className="px-0 py-2">{(lnstr === 'en') ? 'Select plan' : 'اختر الخطة\n'}</h4>

              <form >

                

                <div class="align-items-center travel-selection-option" onClick={togglePopup}>
                  {(lnstr === 'en') ? 'Basic + Medex + Driver + Family (PAB) + UAE' : 'أساسي + ميدكس + سائق + عائلة (PAB) + الإمارات العربية المتحدة\n'}
                  <input type="radio" name="personalaccident" value="Basic + Medex + Driver + Family (PAB) + UAE" className="d-inline-block option-circle ml-auto" onChange={handleOptionChange} />
                </div>

                <div class="align-items-center travel-selection-option my-3" onClick={togglePopup}>
                  {(lnstr === 'en') ? 'Basic + Medex + Driver + Family (PAB)' : 'أساسي + ميديكس + سائق + عائلة (PAB)\n'}
                  <input type="radio" name="personalaccident" value="Basic + Medex + Driver + Family (PAB)" className="d-inline-block option-circle ml-auto" onChange={handleOptionChange} />
                </div>
                <div class="align-items-center travel-selection-option my-3" onClick={togglePopup}>
                  {(lnstr === 'en') ? 'Basic + Medex + Driver + Family (PAB) + RSA' : 'أساسي + ميديكس + سائق + عائلة (PAB) + RSA\n'}
                  <input type="radio" name="personalaccident" value="Basic + Medex + Driver + Family (PAB) + RSA" className="d-inline-block option-circle ml-auto" onChange={handleOptionChange} />
                </div>
                <div class="align-items-center travel-selection-option my-3" onClick={togglePopup}>
                  {(lnstr === 'en') ? 'Basic + Medex + Driver + Family (PAB) + UAE + RSA' : 'أساسي + ميديكس + سائق + عائلة (PAB) + الإمارات العربية المتحدة + RSA\n'}
                  <input type="radio" name="personalaccident" value="Basic + Medex + Driver + Family (PAB) + UAE + RSA" className="d-inline-block option-circle ml-auto" onChange={handleOptionChange} />
                </div>

                {isOpen && <Popup onClose={togglePopup} />}               

              </form>

              <div>

              <button type="submit" disabled={buttonDisabled} onClick={handlesubmit} className="rounded-pill text-white my-1 py-1 px-4" style={{
            backgroundColor: isHovered ? '#0b8ba8' : '#33d0f2',
            transition: 'background-color 0.3s ease',
            position: 'absolute', 
            right: '10px',
          }}  onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>{(lnstr === 'en' ? 'Get Quote' : 'الحصول على الاقتباس')}</button>


<button type="submit" disabled={buttonDisabled} className="rounded-pill text-white my-1 py-1 px-4" style={{
            backgroundColor: isHovered1 ? '#0b8ba8' : '#33d0f2',
            transition: 'background-color 0.3s ease',
          }} onClick={handleBack}  onMouseEnter={() => setIsHovered1(true)} onMouseLeave={() => setIsHovered1(false)}>{(lnstr === 'en' ? 'Back' : 'خلف')}</button>
              </div>
            </Col>

            <Col md={3}></Col>

          </div>

        </div>
      </div>

      <Footer />

      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}

    </Fragment>
  );
}

export default MotorInsuranceComperhensive;
