import React,{Fragment, useState, useEffect} from "react";
import Navbar  from "../Navbar";
import Footer  from "../Footer";
import { useTranslation } from "react-i18next";
import { Container, Form, Row, Col, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';
import MedicalDownload from './Mednet_network_list.xlsx';
import  Table_Of_Benifits from './Download_Table_Of_Benifits.pdf';
import Download_Exclusion_List from './Download_Exclusion_List.pdf';
import Download_Policy_Wording from './Download_Policy_Wording.pdf';
import Download_Covid_terms_and_condition from './Download_Covid-19_terms_and_condition.pdf';
const { config } = require('../api/api');

function MedicalSumAssured(){
  const base_url = config.baseURL;

    const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';

    const { t } = useTranslation();
    const [greenplan, setGreenplan] = useState('');
    const [silverplan, setSilverPlan] = useState('');
    const [goldplan, setGold] = useState('');
    const [fullvalue, setFullvalue] = useState([]);
    const [assured, setAssuredvalue] = useState(null);
    const [color , setColor] = useState('')


    const [selectedColorPlan, setSelectedColorPlan] = useState('');
    const [selectedPlanLimits, setSelectedPlanLimits] = useState(null);


    const [selectedAnnualLimit, setSelectedAnnualLimit] = useState( selectedPlanLimits?.annual_limits?.[0] || '');
    const [selectedOutPatient, setSelectedOutPatient] = useState(selectedPlanLimits?.out_patient?.[0] || '');
    const [selectedCoPay, setSelectedCoPay] = useState(selectedPlanLimits?.co_pays?.[0] || '');
  

    const [vendorKey, setVendorKey] = useState('');

    useEffect(() => {
  
      const postvData = { organization_name: "Superjet" };
  
      if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {
  
  
        fetch( base_url + '/create_organization', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postvData),
        })
          .then(response => response.json())
          .then(data => {
            setVendorKey(data.result[0].vendor_key);
          })
          .catch(error => {
            console.error('Error posting data:', error);
          });
  
  
      }
    }, [vendorKey]);

    const [dhofarData, setDhofarData] = useState(false);
  const [age, setAge] = useState(null);
  useEffect(() => {


    const storedDhofarString = Cookies.get('HealthIndividualData'); //sessionStorage.getItem('userData')
    if (storedDhofarString !== undefined) {
      const storedDhofar = JSON.parse(storedDhofarString);
      setDhofarData(storedDhofar);
    }
    else {
      // alert("token expired");
      // window.location.href='/';
    }

  }, []);

  const Individualvaluejson = sessionStorage.getItem('mediindivialbasicinfo');
  const Individualvalue = JSON.parse(Individualvaluejson)
  console.log(Individualvalue)

  function formatDOB(dob) {
    const date = new Date(dob);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }
//   const [buttonDisabled, setButtonDisabled] = useState(false);
  const postData = { token: dhofarData };

    // const items =[{ amount: '24.000 ', description: ' 5,000' , plan : 'Green' },
    //     { amount: '15.000 ', description: ' 3,000' , plan : 'Silver' },
    //     { amount: '10.000 ', description: ' 1,000' , plan : 'Gold' },];

    const handleSubmit = async (event) => {

            event.preventDefault();
            setLoading(true);
debugger
            var data = {
              "color":selectedColorPlan,
              "assuredvalue": assured,
              "annuallimit": selectedAnnualLimit,
              "outpatient":selectedOutPatient,
              "copay": selectedCoPay
            }
            sessionStorage.setItem("premiumfullvalue" , JSON.stringify(data))
            
            event.preventDefault();
            window.location.href='/medical-plan';
          };
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const [loading, setLoading] = useState(false);


  useEffect(() => {
    const value_data ={
      'token':dhofarData,
      'dob': formatDOB(Individualvalue.dob),
      'gender':Individualvalue.basicinfo_gender_detail.toLowerCase(),
      'married':Individualvalue.basicinfo_marital_status_detail.toLowerCase() === "single" ? 'no' : 'yes',
    }
    debugger

    if (vendorKey !== '') {
      fetch( base_url + '/get_him_premiums', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${vendorKey}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(value_data),

      })
        .then(response => response.json())
        .then(data => {
          debugger
          setGreenplan(data.result.data.green[0]);
          setSilverPlan(data.result.data.silver[0]);
          setGold(data.result.data.gold[0]);
        })
        .catch(error => {
          console.error('Error posting data:', error);
        });

    }
  }, [vendorKey]
  );





  //

  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    debugger
      setIsOpen(!isOpen);
  };
  const getPlanLimitsDetails = (color_plan) => {
    debugger
    setSelectedColorPlan(color_plan);

    const value_data = {
      'token': dhofarData,
    }
    fetch(base_url + '/get_him_planoptions', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${vendorKey}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(value_data),

    })
      .then(response => response.json())
      .then(data => {
        debugger
        console.log(selectedColorPlan);
        // setSelectedPlanLimits(data?.result?.data[color_plan]);
        const planLimits = data?.result?.data[color_plan];
        if (planLimits) {
          setSelectedPlanLimits(planLimits);
          getfullPlansDetails(color_plan);
          setSelectedAnnualLimit(planLimits?.annual_limits?.[0] || '');
          setSelectedOutPatient(planLimits?.out_patient?.[0] || '');
          setSelectedCoPay(planLimits?.co_pays?.[0] || '');
        } else {

          console.error(`No data found for color plan: ${color_plan}`);
        }

      })
      .catch(error => {
        console.error('Error posting data:', error);
      });
      

  }


  const getfullPlansDetails = (color_plan) => {
    setSelectedColorPlan(color_plan);

 const value_data = {
      'token':dhofarData,
      'dob': formatDOB(Individualvalue.dob),
      'gender':Individualvalue.basicinfo_gender_detail.toLowerCase(),
      'married':Individualvalue.basicinfo_marital_status_detail.toLowerCase() === "single" ? 'no' : 'yes',
    }
    fetch( base_url + '/get_him_premiums', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${vendorKey}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(value_data),

    })
      .then(response => response.json())
      .then(data => {
        debugger
        setFullvalue(data?.result?.data[color_plan]);
      })
      .catch(error => {
        console.error('Error posting data:', error);
      });


  }



  const getMatchingPremium = () => {
    const matchingPlan = fullvalue.find(plan =>
      plan["annual-limit"] === selectedAnnualLimit &&
      plan["co-pays"] === selectedCoPay &&
      plan["out-patient"] === selectedOutPatient
    );
    if (matchingPlan == null) {
      setAssuredvalue(null);
    } else {
      setAssuredvalue(matchingPlan.premium);
      return matchingPlan.premium;
    }
    
  };

  const Popup = ({ onClose }) => {

    return (
      
      <div style={popupStyle}  className="popup-container p-3" >

    <h4 className="px-0 py-2 text-center">{(lnstr === 'en') ? 'Choose your limits' : 'اختر حدودك\n'}</h4>

<form>

<div className="d-flex justify-content-between my-3 colmds-4 card m-0">
<div className="d-lg-flex justify-content-between my-1">
  <div>
  <h3><strong>{selectedColorPlan.toUpperCase()}</strong></h3>
  <p><strong>See Details</strong></p>
  </div>
  <div>
<h1>{getMatchingPremium()} OMR</h1>
</div>

</div>
</div>
{/* <div> */}

<div>
<div className="my-3">
            <h4>{lnstr === 'en' ? 'Annual Limit' : 'المبلغ المؤمن'}</h4>
            <div className="pl-3">
              {selectedPlanLimits?.annual_limits?.map((limit, index) => (
                <div key={index} className="d-flex align-items-center my-2">
                  <input
                    style={{ marginRight: '10px' , marginBottom: '7px' }}
                    type="radio"
                    id={`annual_limit_${index}`}
                    name="annual_limit"
                    value={limit}
                    checked={selectedAnnualLimit === limit}
                    onChange={(e) => setSelectedAnnualLimit(e.target.value)}
                  />
                  <label htmlFor={`annual_limit_${index}`}  style={{ fontSize: '16px' }}>{limit}</label>
                </div>
              ))}
            </div>
          </div>

          {/* Out Patient Deductible Section */}
          <div className="my-3">
            <h4>{lnstr === 'en' ? 'Op Deductible' : 'المرجع للخصم'}</h4>
            <div className="pl-3">
              {selectedPlanLimits?.out_patient?.map((out, index) => (
                <div key={index} className="d-flex align-items-center my-2">
                  <input
                    style={{ marginRight: '10px', marginBottom:'7px' }}
                    type="radio"
                    id={`out_patient_${index}`}
                    name="out_patient"
                    value={out}
                    checked={selectedOutPatient === out}
                    onChange={(e) => setSelectedOutPatient(e.target.value)}
                  />
                  <label htmlFor={`out_patient_${index}`} style={{ fontSize: '16px' }}>{out}</label>
                </div>
              ))}
            </div>
          </div>
          {/* Co-Pay Section */}
          <div className="my-3">
            <h4>{lnstr === 'en' ? 'PM Co Pay' : 'PM Co الدفع'}</h4>
            <div className="pl-3">
              {selectedPlanLimits?.co_pays?.map((copay, index) => (
                <div key={index} className="d-flex align-items-center my-2">
                  <input
                    style={{ marginRight: '10px', marginBottom: '7px' }}
                    type="radio"
                    id={`copay_${index}`}
                    name="copay"
                    value={copay}
                    checked={selectedCoPay === copay}
                    onChange={(e) => setSelectedCoPay(e.target.value)}
                  />
                  <label htmlFor={`copay_${index}`} style={{ fontSize: '16px' }}>{copay}</label>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* Action Buttons Section */}
        <div className="d-flex flex-column flex-sm-row justify-content-between my-3">
          <button
            type="button"
            disabled={buttonDisabled}
            onClick={onClose}
            className="btn btn-primary rounded-pill text-white my-1 py-1 px-4"
          >
            {t('Cancel')}
          </button>
          <button
            type="submit"
            disabled={buttonDisabled}
            onClick={handleSubmit}
            className="btn btn-primary rounded-pill text-white my-1 py-1 px-4"
          >
            {t('Next')}
          </button>
        </div>


</form>

        </div>
        
    );
};

const popupStyle = {
    position: 'fixed',
    width:'35%',
    top: '50%',
    left: '48%',
    transform: 'translate(-50%, -50%)',
    padding: '20px',
    background: 'white',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    zIndex: 1000,
    borderRadius: '8px',
};


const popupback = {
  position: "fixed",
  top: '50%',
  left: '50%',
  width: "40%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: "1000",
} 
    return(
        <Fragment>
            <div className="header_section1">
                <Navbar/>
                <div className="container-fluid bg-breadcrumb">
                    <div className="container text-center py-5">
                        <h3 className="abt-txt my-5">{(lnstr === 'en') ? 'Choose your Plan':'اختر خطتك'}</h3>
                    </div>
                </div>
            </div>

            <div className="features_sections"  style={{backgroundColor: isOpen ? "rgba(0, 0, 0, 0.5)" : "transparent", backdropFilter: isOpen ? "blur(5px)" : "none", zIndex: isOpen ? 1040 : "auto", 
        }}>
                <div className="container">
                    <div className="row">
                        <Col md={2}></Col>
                        <Col md={9} className="myforms my-5">
                        <ul style={{listStyle : 'Circle' , fontSize: '30px', margin:'13px'}}>
                          <li>
                            <div style = {{display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                              <div>
                              <p>Plan starting from</p>
                            <h1 className="text-bold"><strong>{greenplan.premium} {"OMR"}</strong></h1>
                            <h2 ><b><u>GreenPlan</u></b></h2>
                            <p> Green Network with Annual Limit between 3,000 to 5,000 OMR</p>
                              </div>
                            {/* <a herf ={MedicalDownload} download='Mednet network list.xlsx'>Download Network List</a> */}
                            <div style = {{marginBottom:'110px' , marginRight: '90px'}}>
                            <button type="submit" disabled={buttonDisabled} onClick={async ()=>{
                              await getPlanLimitsDetails('green')
                              togglePopup();
                        
                            }} className="btn btn-primary rounded-pill text-white my-1 py-1 px-4">{t('ADD')}</button>
                            </div>
                            </div> 
                          </li>



                          <li>
                            <div style = {{display: "flex", justifyContent: "space-between", alignItems: "center" ,  flexDirection: 'row'}}>
                            <div>
                            <p>Plan starting from</p>
                            <h1 className="text-bold"><strong>{silverplan.premium} {"OMR"}</strong></h1>
                            <h2 ><b><u>SilverPlan</u></b></h2>
                            <p>Silver Network with Annual Limit between 3,000 to 10,000 OMR</p>
                            </div>

                            <button style={{marginRight:'90px'}} type="submit" disabled={buttonDisabled} onClick={async ()=>{
await getPlanLimitsDetails('silver');
togglePopup();
}}  className="btn btn-primary rounded-pill text-white my-1 py-1 px-4">{t('ADD')}</button>
                            {/* <a herf ={MedicalDownload} download='Mednet network list.xlsx'>Download Network List</a> */}
                            {/* <div style = {{marginBottom:'110px' , marginRight: '90px'}}  >
                           
                            </div> */}
                            </div>
                          </li>



                          <li>
                            <div style = {{display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                              <div>
                              <p>Plan starting from</p>
                            <h1 className="text-bold"><strong>{goldplan.premium} {"OMR"}</strong></h1>
                            <h2 ><b><u>GoldPlan</u></b></h2>
                            <p>Gold Network with Annual Limit between 5,000 to 15,000 OMR</p>
                              </div>
                           
                            {/* <a herf ={MedicalDownload} download='Mednet network list.xlsx'>Download Network List</a> */}
                            <div style = {{marginBottom:'110px' , marginRight: '90px'}} >
                            <button type="submit" disabled={buttonDisabled} onClick={async ()=>{
await getPlanLimitsDetails('gold');
togglePopup();
}}  className="btn btn-primary rounded-pill text-white my-1 py-1 px-4">{t('ADD')}</button>
                            </div>
                            </div>
                           <p>---------------------------------------------------------------------</p> 
                          </li>
                        </ul>
                       <div><i className="fas fa-chevron-right" style={{marginRight:'15px'}}></i><u><a href={Table_Of_Benifits} download='Download_Table_Of_Benifits.pdf'>Download Table Of Benifits</a></u></div> 
                       <div><i className="fas fa-chevron-right" style={{marginRight:'15px'}}></i><u><a href={Download_Exclusion_List} download='Download_Exclusion_List.pdf'>Download Exclusion List</a></u></div>
                       <div><i className="fas fa-chevron-right" style={{marginRight:'15px'}}></i><u><a href={Download_Policy_Wording} download='Download_Policy_Wording.pdf'>Download Policy Wording</a></u></div>
                       <div><i className="fas fa-chevron-right" style={{marginRight:'15px'}}></i><u><a href={Download_Covid_terms_and_condition} download='Download_Covid-19_terms_and_condition.pdf'>Download Covid-19 terms and condition</a></u></div>
                                       
                        </Col>
                        <Col md={1}></Col>                        
                    </div>
                </div>

            {isOpen && <Popup onClose={togglePopup} />}
            </div>

            <Footer/>

            {loading && (
                <div className="overlay">
                <div className="spinner"></div>
                </div>
            )}
        </Fragment>

    );


}
export default MedicalSumAssured;