import React, { Fragment, useState, useEffect,useRef } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { useTranslation } from 'react-i18next';
import travelimg from '../tr.png';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';
import AOS from 'aos';
import 'aos/dist/aos.css';

function DhofarMotorInsuranceSuccess() {

  const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';
  const signatureRef = useRef();

  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    insurancetype: '',
  });
  const [loading, setLoading] = useState(false);
  useEffect (() =>{
    AOS.init({duration : 1000});
  },[]);


  const handleSubmit = async (event) => {

    event.preventDefault();
    setLoading(true);

    event.preventDefault();
    window.location.href='/dhofar_motorinsurance';

  

  };

   


   


  return (
    <Fragment>

      <div className="header_section1">

        <Navbar />

      </div>


      <div className="features_section">
        <div className="container">

          <div className="row">

            <Col md={3}></Col>

            <Col md={6} className="myforms my-5" data-aos='fade-up'>

            <div>
            <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52" style={{width: '52px' , height: '52px;'}}><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"></circle><path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"></path></svg>
            </div>

            <div className="thanks-title text-center mt-4">
                <h2>{(lnstr === 'en') ? 'Your premium payment is successfull.' : 'تم دفع قسط التأمين الخاص بك بنجاح.'}<br></br></h2>
                <h2>{(lnstr === 'en') ? 'Thank you!':'شكرًا لك!'}</h2>

                <h2>Please <u>download your policy</u></h2>

            </div>
            <br></br>

            <div className="d-flex justify-content-center">
            <button type="submit" className="btn btn-primary rounded-pill text-white " onClick={handleSubmit}>{(lnstr === 'en') ? 'Go To Home' : 'يتابع'}</button>
            </div>

            </Col>

            <Col md={3}></Col>

          </div>

        </div>
      </div>

      <Footer />

      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}

    </Fragment>
  );
}

export default DhofarMotorInsuranceSuccess;
