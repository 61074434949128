import React, { Fragment, useState, useEffect, useRef } from "react";
import Navbar from "../../Navbar";
import Footer from "../../Footer";
import { useTranslation } from 'react-i18next';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';
import Selectoption from "../../personalaccidentinsurance/Selectoptions";
import Table_Of_Benifits from '../Download_Table_Of_Benifits.pdf';
import Download_Exclusion_List from '../Download_Exclusion_List.pdf';
import Download_network_list from '../NextCare.xlsx';
import Download_Covid_terms_and_condition from '../Download_Covid-19_terms_and_condition.pdf';
import $ from 'jquery';
const { config } = require('../../api/api');


function MedicalWorkerBasicInfo() {
  const base_url = config.baseURL;

  const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';


  const { t } = useTranslation();


  const [loading, setLoading] = useState(false);
  const [selectedDateofBirth, setSelectedDateofBirth] = useState(null);
  const [selectedGender, setSelectedGender] = useState(null);
  const [selectedmarital, setSelectedmarital] = useState(null);
  const [dobError, setDobError] = useState("");
  const selectOpt1 = useRef(null);

  const [selectednationality, setSelectednationality] = useState('Oman');
  const [vendorKey, setVendorKey] = useState('');

  // const [selectedOption, setSelectedOption] = useState(null);
  // const [loading, setLoading] = useState(false);
  // const [showSection, setShowSection] = useState(false);

  // const [buttonDisabled, setButtonDisabled] = useState(false);

  // const handleOptionChange = (event) => {
  //   setSelectedOption(event.target.value);
  //   setFormData({ ...formData, 'insurancetype': event.target.value });

  //   if (event.target.value === 'Outbound Travel') {
  //     setShowSection(true);
  //     setButtonDisabled(true);
  //   }
  //   else {
  //     setShowSection(false);
  //     setButtonDisabled(false);
  //   }

  // };



  // const [selectedqOption, setSelectedqOption] = useState(null);
  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split('-').map(Number);
    return new Date(year, month - 1, day); // month is 0-indexed
  };

  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = parseDate(dob);

    // Check if birthDate is valid
    if (isNaN(birthDate.getTime())) {
      // throw new Error('Invalid date');
    }

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    // Adjust age if the birth date hasn't occurred yet this year
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  };

  const handleqOptionChange = (event) => {

    // if (event.target.value === 'No') {
    //   alert("The outbound plans are available only for residents of Oman.");
    //   setButtonDisabled(true);
    //   return;
    // }
    // else {
    //   setButtonDisabled(false);
    // }

  };


  // const [vendorKey, setVendorKey] = useState('');

  useEffect(() => {

    const postvData = { organization_name: "Superjet" };
    debugger

    if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {


      fetch(base_url + '/create_organization', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postvData),
      })
        .then(response => response.json())
        .then(data => {
          setVendorKey(data.result[0].vendor_key);
        })
        .catch(error => {
          console.error('Error posting data:', error);
        });


    }
  }, [vendorKey]); // Dependency array


  const handleSubmit = async (event) => {

    debugger

    if (selectedGender === null){
      event.preventDefault();
      document.getElementsByName('gender')[0].focus();
    }else if (selectedmarital === null){
      event.preventDefault();
      document.getElementsByName('maritalstatus')[0].focus();
    }else{

      var data = {
        "basicinfo_gender_id": selectedgenderOptionid,
        "basicinfo_gender_detail": selectedGender,
        "basicinfo_marital_status_id": selectedmaritalOptionid,
        "basicinfo_marital_status_detail": selectedmarital,
        "dob": selectedDateofBirth,
        'nationality': selectednationality,
      }
  
      debugger
  
      sessionStorage.setItem("medicalbasicinfo", JSON.stringify(data))
  
      event.preventDefault();
      setLoading(true);
  
      event.preventDefault();
      window.location.href = '/medicalworker-coverplan';
    }

    

  };

  useEffect(() => {
    debugger
    const basicvalue = sessionStorage.getItem('medicalbasicinfo');
    if (basicvalue != undefined) {
      const value = JSON.parse(basicvalue);
      setSelectedGender(value.basicinfo_gender_detail);
      setSelectedDateofBirth(value.dob);
      setSelectedmarital(value.basicinfo_marital_status_detail);
      setSelectednationality(value.nationality);
      setLoading(false);

    }

  }, []);

  const [dhofarData, setDhofarData] = useState(false);
  const [planvalue, setPlan] = useState(false);
  const [age, setAge] = useState(null);
  useEffect(() => {

    const storedDhofarString = Cookies.get('medicalData'); //sessionStorage.getItem('userData')
    if (storedDhofarString !== undefined) {
      const storedDhofar = JSON.parse(storedDhofarString);
      setDhofarData(storedDhofar);
    }
    else {
      // alert("token expired");
      // window.location.href='/';
    }

  }, []);



  // useEffect(() => {
  //   const vendortoken = Cookies.get('Vendorkey');

  //   if (vendortoken !== undefined) {

  //     const storedVendor = vendortoken;
  //     setVendorkey(storedVendor)


  //   }
  // }, []);

  const [buttonDisabled, setButtonDisabled] = useState(false);
  const postData = { token: dhofarData };
  // const today = new Date().toISOString().split('T')[0];
  const today = new Date();
  const maxDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate()).toISOString().split('T')[0];
  const minDate = new Date(today.getFullYear() - 60, today.getMonth(), today.getDate()).toISOString().split('T')[0];

  const [dobValue, setdobValue] = useState('');
  const handledobChange = (event) => {
    const selectedDob = event.target.value;
    setdobValue(event.target.value);

    const dobDate = new Date(selectedDob);
    const today = new Date();
    let calculatedAge = today.getFullYear() - dobDate.getFullYear();
    const monthDiff = today.getMonth() - dobDate.getMonth();

    // Adjust age if the current date is before the user's birthday in the current year
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dobDate.getDate())) {
      calculatedAge--;
    }

    setAge(calculatedAge);
  };

  const [selectedgenderOption, setSelectedgenderOption] = useState(null);
  const [selectedgenderOptionid, setSelectedgenderOptionid] = useState(null);
  const handlegenderChange = (event) => {
    setSelectedGender(event.target.value);
    setSelectedgenderOptionid(event.target.options[event.target.selectedIndex].id);
    setSelectedgenderOption(event.target.value);
  };


  const [selectedmaritalOption, setSelectedmaritalOption] = useState(null);
  const [selectedmaritalOptionid, setSelectedmaritalOptionid] = useState(null);
  const handlemaritalChange = (event) => {
    setSelectedmarital(event.target.value)
    setSelectedmaritalOptionid(event.target.options[event.target.selectedIndex].id);
    setSelectedmaritalOption(event.target.value);
  };
  //getgender
  const handleDOBBlur = (e) => {
    const { value } = e.target;
    const [year, month, day] = value.split("-");
    const currentDay = today.getDate();
    const currentMonth = new Date().getMonth() + 1;
    if (age < 18) {
      // document.getElementsByName("dob")[0].focus();
      setDobError("Age must be within 18 to 60 years.");
      } else if (age > 60) {
          // document.getElementsByName("dob")[0].focus();
      setDobError("Age must be within 18 to 60 years.");
      } else if (age === 60 && parseInt(month, 10) < currentMonth) {
          // document.getElementsByName("dob")[0].focus();
      setDobError("Age must be within 18 to 60 years, and for age 60, the month must be the current month or later.");
      } else if (age === 18 && parseInt(month, 10) == currentMonth&&parseInt(day, 10) == currentDay) {
          // document.getElementsByName("dob")[0].focus();
       setDobError("Age must be within 18 to 60 years, and for age 18, the selected date must not be today's date.");
      }else if (age === 60 && parseInt(month, 10) == currentMonth&& parseInt(day, 10) < currentDay) {
          // document.getElementsByName("dob")[0].focus();
      setDobError("Age must be within 18 to 60 years, and for age 60, the selected date must not be before today's date.");
      }else{
      setDobError('');
  } };


  const [goptions, setGender] = useState([]);
  const [moptions, setMarital] = useState([]);

  useEffect(() => {
    debugger
    if (goptions && goptions.length === 0 && vendorKey !== '') {

      

      debugger
      fetch(base_url + '/getGender', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${vendorKey}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
      })
      
        .then(response => response.json())
        .then(data => {
          debugger
          setGender(data.result.data.response_data);
          married()

        })
        .catch(error => {
          console.error('Error posting data:', error);
        });

    }    
  },[vendorKey]
  );

  const married = () => {
    debugger
    if (moptions && moptions.length === 0 && vendorKey !== '') {
      setLoading(true);

      fetch(base_url + '/getMaritalStatus', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${vendorKey}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
      })
        .then(response => response.json())
        .then(data => {
          debugger

          setMarital(data.result.data.response_data);
          setLoading(false);
          document.getElementsByName('gender')[0].focus();


        })
        .catch(error => {
          console.error('Error posting data:', error);
        });

    }
  };

  //getmaritalstatus

  const [natioalitys, setNationalityValue] = useState([]);

  useEffect(() => {

    //debugger


    const fetchNationality = async () => {
      try {


        const response = await fetch(base_url + '/get_udw_countries', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${vendorKey}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postData),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();

        setNationalityValue(data.result.data);
      } catch (error) {
        console.error('Error fetching occupations:', error);
      }
    };

    fetchNationality();
  },
    [vendorKey]);

  useEffect(() => {
    //debugger
    // Initialize Select2
    $(selectOpt1.current).select2();

    // Handle Select2 change event
    $(selectOpt1.current).on('select2:select', (e) => {
      debugger
      // Find the selected option by matching the code (value)
      const selectedValue = e.params.data.id;
      debugger

      setSelectednationality(selectedValue);

      // Call onChange with both the English name and the code
      // if (selectedOption) {
      //   //debugger
      //   onChange(name, selectedOption.ENGLISH, selectedOption.CODE);  // Pass both ENGLISH and CODE
      // }
    });

    // Clean up Select2 instance on unmount
    return () => {
      $(selectOpt1.current).select2('destroy');
    };
  }, [vendorKey, natioalitys]);

  const handleChange1 = (name, value) => {

    // setFormData({ ...formData, [name]: value });
    setSelectednationality(value)

    // console.log(formData);
  };

  const [formData, setFormData] = useState({
    nationality: 'Oman',
  });



  // const handleSubmit = async (event) => {

  //   event.preventDefault();

  //   if (selectedOption === null) {
  //     alert('Please choose plan');
  //     return false;
  //   }
  // }

  const isButtonDisabled = !selectedGender || !selectedmarital;






  return (
    <Fragment>

      <div className="header_section1">

        <Navbar />


        <div className="container-fluid bg-breadcrumb">
          <div className="container text-center py-5">
            <h3 className="abt-txt my-5">{(lnstr === 'en') ? 'Unified Domestic Worker' : 'العمالة المنزلية الموحدة\n'}</h3>
          </div>
        </div>


      </div>


      <div className="features_section">
        <div className="container">

          <div className="row">

            <Col md={3}></Col>

            <Col md={6} className="myforms my-5">



              <h4 className="px-0 py-2">{(lnstr === 'en') ? 'Select Insured Details' : 'حدد تفاصيل المؤمن عليه\n'}</h4>

              <form onSubmit={handleSubmit}>
                <div className="input-group mb-2 mt-2">
                  <div className="input-group-prepend prepends">
                    <span className="input-group-text">{t('Gender')}<span style={{color:'red', marginLeft: '5px'}}>*</span></span>
                  </div>
                  <select value={selectedGender} name="gender" className="form-control" onChange={handlegenderChange} required>
                    <option disabled selected>{(lnstr === 'en') ? '-- Select --' : '--اختار--'}</option>
                    {goptions && goptions.map((opt) =>
                      <option key={opt.id} id={opt.id} value={opt.description}>{t(opt.description)}</option>
                    )}
                  </select>
                </div>
                <div className="input-group mb-2 mt-2">
                  <div className="input-group-prepend prepends">
                    <span className="input-group-text">{(lnstr === 'en') ? 'Marital Status' : 'الحالة الأجتماعية'}<span style={{color:'red', marginLeft: '5px'}}>*</span></span>
                  </div>
                  <select value={selectedmarital} name="maritalstatus" className="form-control" onChange={handlemaritalChange}
                    required>
                    <option disabled selected>{(lnstr === 'en') ? '-- Select --' : '--اختار--'}</option>
                    {moptions && moptions.map((mopt) =>
                      <option key={mopt.id} id={mopt.id}
                        value={mopt.description}>{t(mopt.description)}</option>
                    )}
                  </select>
                </div>


                <div className="input-group mb-2 mt-2">
                  <div className="input-group-prepend prepends">
                    <span className="input-group-text">{t('Nationality')}<span style={{color:'red', marginLeft: '5px'}}>*</span></span>
                  </div>
                  {/* <div className="styledata"><Selectoption name="nationality" options={natioalitys} value={selectednationality} onChange={handleChange1} className='form-control ' required /></div> */}

                  <div className="styledata">
                    <select
                      ref={selectOpt1}
                      className="form-control"
                      style={{ borderRadius: '5px', height: '30px' , width: '100%' ,maxWidth: '300px' }}
                      onChange={handleChange1} required
                      value={selectednationality} // Set the selected value to match session storage or state
                    // Add your desired height and border-radius here
                    >
                      <option>{`-- ${t('Select')} --`}</option>
                      {natioalitys != null && natioalitys.map((option) => (
                        <option key={option} value={option}>
                          {option.charAt(0).toUpperCase() + option.slice(1)}
                        </option>
                      ))}
                    </select>
                  </div>


                </div>
                <div className="input-group mb-2 mt-2">
                  <div className="input-group-prepend prepends">
                    <span className="input-group-text">{t('DOB')}<span style={{color:'red', marginLeft: '5px'}}>*</span></span>
                  </div>
                  <input value={selectedDateofBirth} type="date" name="dob" max={maxDate} min={minDate} className="form-control" onChange={(e) => {
                                            setDobError("");
                                            setSelectedDateofBirth(e.target.value);
                                            const formattedDate = e.target.value.replace(/\//g, '-');
                                            const [year, month, day] = formattedDate.split("-");
                                            const finalformattedDate = `${day}-${month}-${year}`;
                                            setFormData({ ...formData, ['dob']: finalformattedDate });
                                            const currentDay = today.getDate();
                                            const currentMonth = new Date().getMonth() + 1;


                                            // const finalAgeFormattedDate = `${day}-${month}-${year}`;
                                            debugger
                                            // Calculate and set the age
                                            const age = calculateAge(finalformattedDate);
                                            setAge(age);
                                            if (age < 18) {
                                              // document.getElementsByName("dob")[0].focus();
                                              setDobError("Age must be within 18 to 60 years.");
                                              } else if (age > 60) {
                                                  // document.getElementsByName("dob")[0].focus();
                                              setDobError("Age must be within 18 to 60 years.");
                                              } else if (age === 60 && parseInt(month, 10) < currentMonth) {
                                                  // document.getElementsByName("dob")[0].focus();
                                              setDobError("Age must be within 18 to 60 years, and for age 60, the month must be the current month or later.");
                                              } else if (age === 18 && parseInt(month, 10) == currentMonth&&parseInt(day, 10) == currentDay) {
                                                  // document.getElementsByName("dob")[0].focus();
                                               setDobError("Age must be within 18 to 60 years, and for age 18, the selected date must not be today's date.");
                                              }else if (age === 60 && parseInt(month, 10) == currentMonth&& parseInt(day, 10) < currentDay) {
                                                  // document.getElementsByName("dob")[0].focus();
                                              setDobError("Age must be within 18 to 60 years, and for age 60, the selected date must not be before today's date.");
                                              }else{
                                              setDobError('');

                                              // alert("Age is less than 18");
                                              // setSelectedDateofBirth("");
                                          }

                                           
                                        }} onBlur={handleDOBBlur}   required />
                </div>
                {age !== null && <p>Your age is {age} year(s).</p>}
                                        {dobError && <div ><p style={{ color: "red" }}>{dobError}</p></div>}



                <div><i className="fas fa-arrow-right" style={{marginRight:'15px'}}></i><u><a href={Table_Of_Benifits} download='Download_Table_Of_Benifits.pdf'>Download Table Of Benifits</a></u></div>
                <div><i className="fas fa-arrow-right" style={{marginRight:'15px'}}></i><u><a href={Download_Covid_terms_and_condition} download='Download_Covid-19_terms_and_condition.pdf'>Download Covid-19 terms and condition</a></u></div>
                <div><i className="fas fa-arrow-right" style={{marginRight:'15px'}}></i><u><a href={Download_Exclusion_List} download='Download_Exclusion_List.pdf'>Download Exclusion List</a></u></div>
                <div><i className="fas fa-arrow-right" style={{marginRight:'15px'}}></i><u><a href={Download_network_list} download='Download_Policy_Wording.pdf'>Download network list</a></u></div>

                <button type="submit" disabled={isButtonDisabled} className="btn btn-primary rounded-pill text-white my-1 py-1 px-4">{t('Submit')}</button>


              </form>


            </Col>

            <Col md={3}></Col>

          </div>

        </div>
      </div>

      <Footer />

      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}

    </Fragment>
  );


}

export default MedicalWorkerBasicInfo;
