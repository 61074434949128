import React, { Fragment, useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useTranslation } from 'react-i18next';
import travelimg from './tr.png';
import { format } from 'date-fns';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Select2Wrapper from './Select2WrapperOutbound';
import Cookies from 'js-cookie';
import { config } from '../src/api/api';
// import './selectstyle2.css';


function Outboundtravellerinfo() {
    const base_url = config.baseURL;
    const [isChecked, setIsChecked] = useState(false);
    const [options, setCountries] = useState([]);

    // const handleCheckboxChange = (event) => {
    //     setIsChecked(event.target.checked);
    // };
    const handleCheckboxChange = (event) => {
        // setIsChecked(event.target.checked);

    };

    const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';

    const [dhofarData, setDhofarData] = useState(false);
    useEffect(() => {
        const storedDhofarString = Cookies.get('dhofarDataoutbound'); //sessionStorage.getItem('userData')
        if (storedDhofarString !== undefined) {
            const storedDhofar = JSON.parse(storedDhofarString);
            setDhofarData(storedDhofar);
        } else {
            // alert("token expired");
            // window.location.href = '/';
        }
    }, []);


    const [travelInfo, setTravelInfo] = useState(false);
    useEffect(() => {
        const storedtravelinfoString = sessionStorage.getItem('outboundtravelinfo'); //sessionStorage.getItem('userData')
        if (storedtravelinfoString !== 'undefined') {
            const storedtravelin = JSON.parse(storedtravelinfoString);
            setTravelInfo(storedtravelin);
        }
    }, []);


    const postData = { token: dhofarData };

    const [vendorKey, setVendorKey] = useState('');

    useEffect(() => {

        const postvData = { organization_name: "Superjet" };

        if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {


            fetch(base_url + '/create_organization', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(postvData),
            })
                .then(response => response.json())
                .then(data => {
                    setVendorKey(data.result[0].vendor_key);
                    document.getElementsByName('first_name')[0].focus();
                })
                .catch(error => {
                    console.error('Error posting data:', error);
                });


        }
    }, [vendorKey]); // Dependency array

    //getcountries


    if (options && options.length === 0 && vendorKey !== '') {

        fetch(base_url + '/get_outbound_countries', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${vendorKey}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(postData),
        })
            .then(response => response.json())
            .then(data => {
                debugger
                if(data.result.message=='Unauthorize.'){
                alert('Session timeout')
                window.location.href = `/`;
                }else{
                    setCountries(data.result.data.response_data);
                }
                

            })
            .catch(error => {
                console.error('Error posting data:', error);
            });

    }

    //getgender

    const [goptions, setGender] = useState([]);

    if (goptions && goptions.length === 0 && vendorKey !== '') {

        fetch(base_url + '/getGender', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${vendorKey}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(postData),
        })
            .then(response => response.json())
            .then(data => {

                setGender(data.result.data.response_data);

            })
            .catch(error => {
                console.error('Error posting data:', error);
            });

    }

    //getmaritalstatus

    const [moptions, setMarital] = useState([]);

    if (moptions && moptions.length === 0 && vendorKey !== '') {

        fetch(base_url + '/get_outbound_marital_status', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${vendorKey}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(postData),
        })
            .then(response => response.json())
            .then(data => {


                setMarital(data.result.data.response_data);


            })
            .catch(error => {
                console.error('Error posting data:', error);
            });

    }


    const { t } = useTranslation();

    // const [selectedmaritalOption, setSelectedmaritalOption] = useState(null);
    // const [selectedmaritalOptionid, setSelectedmaritalOptionid] = useState(null);
    // const handlemaritalChange = (event) => {
    //   setSelectedmaritalOptionid(event.target.options[event.target.selectedIndex].id);
    //   setSelectedmaritalOption(event.target.value);
    // };

    // const [selectedgenderOption, setSelectedgenderOption] = useState(null);
    // const [selectedgenderOptionid, setSelectedgenderOptionid] = useState(null);
    // const handlegenderChange = (event) => {
    //   setSelectedgenderOptionid(event.target.options[event.target.selectedIndex].id);
    //   setSelectedgenderOption(event.target.value);
    // };

    // const [selectednationalityOption, setSelectednationalityOption] = useState(null);
    // const [selectednationalityOptionid, setSelectednationalityOptionid] = useState(null);
    // const handlenationalityChange = (event) => {
    //   setSelectednationalityOptionid(event.target.options[event.target.selectedIndex].id);
    //   setSelectednationalityOption(event.target.value);
    // };


    // const [fnameValue, setfnameValue] = useState('');
    // const handlefnameChange = (event) => {
    //     setfnameValue(event.target.value);
    // };

    // const [lnameValue, setlnameValue] = useState('');
    // const handlelnameChange = (event) => {
    //     setlnameValue(event.target.value);
    // };

    // const [emailValue, setemailValue] = useState('');
    // const handleemailChange = (event) => {
    //     setemailValue(event.target.value);
    // };

    // const [dobValue, setdobValue] = useState('');
    // const handledobChange = (event) => {
    //     setdobValue(event.target.value);
    // };

    // const [passportValue, setpassportValue] = useState('');
    // const handlepassportChange = (event) => {
    //     setpassportValue(event.target.value);
    // };

    // const [phoneValue, setphoneValue] = useState('');
    // const handlephoneChange = (event) => {
    //     setphoneValue(event.target.value);
    // };

    const [travelDetails, setTraveldetails] = useState(false);
    useEffect(() => {
        const storedtravelString = sessionStorage.getItem('outboundtraveldetails'); //sessionStorage.getItem('userData')
        if (storedtravelString !== 'undefined') {
            const storedtraveld = JSON.parse(storedtravelString);
            setTraveldetails(storedtraveld);
        }
    }, []);

    // const [destinationsinfo, setdestinationsinfo] = useState([]);

    // if(travelDetails.outbound_product_name==='Standard')
    // {
    //   setdestinationsinfo(travelInfo);
    // }
    // else
    // {
    //   setdestinationsinfo(travelDetails.outbound_travel_destinations);
    // }


    const [formRows, setFormRows] = useState([{

        first_name: '',
        last_name: '',
        email: '',
        mobile_number: '',
        passport_number: '',
        relation_id: 5,
        relation_value: "Principal",
        dob: '',
        nationality: '',
        nationality_id: '',
        gender_id: '',
        gender_value: '',
        marital_status_id: '',
        marital_status_value: '',
        ccode: '',
        declaration: false

    }]);
    const [selectedOption, setSelectedOption] = useState([]);
    const [selectedDob, setSelectedDob] = useState([]);


    const [selectedfrmOption, setSelectedfrmOption] = useState(null);
    const [selectedfromOption, setSelectedfromOption] = useState(null);
    const [selectedfromOptionid, setSelectedfromOptionid] = useState(null);
    const handlefromcountryChange = (value) => {
        // console.log(value)

        // console.log(index);
        // const [description, id] = value.split("#");


        // setSelectedfromOptionid(id);
        // setSelectedfromOption(description);
        // setSelectedfrmOption(value);
    };

    const handleInputChange = async (index, event, names) => {
        // console.log(event);;

        // alert(event);
        debugger
        const { name, value } = names !== 'nationality' ? event.target : '';
        const rows = [...formRows];

        if (names === 'dob') {
            // const newDob = [...selectedDob];
            // newDob[index]=format(new Date(value), 'dd-MM-yyyy');
            // setSelectedDob(newDob);
            rows[index][name] = format(new Date(value), 'dd-MM-yyyy');
            debugger
            // rows[index][name] = value;
        }
        else if (names === 'gender_value') {
            rows[index]['gender_id'] = event.target.options[event.target.selectedIndex].id;
            rows[index]['gender_value'] = event.target.value;
        }
        else if (names === 'marital_status_value') {
            rows[index]['marital_status_id'] = event.target.options[event.target.selectedIndex].id;
            rows[index]['marital_status_value'] = event.target.value;
        } else if (name === 'phonea') {
            rows[index]['ccode'] = value;
        }
        else if (names === 'declaration') {
            debugger
            rows[index]['declaration'] = event.target.checked;
        }
        else if (names === 'nationality') {
            const [description, id] = event.split("#");

            rows[index]['nationality_id'] = id;
            rows[index]['nationality'] = description;
            const newnationality = [...selectedOption];
            newnationality[index]=event;
            setSelectedOption(newnationality);
            // rows[index]['ccode'] = event.target.value;

            const cnData = { country: description };

            await fetch(base_url + '/get_country_code', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(cnData),
            })
                .then(response => response.json())
                .then(data => {
                    // debugger

                    const cde = data.result.replace(/\+/g, '');

                    rows[index]['ccode'] = cde;

                })
                .catch(error => {
                    console.error('Error posting data:', error);
                });

        }
        else if (name === 'mobile_number') {
            rows[index]['mobile_number'] = value; //rows[index]['ccode'] + '' + 
        }
        else {
            rows[index][name] = value;
        }
        setFormRows(rows);
    };

     const [emailError, setEmailError] = useState('');
    
      const handleEmailBlur = (e) => {
        const { value } = e.target;
    
        // Regular expression to validate a basic email format
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    
        // Check if the email matches the pattern
        if (!emailPattern.test(value)) {
          document.getElementsByName('email')[0].focus();
          setEmailError('Please enter a valid email address. Example: example@domain.com');
        } else {
          setEmailError(''); // Clear the error if valid
        }
      };


    const handleAddRow = () => {
        setFormRows([...formRows, {
            first_name: '',
            last_name: '',
            email: '',
            mobile_number: '',
            passport_number: '',
            relation_id: 5,
            relation_value: "Principal",
            dob: '',
            nationality: '',
            nationality_id: '',
            gender_id: '',
            gender_value: '',
            marital_status_id: '',
            marital_status_value: '',
            ccode: '',
            declaration: false
        }]);
    };

    const handleRemoveRow = (index) => {
        const rows = [...formRows];
        rows.splice(index, 1);
        const valuesList = rows.map((item) => `${item.nationality}#${item.nationality_id}`);
        setSelectedOption(valuesList); 
        
        // const dobList = rows.map((item) => item.dob==''?item.dob: format(new Date(item.dob), 'yyyy-dd-MM'));
        // setSelectedDob(dobList);

        // const dobList = rows
        //   .filter((item) => item.dob) // This filters out items with null or undefined dob
        //   .map((item) => format(new Date(item.dob), 'yyyy-dd-MM'));
        //   setSelectedDob(dobList);
        // rows[index][name] = format(new Date(value), 'dd-MM-yyyy');
        setFormRows(rows);
    };
    const formatDate = (dateString) => {
        const [day, month, year] = dateString.split("-");
        const date = new Date(`${year}-${month}-${day}`);
        return date.toISOString().split("T")[0]; // Returns "2024-11-01"
      };
    const handleSubmit = (e) => {
        debugger;
        console.log(formRows);
        var declaration_check = false;
        var declaration_not_choosen_traveller;
        for (var i in formRows) {
            debugger;
            if (formRows[i].declaration == true) {
                declaration_check = true;
            } else {
                declaration_check = false;
                declaration_not_choosen_traveller = i;
                break;
            }
        }
        if (declaration_check) {
            e.preventDefault();
            if (travelDetails.outbound_product_name === 'Standard') {
                var data = {
                    "outbound_product_type_id": "2",
                    "outbound_product_id": travelDetails.outbound_product_id,
                    "outbound_product_name": travelDetails.outbound_product_name,
                    "outbound_coverage_id": travelDetails.outbound_coverage_id,
                    "outbound_coverage_name": travelDetails.outbound_coverage_name,
                    "outbound_travel_destinations": travelInfo,
                    "outbound_period_type_id": 1,
                    "outbound_period_value_id": 4,
                    "cumulative_days": travelDetails.cumulative_days,
                    "outbound_travel_start_date": travelDetails.outbound_travel_start_date,
                    "beneficiaries": formRows
                }
                sessionStorage.setItem("outboundapplicationData", JSON.stringify(data));

            } else {
                var data1 = {
                    "outbound_product_type_id": "2",
                    "outbound_product_id": travelDetails.outbound_product_id,
                    "outbound_product_name": travelDetails.outbound_product_name,
                    "outbound_coverage_id": travelDetails.outbound_coverage_id,
                    "outbound_coverage_name": travelDetails.outbound_coverage_name,
                    "outbound_travel_destinations": travelDetails.outbound_travel_destinations,
                    "outbound_period_type_id": 1,
                    "outbound_period_value_id": 4,
                    "cumulative_days": travelDetails.cumulative_days,
                    "outbound_travel_start_date": travelDetails.outbound_travel_start_date,
                    "beneficiaries": formRows
                }
                sessionStorage.setItem("outboundapplicationData", JSON.stringify(data1));

            }
            window.location.href = `/outbound-plan`;
            // onSubmitForm(data, userData.access_token);
        } else {
            e.preventDefault();
            var traveller_details = formRows[declaration_not_choosen_traveller];
            alert(`${traveller_details.first_name} not select the declaration`);
        }

    };

    const today = new Date().toISOString().split('T')[0];

    return (
        <Fragment>

            <div className="header_section1">

                <Navbar />


                <div className="container-fluid bg-breadcrumb">
                    <div className="container text-center py-5">
                        <h3 className="abt-txt my-5">{t('Traveller')} {t('Info')}</h3>
                    </div>
                </div>


            </div>


            <div className="features_section my-1">
                <div className="container">

                    <div className="row">

                        <Col md={3}></Col>

                        <Col md={6} className="myforms my-5">


                            <div>

                                <h4>{(lnstr === 'en') ? 'Traveller Details' : 'تفاصيل المسافر'}</h4>

                                <form onSubmit={handleSubmit}>
                                    {formRows.map((row, index) => (
                                        <div key={index}>

                                            <div className="input-group mb-2 mt-2">
                                                <div className="input-group-prepend prepends">
                                                    <span className="input-group-text">{t('First')} {t('Name')}<span style={{color:'red', marginLeft :'3px'}}>*</span></span>
                                                </div>
                                                <input type="text" name="first_name" className="form-control"
                                                    onChange={(e) => {
                                                        const { value } = e.target;
                                                        if (/^[a-zA-Z]*$/.test(value)) {
                                                            handleInputChange(index, e, 'first_name');
                                                        }
                                                    }}
                                                    value={row.first_name}
                                                    required />
                                            </div>

                                            <div className="input-group mb-2 mt-2">
                                                <div className="input-group-prepend prepends">
                                                    <span className="input-group-text">{(lnstr === 'en') ? 'Last Name' : 'الأسم الأخير'}<span style={{color:'red', marginLeft: '4px'}}>*</span></span>
                                                </div>
                                                <input type="text" name="last_name" className="form-control"
                                                    onChange={(e) => {
                                                        const { value } = e.target;
                                                        if (/^[a-zA-Z]*$/.test(value)) {
                                                            handleInputChange(index, e, 'last_name');
                                                        }
                                                    }}
                                                    value={row.last_name}
                                                    required />
                                            </div>

                                            <div className="input-group mb-2 mt-2">
                                                <div className="input-group-prepend prepends">
                                                    <span className="input-group-text">{t('DOB')}<span style={{color:'red', marginLeft: '4px'}}>*</span></span>
                                                </div>
                                                <input
                                                value={row.dob ? formatDate(row.dob) : ''}
                                                    type="date"
                                                    name="dob"
                                                    max={today}
                                                    className="form-control"
                                                    // value={selectedDob.length>0&&selectedDob[index]!=''?selectedDob[index] : row.dob}
                                                    onChange={(e) => handleInputChange(index, e, 'dob')}
                                                />
                                            </div>


                                            <div className="input-group mb-2 mt-2">
                                                <div className="input-group-prepend prepends">
                                                    <span className="input-group-text">{t('Email')}<span style={{color:'red', marginLeft: '4px'}}>*</span></span>
                                                </div>
                                                <input type="text" name="email" className="form-control" onBlur={handleEmailBlur}
                                                    onChange={(e) => handleInputChange(index, e, 'email')} value={row.email} required />
                                            </div>
                                            <div>{emailError && <p style={{ color: 'red' }}>{emailError}</p>}</div>


                                            <div className="input-group mb-2 mt-2">
                                                <div className="input-group-prepend prepends">
                                                    <span className="input-group-text">{t('Nationality')}<span style={{color:'red', marginLeft: '4px'}}>*</span></span>
                                                </div>

                                                <div className="styledata">
                                                    <Select2Wrapper name="nationality" options={options}
                                                        value={selectedOption.length>0?selectedOption[index]:selectedfrmOption}
                                                        className='form-control select2'
                                                        onChange={(value) => handleInputChange(index, value, 'nationality')}
                                                        required />
                                                </div>

                                                {/*<select name="nationality" className="form-control"*/}
                                                {/*        onChange={(e) => handleInputChange(index, e)} required>*/}
                                                {/*    <option value="">-- {t('Select')} --</option>*/}
                                                {/*    {options && options.map((option) =>*/}
                                                {/*        <option key={option.id} id={option.id}*/}
                                                {/*                value={option.description}>{t(option.description)}</option>*/}
                                                {/*    )}*/}
                                                {/*</select>*/}
                                            </div>


                                            <div className="input-group mb-2 mt-2">
                                                <div className="input-group-prepend prepends">
                                                    <span className="input-group-text">{t('Phone')}<span style={{color:'red', marginLeft: '4px'}}>*</span></span>
                                                </div>
                                                <input type="text" name="phonea" autoComplete="off" style={{
                                                    width: 60,
                                                    border: '1px solid #dee2e6',
                                                    padding: '0 13px'
                                                }} value={row.ccode} onChange={(e) => handleInputChange(index, e, 'ccode')} />
                                                <input
                                                    type="text"
                                                    name="mobile_number"
                                                    className="form-control"
                                                    autoComplete="off"
                                                    onChange={(e) => {
                                                        const { value } = e.target;
                                                        if (/^\d*$/.test(value)) {
                                                            handleInputChange(index, e, 'mobile_number');
                                                        }
                                                    }}
                                                    value={row.mobile_number}
                                                    required
                                                />
                                            </div>

                                            <div className="input-group mb-2 mt-2">
                                                <div className="input-group-prepend prepends">
                                                    <span className="input-group-text">{t('Gender')}<span style={{color:'red', marginLeft: '4px'}}>*</span></span>
                                                </div>
                                                <select name="gender_value" className="form-control" style={{ direction: 'ltr' }}
                                                    onChange={(e) => handleInputChange(index, e, 'gender_value')}
                                                    value={row.gender_value}
                                                    required>
                                                    <option
                                                        value="">{(lnstr === 'en') ? '-- Select --' : '--اختار--'}</option>
                                                    {goptions && goptions.map((opt) =>
                                                        <option key={opt.id} id={opt.id}
                                                            value={opt.description}>{t(opt.description)}</option>
                                                    )}
                                                </select>
                                            </div>

                                            <div className="input-group mb-2 mt-2">
                                                <div className="input-group-prepend prepends">
                                                    <span
                                                        className="input-group-text">{(lnstr === 'en') ? 'Marital Status' : 'الحالة الأجتماعية'}<span style={{color:'red', marginLeft: '4px'}}>*</span></span>
                                                </div>
                                                <select name="marital_status_value" className="form-control" style={{ direction: 'ltr' }}
                                                    onChange={(e) => handleInputChange(index, e, 'marital_status_value')}
                                                    required
                                                    value={row.marital_status_value}>
                                                    <option
                                                        value="">{(lnstr === 'en') ? '-- Select --' : '--اختار--'}</option>
                                                    {moptions && moptions.map((mopt) =>
                                                        <option key={mopt.id} id={mopt.id}
                                                            value={mopt.description}>{t(mopt.description)}</option>
                                                    )}
                                                </select>
                                            </div>

                                            <div className="input-group mb-2 mt-2">
                                                <div className="input-group-prepend prepends">
                                                    <span
                                                        className="input-group-text">{(lnstr === 'en') ? 'Passport Number' : 'رقم جواز السفر'}<span style={{color:'red', marginLeft: '4px'}}>*</span></span>
                                                </div>
                                                <input type="text" name="passport_number" className="form-control"
                                                    onChange={(e) => handleInputChange(index, e, 'passport_number')} value={row.passport_number}
                                                    required />
                                            </div>
                                            <p className={lnstr}><input type="checkbox" checked={formRows[index]['declaration']}
                                                onChange={(e) => {
                                                    debugger;
                                                    handleInputChange(index, e, 'declaration')
                                                }} name="note" />{(lnstr === 'en') ? ' I want to receive insurance updates, product\n' +
                                                    '                                        launches and personalized offers. I can opt out anytime.' : 'أرغب في تلقي تحديثات التأمين، وإطلاقات المنتجات، والعروض الشخصية. يمكنني الانسحاب في أي وقت.'} </p>
                                            <p className={lnstr}>{(lnstr === 'en') ? 'We use this to create your insurance and send you updates about your\n' +
                                                '                                        application' : 'نستخدم هذه المعلومات لإنشاء تأمينك وإرسال تحديثات حول طلبك'}</p>

                                            {/* {formRows.length !== 1 && (
                                                <p className={lnstr}><input type="checkbox" checked={formRows[index]['declaration']}
                                                    onChange={(e) => {
                                                        debugger;
                                                        handleInputChange(index, e, 'declaration')
                                                    }} name="note" />{(lnstr === 'en') ? ' I want to receive insurance updates, product\n' +
                                                        '                                        launches and personalized offers. I can opt out anytime.' : 'أرغب في تلقي تحديثات التأمين، وإطلاقات المنتجات، والعروض الشخصية. يمكنني الانسحاب في أي وقت.'} </p>
                                            )} */}
                                            {formRows.length !== 1 && (
                                                <button type="button"
                                                    className="btn btn-primary rounded-pill text-white my-3 py-1 px-4"
                                                    onClick={() => handleRemoveRow(index)}>
                                                    Remove
                                                </button>
                                            )}
                                        </div>
                                    ))}

                                    {travelDetails.persontype === 'Family' && (
                                        <button type="button"
                                            className="btn btn-primary rounded-pill text-white my-3 py-1 px-4"
                                            onClick={handleAddRow}>
                                            Add Traveller
                                        </button>
                                    )}
                                    <button type="submit"
                                        className="btn btn-primary rounded-pill text-white my-3 mx-2 py-1 px-4">{t('Submit')}</button>

                                    

                                </form>

                            </div>


                        </Col>

                        <Col md={3}></Col>

                    </div>

                </div>
            </div>

            <Footer />


        </Fragment>
    );
}

export default Outboundtravellerinfo;