import React, { Fragment, useState, useEffect, useRef } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { useTranslation } from 'react-i18next';
import travelimg from '../tr.png';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';
import SignatureCanvas from 'react-signature-canvas';
const { config } = require('../api/api');



function Homeinformationquestions(){

  const base_url = config.baseURL;

  const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';
  const signatureRef = useRef();

  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    insurancetype: '',
  });
  const [loading, setLoading] = useState(false);



  const handleqOptionChange = (event) => {

  };
  const [quotationData, setQuotationData] = useState(null);

  // const [bacsicinfomation, setapplicationData] = useState('');
  // const [preminum, setpremium] = useState('');
  // const [benefitys, setaddbenefity] = useState('');
  // const [personalin, setpersonalinfo] = useState({});

  const [questionList, setQuestionList] = useState();

  const [questionsData, setQuestionsData] = useState([{
    english: '',
    arabic: '',
    key: '',
    accepted_answer: '',
    isChecked: '',
    isChecked_answer: '',
    details: ''
  }]);


  const [selectedValue, setSelectedValue] = useState('Yes');
  const [userData, setUserData] = useState();

  // useEffect(() => {
  //   const storedUserString = Cookies.get('homeData'); //sessionStorage.getItem('userData')
  //   if (storedUserString != undefined) {
  //     const storedUser = JSON.parse(storedUserString);
  //     setUserData(storedUser);
  //   } else {
  //     // alert("token expired");
  //     // window.location.href = '/';
  //   }
  // }, []);

  const handleChanges = (e) => {
    setSelectedValue(e.target.value);
  };
  function handleRadioChange(index, isChecked) {
    const updatedQuestionsData = [...questionsData];
    updatedQuestionsData[index] = {
      ...updatedQuestionsData[index],
      isChecked,
      isChecked_answer: isChecked ? "Yes" : "No"
    };
    setQuestionsData(updatedQuestionsData);
  }

  function handleDetailsChange(index, details) {

    const updatedQuestionsData = [...questionsData];
    updatedQuestionsData[index] = {
      ...updatedQuestionsData[index],
      details
    };
    setQuestionsData(updatedQuestionsData);
  }

  const [isSignatureEmpty, setIsSignatureEmpty] = useState(true);


  const clearSignature = () => {
    signatureRef.current.clear();
    setIsSignatureEmpty(true); // Disable button after clearing
  };
  const handleSignature = () => {
    if (!signatureRef.current.isEmpty()) {
      setIsSignatureEmpty(false); // Enable button when signature is drawn
    }
  };
  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };









  const [dhofarData, setDhofarData] = useState(false);
  const [age, setAge] = useState(null);
  useEffect(() => {


    const storedDhofarString = Cookies.get('homeData'); //sessionStorage.getItem('userData')
    debugger
    if (storedDhofarString !== undefined) {
      const storedDhofar = JSON.parse(storedDhofarString);
      setDhofarData(storedDhofar);
    }
    else {
      // alert("token expired");
      // window.location.href='/';
    }

  }, []);


  const [vendorKey, setVendorkey] = useState('');

  useEffect(() => {

    const postvData = { organization_name: "Superjet" };
    debugger

    if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {


      fetch( base_url + '/create_organization', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postvData),
      })
        .then(response => response.json())
        .then(data => {
          setVendorkey(data.result[0].vendor_key);
        })
        .catch(error => {
          console.error('Error posting data:', error);
        });


    }
  }, [vendorKey]); // Dependency array
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const postData = { token: dhofarData };
  // const today = new Date().toISOString().split('T')[0];
  const today = new Date();
  const maxDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate() + 1).toISOString().split('T')[0];


  const [dobValue, setdobValue] = useState('');
  const handledobChange = (event) => {
    const selectedDob = event.target.value;
    setdobValue(event.target.value);

    const dobDate = new Date(selectedDob);
    const today = new Date();
    let calculatedAge = today.getFullYear() - dobDate.getFullYear();
    const monthDiff = today.getMonth() - dobDate.getMonth();

    // Adjust age if the current date is before the user's birthday in the current year
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dobDate.getDate())) {
      calculatedAge--;
    }

    setAge(calculatedAge);
  };

  const [selectedgenderOption, setSelectedgenderOption] = useState(null);
  const [selectedgenderOptionid, setSelectedgenderOptionid] = useState(null);
  const handlegenderChange = (event) => {
    setSelectedgenderOptionid(event.target.options[event.target.selectedIndex].id);
    setSelectedgenderOption(event.target.value);
  };

  const [selectedmaritalOption, setSelectedmaritalOption] = useState(null);
  const [selectedmaritalOptionid, setSelectedmaritalOptionid] = useState(null);
  const handlemaritalChange = (event) => {
    setSelectedmaritalOptionid(event.target.options[event.target.selectedIndex].id);
    setSelectedmaritalOption(event.target.value);
  };
  //getgender

  const [questions, setquestionsValue] = useState([]);

  useEffect(() => {
    if (vendorKey !== '') {
      const fetchQuestions = async () => {
        try {
          debugger
          const response = await fetch( base_url + '/get_home_questions', {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${vendorKey}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(postData),
          });

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const data = await response.json();
          debugger
          setQuestionList(data.result.data);
          var questionsLists = data.result.data;

          questionsLists = questionsLists.map(question => ({
            english: question.English,
            arabic: question.Arabic,
            key: question.key,
            accepted_answer: question.accepted_answer,
            isChecked: '',
            details: '',
            isChecked_answer: ''
          }));
          setQuestionsData(questionsLists);
        } catch (error) {
          console.error('Error fetching occupations:', error);
        }
      };

      fetchQuestions();
    }
  }, [vendorKey]);

  debugger
  // console.log(questions.map(question.index))
  console.log(questions)

  //getmaritalstatus

  // const handleSubmit = async (event) => {

  //   event.preventDefault();

  //   if (selectedOption === null) {
  //     alert('Please choose plan');
  //     return false;
  //   }
  // }

  const api_headers = {
    'Authorization': 'Bearer ' + vendorKey,
    'Content-Type': 'application/json' // Ensure you're sending JSON
  }


const [amount, setAmountValue] = useState('');
const [policy, setPolicyValue] = useState('');











  const handleQotation = async (event) => {
    
      event.preventDefault();
    
      setLoading(true);
        
          window.location.href ='/ownersummary';
    }
    
{/*
  const bacsicinfojson = sessionStorage.getItem('personalaccidentbasicinfo');
    const basicinfo = JSON.parse(bacsicinfojson);

    const basicPremiumjson = sessionStorage.getItem('personalaccidentpremiumcover');
    const basicPremium = JSON.parse(basicPremiumjson);


    const addbenefityjson = sessionStorage.getItem('personalaccidentbeneficiaries');
    const addbenefity = JSON.parse(addbenefityjson);



    const personalinfojson = sessionStorage.getItem('persionalaccidentinformationpage');
    const personalinfo = JSON.parse(personalinfojson);

    function formatDOB(dob) {
      const date = new Date(dob);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
  }

    debugger

    var quotation_data =
    {
      "token": dhofarData,
      "cover_type": "Personal Accident",
      "application_type": "self",
      "plan": "Personal Accident",
      "nationality": personalinfo.nationality,
      "civil_id": personalinfo.civilid,
      "sum_assured": basicPremium.assured_value,
      "personalInformation": {
        "fullName": personalinfo.fullName,
        "email": personalinfo.email,
        "mobileNumber": personalinfo.mobilenumber,
        "dob": formatDOB(basicinfo.dob),
        "resident_location": personalinfo.Residential_Location,
        "gender": basicinfo.basicinfo_gender_detail.toLowerCase(),
        "is_married": basicinfo.basicinfo_marital_status_detail.toLowerCase() === "single" ? 'no' : 'yes',
        "height": personalinfo.height,
        "weight": personalinfo.weight,
        "occupation": personalinfo.occupation,
        "passport": personalinfo.passportnumber,
        "vat_in": personalinfo.vat_in,
      },
      "beneficiaries": addbenefity.beneficiaries
    };
    if (vendorKey !== '') {
      // const fetchPolicy = async () => {
      try {


        const dd = await fetch( base_url + '/get_personal_accident_quotation', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${vendorKey}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(quotation_data),
        });

        if (!dd.ok) {
          throw new Error('Network response was not ok');
        }
        debugger

        const data = await dd.json();

        setAmountValue(data.result.data.amount);
        sessionStorage.setItem("personalaccdientamount", JSON.stringify(data.result.data.amount))
        await handlePolicy(data.result.data.amount)
      } catch (error) {
        console.error('Error fetching occupations:', error);
      }
      // };


    }
  };




  const handlePolicy = async (amount_details) => {



    const bacsicinfojson = sessionStorage.getItem('personalaccidentbasicinfo');
const basicinfo = JSON.parse(bacsicinfojson);

const basicPremiumjson = sessionStorage.getItem('personalaccidentpremiumcover');
const basicPremium = JSON.parse(basicPremiumjson);


const addbenefityjson = sessionStorage.getItem('personalaccidentbeneficiaries');
const addbenefity = JSON.parse(addbenefityjson);

const personalinfojson = sessionStorage.getItem('persionalaccidentinformationpage');
const personalinfo = JSON.parse(personalinfojson);

function formatDOB(dob) {
  const date = new Date(dob);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
}
  


    debugger

    var quotation_data1 =
    {
      "token": dhofarData,
      "cover_type": "Personal Accident",
      "application_type": "self",
      "plan": "Personal Accident",
      "nationality": personalinfo.nationality,
      "civil_id": personalinfo.civilid,
      "sum_assured": basicPremium.assured_value,
      "personalInformation": {
        "fullName": personalinfo.fullName,
        "email": personalinfo.email,
        "mobileNumber": personalinfo.mobilenumber,
        "dob": formatDOB(basicinfo.dob),
        "resident_location": personalinfo.Residential_Location,
        "gender": basicinfo.basicinfo_gender_detail.toLowerCase(),
        "is_married": basicinfo.basicinfo_marital_status_detail.toLowerCase() === "single" ? 'no' : 'yes',
        "height": personalinfo.height,
        "weight": personalinfo.weight,
        "occupation": personalinfo.occupation,
        "passport": personalinfo.passportnumber,
        "vat_in": personalinfo.vat_in,
      },
      "beneficiaries": addbenefity.beneficiaries,
      "amount": amount_details,
      "suffer_from_impairment_of_sight": "No",
      "is_healthy": "Yes",
      "practise_acitivty": "No",
      "have_intention_leaving_country": "No",
      "have_hazardous_cricumstances": "No",
      "have_any_proposal_declined": "No",
      "have_pa_declined": "No",
      "have_pa_policy": "No",
      "have_claimed_compensation": "No",
      "signature": signatureRef.current.toDataURL()
    };
    var policy_url = '';
    if (userData != null && userData != undefined && userData.agency_name != undefined) {
      policy_url =base_url + '/add_personalaccident_policy_agency_user'
      quotation_data1["agent_token"] = userData.access_token;
    }else{
      policy_url =base_url + '/get_personal_accident_add_policy'
    }

    
    if (vendorKey !== '') {
      // const fetchPolicy = async () => {
      try {
        
        const Personalpolicy = await fetch( policy_url, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${vendorKey}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(quotation_data1),
        });

        if (!Personalpolicy.ok) {
          throw new Error('Network response was not ok');
        }
        debugger

        const data = await Personalpolicy.json();

        if (data?.result?.policy_number != null && data?.result?.policy_number != undefined) {
          setPolicyValue(data?.result?.policy_number);
          sessionStorage.setItem("personalaccdientPolicynumber",JSON.stringify(data.result.policy_number))
          window.location.href = '/ownersummary';

      } else {
          if (data?.result?.data?.decision_reasons != undefined && data?.result?.data?.decision_reasons[0] != undefined) {
              alert(data?.result?.data?.decision_reasons[0]);

              window.location.href='';
             
          }

      }

      } catch (error) {
        console.error('Error fetching occupations:', error);
      }
      // };


    }
  };

*/}



  return (
    <Fragment>

      <div className="header_section1">

        <Navbar />


        <div className="container-fluid bg-breadcrumb">
          <div className="container text-center py-5">
            <h3 className="abt-txt my-5">{(lnstr === 'en') ? 'Please answer the following' : 'الرجاء الإجابة على ما يلي\n'}</h3>
          </div>
        </div>


      </div>


      <div className="features_section">
        <div className="container">

          <div className="row">

            <Col md={3}></Col>

            <Col md={6} className="myforms my-5">


              {questionsData.map((question, index) => (
                <div key={index}>
                  <p>{question.english}</p>
                  <div className="custom-radio" style={{color:'#3fd2f2'}}>
                    <input
                      type="radio"
                      id={`yes-${index}`}
                      name={`option-${index}`}
                      value="Yes"
                      checked={question.isChecked === true}
                      onChange={() => handleRadioChange(index, true)}
                    />
                    <label htmlFor={`yes-${index}`}>Yes</label>

                    <input
                      type="radio"
                      id={`no-${index}`}
                      name={`option-${index}`}
                      value="No"
                      checked={question.isChecked === false}
                      onChange={() => handleRadioChange(index, false)}
                    />
                    <label htmlFor={`no-${index}`}>No</label>
                  </div>
                  <br />
                  {question.isChecked_answer != '' && question.accepted_answer !== question.isChecked_answer && (
                    <div className='col-md-12'>
                      <textarea
                        style={{
                          height: '100px',
                          width: '100%'
                        }}
                        value={question.details || ''} // Ensure there's a default value
                        onChange={(e) => handleDetailsChange(index, e.target.value)}
                        placeholder="Please provide more details"
                      />
                    </div>
                  )}
                </div>
              ))}


              <b>Please put your signature here</b><br />

              <SignatureCanvas
                ref={signatureRef}
                penColor="black"
                canvasProps={{ width: 340, height: 200, className: 'sigCanvas' }}
                style={{ border: '1px solid #ccc', backgroundColor: 'white' }} // Ensure background is white
                onEnd={handleSignature} // Detect when drawing ends
              />
              <div>
                <button type="button"
                  style={{ backgroundColor: !isSignatureEmpty ? '#1D3561' : 'grey', borderColor: '#1D3561' }}
                  className="btn btn-primary rounded-pill text-white my-3 py-1 " onClick={async () => {
                    clearSignature();
                  }}
                  disabled={isSignatureEmpty}
                >
                  Clear Signature
                </button>
              </div>



              <p ><input type="checkbox" checked={isChecked}
              onChange={handleCheckboxChange} name="note" />
              {"I/We hereby declare that to the best of my/our knowledge and belief; the above statements and particulars as furnished herein are complete in all form and true and that I/We have not misstated or misrepresented or suppressed any material facts. (A material fact is one which is likely to influence Dhofar Insurance company acceptance or assessment of this proposal. If in any doubt whether facts are material, they should be disclosed). Submitting this form does not bind the Proposer to complete the Insurance, nor Dhofar Insurance company to accept, but it is agreed that this form shall be the basis of the contract should a policy be issued."}
            </p>
            <div className='col-md-2 text-center'>
              <button type="button" disabled={isChecked ? false : true }
                style={{ backgroundColor: isChecked ? '#1D3561' : "grey", borderColor: '#1D3561' }}
                className="btn btn-primary rounded-pill text-white my-3 py-1 "
                // disabled={isChecked ? false : true}
                onClick={async () => {


                  var question_valid = !questionsData.some(q => q.isChecked_answer === '');
                  debugger
                  if (!question_valid) {
                    alert('Please ensure the question answer');
                  } else if (signatureRef.current.isEmpty()) {
                    alert('Please put signature');
                  } else if (!isChecked) {

                    alert('Please ensure the declare');
                  } else {
                    debugger
                    // event.preventDefault();



                    setLoading(true);

                    await handleQotation();
                    
                    
                    // setPersonalPolicyData(null);
                    // setPersonalPolicyPaymentLink(null);
                    // await getQuotation(accessTokenKey);
                  }

                }}
              >
                Proceed
              </button>
            </div>

            </Col>


            <Col md={3}></Col>

          </div>

        </div>
      </div>

      <Footer />

      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}

    </Fragment>
  );
}


export default Homeinformationquestions;