import React, { Fragment, useState, useEffect, useRef } from "react";
import Navbar from "../../Navbar";
import Footer from "../../Footer";
import { useTranslation } from 'react-i18next';
// import travelimg from '../tr.png';
import { format } from 'date-fns';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';
import SignatureCanvas from 'react-signature-canvas';
const { config } = require('../../api/api');

function LifeDownloadTermPlanPolicy() {

  const base_url = config.baseURL;
  const redirect_url = config.redirectURL;
  const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const [vendorKey, setVendorkey] = useState('');
  const [dhofarData, setDhofarData] = useState(false);
  const [downloadpolicy, setDownloadValue] = useState('');
  const [policyLink, setPolicyLink] = useState(null);
  const [policy_no, setPolicyNo] = useState(null);
  const [age, setAge] = useState(null);

  const [payment_link, setPaymentValue] = useState('')
  // useEffect(() => {

  //   var quotation_data =
  //   {
  //     "token": JSON.parse(dhofarData),
  //     "policy_no": policy_no,
  //   };



  //   if (vendorKey !== '') {


  //     fetch(base_url + '/get_personal_accident_policy_details', {
  //       method: 'POST',
  //       headers: {
  //         'Authorization': `Bearer ${vendorKey}`,
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(quotation_data),
  //     })

  //       .then(response => response.json())
  //       .then(data => {

  //         debugger
  //         setDownloadValue(data.result[0]);

  //       })
  //       .catch(error => {
  //         console.error('Error posting data:', error);
  //       });

  //   }

  // }, []);

const pattern = /^(https?:\/\/)?([a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+)(:[0-9]{1,5})?(\/.*)?$/;

useEffect(() => {
  const urlSearchString = window.location.search;
  const uparams = new URLSearchParams(urlSearchString);
  const policy_no = uparams.get('policyno');
  setPolicyNo(policy_no);
}, []);


useEffect(() => {
  const createOrganization = async () => {
    try {
      const postvData = { organization_name: "Superjet" };

      const response = await fetch(base_url + '/create_organization', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(postvData),
      });
      const data = await response.json();
      setVendorkey(data.result[0].vendor_key);
    } catch (error) {
      console.error('Error posting data:', error);
    }
  };

  if (!vendorKey) {
    createOrganization();
  }
}, [vendorKey, base_url]);

useEffect(() => {
  const createToken = async () => {
    if (vendorKey) {
      try {
        const response = await fetch(base_url + '/create_personal_accident_token', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${vendorKey}`,
          },
        });
        const data = await response.json();
        const dhdata = data.result.data.token;
        setDhofarData(dhdata);
      } catch (error) {
        console.error('Error getting token:', error);
      }
    }
  };

  createToken();
}, [vendorKey, base_url]);

useEffect(() => {
  const fetchPolicyDetails = async () => {
    if (vendorKey && dhofarData && policy_no) {
      try {
        const quotation_data = {
          token: dhofarData,
          policy_no: policy_no,
        };

        const response = await fetch(base_url + '/get_personal_accident_policy_details', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${vendorKey}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(quotation_data),
        });

        const data = await response.json();
        setDownloadValue(data.result[0]);

        const postData1 = { token: dhofarData, policy_no: policy_no };
        const response2 = await fetch(base_url + '/download_personalaccident_policy', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${vendorKey}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postData1),
        });
        const data2 = await response2.json();

        if (data2.message === 'success') {
          debugger
          setPolicyLink(data2.result.data.policy_schedule_url);
        } else {
          setPolicyLink('');
        }
      } catch (error) {
        console.error('Error fetching policy details:', error);
      }
    }
  };

  fetchPolicyDetails();
}, [vendorKey, dhofarData, policy_no, base_url]); 


const handleQotation = async (event) => {



  debugger

  var quotation_data =
  {
    "token": dhofarData,
    "policy_no": policy_no,
    "redirect_url": redirect_url + '/personalinformation-downloadpolicy?policyno=' + policy_no
  };
  debugger
  if (vendorKey !== '') {
    // const fetchPolicy = async () => {
    try {


      const Policyresponce = await fetch(base_url + '/create_personal_accident_payment_link', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${vendorKey}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(quotation_data),
      });

      if (!Policyresponce.ok) {
        throw new Error('Network response was not ok');
      }
      debugger

      const data = await Policyresponce.json();

      setPaymentValue(data.result.data.payment_url);
      window.location.href = data.result.data.payment_url;
      //   await handlePolicy(data.result.data.amount)
    } catch (error) {
      console.error('Error fetching occupations:', error);
    }
    // };


  }
};


const handleSubmit = async (event) => {
  event.preventDefault();

  window.location.href = '/insurance';    

};

const handlerepayment = async (event) => {
  // event.preventDefault();
  handleQotation();
}


const isValidUrl = (url) => {
  return pattern.test(url);
};



return (



  <Fragment>

      <div className="header_section1">

          <Navbar />


          <div className="container-fluid bg-breadcrumb">
              <div className="container text-center py-5">
                  <h3 className="abt-txt my-5">{(lnstr === 'en') ? 'Download Policy - ' + policy_no : 'تحميل السياسة\n'}</h3>
              </div>
          </div>


      </div>


      <div className="features_section">
          <div className="container">

              <div className="row">

                  <Col md={3}></Col>

                  <Col md={6} className="myforms my-5" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                      <br />

                      <table className="table table-bordered table-condenced" style={{ textAlign: 'left' }}>

                          <tbody>

                              <tr><td style={{ width: '50%' }}><b>Name</b>
                                  </td><td>{downloadpolicy != null ? downloadpolicy ?.first_name : ''}</td></tr>

                              <tr><td><b>Email</b>
                                  </td><td>{downloadpolicy != null ? downloadpolicy ?.email : ''}</td></tr>
                              


                              <tr><td><b>Passport</b>
                                  </td><td>{downloadpolicy != null ? downloadpolicy?.passport : ''}</td></tr>
                              

                              <tr><td><b>Cover Type</b>
            </td><td>{downloadpolicy != null ? downloadpolicy.policy_data?.cover_type : ''}</td></tr>

                              <tr><td><b>Civil ID</b>
                                  </td><td>{downloadpolicy != null ? downloadpolicy.policy_data?.civil_id : ''}</td></tr>

                             

                          </tbody>

                      </table>

                      <div className="col-12">
                {
                  policyLink != null && policyLink != '' ?
                    // true ?
                    <div className="row">
                      <div className="col-lg-7 col-sm-12 col-md-12 col-12 p-1" >
                      <button className="btn btn-primary "
                        onClick={() => {
                          debugger
                          if (policyLink) {
                            var url = policyLink;
                            const link = document.createElement('a');
                            link.href = url;
                            link.target = '_blank';
                            link.download = url.split('/').pop(); // Extracts filename from URL
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                          } else {
                            alert('Policy error')
                          }

                        }}

                      >
                        Download Policy Document
                      </button></div>
                      <div className="col-lg-5 col-sm-12 col-md-12 col-12 p-1">
                      <button className="btn btn-primary"
                          onClick={handleSubmit}
                        >Go To Home</button></div>
                      </div>
                    : policyLink === '' ? <div><h2>Policy Payment Pending</h2>
                      <div>
                        <button
                          onClick={handlerepayment} style={{ marginLeft: 'auto', width: '200px', fontWeight: 'bold' , backgroundColor: '#3fd2f2', color: 'white' }}>Make Payment</button></div></div> : "Loading"
                }
              </div>

                  </Col>

                  <Col md={3}></Col>

              </div>


          </div>
      </div>

      <Footer />

      {loading && (
          <div className="overlay">
              <div className="spinner"></div>
          </div>
      )}

  </Fragment>



    );
}

export default LifeDownloadTermPlanPolicy;