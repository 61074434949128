import React, { Fragment, useState, useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { connect, useDispatch } from 'react-redux';
import { AGENCY_REGISTER_FAIL, AGENCY_REGISTER_SUCCESS, agencyRegisterAction, submitForm } from './redux/actions/actions';
import { useTranslation } from 'react-i18next';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';
import axios from 'axios';
const { config } = require('../api/api');
const Signup = ({ onSubmitForm, message, signupresult, error }) => {
    const base_url = config.baseURL;
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        confirmpassword: '',
        agreeChecked: false
    });

    const [formData1, setFormData1] = useState({
        AgencyName: '',
        BusinessAddress: '',
        emailAgency: '',
        exp: '',
        file: null,
        agreeCheckedagency: false
    });

    const [vendorKey, setVendorKey] = useState('');
    const [crDocumentFile, setCRDocumentFile] = useState(null);
    useEffect(() => {
        const postvData = { organization_name: "Superjet" };
        if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {
            fetch(base_url + '/create_organization', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(postvData),
            })
                .then(response => response.json())
                .then(data => {
                    setVendorKey(data.result[0].vendor_key);
                })
                .catch(error => {
                    console.error('Error posting data:', error);
                });
        }
    }, [vendorKey]); // Dependency array

    // console.log(vendorKey)

    const [passwordVisible, setPasswordVisible] = useState(false);
    const [cpasswordVisible, setcPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const togglecPasswordVisibility = () => {
        setcPasswordVisible(!cpasswordVisible);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleChanges = (event) => {
        const { name, checked } = event.target;
        setFormData({ ...formData, [name]: checked });
    };


    const [evalue, seteValue] = useState('');
    const handleChanges1 = (event, field) => {
        const { checked } = event.target;
        if (field === 'checkbox') {
            setFormData1({ ...formData1, agreeCheckedagency: checked });
        }
    };



    const handleChange1 = async (e, key) => {
        let name, value;
        setLoading(true);
        if (key !== 'fileupload') {
            // For non-file inputs
            if (key == 'experiance') {
                const newValue = e.target.value;
                if (newValue === '' || /^[0-9\b]+$/.test(newValue)) {
                    seteValue(newValue);
                }
            }
            else {
                ({ name, value } = e.target);
            }
        } else {
            // For file input
            name = e.target.name;
            value = e.target.files[0]; // File objects are in the `files` array

            const maxSize = 2 * 1024 * 1024;
            if (value && value.size > maxSize) {
                alert('File size must be less than 2MB');
                e.target.value = ''; // Clear the input
            } else {

                setCRDocumentFile(e.target.files[0]);

                // await uploadCrDocument(e.target.files[0]);
            }

        }
        setLoading(false);
        setFormData1({ ...formData1, [name]: value });
    };
    const uploadCrDocument = async (upload_file) => {
        debugger
        const frmData = new FormData();
        frmData.append('document-file', upload_file);
        const response = await axios.post(base_url + '/upload_document', frmData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        debugger
        //   if(response?.data?.message == 'file upload succes'){

        //   }
        setLoading(false);
        // var update_data = {
        //     "agency_id": formData.id,
        //     "password": formData.confirmpassword,
        // }
        // fetch('https://api.superjetom.com/upload_document', {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //     },
        //     body: JSON.stringify(update_data),
        // })
        //     .then(response => response.json())
        //     .then(data => {
        //         debugger
        //         if (data.message == 'success') {
        //             alert("Password Set Successfully");
        //             setTimeout(() => {
        //                 window.location.href = '/login';
        //             }, 1000);
        //         }

        //         // setVendorKey(data.result[0].vendor_key);
        //     })
        //     .catch(error => {
        //         console.error('Error posting data:', error);
        //     });
    };

    const [loading, setLoading] = useState(false);

    const handleSubmit = (e) => {
        debugger
        e.preventDefault();

        setLoading(true);

        if (formData.password !== formData.confirmpassword) {
            setFormData({
                ...formData,
                passwordError: 'Passwords do not match',
            });
        } else {
            setFormData({
                ...formData,
                passwordError: '',
            });
            var data = {
                'first_name': formData.firstname,
                'last_name': formData.lastname,
                'email': formData.email,
                'password': formData.password,
                'locale': 'en',
                'vendor_key': vendorKey
            }
            onSubmitForm(data);

            // sessionStorage.clear();
            // sessionStorage.removeItem('userData');


            Cookies.remove('userData');

        }

    };


    // const handleSubmit1 = async (e) => {
    //     e.preventDefault();

    //     // console.log(vendorKey + '--k')
    //     setLoading(true);

    //     // setFormData1({
    //     //     ...formData1,

    //     // });
    //     var document_upload = formData1.file != null && formData1.file != undefined ? 'yes' : 'no';
    //     var data = {
    //         'agency_name': formData1.AgencyName,
    //         'address': formData1.BusinessAddress,
    //         'email': formData1.emailAgency,
    //         'experience': evalue,
    //         // 'cr_document': '',
    //         // 'locale': 'en',
    //         'vendor_key': vendorKey,
    //         "document_upload": document_upload,
    //     }
    //     debugger
    //     // console.log(data)
    //     // onSubmitForm(data);
        // await dispatch(agencyRegisterAction(data));
    //     // debugger
    //     // sessionStorage.clear();
    //     // sessionStorage.removeItem('userData');


    //     Cookies.remove('userData');



    // };

    const handleSubmit1 = async (e) => {
        e.preventDefault();
        setLoading(true);
        const frmData = new FormData();
        frmData.append('agency_name', formData1.AgencyName);
        frmData.append('address', formData1.BusinessAddress);
        frmData.append('email', formData1.emailAgency);
        frmData.append('experience', evalue);
        frmData.append('vendor_key', vendorKey);
        frmData.append('documentFile', crDocumentFile);
        try {
            const response = await axios.post(base_url + '/create_travel_agency', frmData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            debugger
            if (response.status === 200 && response.data) {
                if (response.data.message === 'agent added successfully') {
                    var result = response?.data;
                    dispatch({ type: AGENCY_REGISTER_SUCCESS, result });
                    setTimeout(() => {
                        window.location.href = '/agent-verify';
                    }, 1000);
                } else if(response.data.message.indexOf("agent already exist in this") > -1){
                    alert(response.data.message);
                }else {
                    dispatch({ type: AGENCY_REGISTER_FAIL, error: 'error' });
                }
            } else {
                dispatch({ type: AGENCY_REGISTER_FAIL, error: 'error' });

            }
        } catch (error) {
            dispatch({ type: AGENCY_REGISTER_FAIL, error: 'error' });
        }
        setLoading(false);
    };

    const { t } = useTranslation();

    const [searchData, setSearchData] = useState(false);
    useEffect(() => {
        const storedSearchString = sessionStorage.getItem('searchData');
        if (storedSearchString !== undefined) {
            const storedSearch = JSON.parse(storedSearchString);
            setSearchData(storedSearch);
        }
    }, [searchData]);

    useEffect(() => {
        if (message === 'user added successfully') {
            const udata = signupresult;

            const expiryDate = new Date();
            expiryDate.setTime(expiryDate.getTime() + (1 * 60 * 60 * 1000));

            Cookies.set('userData', JSON.stringify(udata), { expires: expiryDate });

            // sessionStorage.setItem("userData", JSON.stringify(udata));

            if (searchData) {
                window.location.href = '/visatypes';
            } else {
                window.location.href = '/';
            }

        } else {
            setLoading(false);
        }
    }, [message, signupresult, searchData]);

    const urlSearchString = window.location.search;

    const uparams = new URLSearchParams(urlSearchString);

    const selctedtype = uparams.get('type') ? uparams.get('type') : 'option1';

    const [selectedOption, setSelectedOption] = useState(selctedtype);

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    return (


        <Fragment>

            <Navbar />


            <div className="features_section layout_padding my-3">
                <div className="container">


                    <div className="row">

                        <Col md={3}></Col>

                        <Col md={6} className="myforms">


                            {/*  radio button*/}



                            {/*  radio button end*/}

                            <h1>{t('Signup')}</h1>

                            <div style={{ display: 'flex', alignContent: 'space-between' }}>
                                <label style={{ width: '40%' }}>
                                    <input
                                        type="radio"
                                        value="option1"
                                        checked={selectedOption === 'option1'}
                                        onChange={handleOptionChange}
                                    />&nbsp;
                                    User
                                </label>

                                <label>
                                    <input
                                        type="radio"
                                        value="option2"
                                        checked={selectedOption === 'option2'}
                                        onChange={handleOptionChange}
                                    />&nbsp;
                                    Travel Agency
                                </label>
                            </div>

                            {selectedOption === 'option1' && (
                                <form onSubmit={handleSubmit}>

                                    <div className="input-group mb-2 mt-2">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">{t('First')} {t('Name')}</span>
                                        </div>
                                        <input type="text" name="firstname" className="form-control"
                                            value={formData.firstname} onChange={handleChange}
                                         required 
                                        />
                                    </div>

                                    <div className="input-group mb-2">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">{t('Last')} {t('Name')}</span>
                                        </div>
                                        <input type="text" name="lastname" className="form-control"
                                            value={formData.lastname} onChange={handleChange}
                                         required
                                        />
                                    </div>

                                    <div className="input-group mb-2">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">{t('Email')}</span>
                                        </div>
                                        <input type="email" name="email" className="form-control" value={formData.email}
                                            onChange={handleChange}
                                        required
                                        />
                                    </div>

                                    <div className="input-group mb-2">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">{t('Password')}</span>
                                        </div>
                                        <input type={passwordVisible ? 'text' : 'password'} name="password"
                                            className="form-control" value={formData.password}
                                            onChange={handleChange}
                                            required />
                                        <div className="input-group-append">
                                            <button type="button" className="btn btn-outline-grey"
                                                onClick={togglePasswordVisibility}>
                                                {passwordVisible ? <i className="fas fa-eye"></i> :
                                                    <i className="fas fa-eye-slash"></i>}
                                            </button>
                                        </div>
                                    </div>

                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">{t('Confirm')} {t('Password')}</span>
                                        </div>
                                        <input type={cpasswordVisible ? 'text' : 'password'} name="confirmpassword"
                                            className="form-control" value={formData.confirmpassword}
                                            onChange={handleChange} required />
                                        <div className="input-group-append">
                                            <button type="button" className="btn btn-outline-grey"
                                                onClick={togglecPasswordVisibility}>
                                                {cpasswordVisible ? <i className="fas fa-eye"></i> :
                                                    <i className="fas fa-eye-slash"></i>}
                                            </button>
                                        </div>
                                    </div>

                                    <input type="checkbox" className="mt-1 mb-3 mx-1" name="agreeChecked"
                                        checked={formData.agreeChecked} onChange={handleChanges}
                                        style={{ marginTop: 10 }} />&nbsp;{t('I')} {t('agree')} {t('to')} {t('the')} <a
                                            href="Privacy">{t('Privacy')} {t('Policy')}</a> and <a
                                                href="Terms">{t('Terms')} {t('of')} {t('use')}</a><br />

                                    {formData.passwordError ?
                                        <p style={{ color: 'red' }}>{formData.passwordError}</p> : ''}

                                    <button type="submit"
                                        className="btn btn-primary rounded-pill text-white my-2 py-1 px-4"
                                        disabled={!formData.agreeChecked}>{t('Submit')}</button>
                                    <span
                                        style={{ color: 'red' }}> {(message === 'user added successfully') ? '' : message}</span>
                                </form>
                            )}

                            {selectedOption === 'option2' && (
                                <form onSubmit={handleSubmit1}>

                                    <div className="input-group mb-2 mt-2">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">Agency Name</span>
                                        </div>
                                        <input type="text" name="AgencyName" className="form-control"
                                            value={formData1.AgencyName}
                                            onChange={(event) => handleChange1(event, 'agencyname')}
                                         required 
                                        />
                                    </div>

                                    <div className="input-group mb-2">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">Business Address</span>
                                        </div>
                                        <textarea className="form-control" name="BusinessAddress" id="" cols="30" rows="2" onChange={(event) => handleChange1(event, 'businessaddress')}
                                        required
                                        >{formData1.BusinessAddress}</textarea>

                                        {/*<input type="text" name="BusinessAddress" className="form-control"*/}
                                        {/*       value={formData1.BusinessAddress}*/}
                                        {/*       onChange={(event) => handleChange1(event, 'businessaddress')}*/}
                                        {/*       required/>*/}
                                    </div>

                                    <div className="input-group mb-2">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">Email</span>
                                        </div>
                                        <input type="email" name="emailAgency" className="form-control"
                                            value={formData1.emailAgency}
                                            onChange={(event) => handleChange1(event, 'email')}
                                        required 
                                        />
                                    </div>

                                    {/*<div className="input-group mb-2">*/}
                                    {/*    <div className="input-group-prepend">*/}
                                    {/*        <span className="input-group-text">{t('Password')}</span>*/}
                                    {/*    </div>*/}
                                    {/*    <input type={passwordVisible ? 'text' : 'password'} name="password"*/}
                                    {/*           className="form-control"*/}
                                    {/*           value={formData.password} onChange={handleChange} required/>*/}
                                    {/*    <button type="button" className="btn btn-outline-grey"*/}
                                    {/*            onClick={togglePasswordVisibility}>*/}
                                    {/*        {passwordVisible ? <i className="fas fa-eye"></i> :*/}
                                    {/*            <i className="fas fa-eye-slash"></i>}*/}
                                    {/*    </button>*/}
                                    {/*</div>*/}

                                    {/*<div className="input-group mb-3">*/}
                                    {/*    <div className="input-group-prepend">*/}
                                    {/*        <span className="input-group-text">{t('Confirm')} {t('Password')}</span>*/}
                                    {/*    </div>*/}
                                    {/*    <input type={cpasswordVisible ? 'text' : 'password'} name="confirmpassword"*/}
                                    {/*           className="form-control"*/}
                                    {/*           value={formData.confirmpassword} onChange={handleChange} required/>*/}
                                    {/*    <button type="button" className="btn btn-outline-grey"*/}
                                    {/*            onClick={togglecPasswordVisibility}>*/}
                                    {/*        {cpasswordVisible ? <i className="fas fa-eye"></i> :*/}
                                    {/*            <i className="fas fa-eye-slash"></i>}*/}
                                    {/*    </button>*/}
                                    {/*</div>*/}

                                    <div className="input-group mb-2">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">Experience (No.Of Years)</span>
                                        </div>
                                        <input type="number" name="exp" className="form-control"
                                            value={evalue}
                                            min="0"
                                            max="25"
                                            onChange={(event) => {
                                                debugger
                                                var enter_value = event.target.value;
                                                if (enter_value.length > 2) {
                                                    event.preventDefault();
                                                } else {
                                                    handleChange1(event, 'experiance');

                                                }
                                            }}
                                        required 
                                        />
                                    </div>

                                    <div className="input-group mb-2">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">CR Upload</span>
                                        </div>
                                        <input className="form-control" name="file" type="file"
                                            onChange={(event) => handleChange1(event, 'fileupload')} accept=".png,.jpg,.jpeg,.pdf,.csv"
                                        required
                                        />
                                        <small style={{ textAlign: 'right', display: 'block' }}>
                                            Accepted formats: .png, .jpg, .jpeg, .pdf, .csv. Maximum file size: 2MB.
                                        </small>
                                    </div>

                                    <input type="checkbox" className="mt-1 mb-3 mx-1" name="agreeCheckedagency"
                                        checked={formData1.agreeCheckedagency}
                                        onChange={(event) => handleChanges1(event, 'checkbox')}
                                        style={{ marginTop: 10 }} />&nbsp;{t('I')} {t('agree')} {t('to')} {t('the')} <a
                                            href="Privacy">{t('Privacy')} {t('Policy')}</a> and <a
                                                href="Terms">{t('Terms')} {t('of')} {t('use')}</a><br />



                                    <button type="submit"
                                        className="btn btn-primary rounded-pill text-white my-2 py-1 px-4"
                                        disabled={!formData1.agreeCheckedagency}>{t('Submit')}</button>
                                    <span
                                        style={{ color: 'red' }}> {(message === 'agent added successfully') ? '' : message}</span>
                                </form>
                            )}

                            {/* <span>Don't have an account ? <a href={`/signup?type=${encodeURIComponent(selectedOption)}`}>Register</a></span> :
                <span> ليس لديك حساب؟ <a href={`/signup?type=${encodeURIComponent(selectedOption)}`}>سجل الآن</a></span>} </p> */}

                            <p className="mt-2 mb-0">{t('Already')} {t('Registered')}? <a href={`/login?type=${encodeURIComponent(selectedOption)}`}>{t('Login')}</a>
                            </p>

                        </Col>

                        <Col md={3}></Col>

                    </div>

                </div>
            </div>


            <Footer />

            {loading && (
                <div className="overlay">
                    <div className="spinner"></div>
                </div>
            )}

        </Fragment>

    );
};

const mapStateToProps = state => ({
    message: state.signup.message,
    signupresult: state.signup.signupdata,
    error: state.signup.error
});

const mapDispatchToProps = (dispatch) => ({
    onSubmitForm: (formData) => dispatch(submitForm(formData)),
    // onAgencyRegisterForm: (formData1) => dispatch(agencyRegisterAction(formData1)),

});

export default connect(mapStateToProps, mapDispatchToProps)(Signup);