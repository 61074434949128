import React, { useState, useEffect, Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import i18n from '../i18n';
import Navbar from "../Navbar";
import Footer from "../Footer";
import AOS from 'aos';
import 'aos/dist/aos.css';

function DhofarMotorinsurance() {

    const { t } = useTranslation();

    const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';

    const [loading, setLoading] = useState(false);

    const [fileName, setFileName] = useState('');
  const [fileName1, setFileName1] = useState('');
  const [fileName2, setFileName2] = useState('');
  const [fileNam3, setFileNam3] = useState('');

  const [name , setfnameValue] =useState('');
  const [email , setEmailvalue] = useState('');
  const [mobileno, setMobilevalue] = useState('');

  const changeLanguage = (lng) => {

    const expiryDate = new Date();
    expiryDate.setTime(expiryDate.getTime() + (1 * 60 * 60 * 1000));

    Cookies.set('langData', lng, { expires: expiryDate });

    i18n.changeLanguage(lng);
  };

  // Function to update HTML dir attribute based on language
  const updateHtmlDirAttribute = () => {
    document.documentElement.dir = i18n.dir(); // Set document direction
  };

  // Update HTML dir attribute when component mounts and language changes
  useEffect(() => {
    updateHtmlDirAttribute();
  }, [i18n.language]);

  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = (e) => {
    if (!e.target.value) {
      setIsFocused(false);
    }
  };

  useEffect (()=>{
    AOS.init({duration:800});
  },[]);


  const [formData, setFormData] = useState({
    name: '',
    email:'',
    countrycode: '+968',
    contactno: ''
  });

  const [mobileError, setMobileError] = useState(false);
  const [emailError , setEmailError] = useState(false);
  const [nameerror, setNameError] = useState(false);
  const [error, setError] = useState('');
  const [error1, setError1] = useState('');
  const [error2, setError2] = useState('');
  const [error3, setError3] = useState('');

  const handleMobileBlur = (e) => {
    const { value } = e.target;

    // Check if the mobile number has exactly 8 digits
    if (value.length !== 8 || !/^\d+$/.test(value)) {
      setMobileError('Please enter 8 digit number');

    } else {
      setMobileError(false);
    }
  };

  const handleEmailBlur = (e) => {
    const { value } = e.target;

    // Regular expression to validate a basic email format
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // Check if the email matches the pattern
    if (!emailPattern.test(value)) {
      setEmailError('Please enter a valid email address');
      setEmailvalue('');
    } else {
      setEmailError(''); // Clear the error if valid
    }
  };


  const handlefnameChange = (event) => {
    setfnameValue(event.target.value);
    
  };

  const handleEmailchange =(event) =>{
    setEmailvalue (event.target.value);
  }

  const handleMobileChange = (event) =>{
    setMobilevalue(event.target.value);
  }


  const [isHovered, setIsHovered] = useState(false);

  const handleChange = (e) => {

    const { name, value } = e.target;

    if (name === 'contactno' && !/^\d*$/.test(value)) {
        // If the value is not a number, do not update the state
        return;
    }

    setFormData({ ...formData, [name]: value });
};

const lastNameRef = React.createRef();
const last1NameRef = React.createRef();
const last2NameRef = React.createRef();

const handleKeyDown = (e, nextInputRef) => {
  if (e.key === 'Enter') {
    e.preventDefault(); 
    if (nextInputRef.current) {
      nextInputRef.current.focus(); 
    }
  }
};


const handleKeyDown1 = (e, nextInputRef) => {
  if (e.key === 'Enter') {
    e.preventDefault(); 
    if (nextInputRef.current) {
      nextInputRef.current.focus(); 
    }
  }
};

const handleKeyDown2 = (e, nextInputRef) => {
  if (e.key === 'Enter') {
    e.preventDefault(); 
    if (nextInputRef.current) {
      nextInputRef.current.focus(); 
    }
  }
};


//   const handleSubmit = (event) => {

    const handleClick = (e) => {

      debugger
    
    // File validation
    const files = [fileName, fileName1, fileName2, fileNam3];
    const errors = [setError, setError1, setError2, setError3];
    
    files.forEach((file, index) => {
      if (file === '') {
        errors[index]('file is required!');
      } else {
        errors[index]('');
      }
    });
    
    if (name && email && mobileno && !files.includes('') && fileName && fileName1 && fileName2 && fileNam3) {
      const insuranceplandata = {
        name: name,
        email: email,
        countrycode: formData.countrycode,
        contactno: mobileno,
        insurancetype: e.target.value,
        driverage: '23'
      };
    

      sessionStorage.setItem("motorinsurancePlan", JSON.stringify(insuranceplandata));

      window.location.href = '/dhofar_motoroffers';
    }
    
    
  };

  const handleFileChange = (event, setter) => {
    const file = event.target.files[0]; 
    if (file) {
      setter(file.name); 
    }else{
      setError('no file seleted')
    }
  };

  const buttonDisabled = name === '' || email === '' || mobileno === ''



  return (


    <Fragment>

      <div className="header_section1">

        <Navbar />


        <div className="container-fluid bg-breadcrumb" >
          <div className="container text-center py-5" >
            <h3 className="abt-txt my-3">{(lnstr === 'en') ? ' Motor Insurance' : 'التأمين على السيارات\n'}</h3>
          </div>
        </div>


      </div>


      <div className="features_section">
        <div className="container">

          <div className="row">

            <Col md={3}></Col>
<div>
            <div class="row g-4" style={{marginTop : '30px'}}>
                    <div class="col-lg-6 col-md-12 col-sm-12 col-12 wow fadeInUp" data-wow-delay="0.1s">
                        
                        <div className="card mt-3 ml-2" data-aos='fade-up'>

                            <div className="card-body customcard">

                            <div className="row mt-4 mx-0 p-0">
                                <div className="col-md-12 p-0" style={lnstr === 'en' ? { textAlign: 'left' } : { textAlign: 'right' }}>
                            <h3>{(lnstr === 'en' ? 'Get Motor Insurance Offers' : 'أحصل على عروض التأمين')}</h3>
                            </div>
                            </div>



                            {/* <form onSubmit={handleSubmit}> */}

                            

                                <div className="row mt-3 mx-0" style={{ backgroundColor: "#cff4fc"}}>
                                <div className="form-group col-lg-12 col-md-12 col-sm-12 col-12" style={lnstr === 'en' ? { float: 'left', textAlign: 'left' } : { float: 'right', textAlign: 'right' }}>
                                <label htmlFor="name" className={isFocused ? 'focused' : ''} style={lnstr === 'en' ? { textAlign: 'left', left:'1.2rem' } : { textAlign: 'right', right:'1.2rem' }}>
                                    {(lnstr === 'en' ? 'Name' : 'اسم')} <span style={{ color: 'red' }}>*</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    name="name"
                                    placeholder=" "
                                    required
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    onChange={handlefnameChange}
                                    onInput={(e) => {
                                      e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, '');
                                    }} 
                                    onKeyDown={(e) => handleKeyDown(e, lastNameRef)}
                                    autoComplete="off"
                                    style={{
                                      borderColor: nameerror ? 'red' : '', 
                                      borderWidth: nameerror ? '2px' : '1px',
                                    }}
                                />
                                
                                </div>
                                </div>


                                <div className="row mt-3 mx-0" style={{ backgroundColor: "#cff4fc" }}>
                                <div className="form-group col-lg-12 col-md-12 col-sm-12 col-12" style={lnstr === 'en' ? { float: 'left', textAlign: 'left' } : { float: 'right', textAlign: 'right' }}>
                                <label htmlFor="email" className={isFocused ? 'focused' : ''} style={lnstr === 'en' ? { textAlign: 'left', left:'1.2rem' } : { textAlign: 'right', right:'1.2rem' }}>
                                    {(lnstr === 'en' ? 'Email' : 'بريد إلكتروني')} <span style={{ color: 'red' }}>*  {emailError && <span>{emailError}</span>}</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="email"
                                    name="email"
                                    placeholder=" "
                                    required
                                    onFocus={handleFocus}
                                    onBlur={handleEmailBlur}
                                    onChange={handleEmailchange}
                                    ref={lastNameRef} 
                                    onKeyDown={(e) => handleKeyDown1(e, last1NameRef)}
                                    autoComplete="off"
                                    style={{
                                      borderColor: emailError ? 'red' : '', 
                                      borderWidth: emailError ? '2px' : '1px', 
                                    }}
                                />
                                
                                </div>
                                </div>


                                <div className="row mt-3 mb-3 mx-0" style={{ backgroundColor: "#cff4fc" }}>
                                
                                <div className="form-group col-lg-4 col-md-4 col-sm-4 col-4" style={lnstr === 'en' ? { float: 'left', textAlign: 'left' } : { float: 'right', textAlign: 'right' }}>
                                <label htmlFor="code" className={isFocused ? 'focused' : ''} style={lnstr === 'en' ? { textAlign: 'left', left:'1.2rem' } : { textAlign: 'right', right:'1.2rem' }}>
                                    {(lnstr === 'en' ? 'Code' : 'شفرة')}
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="countrycode"
                                    name="countrycode"
                                    placeholder=""
                                    required
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    value="968"
                                    readOnly
                                    onChange={handleChange}
                                    autoComplete="off"
                                />
                                
                                </div>

                                <div className="form-group col-lg-8 col-md-8 col-sm-8 col-8" style={lnstr === 'en' ? { float: 'left', textAlign: 'left' } : { float: 'right', textAlign: 'right' }}>
                                <label htmlFor="contactno" className={isFocused ? 'focused' : ''} style={lnstr === 'en' ? { textAlign: 'left', left:'1.2rem' } : { textAlign: 'right', right:'1.2rem' }}>
                                    {(lnstr === 'en' ? 'Contact No' : 'رقم الاتصال')} <span style={{ color: 'red' }}>*  {mobileError && <span>{mobileError}</span>}</span>
                                </label>
                                <input
                                    type="num"
                                    className="form-control"
                                    id="contactno"
                                    name="contactno"
                                    onFocus={handleFocus}
                                    onBlur={handleMobileBlur}
                                    onChange={handleMobileChange}
                                    ref={last1NameRef} 
                                    onKeyDown={(e) => handleKeyDown2(e, last2NameRef)}
                                    autoComplete="off"
                                    placeholder=" "
                                    required
                                    style={{
                                      borderColor: mobileError ? 'red' : '', 
                                      borderWidth: mobileError ? '2px' : '1px', 
                                    }}                                    
                                />
                                
                                </div>

                                </div>

                             

                            {/* </form> */}

                            


                            </div>

                        </div>

                    </div>

                    <div class="col-lg-6 col-md-12 col-sm-12 col-12 wow fadeInUp" data-wow-delay="0.1s">
                        
                    <div className="card mt-3 ml-2" data-aos='fade-up'>

                            <div className="card-body">

                            {/* <div className="row mt-4 mx-0 p-0">
                                <div className="col-md-12 p-0" style={lnstr === 'en' ? { textAlign: 'left' } : { textAlign: 'right' }}>
                            <h3>{(lnstr === 'en' ? 'Get Motor Insurance Offers' : 'أحصل على عروض التأمين')}</h3>
                            </div>
                            </div> */}



                            {/* <form onSubmit={handleSubmit}> */}

                            

                                <div className="row mt-3 mx-0">
                                
                                <div className="col-md-12 col-12" style={lnstr === 'en' ? { textAlign: 'left'} : { textAlign: 'right'}}>

                                <span className="text-dark mx-4">
                                    {(lnstr === 'en' ? 'Driving License Front Side' : 'رخصة القيادة على الجانب الأمامي')}<span style={{ color: 'red' }}> * {error && <span style={{color: 'red'}}>{error}</span>}</span> 
                                </span>

                                </div>

                                <div className="col-md-12 col-12 m-auto">

                                {/* <input type="file" id="file-upload" /> */}

                                <div class="file-upload-container">
  <label for="file-upload-1" class="file-upload-label">
  {fileName !== '' ? <span>{fileName}</span> : (lnstr === 'en' ? 'Choose a file' : 'اختر ملفًا')}
  </label>
  <input type="file" id="file-upload-1" class="file-upload-input" onChange={(e) => handleFileChange(e, setFileName)} />
</div>

                                </div>

                                </div>

                                <div className="row mt-3 mx-0">
                                
                                <div className="col-md-12 col-12" style={lnstr === 'en' ? { textAlign: 'left'} : { textAlign: 'right'}}>

                                <span className="text-dark mx-4">
                                    
                                    {(lnstr === 'en' ? 'Driving License Back Side' : 'رخصة القيادة الجانب الخلفي')}<span style={{ color: 'red' }}> *{error1 && <span style={{color: 'red'}}>{error1}</span>}</span>
                                    
                                </span>

                                </div>

                                <div className="col-md-12 col-12 m-auto">

                                {/* <input type="file" id="file-upload" /> */}

                                <div class="file-upload-container">
  <label for="file-upload-2" class="file-upload-label">
  {fileName1 !== '' ? <span>{fileName1}</span> : (lnstr === 'en' ? 'Choose a file' : 'اختر ملفًا')}
  </label>
  <input type="file" id="file-upload-2" class="file-upload-input" onChange={(e) => handleFileChange(e, setFileName1)} />
</div>

                                </div>

                                </div>

                                <div className="row mt-3 mx-0">
                                
                                <div className="col-md-12 col-12" style={lnstr === 'en' ? { textAlign: 'left'} : { textAlign: 'right'}}>

                                <span className="text-dark mx-4">
                                    {(lnstr === 'en' ? 'Mulkiya Front Side' : 'واجهة الملكية')}<span style={{ color: 'red' }}> *{error2 && <span style={{color: 'red'}}>{error2}</span>}</span>
                                </span>

                                </div>

                                <div className="col-md-12 col-12 m-auto">

                                {/* <input type="file" id="file-upload" /> */}

                                <div class="file-upload-container">
  <label for="file-upload-3" class="file-upload-label">
  {fileName2 !== '' ? <span>{fileName2}</span> : (lnstr === 'en' ? 'Choose a file' : 'اختر ملفًا')}
  </label>
  <input type="file" id="file-upload-3" class="file-upload-input" onChange={(e) => handleFileChange(e, setFileName2)} required/>
</div>

                                </div>

                                </div>


                                <div className="row mt-3 mx-0">
                                
                                <div className="col-md-12 col-12" style={lnstr === 'en' ? { textAlign: 'left'} : { textAlign: 'right'}}>

                                <span className="text-dark mx-4">
                                    
                                    {(lnstr === 'en' ? 'Mulkiya Back Side' : 'الملكية الجهة الخلفية')}<span style={{ color: 'red' }}> *{error3 && <span style={{color: 'red'}}>{error3}</span>}</span>
                                
                                </span>

                                </div>

                                <div className="col-md-12 col-12 m-auto">

                                {/* <input type="file" id="file-upload" /> */}

                                <div class="file-upload-container">
  <label for="file-upload-4" class="file-upload-label">
  {fileNam3 !== '' ? <span>{fileNam3}</span> : (lnstr === 'en' ? 'Choose a file' : 'اختر ملفًا')}
  </label>
  <input type="file" id="file-upload-4" class="file-upload-input" onChange={(e) => handleFileChange(e, setFileNam3)} />
</div>

                                </div>

                                </div>

                            </div>
                        </div>

                        
                    

                        
                    </div>

                    
                </div>

                <div className="row mb-3">
                                    <div className="col-md-12 col-12 my-2"  style={{ textAlign: 'right' }}>
                                <button type="submit" onClick={handleClick} style={{
            backgroundColor: isHovered ? '#0b8ba8' : '#33d0f2',
            transition: 'background-color 0.3s ease',
            marginRight:'10px'
          }}  onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} disabled={buttonDisabled} value="Third Party"  >
                                {(lnstr === 'en' ? 'Submit' : 'يُقدِّم')}
                                </button>
                                </div>
                                </div>

                </div>

            <Col md={3}></Col>

          </div>

        </div>
      </div>

      <Footer />

      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}

    </Fragment>
    
  );
}

export default DhofarMotorinsurance;
