import React, { Fragment, useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import Navbar from "../Navbar";
import Cookies from 'js-cookie';
import Footer from "../Footer";
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
const { config } = require('../api/api');

function HomeinsurancePhotograph(){

  const base_url = config.baseURL;
  const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';
  const [loading, setLoading] = useState(false);
const { t } = useTranslation();
{/*const [fileName, setFileName] = useState("No File Chosen");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
    } else {
      setFileName("No File Chosen");
    }
  };
*/}
//
const [fileName, setFileName] = useState("No File Chosen");

  // Handle File Upload
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFileName(file ? file.name : "No File Chosen");


      // Check if a file is selected
      if (e.target.files && e.target.files.length > 0) {
        setIsFileUploaded(true);
      } else {
        setIsFileUploaded(false);
      }
    };
  

  // Handle Skip and Proceed Actions
  const handleSkip = () => {
    
    window.location.href ="/planofhomeinsurance"
    
    // Add your navigation or logic here
  };

  const handleProceed = () => {
      window.location.href ="/planofhomeinsurance"
    
    }
  
 
  const handleRemove = () => {
    setFileName(null);
    setIsFileUploaded(false)
   // setFileName("No File Chosen");
    document.getElementById("file-upload").value = "";
   
  }

//

const [isFileUploaded, setIsFileUploaded] = useState(false);





   
    return(

        <Fragment>

        <div className="header_section1">
          <Navbar />
        <div className="container-fluid bg-breadcrumb">
        <div className="container text-center py-5">
          <h3 className="abt-txt my-5">{(lnstr === 'en') ? 'Home Insurance' : 'التأمين على المنزل'}</h3>
        </div>
        </div>
        </div>
  
  
        <div className="features_section">
          <div className="container">
  
            <div className="row">
  
              <Col md={3}></Col>
  
              <Col md={6} className="myforms my-5">
  
  

 {

    <div className="container  text-center">
      
    <h2>{(lnstr === 'en') ? "Please select Photographs of the property":"يرجى اختيار صور فوتوغرافية لمكان الإقامة.\n"}</h2>


      {/* File Upload Section */}
      <div className="container text-center mt-5">
        <label
          htmlFor="file-upload"

         
          className="d-flex flex-column align-items-center justify-content-center border rounded p-3"
          style={{
            cursor: "pointer",
            borderStyle: "dashed",
            borderColor: "#ccc",
            borderWidth: "2px",
            width: "55px",
            height:"55px",
            margin: "0 auto",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="currentColor"
            className="bi bi-plus-circle"
            viewBox="0 0 16 16"
          >
            <path d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zm0 13A6 6 0 1 1 8 2a6 6 0 0 1 0 12z" />
            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
          </svg>
          
        </label>
        <div className="mt-2">
        <span className="mt-4">{(lnstr === 'en') ? "Add File":"إضافة ملف\n"}</span>
        </div>
        <input
          type="file"
          id="file-upload"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
      


<div className="d-flex justify-content-center mt-4">
<p className=""><b>{fileName ||"No File Chosen" }</b></p>
       
{isFileUploaded && (<div class="col-3 p-0 text-right text-danger cursor-pointer" style={{
              borderRadius: "5px",
              fontSize: "12px",
              cursor: isFileUploaded ? "pointer" : "not-allowed",
            }}
            onClick={handleRemove}>Remove</div>)}

        {/*{fileName !== "No File Chosen" && (
          <button
            className="btn btn-danger ml-5 rounded-pill text-white "
            style={{
              borderRadius: "5px",
              fontSize: "12px",
            }}
            onClick={handleRemove}
          >
            Remove
          </button>
        )}*/}
      </div>
  

      {/* Buttons */}
      <div className="d-flex justify-content-center ">
        <button
          className="btn btn-primary rounded-pill text-white  mt-5"
          style={{
          width: "100px",
          border: "none",
          }}
          onClick={handleSkip}
        >
         {(lnstr === 'en') ? "Skip":"يتخطى\n"} 
        </button>
       
        <button type="submit"   disabled={!isFileUploaded || !fileName} onClick={handleProceed} className="btn btn-primary rounded-pill text-white ml-5 mt-5">{(lnstr === 'en') ? "Proceed":"يتابع\n"}</button>
      </div>
    </div>
    </div>}



</Col>
          
            <Col md={3}></Col>
            
          </div>
        </div>
      </div>





      
        
       
       <Footer />
       {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div> )}

       </Fragment>
    )
  }
export default HomeinsurancePhotograph;