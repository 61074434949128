import React, { Fragment, useState, useEffect, useRef } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { useTranslation } from 'react-i18next';
import travelimg from '../tr.png';
import { format } from 'date-fns';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';
import SignatureCanvas from 'react-signature-canvas';
const { config } = require('../api/api');

function PersonalPolicyStatus() {

  const base_url = config.baseURL;
  const redirect_url = config.redirectURL;

    const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';

    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const [userData, setUserData] = useState();
    const [policy, setPolicyValue] = useState('');
    const [modalMessage, setModalMessage] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [Referanceid, setReferanceid] = useState('');


    const [dhofarData, setDhofarData] = useState(false);
  const [age, setAge] = useState(null);
  useEffect(() => {


    const storedDhofarString = Cookies.get('personalData'); 
    debugger
    if (storedDhofarString !== undefined) {
      const storedDhofar = JSON.parse(storedDhofarString);
      setDhofarData(storedDhofar);
    }
    else {
      alert("token expired");
      window.location.href='/';
    }

  }, []);



  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = 'hidden'; 
    } else {
      document.body.style.overflow = 'auto';
    }
    
    return () => {
      document.body.style.overflow = 'auto'; 
    };
  }, [isModalOpen]);

  
  const [vendorKey, setVendorkey] = useState('');

  useEffect(() => {

    const postvData = { organization_name: "Superjet" };
    debugger

    if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {


      fetch( base_url + '/create_organization', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postvData),
      })
        .then(response => response.json())
        .then(data => {
          setVendorkey(data.result[0].vendor_key);
        })
        .catch(error => {
          console.error('Error posting data:', error);
        });


    }
  }, [vendorKey]); // Dependency array

  const [payment_link , setPaymentValue] = useState('');

  const handlePolicy = async ( ) => {

debugger

    const bacsicinfojson = sessionStorage.getItem('personalaccidentbasicinfo');
const basicinfo = JSON.parse(bacsicinfojson);

const basicPremiumjson = sessionStorage.getItem('personalaccidentpremiumcover');
const basicPremium = JSON.parse(basicPremiumjson);


const addbenefityjson = sessionStorage.getItem('personalaccidentbeneficiaries');
const addbenefity = JSON.parse(addbenefityjson);

const personalinfojson = sessionStorage.getItem('persionalaccidentinformationpage');
const personalinfo = JSON.parse(personalinfojson);

const personalquestionjson = sessionStorage.getItem('personalquestionvalue');
const personalqus = JSON.parse(personalquestionjson);

const personalamountjson = sessionStorage.getItem('personalaccdientamount');
const personalamount = JSON.parse(personalamountjson)

function formatDOB(dob) {
  const date = new Date(dob);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
}
  


    debugger

    var quotation_data1 =
    {
      "token": dhofarData,
      "cover_type": "Personal Accident",
      "application_type": "self",
      "plan": "Personal Accident",
      "nationality": personalinfo.nationality,
      "civil_id": personalinfo.civilid,
      "sum_assured": basicPremium.assured_value,
      "personalInformation": {
        "fullName": personalinfo.fullName,
        "email": personalinfo.email,
        "mobileNumber": personalinfo.mobilenumber,
        "dob": formatDOB(basicinfo.dob),
        "resident_location": personalinfo.Residential_Location,
        "gender": basicinfo.basicinfo_gender_detail.toLowerCase(),
        "is_married": basicinfo.basicinfo_marital_status_detail.toLowerCase() === "single" ? 'no' : 'yes',
        "height": personalinfo.height,
        "weight": personalinfo.weight,
        "occupation": personalinfo.occupation,
        "passport": personalinfo.passportnumber,
        "vat_in": personalinfo.vat_in,
      },
      "beneficiaries": addbenefity.beneficiaries,
      "amount": personalamount,
      "suffer_from_impairment_of_sight": "No",
      "is_healthy": "Yes",
      "practise_acitivty": "No",
      "have_intention_leaving_country": "No",
      "have_hazardous_cricumstances": "No",
      "have_any_proposal_declined": "No",
      "have_pa_declined": "No",
      "have_pa_policy": "No",
      "have_claimed_compensation": "No",
      "signature": personalqus.signature
    };
    var policy_url = '';
    if (userData != null && userData != undefined && userData.agency_name != undefined) {
      policy_url =base_url + '/add_personalaccident_policy_agency_user'
      quotation_data1["agent_token"] = userData.access_token;
    }else{
      policy_url =base_url + '/get_personal_accident_add_policy'
    }

    
    if (vendorKey !== '') {
      // const fetchPolicy = async () => {
      try {
        
        const Personalpolicy = await fetch( policy_url, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${vendorKey}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(quotation_data1),
        });

        if (!Personalpolicy.ok) {
          throw new Error('Network response was not ok');
        }
        debugger

        const data = await Personalpolicy.json();

        if (data?.result?.policy_number != null && data?.result?.policy_number != undefined) {
          // setPolicyValue(data?.result?.policy_number);
          await handleQotation(data?.result?.policy_number);
          setLoading(false);  

      } else {
        debugger
        if (data?.result?.data?.decision_reasons != undefined && data?.result?.data?.decision_reasons[0] != undefined) {
          // alert(data?.result?.data?.decision_reasons[0]);
          var referance_id = data?.result?.data?.referal_quote_id
          setModalMessage(data?.result?.data?.decision_reasons[0]);
          setReferanceid(referance_id);
           setIsModalOpen(true);
           setLoading(false);
      }
      }

      } catch (error) {
        console.error('Error fetching occupations:', error);
      }
      // };


    }
  };

  const handleQotation = async (policy_no) => {

    
  
      debugger
  
      var quotation_data =
      {
        "token": dhofarData,
        "policy_no":policy_no,
        "redirect_url": redirect_url + '/personalinformation-downloadpolicy?policyno='+ policy_no
      };
      debugger
      if (vendorKey !== '') {
        // const fetchPolicy = async () => {
        try {
  
  
          const Policyresponce = await fetch( base_url + '/create_personal_accident_payment_link', {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${vendorKey}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(quotation_data),
          });
  
          if (!Policyresponce.ok) {
            throw new Error('Network response was not ok');
          }
          debugger
  
          const data = await Policyresponce.json();
  
        setPaymentValue(data.result.data.payment_url);
        window.location.href = data.result.data.payment_url;
        //   await handlePolicy(data.result.data.amount)
        } catch (error) {
          console.error('Error fetching occupations:', error);
        }
        // };
  
  
      }
    };

    const handleSubmit = async (event) => {
      setLoading(true);
debugger
      await handlePolicy();  
    
      };

      const handlecancel = async (event) => {

        window.location.href = './basic-information'
      };

      const handlepopupclose = async () =>{
        setIsModalOpen(false);
        window.location.href = '/personal-accident-insurance'
      }

      function formatDOB(dob) {
        const date = new Date(dob);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
      };

      const NumberFormatter = ({ value }) => {
        // Ensure value is a number and format to 3 decimal places
        const formattedValue = `${Number(value).toFixed(3)} OMR`;
      debugger
        return formattedValue ;
      };

      debugger

      const sumassuredjson = sessionStorage.getItem("personalaccidentpremiumcover");
      const sumaasured = JSON.parse(sumassuredjson);

      const personalinfojson = sessionStorage.getItem('persionalaccidentinformationpage');
      const personalinfo = JSON.parse(personalinfojson);

      const amountjson = sessionStorage.getItem('personalaccdientamount');
      const amount = JSON.parse(amountjson);



  return (


    <Fragment>

      <div className="header_section1">

        <Navbar />


        <div className="container-fluid bg-breadcrumb">
          <div className="container text-center py-5">
            <h3 className="abt-txt my-5">{(lnstr === 'en') ? 'Personal Accident Insurance Policy' : 'بوليصة التأمين ضد الحوادث الشخصية\n'}</h3>
          </div>
        </div>


      </div>


      <div className="features_section">
        <div className="container">

          <div className="row">

            <Col md={3}></Col>
            {/* style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} */}

            <Col md={6} className="myforms my-5">

            <h1 style={{textAlign:"center"}}><b>{(lnstr === 'en') ? 'SUMMARY' : 'ملخص\n'}</b></h1>



            {/* <h2 style={{textAlign:'left'}}>{(lnstr === 'en') ? 'Plan Details':'تفاصيل الخطة'}</h2> */}
            <div className="d-flex justify-content-between my-3 colmds-4 card m-0">
              <div className="d-flex justify-content-between my-3">
                <div>
                <p>{(lnstr === 'en') ? 'Plan Type' :'نوع الخطة'}</p>
                <h6><strong>Personal Accident</strong></h6>
                </div>
                <div>
              <p>{(lnstr === 'en') ? 'Sum Assured':'المبلغ المؤمن'}</p>
              <h6><strong>{sumaasured !=null ? sumaasured ?.assured_value :''}</strong></h6>
              </div>
              
              </div>
              
            </div>

            


            <br />
            <h3>{(lnstr === 'en') ? 'Personal Details':'التفاصيل الشخصية'}</h3>


            <div>
            <table className="table table-bordered table-condenced" style={{ textAlign: 'left' }}>

  <tbody>

      <tr><td style={{ width: '50%' }}>
      First Name
          </td><td><b>{personalinfo != null ? personalinfo ?.fullName : ''}</b></td></tr>

      <tr><td>Email	
          </td><td><b>{personalinfo != null ? personalinfo ?.email : ''}</b></td></tr>
        
      
          <tr><td>Civil ID
      
      </td><td><b>{personalinfo != null ? personalinfo?.civilid : ''}</b>
      </td></tr>


      <tr><td>Mobile
  </td><td><b>{personalinfo !=null ? personalinfo ?.mobilenumber : ''}</b></td></tr>

      <tr><td>Expiry Date of Civil ID / Passport
          </td><td><b>{personalinfo != null ? formatDOB (personalinfo?.expiry_date) : ''}</b></td></tr>

      <tr><td>Passport
  </td><td><b>{personalinfo != null ? personalinfo?.passportnumber : ''}</b></td></tr>

  <tr><td>Nationality
  </td><td><b>{personalinfo != null ? personalinfo?.nationality: ''}</b></td></tr>
  </tbody>

  </table>
            </div>
                            <br/>



                            <h3>{(lnstr === 'en') ? 'Premium Details':'تفاصيل متميزة'}</h3>
            <div>
            <table className="table table-bordered table-condenced" style={{ textAlign: 'left' }}>

<tbody>

    <tr><td style={{ width: '50%' }}>
    Basic premium
    </td><td><b>{amount != null ? <NumberFormatter value={amount?.base_price} /> : ''}</b></td></tr>

<tr><td>Policy Fee	
          </td><td><b>{amount != null ? <NumberFormatter value={amount?.policy_fee} /> : ''}</b></td></tr>

    <tr><td>Death Cover</td>
    <td><b>{amount != null ? <NumberFormatter value={amount?.death_cover} /> : ''}</b></td></tr>

    <tr><td>Government Fee
    
    </td><td><b>{amount != null ? <NumberFormatter value={amount?.government_fee} />: ''}</b>
    </td></tr>
    

    <tr><td>Emergency Fund Fee
    </td><td><b>{amount != null ? <NumberFormatter value={amount?.emergency_fund_fee} />: ''}</b></td></tr>
    <tr><td>VAT
    </td><td><b>{amount != null ?<NumberFormatter value={amount?.vat} />  : ''}</b></td></tr>

    <tr><td>Total Premium
    </td><td><b>{amount != null ?<NumberFormatter value={amount?.total} />  : ''}</b></td></tr>
</tbody>

</table>
            </div>

            <div className="d-flex  my-3">

            <button className="btn btn-primary" style={{ padding: '10px 30px' , marginRight : '10px'}}   onClick={handlecancel} >Edit</button>

            <button className="btn btn-primary" style={{ padding: '10px 30px'}}   onClick={handleSubmit} > Proceed to Payment</button>

            </div>

            


            </Col>

            <Col md={3}></Col>

          </div>

          {isModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={ () => handlepopupclose()}>&times;</span>
                        <div>
            <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52" style={{width: '52px' , height: '52px;'}}><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"></circle><path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"></path></svg>
            </div>
            <div><h3>Thank you for contacting</h3></div>
            <div><p>We have received your request for</p></div>
            <div>{Referanceid}</div>

            <div><p>Please call us @ + 968 7707 4345 for further assistance.</p></div>
                        {/* {bmi != 0 && <p style={{
                                    color: bmi < 18.5 ? 'blue' : bmi < 30 ? 'green' : 'red',
                                }}>Your Body Mass Index is {bmi}</p>}
                        <p>referal_quote_id : {Referanceid}</p> */}
                        {/* <p>{modalMessage}</p> */}
                    </div>
                </div>
            )}


        </div>
      </div>

      <Footer />

      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}

    </Fragment>



    
  );
}

export default PersonalPolicyStatus;