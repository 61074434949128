import React, { Fragment, useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import Navbar from "../Navbar";
import Footer from "../Footer";
import Select2Wrapper from '../Select2Wrapper';
import Cookies from 'js-cookie';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import ReactSelect from "react-select";

const { config } = require('../api/api');

Modal.setAppElement('#root');
const Applicationnew = () => {
  const base_url = config.baseURL;
  const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';

  const { t } = useTranslation();


  const [searchData, setSearchData] = useState(false);
  useEffect(() => {
    const storedSearchString = sessionStorage.getItem('searchData');
    if (storedSearchString !== 'undefined') {
      const storedSearch = JSON.parse(storedSearchString);
      setSearchData(storedSearch);
    }
  }, []);


  // const [vendorKey, setVendorKey] = useState('');


  const [userData, setUserData] = useState();

  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [lemail, setLemail] = useState('');
  const [phone, setphone] = useState('');
  const [street, setstreet] = useState('');
  const [city, setcity] = useState('');
  const [state, setstate] = useState('');
  const [country, setcountry] = useState('');
  const [apartment, setapartment] = useState('');
  const [building, setbuilding] = useState('');
  const [floor, setfloor] = useState('');
  const [id, setid] = useState("");

  const [phoneValue, setPhoneValue] = useState("");


  const [storedUser, setStoredUser] = useState(null);

  const [loading, setLoading] = useState(true);

  const [vendorKey, setVendorKey] = useState('');

  //debugger
  const [usrtok, setUsrtok] = useState("");
  useEffect(() => {
    const storedUserString = Cookies.get('userData');
    if (storedUserString !== 'undefined') {
      const storedData = JSON.parse(storedUserString);
      setUserData(storedData);


      // if (storedData.id) {
      //   setUserData((prevProfile) => ({ ...prevProfile, id: storedData.id }));
      // }
    }
  }, []);


  useEffect(() => {
    debugger

    const postvData = { organization_name: "Superjet" };

    if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {


      fetch(base_url + '/create_organization', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postvData),
      })
        .then(response => response.json())
        .then(data => {
          setVendorKey(data.result[0].vendor_key);
        })
        .catch(error => {
          console.error('Error posting data:', error);
        });
    }
  }, [vendorKey]); // Dependency array


  useEffect(() => {
    const storedUserString = Cookies.get('userData');
    if (storedUserString !== undefined) {
      const user_data = JSON.parse(storedUserString);
      setUsrtok(user_data.access_token);
      const vkData = { vendor_key: vendorKey };

      fetch(base_url + '/each_traveller_details', {

        method: 'POST',
        headers: {
          'Authorization': `Bearer ${usrtok}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(vkData),
      })
        .then(response => response.json())
        .then(data => {
          setStoredUser(data.result[0]);
        })
        .catch(error => {
          console.error('Error fetching countries:', error);
        });
    } else {
      alert('Login Session Expired');
      window.location.href = '/';
    }

  }, [usrtok, vendorKey]);

  useEffect(() => {
    if (storedUser) {
      setFname(storedUser.first_name);
      setLname(storedUser.last_name);
      setLemail(storedUser.email);
      setphone(storedUser.phone);
      setbuilding(storedUser.building);
      setfloor(storedUser.floor);
      setapartment(storedUser.apartment);
      setstreet(storedUser.street);
      setcity(storedUser.city);
      setstate(storedUser.state);
      setcountry(storedUser.country);
      setid(storedUser.id);
    }
    setLoading(false);
  }, [storedUser, usrtok]);

  const [searchResult, setSearchResult] = useState(false);
  useEffect(() => {
    const storedResultString = sessionStorage.getItem('searchResult');
    if (storedResultString !== "undefined") {
      const storedResult = JSON.parse(storedResultString);
      setSearchResult(storedResult);
    }
  }, []);





  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    dob: '',
    birthCountry: '',
    citizenshipCountry: '',
    sex: '',
    email: '',
    phoneA: '',
    phoneP: '',
    livingCountry: '',
    passportNumber: '',
    passportIssuingCountry: '',
    passportIssuingDate: '',
    passportExpiringDate: '',
    transit: 'Yes',
    applicantEmployed: ''
  });

  const [dob, setDob] = useState('');
  const [birthcountry, setBirth] = useState('');
  const [passportNumber, setPnumber] = useState('');
  const [passportIssuingDate, setIdate] = useState('');
  const [passportExpiringDate, setEdate] = useState('');

  function generateRandomValue() {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let randomValue = '';
    for (let i = 0; i < 10; i++) {
      randomValue += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return randomValue;
  }
  // useEffect(() => {

  //   // debugger;

  // const storedUserString = Cookies.get('userData'); //sessionStorage.getItem('userData')
  //   if (storedUserString!=='undefined') {
  //     const storedUser = JSON.parse(storedUserString);
  //     setUserData(storedUser);
  //   }

  // }, []);


  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'firstName') {
      const first_name = value.replace(/[^A-Za-z\s]/g, "")
      setFname(first_name);
    }
    if (name === 'lastName') {
      const last_name = value.replace(/[^A-Za-z\s]/g, "")
      setLname(last_name);
    }
    if (name === 'email') {

      setLemail(value);
    }
    if (name === 'dob') {
      setDob(value);
    }
    if (name === 'birthCountry') {
      setBirth(value);
    }
    if (name === 'passportNumber') {
      setPnumber(value);
    }
    if (name === 'passportIssuingDate') {
      setIdate(value);
    }
    if (name === 'passportExpiringDate') {
      setEdate(value);
    }
    if ((name === 'phone_number') && (value.length <= 12)) {
      const phone_no = value.replace(/[^0-9]/g, "")
      setphone(phone_no);
    }
    if (name === 'phone_code') {
      setPhoneValue(value);
    }
    if (name === 'street') {
      setstreet(value);
    }
    if (name === 'city') {
      setcity(value);
    }
    if (name === 'state') {
      setstate(value);
    }
    if (name === 'country') {
      setcountry(value);
    }
    if (name === 'apartment') {
      setapartment(value);
    }
    if (name === 'building') {
      setbuilding(value);
    }
    if (name === 'floor') {
      setfloor(value);
    }


    setFormData({ ...formData, [name]: value });
  };




  //getcounutries

  const [countryList, setCountries] = useState([]);

  useEffect(() => {

    var vkData = { vendor_key: vendorKey };

    fetch(base_url + '/country', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(vkData),
    })
      .then(response => response.json())
      .then(data => {
        // debugger
        setCountries(data.result);
      })
      .catch(error => {
        console.error('Error fetching countries:', error);
      });
  }, [vendorKey]);

  const [phoneaValue, setphoneaValue] = useState('');
  const [citizcountry, setcitizcountry] = useState('');
  const handlecitizenshipChange = (value) => {

    // const [description, id] = value.split("#");

    const cnData = { country: value };

    setcitizcountry(value);

    fetch(base_url + '/get_country_code', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(cnData),
    })
      .then(response => response.json())
      .then(data => {
        // debugger

        const cde = data.result.replace(/\+/g, '');

        setphoneaValue(cde);

      })
      .catch(error => {
        console.error('Error posting data:', error);
      });

  };



  useEffect(() => {
    const cnData = { country: country };

    // setcitizcountry(value);

    fetch(base_url + '/get_country_code', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(cnData),
    })
      .then(response => response.json())
      .then(data => {
        // debugger

        const cde = data.result.replace(/\+/g, '');

        setPhoneValue(cde);

      })
      .catch(error => {
        console.error('Error posting data:', error);
      });
  }, [country]);

  const [pkey, setpKey] = useState('');

  const vkdata = { vendor_key: vendorKey };

  if (vendorKey !== '' && pkey === '') {

    fetch(base_url + '/generate_payment_token', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${usrtok}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(vkdata),
    })
      .then(response => response.json())
      .then(data => {
        // debugger

        setpKey(data.result.payment_token);


      })
      .catch(error => {
        console.error('Error posting data:', error);
      });

  }

  debugger
  const handleSubmit = async (e) => {
    e.preventDefault();
    debugger
    // const regexEmail=/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    // const emailRegex= /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // if(!emailRegex.test(lemail)){
    //   return alert("please enter a valid email address.");
    // }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if the entered email is valid
    if (fname == '') {
      alert('Please enter First name');
    } else if (lname == '') {
      alert('Please enter Last name');
    } else if (!emailPattern.test(lemail)) {
      alert('Please enter valid e-mail address');
    } else if (phone == '') {
      alert('Please enter valid Phone No');
    } else if (country == '') {
      alert('please select the country');
    }
    else {
      debugger
      e.preventDefault();
      console.log(userData);
      console.log(searchResult);
      console.log(searchData);

      // e.preventDefault();
      // window.location.href = '/application-verify';
      setLoading(true);
      if ((fname !== "" && lname !== "") && (lemail !== "" && phone !== "") && (country !== "")) {
        debugger
        var visa_detail = [{ vendor_key: vendorKey },
        {
          profile: {
            id: id,
            first_name: fname,
            last_name: lname,
            email: lemail,
            phone: phone,
            building: building,
            floor: floor,
            apartment: apartment,
            street: street,
            city: city,
            state: state,
            country: country,
            phone_code: phoneValue
          }
        }];


        fetch(base_url + '/traveller_profile_update', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(visa_detail),
        })
          .then(response => response.json())
          .then(data => {

            if (data.message == 'success') {
              // alert('application submitted');
            }

          })
          .catch(error => {
            // alert('error')
            console.error('Error fetching in application', error);
          });
      }
      else {
        alert("fill out the required information");
      }
      var order_data = {
        "vendor_key": vendorKey,
        "reference_no": generateRandomValue(),
        "description": "",
        "program_id": searchResult.id,
        "quantity": 1,
        "first_name": fname,
        "last_name": lname,
        "email": lemail,
        "fee": searchResult.fee,
        "arrival": searchData.citizenship,
        "destination": searchData.destination,
        "commission": searchResult.commision,
        "commission_type": searchResult.commision_type,
        "travel_date": searchData.arrivalDate,
        "apartment": apartment,
        "building": building,
        "floor": floor,
        "phone_no": phone,
        "street": street,
        "city": city,
        "state": state,
        "country": country
      }
      if (userData.agency_name === null || userData.agency_name === undefined || userData.agency_name === '') {
        var iframe_visa_order_url = base_url + '/iframe_order_visa';
      } else {
        var iframe_visa_order_url = base_url + '/iframe_order_visa_agent';
      }
      await fetch(iframe_visa_order_url, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${usrtok}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(order_data),
      })
        .then(response => response.json())
        .then(data => {
          // sessionStorage.setItem("viplink", JSON.stringify(data.result[0].payment_link));
          setLoading(false);
          if (data.message === 'success') {
            sessionStorage.setItem("order_data", JSON.stringify(order_data));
            sessionStorage.setItem("deeplink", JSON.stringify(data));
            window.location.href = `/application-verify`;
          }
        })
        .catch(error => {
          console.error('Error posting data:', error);
        });



      // try {
      //   const response = await fetch(iframe_visa_order_url, {
      //     method: 'POST',
      //     headers: {
      //       'Authorization': `Bearer ${usrtok}`,
      //       'Content-Type': 'application/json',
      //     },
      //     body: JSON.stringify(order_data),
      //   });

      //   // if (!response.ok) {
      //   //   throw new Error(`HTTP error! status: ${response.status}`);
      //   // }
      // debugger
      //   const data = await response.json();
      //   console.log('data', data);

      //   // sessionStorage.setItem("viplink", JSON.stringify(data.result[0].payment_link));
      //   // window.location.href = `/verify`;
      // } catch (error) {
      //   console.error('Error posting data:', error);
      // }

    }
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  // Handle link click to open modal
  const handleOpenModal = (event) => {
    event.preventDefault(); // Prevent the default link behavior
    setIsModalOpen(true);
  };

  // Handle close modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (

    <Fragment>

      <div className="header_section1">

        <Navbar />


        <div className="container-fluid bg-breadcrumb">
          <div className="container text-center py-5">
            <h3 className="abt-txt my-5">{t('Visa')} {t('Application')} {t('Form')}</h3>
          </div>
        </div>


      </div>


      <div class="features_section">
        <div class="container">

          <Row>

            <Col md={3}></Col>

            <Col md={6} className="myforms my-5">


              <div className="clformrow mt-1">


                <form onSubmit={handleSubmit}>


                  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                      <span className="input-group-text">{t('First')} {t('Name')}<span style={{ color: "red" }}>&nbsp;*</span></span>
                    </div>
                    <input type="text" name="firstName" className="form-control"
                      value={fname} onChange={handleChange} required />
                  </div>

                  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                      <span className="input-group-text">{t('Last')} {t('Name')}
                        <span style={{ color: "red" }}>&nbsp;*</span>
                      </span>
                    </div>
                    <input type="text" name="lastName" className="form-control"
                      value={lname} onChange={handleChange} required />
                  </div>

                  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                      <span className="input-group-text">{t('Email')}
                        <span style={{ color: "red" }}>&nbsp;*</span></span>
                    </div>
                    <input type="text" name="email" className="form-control" value={lemail}
                      onChange={handleChange} required />
                  </div>
                  {/* <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                      <span className="input-group-text">{t('Phone No')}</span>
                    </div>
                    <input type="text" name="phone_number" className="form-control" value={phone}
                      onChange={handleChange} required />
                  </div> */}

                  <div className="row mt-1 ">

                    <div className="col-md-6 mb-2 ">
                      <div className="input-group w-100">
                        <div className="input-group-prepend prepends" style={{ width: '43%' }}>
                          <span className="input-group-text">{t('Building')}</span>
                        </div>
                        <input
                          type="text"
                          name="building"
                          className="form-control"
                          value={building}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-2">
                      <div className="input-group w-100" >
                        <div className="input-group-prepend prepends" style={{ width: '43%' }}>
                          <span className="input-group-text">{t('Floor')}</span>
                        </div>
                        <input
                          type="text"
                          name="floor"
                          className="form-control"
                          value={floor}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="input-group mb-2">
                    <div className="input-group-prepend prepends">
                      <span className="input-group-text">{t('Apartment')}</span>
                    </div>
                    <input type="text" name="apartment" className="form-control" value={apartment}
                      onChange={handleChange} />
                  </div>




                  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                      <span className="input-group-text">{t('Street')}</span>
                    </div>
                    <input type="text" name="street" className="form-control" value={street}
                      onChange={handleChange} />
                  </div>

                  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                      <span className="input-group-text">{t('City')}</span>
                    </div>
                    <input type="text" name="city" className="form-control" value={city}
                      onChange={handleChange} />
                  </div>

                  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                      <span className="input-group-text">{t('State')}</span>
                    </div>
                    <input type="text" name="state" className="form-control" value={state}
                      onChange={handleChange} />
                  </div>

                  <div className="d-flex input-group mb-2 mt-2 ">

                    <div className="input-group-prepend prepends">
                      <span className="input-group-text">{t('Country')}
                        <span style={{ color: "red" }}>&nbsp;*</span>
                      </span>
                    </div>
                    {/* <input type="text" name="country" className="form-control" value={country}
                      onChange={handleChange} /> */}

                    <ReactSelect className="form-control"
                      options={
                        countryList?.map((country) => ({
                          value: country.id, // or any unique identifier from the API
                          label: country.country, // or whatever name field your API returns
                        }))}
                      value={
                        countryList
                          ?.map((country) => ({
                            value: country.id,
                            label: country.country,
                          }))
                          .find((option) => option.label === country) // Match with profile.country
                      }
                      onChange={(selectedOption) => {
                        handleChange({
                          target: {
                            name: "Country".toLowerCase(), // Use the field name dynamically
                            value: selectedOption?.label, // Pass the selected value
                          },
                        }, "Country".toLowerCase(),);
                      }}
                      placeholder={t(`Select your country`)}
                      isSearchable
                      required
                    />

                  </div>

                  <div className="d-flex input-group mb-2 mt-2  ">
                    <div className="input-group-prepend prepends">
                      <span className="input-group-text">{t('Phone No')}
                        <span style={{ color: "red" }}>&nbsp;*</span></span>
                    </div>
                    {/* <input type="text" name="phone_number" className="form-control" value={phone}
                      onChange={handleChange} required /> */}


                    <input type="text" style={{ width: "10%" }} className=" border text-center mr-2" name="phone_code"
                      value={phoneValue} onChange={handleChange} required />
                    <input
                      type="text"
                      name="phone_number"
                      className="form-control"
                      value={phone}
                      onChange={handleChange}
                      // style={{ borderRadius: "8px" }}
                      required
                    />
                  </div>

                  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                      <span className="input-group-text">{t('Passport')} {t('Country')}</span>
                    </div>
                    <input type="text" className="form-control" value={searchResult?.citizenship}
                      required readOnly />
                  </div>


                  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                      <span className="input-group-text">{t('Destination')} {t('Country')}</span>
                    </div>
                    <input type="text" className="form-control" value={searchResult?.destination}
                      required readOnly />
                  </div>


                  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                      <span className="input-group-text">{t('Visa')} {t('Fee')}</span>
                    </div>
                    <input type="text" name="fee" className="form-control" value={searchResult?.fee}
                      required readOnly />
                  </div>
                  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                      <span className="input-group-text">{t('Travel')} {t('Date')}</span>
                    </div>
                    <input type="text" name="fee" className="form-control" value={searchData?.arrivalDate}
                      required readOnly />
                  </div>

                  <br />
                  <p>We use this to create your E-Visa and send you updates about your
                    application</p>

                  <p><input type="checkbox" checked={isChecked}
                    onChange={handleCheckboxChange} name="note" /> <a href="" onClick={handleOpenModal}>I want to receive E-Visa updates, product
                      launches and personalized offers. I can opt out anytime.</a></p>
                  {/* <br />
                  <b>Please ensure you have a valid passport, a recent photo and any country-specific documents ready.</b>
                  <br /> */}
                  <button type="submit" disabled={!isChecked}
                    className="btn btn-primary rounded-pill text-white my-2 py-1 px-4">{t('Submit')}</button>


                </form>

              </div>

            </Col>

            <Col md={3}></Col>

          </Row>

          {loading &&
            <div className="overlay">
              <div className="spinner"></div>

            </div>
          }

        </div>

      </div>



      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        contentLabel="Example Modal"
        style={{
          overlay: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
          content: {
            position: 'relative',
            padding: '20px',
            maxWidth: '80%', // Set larger width for larger modal
            maxHeight: '80vh',
            overflow: 'auto',
            inset: 'auto',
            width: '100%',
          },
        }}
      >
        <div>
          <button
            onClick={handleCloseModal}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              background: 'transparent',
              border: 'none',
              fontSize: '24px',
              cursor: 'pointer'
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>

          <center>
            <h2 style={{ fontWeight: 600 }}>Form Filling Disclaimers for Visa Processing</h2>
          </center>
        </div>

        <br />
        <br />
        <br />

        <p><b style={{ fontWeight: 600 }}>Accuracy of Information</b>
          <br />
          • I hereby acknowledge that all information provided in this form is accurate and truthful to the best
          of my knowledge. I understand that providing false or misleading information may result in the rejection
          of my visa application and potential legal consequences.
          <br />
          <br />
          <b style={{ fontWeight: 600 }}>Role of SuperJetOM</b>
          <br />
          • I understand and agree that SuperJetOM is a service provider facilitating the submission of my visa
          application. I acknowledge that the decision to grant or deny the visa is solely at the discretion of
          the relevant embassy and government authorities. SuperJetOM has no influence or control over the
          decision-making process of the visa application.
          <br />
          <br />
          <b style={{ fontWeight: 600 }}>Processing Time and Delays</b>
          <br />
          • I acknowledge that visa processing times may vary and that SuperJetOM cannot guarantee any specific
          timeframe for the processing of my application. I understand that delays may occur due to various
          factors beyond the control of SuperJetOM.
          <br />
          <br />
          <b style={{ fontWeight: 600 }}>Non-Refundable Fees</b>
          <br />
          • I understand that all fees paid to SuperJetOM for the visa processing service are non-refundable,
          regardless of the outcome of the visa application.
          <br />
          <br />
          <b style={{ fontWeight: 600 }}>Additional Documentation</b>
          <br />
          • I acknowledge that the embassy or government authorities may request additional documentation or
          information during the processing of my visa application. I agree to promptly provide any such requested
          information to avoid delays in processing.
          <br />
          <br />
          <b style={{ fontWeight: 600 }}>No Guarantees of Approval</b>
          <br />
          • I understand that the submission of a visa application through SuperJetOM does not guarantee approval
          of the visa. The decision rests solely with the embassy and government authorities, and SuperJetOM
          cannot influence this outcome.
          <br />
          <br />
          <b style={{ fontWeight: 600 }}>Legal Compliance</b>
          <br />
          • I agree to comply with all applicable laws and regulations related to the visa application process. I
          understand that any violation of these laws may result in the rejection of my application and potential
          legal consequences.
          <br />
          <br />
          <b style={{ fontWeight: 600 }}>Privacy and Data Protection</b>
          <br />
          • I acknowledge that SuperJetOM will handle my personal information in accordance with its privacy
          policy and applicable data protection laws. I consent to the collection, processing, and storage of my
          personal data for the purpose of visa processing.
          <br />
          <br />
          <b style={{ fontWeight: 600 }}>Changes to Application Requirements</b>
          <br />
          • I understand that visa application requirements and procedures may change without notice. I agree to
          provide any updated information or documentation as required by the embassy or government authorities.
          <br />
          <br />
          <b style={{ fontWeight: 600 }}>Communication and Notifications</b>
          <br />
          • I agree to receive communication and notifications from SuperJetOM regarding my visa application via
          the contact information provided in this form. I understand that it is my responsibility to ensure that
          my contact details are accurate and up to date.
        </p>
      </Modal>

      <Footer />

    </Fragment>

  );

};

export default Applicationnew;