import React, { useState, useEffect } from 'react';
import logoimg from './logo.png';
import logogreenimg from './logogreen.png';
import whatsappimg from './whatsap.png';
import braxtone from './truck_towing.gif'
import { useTranslation } from 'react-i18next';
import i18n from './i18n';
import Cookies from 'js-cookie';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Logout from './Logout';
const { config } = require('../src/api/api');
const Navbar = () => {
  const base_url = config.baseURL;

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  //model start
  // Form state for input fields
  const [priority, setPriority] = useState('');
  const [reference_number, setReference] = useState('');
  const [mobile, setMobile] = useState('');
  const [policy_number, setPolicy] = useState('');
  const [chassis_number, setChassis] = useState('');

  const [showModal, setShowModal] = useState(false);
  const [apicall, setApical] = useState(false);
  const [alertmsg, setShowAlert] = useState('');
  const [resmsg, setResAlert] = useState('');
  const [showMaintenance, setShowMaintenance] = useState(false);
  const maintenancePopupStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  };

  const popupContentStyle = {
    background: "white",
    padding: "20px",
    borderRadius: "10px",
    textAlign: "center",
    maxWidth: "400px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  };
  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => (document.body.style.overflow = "auto");
  }, [showModal]);

  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(base_url, {
          method: 'GET',
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response;
        setShowMaintenance(false);
      } catch (error) {
        setShowMaintenance(true);
      }
    };

    fetchData();
  }, []);
  const handleLogoClick = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleCloseModal = () => {
    resetForm();
    setShowModal(false);
    setResAlert('');
  };
  const resetForm = () => {
    setReference('');
    setPriority('');
    setMobile('');
    setPolicy('');
    setChassis('');
  }
  const modalSubmit = async () => {
    if (mobile == '') {
      setShowAlert('Please enter a Mobile No');
    } else if (priority == '') {
      setShowAlert('Please Select a Priority');
    } else {
      setApical(true);

      setShowAlert('');
      // setLoading(false);
      const datav = {
        'mobile': mobile,
        'priority': priority,
        'reference_number': reference_number,
        'policy_number': policy_number,
        'chassis_number': chassis_number,
      };
      console.log(datav);
      await fetch(base_url + '/roadside_assistance', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(datav),
      })
        .then(response => response.json())
        .then(data => {
          setApical(false);
          if (data.message == 'success') {
            setResAlert(data.result.message);
            // setTimeout(() => {
            //   handleCloseModal();
            // }, 2000);

            // setShowModal(false);
            // setResAlert('');
          } else {
            setResAlert(data.result.message);
          }


        })
        .catch(error => {
          console.error('Error posting data:', error);
        });

    }

  }


  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setShowAlert('');
    setResAlert('');
    if (name == 'reference_number') {
      setReference(value);
    }
    if (name == 'priority') {
      setPriority(value);
    }
    if (name == 'mobile_number') {
      setMobile(value);
    }
    if (name == 'policy_number') {
      setPolicy(value);
    }
    if (name == 'chassis_number') {
      setChassis(value);
    }
  }
  //model end
  const [userData, setUserData] = useState();

  useEffect(() => {
    const checkVisaUserData = async () => {
      try {
        const storedUserString = Cookies.get('userData'); //sessionStorage.getItem('userData')
        if (storedUserString) {
          const storedUser = JSON.parse(storedUserString);
          setUserData(storedUser);
          if(storedUser.access_token){
            // var usrtok = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFydW5Abmlnc29mdC5jb20iLCJpZCI6NjIsImlhdCI6MTczMzk4NjA1MSwiZXhwIjoxNzMzOTg2MTExfQ.bSC0xAxmAS0-71YmdU_oYbvDMmNW_3trUXc_oetw_xo';
            var usrtok = storedUser.access_token;
                  try {
                    const response = await fetch(base_url + '/traveller_user_token_check', {
                      method: 'POST',
                      headers: {
                        'Authorization': `Bearer ${usrtok}`,
                        'Content-Type': 'application/json',
                      },
                    });
                    if (!response.ok) {
                      throw new Error('Network response was not ok');
                    }
                    const data = await response.json();
                    if (data != null && data.message != null && (data.message == 'Invalid Token' || data.message == 'Token has expired')) {
                      window.alert('Session Timed Out');
                      await Logout();
                    }
                } catch (error) {

                }
          }
        }
      } catch (error) {
      }
    };
    checkVisaUserData();
  }, []);

  useEffect(() => {
    const checkDhofarUserData = async () => {
      try {
        const inboundDhofarData = JSON.parse(Cookies.get('dhofarData'));
        const outboundDhofarData = JSON.parse(Cookies.get('dhofarDataoutbound'));
        const personalDhofarData = JSON.parse(Cookies.get('personalData'));
        const healthDhofarData = JSON.parse(Cookies.get('HealthIndividualData'));
        const medicalDhofarData = JSON.parse(Cookies.get('medicalData'));
        const medicalAffordableData = JSON.parse(Cookies.get('medicalAffordableData'));
        const homeDhofarData = JSON.parse(Cookies.get('homeData'));


        var dhofar_token = '';
        if (inboundDhofarData) {
          dhofar_token = inboundDhofarData;
        } else if (outboundDhofarData) {
          dhofar_token = outboundDhofarData;
        } else if (personalDhofarData) {
          dhofar_token = personalDhofarData;
        } else if (healthDhofarData) {
          dhofar_token = healthDhofarData;
        } else if (medicalDhofarData) {
          dhofar_token = medicalDhofarData;
        } else if (medicalAffordableData) {
          dhofar_token = medicalAffordableData;
        } else if (homeDhofarData) {
          dhofar_token = homeDhofarData;
        }
        if (dhofar_token != null && dhofar_token != undefined && dhofar_token != '') {
          try {
            const response = await fetch(base_url + '/validate_dhofar_access_token', {
              method: 'GET',
              headers: {
                'Authorization': `${dhofar_token}`,
                'Content-Type': 'application/json',
              },
            });
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const data = await response.json();
            if (data != null && data.message != null && (data.message == 'Invalid Token' || data.message == 'Token has expired')) {
              window.alert('Session Timed Out');
              window.location.href = '/';
            }
          } catch (error) {

          }
        }
      } catch (error) {
      }
    };
    checkDhofarUserData();
  }, []);


  

  const { t } = useTranslation();

  const lngstr = Cookies.get('langData') ? (Cookies.get('langData') === 'ar') ? 'عربي' : 'English' : 'English';

  const [sellng, setsellng] = useState(lngstr);

  const changeLanguage = (lng) => {

    const expiryDate = new Date();
    expiryDate.setTime(expiryDate.getTime() + (1 * 60 * 60 * 1000));

    Cookies.set('langData', lng, { expires: expiryDate });

    // sessionStorage.setItem("langData", lng);

    if (lng === 'ar') {
      setsellng('العربية');
    }
    else {
      setsellng('English');
    }

    i18n.changeLanguage(lng);
  };


  // Function to update HTML dir attribute based on language
  const updateHtmlDirAttribute = () => {
    document.documentElement.dir = i18n.dir(); // Set document direction
  };

  // Update HTML dir attribute when component mounts and language changes
  useEffect(() => {
    updateHtmlDirAttribute();
  }, [i18n.language]);



  const currentPageUrl = window.location.href;

  const currentPageName = currentPageUrl.substring(currentPageUrl.lastIndexOf('/') + 1);



  return (
    <div>
      {showMaintenance && (
        <div style={maintenancePopupStyle}>
          <div style={popupContentStyle}>
            <h2>Maintenance Notice</h2>
            <p>
              Our website is currently undergoing maintenance.
            </p>
          </div>
        </div>
      )}

      {(currentPageName === '') ?
        <HelmetProvider>
          <Helmet>
            <title>SUPJERJET | Visa at click. Insurance for everything.</title>
            <meta name="description" content="Visa at click. Insurance for everything." />
            <meta name="keywords" content="e-Visa, Travel Insurance, Insurance, Visa Services, Visa, e-Insurance" />
            <link rel="canonical" href={currentPageUrl} />
          </Helmet>
        </HelmetProvider> : (currentPageName === 'visa') ? <HelmetProvider>
          <Helmet>
            <title>e-Visa | SUPERJET</title>
            <meta name="description" content="Visa at a click. Travel made simple" />
            <meta name="keywords" content="e-Visa, Travel Insurance, Insurance, Visa Services, Visa, e-Insurance" />
            <link rel="canonical" href={currentPageUrl} />
          </Helmet>
        </HelmetProvider> : (currentPageName === 'insurance') ? <HelmetProvider>
          <Helmet>
            <title>e-Insurance | SUPERJET</title>
            <meta name="description" content="Insurance for everything." />
            <meta name="keywords" content="e-Visa, Travel Insurance, Insurance, Visa Services, Visa, e-Insurance" />
            <link rel="canonical" href={currentPageUrl} />
          </Helmet>
        </HelmetProvider> : (currentPageName === 'application') ? <HelmetProvider>
          <Helmet>
            <title>e-Visa | SUPERJET</title>
            <meta name="description" content="Visa at a click. Travel made simple" />
            <meta name="keywords" content="e-Visa, Travel Insurance, Insurance, Visa Services, Visa, e-Insurance" />
            <link rel="canonical" href={currentPageUrl} />
          </Helmet>
        </HelmetProvider> : (currentPageName === 'verify') ? <HelmetProvider>
          <Helmet>
            <title>e-Visa | SUPERJET</title>
            <meta name="description" content="Visa at a click. Travel made simple" />
            <meta name="keywords" content="e-Visa, Travel Insurance, Insurance, Visa Services, Visa, e-Insurance" />
            <link rel="canonical" href={currentPageUrl} />
          </Helmet>
        </HelmetProvider> : (currentPageName === 'myorders') ? <HelmetProvider>
          <Helmet>
            <title>e-Visa - My Orders | SUPERJET</title>
            <meta name="description" content="Visa at a click. Travel made simple" />
            <meta name="keywords" content="e-Visa, Travel Insurance, Insurance, Visa Services, Visa, e-Insurance" />
            <link rel="canonical" href={currentPageUrl} />
          </Helmet>
        </HelmetProvider> : (currentPageName === 'myprofile') ? <HelmetProvider>
          <Helmet>
            <title>My Profile | SUPERJET</title>
            <meta name="description" content="Visa at a click. Travel made simple" />
            <meta name="keywords" content="e-Visa, Travel Insurance, Insurance, Visa Services, Visa, e-Insurance" />
            <link rel="canonical" href={currentPageUrl} />
          </Helmet>
        </HelmetProvider> : (currentPageName === 'order-page') ? <HelmetProvider>
          <Helmet>
            <title>e-Visa | SUPERJET</title>
            <meta name="description" content="Visa at a click. Travel made simple" />
            <meta name="keywords" content="e-Visa, Travel Insurance, Insurance, Visa Services, Visa, e-Insurance" />
            <link rel="canonical" href={currentPageUrl} />
          </Helmet>
        </HelmetProvider> : (currentPageName === 'travel-insurance') ? <HelmetProvider>
          <Helmet>
            <title>Travel Insurance | SUPERJET</title>
            <meta name="description" content="Travel Insurance is an essential cover for people travelling abroad for leisure holidays, business trips and short stays." />
            <meta name="keywords" content="e-Visa, Travel Insurance, Insurance, Visa Services, Visa, e-Insurance" />
            <link rel="canonical" href={currentPageUrl} />
          </Helmet>
        </HelmetProvider> : (currentPageName === 'inbound-travel-details') ? <HelmetProvider>
          <Helmet>
            <title>Travel Insurance | SUPERJET</title>
            <meta name="description" content="Travel Insurance is an essential cover for people travelling abroad for leisure holidays, business trips and short stays." />
            <meta name="keywords" content="e-Visa, Travel Insurance, Insurance, Visa Services, Visa, e-Insurance" />
            <link rel="canonical" href={currentPageUrl} />
          </Helmet>
        </HelmetProvider> : (currentPageName === 'inbound-travel-info') ? <HelmetProvider>
          <Helmet>
            <title>Travel Insurance | SUPERJET</title>
            <meta name="description" content="Travel Insurance is an essential cover for people travelling abroad for leisure holidays, business trips and short stays." />
            <meta name="keywords" content="e-Visa, Travel Insurance, Insurance, Visa Services, Visa, e-Insurance" />
            <link rel="canonical" href={currentPageUrl} />
          </Helmet>
        </HelmetProvider> : (currentPageName === 'inbound-traveller-info') ? <HelmetProvider>
          <Helmet>
            <title>Travel Insurance | SUPERJET</title>
            <meta name="description" content="Travel Insurance is an essential cover for people travelling abroad for leisure holidays, business trips and short stays." />
            <meta name="keywords" content="e-Visa, Travel Insurance, Insurance, Visa Services, Visa, e-Insurance" />
            <link rel="canonical" href={currentPageUrl} />
          </Helmet>
        </HelmetProvider> : (currentPageName === 'insurance-plan') ? <HelmetProvider>
          <Helmet>
            <title>Travel Insurance | SUPERJET</title>
            <meta name="description" content="Travel Insurance is an essential cover for people travelling abroad for leisure holidays, business trips and short stays." />
            <meta name="keywords" content="e-Visa, Travel Insurance, Insurance, Visa Services, Visa, e-Insurance" />
            <link rel="canonical" href={currentPageUrl} />
          </Helmet>
        </HelmetProvider> : (currentPageName === 'insurance-question') ? <HelmetProvider>
          <Helmet>
            <title>Travel Insurance | SUPERJET</title>
            <meta name="description" content="Travel Insurance is an essential cover for people travelling abroad for leisure holidays, business trips and short stays." />
            <meta name="keywords" content="e-Visa, Travel Insurance, Insurance, Visa Services, Visa, e-Insurance" />
            <link rel="canonical" href={currentPageUrl} />
          </Helmet>
        </HelmetProvider> : (currentPageName === 'insurance-summary') ? <HelmetProvider>
          <Helmet>
            <title>Travel Insurance | SUPERJET</title>
            <meta name="description" content="Travel Insurance is an essential cover for people travelling abroad for leisure holidays, business trips and short stays." />
            <meta name="keywords" content="e-Visa, Travel Insurance, Insurance, Visa Services, Visa, e-Insurance" />
            <link rel="canonical" href={currentPageUrl} />
          </Helmet>
        </HelmetProvider> : (currentPageName === 'outbound-travel-details') ? <HelmetProvider>
          <Helmet>
            <title>Travel Insurance | SUPERJET</title>
            <meta name="description" content="Travel Insurance is an essential cover for people travelling abroad for leisure holidays, business trips and short stays." />
            <meta name="keywords" content="e-Visa, Travel Insurance, Insurance, Visa Services, Visa, e-Insurance" />
            <link rel="canonical" href={currentPageUrl} />
          </Helmet>
        </HelmetProvider> : (currentPageName === 'outbound-traveller-info') ? <HelmetProvider>
          <Helmet>
            <title>Travel Insurance | SUPERJET</title>
            <meta name="description" content="Travel Insurance is an essential cover for people travelling abroad for leisure holidays, business trips and short stays." />
            <meta name="keywords" content="e-Visa, Travel Insurance, Insurance, Visa Services, Visa, e-Insurance" />
            <link rel="canonical" href={currentPageUrl} />
          </Helmet>
        </HelmetProvider> : (currentPageName === 'outbound-travel-info') ? <HelmetProvider>
          <Helmet>
            <title>Travel Insurance | SUPERJET</title>
            <meta name="description" content="Travel Insurance is an essential cover for people travelling abroad for leisure holidays, business trips and short stays." />
            <meta name="keywords" content="e-Visa, Travel Insurance, Insurance, Visa Services, Visa, e-Insurance" />
            <link rel="canonical" href={currentPageUrl} />
          </Helmet>
        </HelmetProvider> : (currentPageName === 'outbound-summary') ? <HelmetProvider>
          <Helmet>
            <title>Travel Insurance | SUPERJET</title>
            <meta name="description" content="Travel Insurance is an essential cover for people travelling abroad for leisure holidays, business trips and short stays." />
            <meta name="keywords" content="e-Visa, Travel Insurance, Insurance, Visa Services, Visa, e-Insurance" />
            <link rel="canonical" href={currentPageUrl} />
          </Helmet>
        </HelmetProvider> : (currentPageName === 'outbound-plan') ? <HelmetProvider>
          <Helmet>
            <title>Travel Insurance | SUPERJET</title>
            <meta name="description" content="Travel Insurance is an essential cover for people travelling abroad for leisure holidays, business trips and short stays." />
            <meta name="keywords" content="e-Visa, Travel Insurance, Insurance, Visa Services, Visa, e-Insurance" />
            <link rel="canonical" href={currentPageUrl} />
          </Helmet>
        </HelmetProvider> : (currentPageName === 'outbound-question') ? <HelmetProvider>
          <Helmet>
            <title>Travel Insurance | SUPERJET</title>
            <meta name="description" content="Travel Insurance is an essential cover for people travelling abroad for leisure holidays, business trips and short stays." />
            <meta name="keywords" content="e-Visa, Travel Insurance, Insurance, Visa Services, Visa, e-Insurance" />
            <link rel="canonical" href={currentPageUrl} />
          </Helmet>
        </HelmetProvider> : (currentPageName === 'about-us') ? <HelmetProvider>
          <Helmet>
            <title>About Us | SUPERJET</title>
            <meta name="description" content="SuperJet is the first company in the GCC to provide integrated eVisa and comprehensive insurance services through advanced APIs. " />
            <meta name="keywords" content="e-Visa, Travel Insurance, Insurance, Visa Services, Visa, e-Insurance" />
            <link rel="canonical" href={currentPageUrl} />
          </Helmet>
        </HelmetProvider> : (currentPageName === 'api-page') ? <HelmetProvider>
          <Helmet>
            <title>API | SUPERJET</title>
            <meta name="description" content="SuperJet is the first company in the GCC to provide integrated eVisa and comprehensive insurance services through advanced APIs. " />
            <meta name="keywords" content="e-Visa, Superjetom, Travel Insurance, Insurance, Visa Services, Visa, e-Insurance" />
            <link rel="canonical" href={currentPageUrl} />
          </Helmet>
        </HelmetProvider> : (currentPageName === 'travel-agent') ? <HelmetProvider>
          <Helmet>
            <title>Travel Agent | SUPERJET</title>
            <meta name="description" content="SuperJet is the first company in the GCC to provide integrated eVisa and comprehensive insurance services through advanced APIs. " />
            <meta name="keywords" content="e-Visa, Superjetom, Travel Insurance, Insurance, Visa Services, Visa, e-Insurance" />
            <link rel="canonical" href={currentPageUrl} />
          </Helmet>
        </HelmetProvider> : (currentPageName === 'terms') ? <HelmetProvider>
          <Helmet>
            <title>Terms of Use | SUPERJET</title>
            <meta name="description" content="Visa at a click. Travel made simple." />
            <meta name="keywords" content="e-Visa, Travel Insurance, Superjetom, Insurance, Visa Services, Visa, e-Insurance" />
            <link rel="canonical" href={currentPageUrl} />
          </Helmet>
        </HelmetProvider> : (currentPageName === 'apipage') ? <HelmetProvider>
          <Helmet>
            <title>API | SUPERJET</title>
            <meta name="description" content="Our APIs are designed to simplify the process of obtaining eVisas and insurance, providing your customers with a smooth and efficient experience." />
            <meta name="keywords" content="e-Visa, Travel Insurance, Insurance, Superjetom, Visa Services, Visa, e-Insurance" />
            <link rel="canonical" href={currentPageUrl} />
          </Helmet>
        </HelmetProvider> : (currentPageName === 'travelagent') ? <HelmetProvider>
          <Helmet>
            <title>Travel Agents | SUPERJET</title>
            <meta name="description" content="SuperJet simplifies travel by facilitating border crossing administration and insurance needs. " />
            <meta name="keywords" content="e-Visa, Travel Insurance, Superjetom, Insurance, Visa Services, Visa, e-Insurance" />
            <link rel="canonical" href={currentPageUrl} />
          </Helmet>
        </HelmetProvider> : (currentPageName === 'login') ? <HelmetProvider>
          <Helmet>
            <title>Login | SUPERJET</title>
            <meta name="description" content="SuperJet simplifies travel by facilitating border crossing administration and insurance needs. " />
            <meta name="keywords" content="e-Visa, Travel Insurance, Insurance, Visa Services, Superjetom, Visa, e-Insurance" />
            <link rel="canonical" href={currentPageUrl} />
          </Helmet>
        </HelmetProvider> : (currentPageName === 'signup') ? <HelmetProvider>
          <Helmet>
            <title>Registration | SUPERJET</title>
            <meta name="description" content="SuperJet simplifies travel by facilitating border crossing administration and insurance needs. " />
            <meta name="keywords" content="e-Visa, Travel Insurance, Superjetom, Insurance, Visa Services, Visa, e-Insurance" />
            <link rel="canonical" href={currentPageUrl} />
          </Helmet>
        </HelmetProvider> : (currentPageName === 'travellerlist') ? <HelmetProvider>
          <Helmet>
            <title>e-Visa - My Travellers | SUPERJET</title>
            <meta name="description" content="Visa at a click. Travel made simple" />
            <meta name="keywords" content="e-Visa, Travel Insurance, Insurance, Visa Services, Visa, e-Insurance" />
            <link rel="canonical" href={currentPageUrl} />
          </Helmet>
        </HelmetProvider> : ''}

      <div className="header_main">
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="logo"><a href="/">{currentPageName == 'application-verify' ? <img src={logogreenimg} alt="SUPERJET" /> : <img src={logoimg} alt="SUPERJET" />}</a></div>
            {/* <div className="logo"><a href="/"><img src={logoimg} alt="SUPERJET" /></a></div> */}
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav mr-auto mx-2 my-2">

                <li className="nav-item dropdown">
                  {(currentPageName === 'visa' || currentPageName === 'insurance') ? <div><a className="nav-link dropdown-toggle active" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{t('Explore')}</a>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                      <a href="/visa" className="dropdown-item">{(sellng === 'English') ? <span>e-Visa</span> : <span>التأشيرات الإلكترونية </span>}</a>
                      <a href="/insurance" className="dropdown-item">{(sellng === 'English') ? <span>e-Insurance</span> : <span>التأمين الإلكتروني </span>}</a>
                    </div></div> : <div><a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{t('Explore')}</a>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                      <a href="/visa" className="dropdown-item">{(sellng === 'English') ? <span>e-Visa</span> : <span>التأشيرات الإلكترونية </span>}</a>
                      <a href="/insurance" className="dropdown-item">{(sellng === 'English') ? <span>e-Insurance</span> :
                        <span>التأمين الإلكتروني </span>}</a>
                    </div></div>}
                </li>

                <li className="nav-item dropdown">
                  {(currentPageName === 'api-page' || currentPageName === 'travel-agent')
                    ?
                    <div>
                      <a className="nav-link dropdown-toggle active" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{t('Partner With Us')}</a>
                      <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <a href="/api-page" className="dropdown-item">API</a>
                        <a href="/travel-agent" className="dropdown-item">{(sellng === 'English') ? <span>Travel Agents</span> : <span>طلب شراكة
                        </span>}</a>
                      </div>
                    </div>
                    :
                    <div>
                      <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{t('Partner With Us')}</a>
                      <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <a href="/api-page" className="dropdown-item">API</a>
                        <a href="/travel-agent" className="dropdown-item">{(sellng === 'English') ? <span>Travel Agents</span> : <span>طلب شراكة
                        </span>}</a>
                      </div>
                    </div>}
                </li>


                <li className="nav-item dropdown">
                  {(currentPageName === 'about-us') ? <div><a className="nav-link dropdown-toggle active" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{t('About Us')}</a>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                      <a href="/about-us" className="dropdown-item">{(sellng === 'English') ? <span>The Company</span> : <span>نبذة عنا
                      </span>}</a>
                    </div></div> : <div><a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{t('About Us')}</a>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                      <a href="/about-us" className="dropdown-item">{(sellng === 'English') ? <span>The Company</span> : <span>نبذة عنا
                      </span>}</a>
                    </div></div>}
                </li>


                <li className="nav-item dropdown">
                  {userData ? (<div><a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{userData.agency_name != null && userData.agency_name != undefined ? userData.agency_name : userData.first_name}</a>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                      {userData != null && userData != undefined && userData.agency_name == null && userData.agency_name == undefined ? <a href="/profile" className="dropdown-item">{t('My')} {t('Profile')}</a> : <a href="/agentprofile" className="dropdown-item">{t('My')} {t('Profile')}</a>}

                      {/* <a href="/profile" className="dropdown-item">{t('My')} {t('Profile')}</a> */}

                      <a href="/myorders" className="dropdown-item">{t('My')} {t('Orders')}</a>
                      {userData != null && userData != undefined && userData.agency_name != null && userData.agency_name != undefined && <a href="/travellerlist" className="dropdown-item">{t('My')} {t('Travellers')}</a>}
                      <a href="/logout" className="dropdown-item">{t('Logout')}</a>
                    </div></div>) : (currentPageName === 'login' || currentPageName === 'signup' ? <a className="nav-link active" href="/login">{(sellng === 'English') ? <span>Login / Signup</span> : <span>اشتراك / تسجيل دخول
                    </span>}</a> : <a className="nav-link" href="/login">{(sellng === 'English') ? <span>Login / Signup</span> : <span>اشتراك / تسجيل دخول
                    </span>}</a>)}
                </li>


              </ul>
              {/* &nbsp;
              <div className=""><img src={braxtone} alt="braxtone" style={{ width: "70px", }} onClick={handleLogoClick} />
              </div>
              &nbsp; */}
              {/* {showModal && <div className="modal-backdrop fade show"></div>} */}

              <div
                className={`modal fade ${showModal ? 'show' : ''}`}
                tabIndex="-1"
                role="dialog"
                aria-labelledby="Roadside Assistance"
                aria-hidden={!showModal}
                style={{ display: showModal ? 'block' : 'none' }}
                onClick={handleCloseModal}
              >
                <div
                  className="modal-dialog"
                  role="document"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="modal-content" style={{ maxHeight: '100vh', overflowY: 'auto' }}>
                    <div className="modal-header">
                      <h3 className="modal-title" id="Roadside Assistance">{t('Roadside Assistance')}</h3>
                      <button

                        type="button"
                        className="close"
                        aria-label="Close"
                        onClick={handleCloseModal}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>

                    <div className="modal-body" style={{textAlign:'left'}}>
                      {/* <p><b>{t('Reference Number')}</b></p> */}

                      <label htmlFor="MobileNumber *" >{t('Mobile Number')}</label>
                      <input
                        type='tel'
                        name="mobile_number"
                        className={`form-control `}
                        placeholder={t('Mobile Number')}
                        value={mobile}
                        onChange={(e) => handleChange(e)}
                        pattern="[0-9]*"
                        required
                      />
                      <p></p>

                      <label htmlFor="prioritySelect *">{t('Priority Level')}</label>
                      <select
                        name="priority"
                        className="form-control"
                        onChange={(e) => handleChange(e)}
                        value={priority}
                        required
                      >
                        <option value="">{t('Select Priority')}</option>
                        <option value="High">{t('High')}</option>
                        <option value="Medium">{t('Medium')}</option>
                        <option value="Low">{t('Low')}</option>
                      </select>

                      {/* <p></p>
                      <label htmlFor="ServiceName">{t('Service Name')}</label>
                      <input
                      type='text'
                        name="service_name"
                        className={`form-control `}
                        placeholder={t('Service Name')}
                        value={service_name}
                        //  onChange={(e) => handleChange(e)}
                        required
                      /> */}

                      {/* {emailError && <div className="invalid-feedback">{emailError}</div>} */}
                      <p></p>
                      <label htmlFor="ReferenceNumber *">{t('Reference Number')} {t('(Optional)')}</label>
                      <input
                        type='text'
                        name="reference_number"
                        className={`form-control `}
                        placeholder={t('Reference Number')}
                        value={reference_number}
                        onChange={(e) => handleChange(e)}
                        required
                      />

                      <p></p>
                      <label htmlFor="ReferenceNumber">{t('Policy Number')} {t('(Optional)')}</label>
                      <input
                        type='text'
                        name="policy_number"
                        className={`form-control `}
                        placeholder={t('Policy Number')}
                        value={policy_number}
                        onChange={(e) => handleChange(e)}
                        required
                      />

                      <p></p>
                      <label htmlFor="ReferenceNumber">{t('Chassis Number')} {t('(Optional)')}</label>
                      <input
                        type='text'
                        name="chassis_number"
                        className={`form-control `}
                        placeholder={t('Chassis Number')}
                        value={chassis_number}
                        onChange={(e) => handleChange(e)}
                        required
                      />
                      <p></p>
                      <span style={{ color: 'red' }}> {(alertmsg === '') ? '' : alertmsg}</span>
                      {resmsg === 'Ticket Created Successfully' ? <span style={{ color: 'green' }}> ${resmsg}  </span> : <span style={{ color: 'red' }}> {(resmsg === '') ? '' : resmsg}</span>}

                    </div>



                    {!apicall ? <div className="modal-footer">
                      <button type="button"
                        className="btn btn-primary"
                        onClick={modalSubmit}
                      >
                        {t('Submit')} </button>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleCloseModal}
                      >
                        {t('Close')}
                      </button>

                    </div> : <div className="modal-footer"> <span><b>Processing</b> </span> </div>}
                    <br></br>

                  </div>
                </div>
              </div>

              <div class="dropdown mr-5">
                {currentPageName == 'application-verify' ? <button class="btn btn-secondary dropdown-toggle" style={{ backgroundColor: '#00EDA2', borderColor: '#00EDA2' }} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{sellng}</button> : <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{sellng}</button>}
                {/* <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{sellng}</button> */}
                <div class="dropdown-menu dropdown-menu1" aria-labelledby="dropdownMenuButton">
                  {(sellng !== 'English') ? <button className="dropdown-item" onClick={() => changeLanguage('en')}>English</button> : ''}
                  {(sellng === 'English') ? <button className="dropdown-item" onClick={() => changeLanguage('ar')}>العربية</button> : ''}
                </div>
              </div>

              {/* <p class="call_text"><a href="https://wa.me/96877074345?text=support"><img src={whatsappimg} alt="SUPERJET" /> {(sellng==='English') ? <span>(968)77074345</span> : <span>77074345(968)</span>}</a></p> */}
              {/* <img src={whatsappimg} alt="SUPERJET" /> */}
            </div>
          </nav>
        </div>
      </div>

    </div>

  );
}

export default Navbar;