import React, { Fragment, useState, useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { useTranslation } from 'react-i18next';
import travelimg from '../tr.png';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';
import { event } from "jquery";
const { config } = require('../api/api');


function NewpolicyDriverDetails() {
  const base_url = config.baseURL;

  const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';


  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    insurancetype: '',
  });
  const [loading, setLoading] = useState(false);
  
  const [civilid, setCivilidvalue] = useState(null);
  const [firstname, setfirstnamevalue] = useState(null);
  const [lastname, setlastnamevalue] = useState(null);
  const [dateofbirth, setdateofbirthvalue] = useState(null);
  const [age, setAgevalue] = useState(null);
  const [gender, setgender] = useState(null);
  const [nationality, setnationalityvalue] = useState(null);
  const [city, setcityvalue] = useState(null);
  const [vendorKey, setVendorkey] = useState('');
  


  // const [selectedqOption, setSelectedqOption] = useState(null);
  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split('-').map(Number);
    return new Date(year, month - 1, day); // month is 0-indexed
  };

  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = parseDate(dob);

    // Check if birthDate is valid
    if (isNaN(birthDate.getTime())) {
      throw new Error('Invalid date');
    }

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    // Adjust age if the birth date hasn't occurred yet this year
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  };




  useEffect(() => {

    const postvData = { organization_name: "Superjet" };
    debugger

    if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {


      fetch( base_url + '/create_organization', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postvData),
      })
        .then(response => response.json())
        .then(data => {
          setVendorkey(data.result[0].vendor_key);
        })
        .catch(error => {
          console.error('Error posting data:', error);
        });


    }
  }, [vendorKey]); // Dependency array


  const handleSubmit = async (event) => {

    debugger

    // var data = {
    //   "basicinfo_gender_id": selectedgenderOptionid,
    //   "basicinfo_gender_detail": selectedgenderOption,
    //   "basicinfo_marital_status_id": selectedmaritalOptionid,
    //   "basicinfo_marital_status_detail": selectedmaritalOption,
    //   "dob": selectedDateofBirth
    // }

    // debugger

    // sessionStorage.setItem("personalaccidentbasicinfo", JSON.stringify(data))

    event.preventDefault();
    setLoading(true);

    event.preventDefault();
    window.location.href = '/newpolicy-driverlicense';

  };

//   useEffect(() => {
//     debugger
//     const basicvalue = sessionStorage.getItem('personalaccidentbasicinfo');
//     if (basicvalue != undefined) {
//       const value = JSON.parse(basicvalue);
//       setSelectedGender(value.basicinfo_gender_detail);
//       setSelectedmarital(value.basicinfo_marital_status_detail);
//       setSelectedDateofBirth(value.dob);
//       setSelectedgenderOption(value.basicinfo_gender_detail);
//       setSelectedgenderOptionid(value.basicinfo_gender_id);
//       setSelectedmaritalOptionid(value.basicinfo_marital_status_id);
//       setSelectedmaritalOption(value.basicinfo_marital_status_detail);

//     }

//   }, []);

  const [dhofarData, setDhofarData] = useState(false);
//   const [age, setAge] = useState(null);
  useEffect(() => {


    const storedDhofarString = Cookies.get('personalData'); //sessionStorage.getItem('userData')
    if (storedDhofarString !== undefined) {
      const storedDhofar = JSON.parse(storedDhofarString);
      setDhofarData(storedDhofar);
    }
    else {
      // alert("token expired");
      // window.location.href='/';
    }

  }, []);


  const [buttonDisabled, setButtonDisabled] = useState(false);
  const postData = { token: dhofarData };
  // const today = new Date().toISOString().split('T')[0];
//   const today = new Date();
//   const maxDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate() + 1).toISOString().split('T')[0];
//   const minDate = new Date(today.getFullYear() - 60, today.getMonth(), today.getDate()).toISOString().split('T')[0];

//   const [dobValue, setdobValue] = useState('');
//   const handledobChange = (event) => {
//     const selectedDob = event.target.value;
//     setdobValue(event.target.value);

//     const dobDate = new Date(selectedDob);
//     const today = new Date();
//     let calculatedAge = today.getFullYear() - dobDate.getFullYear();
//     const monthDiff = today.getMonth() - dobDate.getMonth();

//     // Adjust age if the current date is before the user's birthday in the current year
//     if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dobDate.getDate())) {
//       calculatedAge--;
//     }

//     setAge(calculatedAge);
//   };

  
  
  const handleCivilidChange =(event) => {
    setCivilidvalue(event.target.value)
  };

  const handleFirstnameChange = (event) => {
    setfirstnamevalue(event.target.value)
  };

  const handleLastnameChange = (event) => {
    setlastnamevalue(event.target.value)
  };

  const handledateofbirthChange = (event) => {
    setdateofbirthvalue(event.target.value)
  };

  const handleAgeChange = (event) => {
    setAgevalue(event.target.value)
  };

  const handlegenderChange = (event) => {
    setgender(event.target.value)
  };

  const handlenationalityChange = (event) => {
    setnationalityvalue(event.target.value)
  };

  const handlecityChange = (event) => {
    setcityvalue(event.target.value)
  };

 
  //getgender

//   const [goptions, setGender] = useState([]);
//   const [moptions, setMarital] = useState([]);

//   useEffect(() => {
//     if (goptions && goptions.length === 0 && vendorKey !== '') {

      

//       debugger
//       fetch(base_url + '/getGender', {
//         method: 'POST',
//         headers: {
//           'Authorization': `Bearer ${vendorKey}`,
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(postData),
//       })
//         .then(response => response.json())
//         .then(data => {
//           setGender(data.result.data.response_data);
//           married()

//         })
//         .catch(error => {
//           console.error('Error posting data:', error);
//         });

//     }    
//   },[vendorKey]
//   );

//   const married = () => {
//     if (moptions && moptions.length === 0 && vendorKey !== '') {
//       setLoading(true);

//       fetch(base_url + '/getMaritalStatus', {
//         method: 'POST',
//         headers: {
//           'Authorization': `Bearer ${vendorKey}`,
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(postData),
//       })
//         .then(response => response.json())
//         .then(data => {

//           setMarital(data.result.data.response_data);
//           setLoading(false);


//         })
//         .catch(error => {
//           console.error('Error posting data:', error);
//         });

//     }
//   };






  // const isButtonDisabled =  






  return (
    <Fragment>

      <div className="header_section1">

        <Navbar />


        <div className="container-fluid bg-breadcrumb">
          <div className="container text-center py-5">
            <h3 className="abt-txt my-5">{(lnstr === 'en') ? 'New Policy' : 'سياسة جديدة\n'}</h3>
          </div>
        </div>


      </div>


      <div className="features_section">
        <div className="container">

          <div className="row">

            <Col md={3}></Col>

            <Col md={5} className="myforms my-5">



              <h4 className="px-0 py-2">{(lnstr === 'en') ? 'Insured / Driver Details' : 'تفاصيل المؤمن عليه / السائق\n'}</h4>

              <form onSubmit={handleSubmit}>

              {/* <div className="input-group mb-2 mt-2">
                  <div className="input-group-prepend prepends">
                    <span className="input-group-text">{(lnstr === 'en') ? 'Product':'منتج'}</span>
                  </div>
                  <select  name="product" className="form-control" onChange={handleproductChange} required>
                    <option  >{(lnstr === 'en') ? '-- Select --' : '--اختار--'}</option>
                    <option value=' Comprehensive '> Comprehensive </option>
                    <option value = ' Third Party '> Third Party </option>
                  </select>
                </div> */}

                <div className="input-group mb-2 mt-2">
                  <div className="input-group-prepend prepends">
                    {/* <span className="input-group-text">{(lnstr === 'en') ? 'Civil ID':'أدخل رقم الهيكل'}</span> */}
                  </div>
                  <input type="text" name="civilid" placeholder={(lnstr === 'en') ? 'Civil ID':'أدخل رقم الهيكل'} className="form-control" onChange={handleCivilidChange} required/>
                </div>

                <div className="input-group mb-2 mt-2">
                  <div className="input-group-prepend prepends">
                  {/* <span className="input-group-text">{(lnstr === 'en') ? 'First Name':'الاسم الأول'}</span> */}
                  </div>
                  <input type="text" name="firstname" placeholder={(lnstr === 'en') ? 'First Name':'الاسم الأول'} className="form-control" onChange={handleFirstnameChange} required/>
                </div>

                <div className="input-group mb-2 mt-2">
                  <div className="input-group-prepend prepends">
                  {/* <span className="input-group-text">{(lnstr === 'en') ? 'Last Name':'اسم العائلة'}</span> */}
                  </div>
                  <input type="text" name="lastname" placeholder={(lnstr === 'en') ? 'Last Name':'اسم العائلة'} className="form-control" onChange={handleLastnameChange} required/>
                </div>

                <div className="input-group mb-2 mt-2">
                  <div className="input-group-prepend prepends">
                  <span className="input-group-text">{(lnstr === 'en') ? 'Date Of Birth':'تاريخ الميلاد'}</span>
                  </div>
                  <input type="date" name="dob" className="form-control" onChange={handledateofbirthChange} required/>
                </div>

                <div className="input-group mb-2 mt-2">
                  <div className="input-group-prepend prepends">
                  {/* <span className="input-group-text">{(lnstr === 'en') ? 'Age':'عمر'}</span> */}
                  </div>
                  <input type="number" name="age" placeholder={(lnstr === 'en') ? 'Age':'عمر'} className="form-control" onChange={handleAgeChange} required/>
                </div>

                <div className="input-group mb-2 mt-2">
                  <div className="input-group-prepend prepends">
                  {/* <span className="input-group-text">{(lnstr === 'en') ? 'Gender':'جنس'}</span> */}
                  </div>
                  <select  name="gender" className="form-control" onChange={handlegenderChange} required>
                        <option value='' disabled selected>{(lnstr === 'en') ? 'Gender':'جنس'}</option>
                        </select>
                </div>

                <div className="input-group mb-2 mt-2">
                  <div className="input-group-prepend prepends">
                  {/* <span className="input-group-text">{(lnstr === 'en') ? 'Nationality':'جنسية'}</span> */}
                  </div>
                  <select  name="nationaloity" className="form-control" onChange={handlenationalityChange} required>
                        <option value='' disabled selected >{(lnstr === 'en') ? 'Nationality':'جنسية'}</option>
                        </select>
                </div>

                <div className="input-group mb-2 mt-2">
                  <div className="input-group-prepend prepends">
                  {/* <span className="input-group-text">{(lnstr === 'en') ? 'City':'مدينة'}</span> */}
                  </div>
                  <select  name="city" className="form-control" onChange={handlecityChange} required>
                        <option value = '' disabled selected>{(lnstr === 'en') ? 'City':'مدينة'}</option>
                        </select>
                </div>



                <button type="submit"  className="btn btn-primary rounded-pill text-white my-1 py-1 px-4">{(lnstr === 'en') ? 'Next':'التالي'}</button>


              </form>


            </Col>

            <Col md={3}></Col>

          </div>

        </div>
      </div>

      <Footer />

      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}

    </Fragment>
  );


}

export default NewpolicyDriverDetails;
