import React, { Fragment, useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useTranslation } from 'react-i18next';
import { config } from './api/api';
import Cookies from 'js-cookie';

function DownloadInboundPolicy() {
  const pattern = /^(https?:\/\/)?([a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+)(:[0-9]{1,5})?(\/.*)?$/;

  const base_url = config.baseURL;
  const redirecturl = config.redirectURL;
  const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';
  const { t } = useTranslation();

  const [dhofarData, setDhofarData] = useState(null);
  const [policyNo, setPolicyNo] = useState(null);
  const [policyLink, setPolicyLink] = useState(null);

  useEffect(() => {
    const urlSearchString = window.location.search;
    const uparams = new URLSearchParams(urlSearchString);
    const policy_no = uparams.get('policyno');
    setPolicyNo(policy_no);
  }, []);

  const [vendorKey, setVendorKey] = useState('');

  useEffect(() => {
    const postvData = { organization_name: "Superjet" };

    if (!vendorKey) {
      fetch(base_url + '/create_organization', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postvData),
      })
        .then(response => response.json())
        .then(data => {
          setVendorKey(data.result[0].vendor_key);
        })
        .catch(error => {
          console.error('Error posting data:', error);
        });
    }

    if (vendorKey) {
      fetch(base_url + '/create_inbound_token', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${vendorKey}`,
        }
      })
        .then(response => response.json())
        .then(data => {
          const dhdata = data.result.data.token;
          setDhofarData(dhdata);
        })
        .catch(error => {
          console.error('Error posting data:', error);
        });
    }
  }, [vendorKey]); // Dependency array

  const postData = [{ token: dhofarData }, { policy_number: policyNo }];

  if (vendorKey && policyNo && dhofarData) {
    fetch(base_url + '/get_inbound_policy_documents', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${vendorKey}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
    })
      .then(response => response.json())
      .then(data => {
        if (data.message === 'success') {
          setPolicyLink(data.result.data.policy_schedule_url);
          // setPolicyLink('');
        } else {
          setPolicyLink('');
        }
      })
      .catch(error => {
        console.error('Error posting data:', error);
      });
  }
  const [paymentUrl, setPaymenturl] = useState('');

  const handleQotation = async (event) => {
    const quotation_data = [{ token: dhofarData }, { policy_number: policyNo }, { redirect_url: redirecturl + "/download-policy?policyno=" + policyNo }];
    // var quotation_data =
    // {
    //   "token": dhofarData,
    //   "policy_no": policyNo,
    //   "redirect_url": redirecturl+"/download-policy?policyno="+policyNo
    // };
    debugger
    if (vendorKey !== '') {
      // const fetchPolicy = async () => {
      try {


        const Policyresponce = await fetch(base_url + '/get_inbound_paymentlink', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${vendorKey}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(quotation_data),
        });

        if (!Policyresponce.ok) {
          throw new Error('Network response was not ok');
        }
        debugger

        const data = await Policyresponce.json();

        setPaymenturl(data.result.data.payment_url);
        window.location.href = data.result.data.payment_url;
        //   await handlePolicy(data.result.data.amount)
      } catch (error) {
        console.error('Error fetching occupations:', error);
      }
      // };


    }

    // const redirect_url = redirecturl+"/download-policy?policyno="+policyNo;

    // const postData = [{ token: dhofarData }, {policy_number: policyNo}, {redirect_url}];

    // if(vendorKey!=='' && paymentUrl==='')
    // {

    //   fetch(base_url+'/get_inbound_paymentlink', {
    //   method: 'POST',
    //   headers: {
    //       'Authorization': `Bearer ${vendorKey}`,
    //       'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify(postData),
    //   })
    //   .then(response => response.json())
    //   .then(data => {
    //       setPaymenturl(data.result.data.payment_url);
    //   })
    //   .catch(error => {
    //       console.error('Error posting data:', error);
    //   });

  };

  const handlerepayment = async (event) => {
    // event.preventDefault();
    handleQotation();
  }


  const isValidUrl = (url) => {
    return pattern.test(url);
  };

  return (
    <Fragment>
      <Navbar />
      <div className="features_section layout_padding my-5">

        {policyLink ? (
          <div className="container-fluid bg-breadcrumb">
            <div className="container text-center">
              <div className="row">
                <div className="col-md-12">
                  <h3 className="abt-txt">Download Policy {policyNo}</h3>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-md-12">
              <div className="text-center">
                <h3 className="abt-txt">Policy Status {policyNo}</h3>
              </div>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-md-12">
            <div className="text-center">
              {policyLink ? (
                isValidUrl(policyLink) ? (
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      var url = policyLink;
                      const link = document.createElement('a');
                      link.href = url;
                      link.target = '_blank';
                      link.download = url.split('/').pop(); // Extracts filename from URL
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);
                    }}
                  >
                    Download Policy Document
                  </button>
                ) : (
                  <h2>Sorry, the policy document is not available.</h2>
                )
              ) : policyLink === '' ? (
                <div>
                  <h2>Policy Payment Pending</h2>
                  <div>
                    <button
                      onClick={handlerepayment} style={{ marginLeft: 'auto', width: '200px', fontWeight: 'bold', backgroundColor: '#3fd2f2', color: 'white' }}>Make Payment</button></div>
                </div>

              ) : (
                "Loading"
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
}

export default DownloadInboundPolicy;