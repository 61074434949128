import React, { Fragment, useState, useEffect } from 'react';
import Navbar from "./Navbar";
import Footer from "./Footer";
import myLoader from './loaderred.gif';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import ReactSelect from "react-select";
import Logout from './Logout';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const { config } = require('./api/api');

const Profile = ({ usrtok }) => {

  const tabListStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    width:"80%",
    flexWrap: 'no-wrap'
  };

  const tabItemStyle = {
    flex: '1 1 33%',
    textAlign: 'center',
    cursor: 'pointer',
    transition: 'all 0.6s ease',
  };

  const activeTabStyle = {
    backgroundColor: '#33d0f2',
    color: '#FFFFFF',
    fontWeight: 'bold',
    transform: 'scale(1)',
    fontSize: 18
  };

  const inactiveTabStyle = {
    backgroundColor: '#f8f9fa',
    color: '#495057',
    transform: 'scale(1)',
    fontWeight: 'bold',
    fontSize: 15
  };

  const tabHoverStyle = {
    backgroundColor: '#e9ecef',
    transform: 'scale(1)',
  };
  const base_url = config.baseURL;
  const { t } = useTranslation();
  const [vendorKey, setVendorKey] = useState('');
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(false);
  const [phoneValue, setPhoneValue] = useState("");
  const [country, setCountry] = useState("");
  const [profile, setProfile] = useState(null);
  const [error, setError] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState([]);
  const [maritalStatus, setMaritalStatus] = useState([]);
  const [token, setToken] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);  // Track active tab
  const [dobChange,setDobChange]=useState(false);

  const [countryList, setCountries] = useState([]);
  useEffect(() => {
    const storedUserString = Cookies.get('userData');
    if (storedUserString !== 'undefined') {
      const storedUser = JSON.parse(storedUserString);
      setUserData(storedUser);

      if (storedUser.id) {
        setCountry(storedUser.country);
        setProfile((prevProfile) => ({ ...prevProfile, id: storedUser.id }));
      }
    }
  }, []);
  

  useEffect(() => {
    const checkUserData = async () => {
      try {
        const storedUserString = Cookies.get('userData'); //sessionStorage.getItem('userData')
        if (storedUserString) {
          const storedUser = JSON.parse(storedUserString);
          setUserData(storedUser);
          if (storedUser.id) {
            setCountry(storedUser.country);
            setProfile((prevProfile) => ({ ...prevProfile, id: storedUser.id }));
          }
          if (storedUser.access_token) {
            // var usrtok = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFydW5Abmlnc29mdC5jb20iLCJpZCI6NjIsImlhdCI6MTczMzk4NjA1MSwiZXhwIjoxNzMzOTg2MTExfQ.bSC0xAxmAS0-71YmdU_oYbvDMmNW_3trUXc_oetw_xo';
            var usrtok = storedUser.access_token;
            try {
              const response = await fetch(base_url + '/traveller_user_token_check', {
                method: 'POST',
                headers: {
                  'Authorization': `Bearer ${usrtok}`,
                  'Content-Type': 'application/json',
                },
              });
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              const data = await response.json();
              if (data != null && data.message != null && (data.message === 'Invalid Token' || data.message == 'Token has expired')) {
                window.alert('Session Timed Out');
                await Logout();
              }
            } catch (error) {

            }
          }
        }
      } catch (error) {
      }
    };

    checkUserData();
  }, []);

  usrtok = userData.access_token;

  useEffect(() => {
    const postvData = { organization_name: "Superjet" };
    if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {
      fetch(base_url + '/create_organization', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postvData),
      })
        .then(response => response.json())
        .then(data => {
          setVendorKey(data.result[0].vendor_key);
        })
        .catch(error => {
          console.error('Error posting data:', error);
        });


    }
  }, [vendorKey]); // Dependency array


  useEffect(() => {
    // debugger

    if(profile != null && profile?.country ){
      const cnData = { country: profile?.country };
      fetch(base_url + '/get_country_code', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(cnData),
      })
        .then(response => response.json())
        .then(data => {
          const cde = data.result.replace(/\+/g, '');
          setPhoneValue(cde);
          setProfile((prevpro) => ({ ...prevpro, country_code: cde }));
        })
        .catch(error => {
          console.error('Error posting data:', error);
        });
  
    }
   

  }, [profile?.country]);

  useEffect(() => {

    if (vendorKey !== '' && countryList.length === 0) {
      var vkData = { vendor_key: vendorKey };
      fetch(base_url + '/country', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(vkData),
      })
        .then(response => response.json())
        .then(data => {
          // debugger
          setCountries(data.result);
        })
        .catch(error => {
          console.error('Error fetching countries:', error);
        });
    }

  }, [vendorKey]);

  useEffect(() => {
    if (vendorKey !== "") {
      fetch(base_url + "/create_inbound_token", {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${vendorKey}`
        }
      }).then((response) => {
        return response.json()
      }).then((data) => {
        setToken(data.result.data);
      }).catch((err) => {
        console.error("Error fetching token:", err);
      });

    }
  }, [vendorKey])


  useEffect(() => {
    if (vendorKey !== '' && gender.length === 0) {
      fetch(base_url + "/getGender", {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${vendorKey}`,
          "Content-Type": 'application/json'
        },
        body: JSON.stringify(token)
      }).then((response) => {
        return response.json()
      }).then((data) => {
        setGender(data.result.data.response_data)
      }).catch((err) => {
        console.log("getting gender " + err);;
      });
    }
    if (vendorKey !== '' && maritalStatus.length === 0) {
      fetch(base_url + "/getMaritalStatus", {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${vendorKey}`,
          "Content-Type": 'application/json'
        },
        body: JSON.stringify(token)
      }).then((response) => {
        return response.json()
      }).then((data) => {
        setMaritalStatus(data.result.data.response_data);
      }).catch((err) => {
        console.log("getting marital status " + err);;
      });
    }
  }, [token]);


  useEffect(() => {
    var vkData = { vendor_key: vendorKey };
    if (vendorKey != '') {
      fetch(base_url + '/each_traveller_details', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${usrtok}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(vkData),
      })
        .then(response => response.json())
        .then(async data => {
         
          setProfile(data.result[0]);
          setDobChange(true);
        })
        .catch(error => {
          console.error('Error fetching countries:', error);
        });
    }
  }, [usrtok, vendorKey]);


  useEffect(() => {

    if (profile !== null && profile !== undefined) {
      setFirstName(profile.first_name);
      setLastName(profile.last_name);
      setPhone(profile.phone);
      setCountry(profile.country);
    }
   
  },[profile]);

  useEffect(()=>{
    if ((profile!== null)&& dobChange) {
      const dobDate = new Date(profile.dob);
      dobDate.setDate(dobDate.getDate() + 1); // Increment by one day
      const formattedDob = dobDate.toISOString().split('T')[0];
      setProfile((prevProfile) => ({ ...prevProfile, dob: formattedDob }));
    }
  },[dobChange]);
  
debugger
  const handleInputChange = (e, field) => {
  debugger
    const value = e.target.value;
    if (field !== 'first_name' && field !== 'last_name' && field !== "phone") {
      setProfile((prevProfile) => ({
        ...prevProfile,
        [field]: value,
      }));
    }
    if (field === "country") {
      setCountry(value);
    }
    if (field === "first_name") {
      setProfile((prevProfile) => ({
        ...prevProfile,
        [field]: value.replace(/[^A-Za-z\s]/g, ""),
      }));

    }
    if (field === "last_name") {
      setProfile((prevProfile) => ({
        ...prevProfile,
        [field]: value.replace(/[^A-Za-z\s]/g, ""),
      }));

    }
    if ((field === 'phone') && (value.length <= 12)) {
      setProfile((prevProfile) => ({
        ...prevProfile,
        [field]: value.replace(/[^0-9]/g, "")
      }));
    }
    if (field === 'dob') {
      const localDate = new Date(value);
      const utcDate = new Date(localDate.getUTCFullYear(), localDate.getUTCMonth(), localDate.getUTCDate()+1);
      setProfile((prevProfile) => ({ ...prevProfile, [field]: utcDate.toISOString() }));
    } 

  };


  const handleSave = () => {
    console.log('Updated Profile:', profile);
    if (
      (firstName !== "" &&
      firstName !== null &&
      firstName !== undefined) &&
      (lastName !== "" &&
      lastName !== null &&
      lastName !== undefined) &&
      (country !== "" &&
      country !== null &&
      country !== undefined) &&
      (phone !== "" &&
      phone !== null &&
      phone !== undefined)
    ) {
      var traveller_detail = [{ vendor_key: vendorKey }, { profile: profile }];
      fetch(base_url + "/traveller_profile_update", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(traveller_detail),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.message == "success") {
            alert("Profile Updated");
          }
        })
        .catch((error) => {
          console.error("Error fetching countries:", error);
        });
      const expiryDate = new Date();
      expiryDate.setTime(expiryDate.getTime() + 1 * 60 * 60 * 1000);

      Cookies.set("profileData", JSON.stringify(profile), {
        expires: expiryDate,
      });
    } else {
      alert("Please fill required information");
    }
  };

  if (!profile) {
    // debugger
    return <div className="overlay">
      <div className="spinner"></div>
    </div>;
  }
  return (
    <div>
      <div className="header_section1">
        <Navbar />
        <div className="container-fluid bg-breadcrumb">
          <div className="container text-center py-5">
            <h3 className="abt-txt my-5">{t('PROFILE DETAILS')}</h3>
          </div>
        </div>
      </div>

      <div className="container-fluid row justify-content-center mt-5" style={{ padding: 0 }}>
        <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)} style={{ width: '100%' }}>
          <TabList className=" mx-md-auto" style={tabListStyle}>
            <Tab
              style={{
                ...tabItemStyle,
                ...(tabIndex === 0 ? activeTabStyle : inactiveTabStyle),
              }} ouseLeave={(e) => (e.target.style.backgroundColor = '')}
            >
              <a
                className="nav-link"
                href="#"
                style={{
                  ...(tabIndex === 0 ? activeTabStyle : inactiveTabStyle),
                }}
              >
                Personal Details
              </a>
            </Tab>
            <Tab
              style={{
                ...tabItemStyle,
                ...(tabIndex === 1 ? activeTabStyle : inactiveTabStyle),
              }}
            >
              <a
                className="nav-link"
                href="#"
                style={{
                  ...(tabIndex === 1 ? activeTabStyle : inactiveTabStyle),
                }}
              >
                Address Details
              </a>
            </Tab>
            <Tab
              style={{
                ...tabItemStyle,
                ...(tabIndex === 2 ? activeTabStyle : inactiveTabStyle),
              }}
            >
              <a
                className="nav-link"
                href="#"
                style={{
                  ...(tabIndex === 2 ? activeTabStyle : inactiveTabStyle),
                }}
              >
                Additional Details
              </a>
            </Tab>
          </TabList>

          <TabPanel>
            <div style={{ padding: '30px' }}>
              <div className="row">
                {/* First Name */}
                <div className="col-md-1"> </div>
                <div className="col-12 col-md-5 mb-3">
                  <label className="form-label text-secondary">
                    {t('First')} {t('Name')}
                    <span style={{ color: 'red' }}>&nbsp;*</span>
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    className="form-control"
                    value={profile?.first_name}
                    onInput={(e) => handleInputChange(e, 'first_name')}
                    placeholder={t('Enter your first name')}
                    style={{ borderRadius: '8px' }}
                    required
                  />
                </div>
                

                {/* Last Name */}
                
                <div className="col-12 col-md-5 mb-3">
                  <label className="form-label text-secondary">
                    {t('Last')} {t('Name')}
                    <span style={{ color: 'red' }}>&nbsp;*</span>
                  </label>
                  <input
                    type="text"
                    name="lastName"
                    className="form-control"
                    value={profile?.last_name}
                    onChange={(e) => handleInputChange(e, 'last_name')}
                    placeholder={t('Enter your last name')}
                    style={{ borderRadius: '8px' }}
                    required
                  />
                </div>
                <div className="col-md-1"></div>

                {/* Email */}
                <div className="col-md-1"></div>
                <div className="col-12 col-md-5 mb-3">
                  <label className="form-label text-secondary">{t('Email')}</label>
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    value={profile.email}
                    onChange={(e) => handleInputChange(e, 'email')}
                    placeholder={t('Enter your email')}
                    style={{ borderRadius: '8px' }}
                    disabled
                  />
                </div>

                {/* Country */}
                <div className="col-12 col-md-5 mb-3">
                  <label className="form-label text-secondary">
                    {t('Country')}
                    <span style={{ color: 'red' }}>&nbsp;*</span>
                  </label>
                  <ReactSelect
                    options={
                      countryList?.map((country) => ({
                        value: country.id,
                        label: country.country,
                      }))
                    }
                    value={
                      countryList
                        ?.map((country) => ({
                          value: country.id,
                          label: country.country,
                        }))
                        .find((option) => option.label === profile.country)
                    }
                    onChange={(selectedOption) => {
                      handleInputChange(
                        {
                          target: {
                            name: 'Country'.toLowerCase(),
                            value: selectedOption?.label,
                          },
                        },
                        'Country'.toLowerCase()
                      );
                    }}
                    placeholder={t('Select your country')}
                    isSearchable
                    styles={{
                      control: (base) => ({ ...base, borderRadius: '8px' }),
                    }}
                  />
                </div>
                <div className="col-md-1"></div> 
                {/* Phone */}
                  <div className="col-md-1"></div>
                <div className="col-12 col-md-5 mb-3">
                  <label className="form-label text-secondary">
                    {t('Phone')}
                    <span style={{ color: 'red' }}>&nbsp;*</span>
                  </label>
                  <div className="d-flex">
                    <input
                      type="text"
                      className="form-control mr-2 text-center"
                      style={{ width: '20%', borderRadius: '8px' }}
                      name="country_code"
                      value={profile?.country_code}
                      onChange={(e) => handleInputChange(e, 'country_code')}
                      required
                    />
                    <input
                      type="text"
                      name="phone"
                      className="form-control"
                      value={profile?.phone}
                      onChange={(e) => handleInputChange(e, 'phone')}
                      placeholder={t('Enter phone number')}
                      style={{ borderRadius: '8px' }}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div style={{ padding: '30px' }}>
              <div className="row">
                {
                  ['Building', 'Floor', 'Apartment', 'Street', 'City', 'State'].map((field) => (
                    (field==='Building'||field==="Apartment"||field==="City")?<>
                    <div className="col-md-1"></div>
                    <div className="col-12 col-md-5 mb-3" key={field}>
                      <label className="form-label text-secondary">{t(field)}</label>
                      <input
                        type="text"
                        name={field.toLowerCase()}
                        className="form-control"
                        value={profile[field.toLowerCase()] || ''}
                        onChange={(e) => handleInputChange(e, field.toLowerCase())}
                        placeholder={t(`Enter your ${field.toLowerCase()}`)}
                        style={{ borderRadius: "8px" }}
                      />
                    </div>
                    </>:
                    <>
                    <div className="col-12 col-md-5 mb-3" key={field}>
                      <label className="form-label text-secondary">{t(field)}</label>
                      <input
                        type="text"
                        name={field.toLowerCase()}
                        className="form-control"
                        value={profile?.[field.toLowerCase()] || ''}
                        onChange={(e) => handleInputChange(e, field.toLowerCase())}
                        placeholder={t(`Enter your ${field.toLowerCase()}`)}
                        style={{ borderRadius: "8px" }}
                      />
                    </div><div className='col-md-1'></div>
                    </>
                  ))
                }
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div style={{ padding: '30px' }}>
              <div className="row">

                {/* Date of Birth */}
                <div className="col-md-1"></div>
                <div className="col-12 col-md-5 mb-3">
                  <label className="form-label text-secondary">{t('Date')} {t('Of')} {t('Birth')}</label>
                  <input
                    type="date"
                    name="dob"
                    className="form-control"
                    value={profile.dob ? profile?.dob.split('T')[0] : ''}
                    onChange={(e) => handleInputChange(e, 'dob')}
                    placeholder={t('Enter date of birth')}
                    style={{ borderRadius: '8px' }}
                  />
                </div>

                {/* Gender */}
                <div className="col-12 col-md-5 mb-3">
                  <label className="form-label text-secondary">{t('Gender')}</label>
                  <ReactSelect
                    options={
                      gender?.map((gen) => ({
                        value: gen.id,
                        label: gen.description,
                      }))
                    }
                    value={
                      gender
                        ?.map((gen) => ({
                          value: gen.id,
                          label: gen.description,
                        }))
                        .find((option) => option.label === profile?.gender)
                    }
                    onChange={(selectedOption) => {
                      handleInputChange(
                        {
                          target: {
                            name: 'Gender'.toLowerCase(),
                            value: selectedOption?.label,
                          },
                        },
                        'Gender'.toLowerCase()
                      );
                    }}
                    placeholder={t('Select your gender')}
                    styles={{
                      control: (base) => ({ ...base, borderRadius: '8px' }),
                    }}
                  />
                </div>
                 <div className="col-md-1"></div>

                {/* Marital Status */}
                 <div className="col-md-1"></div>
                <div className="col-12 col-md-5 mb-3">
                  <label className="form-label text-secondary">{t('Marital')} {t('Status')}</label>
                  <ReactSelect
                    options={
                      maritalStatus?.map((marital) => ({
                        value: marital.id,
                        label: marital.description,
                      }))
                    }
                    value={
                      maritalStatus
                        ?.map((marital) => ({
                          value: marital.id,
                          label: marital.description,
                        }))
                        .find((option) => option?.label === profile?.marital_status)
                    }
                    onChange={(selectedOption) => {
                      handleInputChange(
                        {
                          target: {
                            name: 'marital_status'.toLowerCase(),
                            value: selectedOption?.label,
                          },
                        },
                        'marital_status'.toLowerCase()
                      );
                    }}
                    placeholder={t('Select your marital status')}
                    styles={{
                      control: (base) => ({ ...base, borderRadius: '8px' }),
                    }}
                  />
                </div>


                {/* Passport Number */}
                <div className="col-12 col-md-5 mb-3">
                  <label className="form-label text-secondary">{t('Passport')} {t('No')}</label>
                  <input
                    type="text"
                    name="passport_no"
                    className="form-control"
                    value={profile?.passport_no}
                    onChange={(e) => handleInputChange(e, 'passport_no')}
                    placeholder={t('Enter your passport number')}
                    style={{ borderRadius: '8px' }}
                  />
                </div>
                 <div className="col-md-1"></div>
                
                {/* Civil ID */}
                 <div className="col-md-1"></div>

                <div className="col-12 col-md-5 mb-3">
                  <label className="form-label text-secondary">{t('Civil')} {t('ID')}</label>
                  <input
                    type="text"
                    name="civil_id"
                    className="form-control"
                    value={profile?.civil_id}
                    onChange={(e) => handleInputChange(e, 'civil_id')}
                    placeholder={t('Enter your civil ID')}
                    style={{ borderRadius: '8px' }}
                  />
                </div>
              </div>
            </div>
          </TabPanel>

        </Tabs>
      </div>

      <div className="w-sm-50 d-flex justify-content-end mt-4 ml-sm-5"
       style={{ paddingBottom: "20px", paddingRight: '20px',marginRight:"10%" }}>
        
        <button
          className="btn btn-primary "
          onClick={handleSave}
          style={{
            borderRadius: "8px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)"
          }}
        >
          {t('Save')} {t('Changes')}
        </button>
      </div>
      <Footer />
    </div>
  );
};

export default Profile;