import React, { Fragment, useState, useEffect,useRef } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { useTranslation } from 'react-i18next';
import travelimg from '../tr.png';
import { Container, Form, Row, Col, Card, Button, CardBody,Modal } from "react-bootstrap";
import Cookies from 'js-cookie';
import SessionRemove from "../helper/sessionremove";
import SignatureCanvas from 'react-signature-canvas';
import { event } from "jquery";

const { config } = require('../api/api');


function MedicalPlan() {
  const base_url = config.baseURL;

  const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';
 
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    insurancetype: '',
  });

  const [loading, setLoading] = useState(false);
  const [postData , setPostData] = useState('')


// popup 1 

const [showModal1, setShowModal1] = useState(false);
const closeModal = () => setShowModal1(false);
debugger
const openModal = () => setShowModal1(true);

const [weightValue, setweightValue] = useState('');
const [heightValue, setheightValue] = useState("");
const [bmi, setBmi] = useState('0');

// popup 2 

const [showModal2, setShowModal2] = useState(false);
const beforepopup = () =>{ 
  setShowModal2(false);
  setShowModal1(true);}
debugger
const openModal2 = () => {setShowModal2(true);

  setShowModal1(false);
}
const [selectedOption, setSelectedOption] = useState(null);

// popup 3

const [showModal3, setShowModal3] = useState(false);
const beforepopup1 = () => {
  setShowModal3(false);
  setShowModal2(true);
  ;
}
debugger
const [assure, setAssured] = useState('');
const [premium, setpermium] = useState('');
const [price, setprice] = useState([{}]);

  
const [vendorKey, setVendorkey] = useState('');

// popup 4

const [showModal4, setShowModal4] = useState(false);
const beforepopup2 = () => {
  setShowModal3(true);
  setShowModal4(false);
}
debugger
const openModal4 = () =>{ 
  setShowModal4(true);
  setShowModal3(false);
}

const [relations, setRelation] = useState([]);
const [error , setErrorValue] = useState('');
const [error1, setError1Valule] = useState('');


// popup 5
const [showModal5, setShowModal5] = useState(false);

const beforepopup3 = () => {
  setShowModal4(true);
  setShowModal5(false);
}
debugger
const openModal5 = () =>{ 
  setShowModal5(true);
  setShowModal4(false);
}


// popup 6


const [showModal6, setShowModal6] = useState(false);

const openModal6 = () =>{ 
 
}





const assuredvaluejson = sessionStorage.getItem('premiumfullvalue');

const assuredvalue = JSON.parse(assuredvaluejson);


//accident cover//  


useEffect(() => {

  const postvData = { organization_name: "Superjet" };
  debugger

  if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {


    fetch( base_url + '/create_organization', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postvData),
    })
      .then(response => response.json())
      .then(data => {
        setVendorkey(data.result[0].vendor_key);
      })
      .catch(error => {
        console.error('Error posting data:', error);
      });


  }
}, [vendorKey]); // Dependency array

// const [dhofarData, setDhofarData] = useState(false);
 const [age, setAge] = useState(null);
//   useEffect(() => {


//     const storedDhofarString = Cookies.get('personalData');
    
//     if (storedDhofarString !== undefined) {
//       const storedDhofar = JSON.parse(storedDhofarString);
//       setDhofarData(storedDhofar);
//     }
//     else {
//       alert("token expired");
//       window.location.href='/';
//     }

//   }, []);



  const NumberFormatter = ({ value }) => {
    const formattedValue = `${Number(value).toFixed(3)} OMR`;
  debugger
    return formattedValue ;
  };  

const handleSubmit = (event) => {
  debugger
  window.location.href = '/medical-personal'
};


  const today = new Date();
  const maxDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate() + 1).toISOString().split('T')[0];


  const [dobValue, setdobValue] = useState('');
  const handledobChange = (event) => {
    const selectedDob = event.target.value;
    setdobValue(event.target.value);

    const dobDate = new Date(selectedDob);
    const today = new Date();
    let calculatedAge = today.getFullYear() - dobDate.getFullYear();
    const monthDiff = today.getMonth() - dobDate.getMonth();

    // Adjust age if the current date is before the user's birthday in the current year
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dobDate.getDate())) {
      calculatedAge--;
    }

    setAge(calculatedAge);
  };

  const [selectedgenderOption, setSelectedgenderOption] = useState(null);
  const [selectedgenderOptionid, setSelectedgenderOptionid] = useState(null);
  const handlegenderChange = (event) => {
    setSelectedgenderOptionid(event.target.options[event.target.selectedIndex].id);
    setSelectedgenderOption(event.target.value);
  };

  


// Height and weight first popup

const handleWeightChange = (event) => {
  setweightValue(event.target.value);
  calculateBmi(event.target.value, heightValue);
};

const handleHeightChange = (event) => {
  setheightValue(event.target.value);
  calculateBmi(weightValue, event.target.value);
};

const [heightError, setHeightError] = useState('');

const handleHeightBlur = (e) => {
  debugger
  const { value } = e.target;

  const heightValue = Number(value);

  if (isNaN(heightValue) || heightValue < 0 || heightValue > 999 || value == '') {
    setHeightError('Please enter valid height, accept max 3 digits.');
  } else {
    setHeightError('');
  }
};

const [weightError, setWeightError] = useState('');
const handleWeightBlur = (e) => {
  const { value } = e.target;

  const weightValue = Number(value);

  if (isNaN(weightValue) || weightValue < 0 || weightValue > 999 || value == '') {
    setWeightError('Please enter valid weight, accept max 3 digits.');
  } else {
    setWeightError('');
  }
};

const calculateBmi = (weight, height) => {
  const w = parseFloat(weight);
  const h = parseFloat(height) / 100; 


  const bmiValue = (w / (h ** 2)).toFixed(2);
  setBmi(bmiValue);
 
};

const buttonDisabled = heightValue === '' || weightValue=== ''



//  choose the plan popup



useEffect(() => {
  if (showModal5) {
    document.body.style.overflow = 'hidden'; 
  } else {
    document.body.style.overflow = 'auto';
  }
  
  return () => {
    document.body.style.overflow = 'auto'; 
  };
}, [showModal5]);


const [questionList, setQuestionList] = useState();

const handleOptionChange = (event) => {
  setSelectedOption(event.target.value);
};

const openModal3 = async (event) => {
  event.preventDefault();

  if (selectedOption === null) {
    alert('Please choose a plan');
    return false;
  }

  setLoading(true);

  const postvData = { organization_name: "Superjet" };

  try {
    // Create the organization
    const response = await fetch( base_url + '/create_organization', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postvData),
    });

    const data = await response.json();
    const vendorKey = data.result[0].vendor_key;

    // Set vendor key in cookie
    Cookies.set('Vendorkey', vendorKey);

    // Check if the selected option is 'Personal Accident'
    if (selectedOption === 'Personal Accident') {
      const tokenResponse = await fetch( base_url + '/create_personal_accident_token', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${vendorKey}`,
        },
      });
debugger
      const tokenData = await tokenResponse.json();
      const personaltocken = tokenData.result.data.token;

      const postData = { token: personaltocken };

      const expiredpersonaltocken = new Date();
      expiredpersonaltocken.setTime(expiredpersonaltocken.getTime() + (1 * 60 * 60 * 1000));
      // sumassured popup

        fetch(base_url + '/get_personal_accident_premium', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${vendorKey}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postData),
    
        })
          .then(response => response.json())
          .then(data => {
            debugger
            setpermium(data.result.data.premium);
            setAssured(data.result.data.sum_assured);
    
          })
          .catch(error => {
            console.error('Error posting data:', error);
          });


          if (vendorKey !== '') {

            fetch(base_url + '/get_personal_accident_relations', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${vendorKey}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(postData),
            })
                .then(response => response.json())
                .then(data => {
    
                    setRelation(data.result.data);
    
                })
                .catch(error => {
                    console.error('Error posting data:', error);
                });
    
        }

        if (vendorKey !== '') {
          const fetchQuestions = async () => {
            try {
              debugger
              const response = await fetch( base_url + '/get_personal_accident_questions', {
                method: 'POST',
                headers: {
                  'Authorization': `Bearer ${vendorKey}`,
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(postData),
              });
    
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
    
              const data = await response.json();
              debugger
              setQuestionList(data.result.data);
              var questionsLists = data.result.data;
    
              questionsLists = questionsLists.map(question => ({
                english: question.English,
                arabic: question.Arabic,
                key: question.key,
                accepted_answer: question.accepted_answer,
                isChecked: '',
                details: '',
                isChecked_answer: ''
              }));
              setQuestionsData(questionsLists);
            } catch (error) {
              console.error('Error fetching occupations:', error);
            }
          };
    
          fetchQuestions();
        }
    

      // popup 3 assured api

      setShowModal3(true);
      setShowModal2(false);
    } else {

      const tokenResponse = await fetch( base_url + '/create_term_plan_token', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${vendorKey}`,
        },
      });

      const tokenData = await tokenResponse.json();
      const personaltocken = tokenData.result.data.token;

      const postData = { token: personaltocken };

      const expiredpersonaltocken = new Date();
      expiredpersonaltocken.setTime(expiredpersonaltocken.getTime() + (1 * 60 * 60 * 1000));

      Cookies.set('personalData', JSON.stringify(personaltocken), {
        expires: expiredpersonaltocken,
      });


      if (vendorKey !== '') {
        fetch(base_url + '/get_term_plan_premium', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${vendorKey}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postData),
  
        })
          .then(response => response.json())
          .then(data => {
            debugger
            setprice(data.result.data.prices);
          })
          .catch(error => {
            console.error('Error posting data:', error);
          });
  
      }


        if (vendorKey !== '') {

          fetch(base_url + '/get_term_plan_relations', {
              method: 'POST',
              headers: {
                  'Authorization': `Bearer ${vendorKey}`,
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(postData),
          })
              .then(response => response.json())
              .then(data => {
  
                  setRelation(data.result.data);
  
              })
              .catch(error => {
                  console.error('Error posting data:', error);
              });
  
      }

      if (vendorKey !== '') {
        const fetchQuestions = async () => {
          try {
            debugger
            const response = await fetch( base_url + '/get_term_plan_questions', {
              method: 'POST',
              headers: {
                'Authorization': `Bearer ${vendorKey}`,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(postData),
            });
  
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
  
            const data = await response.json();
            debugger
            setQuestionList(data.result.data);
            var questionsLists = data.result.data;
  
            questionsLists = questionsLists.map(question => ({
              english: question.English,
              arabic: question.Arabic,
              key: question.key,
              accepted_answer: question.accepted_answer,
              isChecked: '',
              details: '',
              isChecked_answer: ''
            }));
            setQuestionsData(questionsLists);
          } catch (error) {
            console.error('Error fetching occupations:', error);
          }
        };
  
        fetchQuestions();
      }
  

    // popup 3 assured api

    setShowModal3(true);
    setShowModal2(false);
    }
  } catch (error) {
    console.error('Error:', error);
  } finally {
    setLoading(false); // Ensure loading state is reset
  }
};

const disablebutton2 = selectedOption ===  null


//  ADD BENEFICIARY POPUP




// useEffect(() => {
   
// }, [vendorKey]
// );



const [isChecked, setIsChecked] = useState(false);
const [benefity, setbeneficiarycheck] = useState(false);
const [totpercentage, settotpercentage] = useState();

const [name, setName] = useState('');

const [selectedRelation, setSelectedRelation] = useState('');

const handleCheckboxChange = (event) => {
    setName(event.target.value);
};




const handleInputChange = async (index, event, names, field) => {
    setbeneficiarycheck(false);
    // console.log(event);;
    // const { name, value } = event.target;
    const rows = [...formRows];

    rows[index][field] = event.target.value;

    debugger

    if (names === 'name') {
        rows[index]['name'] = event.target.value;
    }
    else if (names === 'relation') {
        rows[index]['relation'] = event.target.value;
    }
    else if (names === 'benefit_percentage') {
        debugger
        const currentTotal = rows.reduce((total, person, idx) => {
            if (idx !== index) {
                return total + parseInt(person.benefit_percentage, 10);
            } else {
                return total;
            }
        }, 0);

        if (currentTotal + parseInt(event.target.value, 10) > 100) {
            // alert("Total percentage is not valid");
            setError1Valule("Total percentage is not valid")


            rows[index]['benefit_percentage'] = '';

        } else {
            setError1Valule("")

            settotpercentage(currentTotal + parseInt(event.target.value, 10));

            rows[index]['benefit_percentage'] = event.target.value;
        }

    }

    setFormRows(rows);
};

debugger
const [formRows, setFormRows] = useState([{


    name: '',
    relation: '',
    benefit_percentage: '',

}]);

const handleRemoveRow = (index) => {
    let totalBenefitPercentage = 0;
    const rows = [...formRows];
    rows.splice(index, 1);
    for (let i = 0; i < rows.length; i++) {
        if (rows[i].benefit_percentage !== '') {
            totalBenefitPercentage += parseInt(rows[i].benefit_percentage);
        }
    }
    settotpercentage(totalBenefitPercentage);
    console.log("Total Benefit Percentage:", totalBenefitPercentage);
    setFormRows(rows);
};

const handleAddRow = () => {
    setFormRows([...formRows, {
        name: '',
        relation: '',
        benefit_percentage: '',

    }]);
};

useEffect(() => {
    const beneficiariesData = sessionStorage.getItem('personalaccidentbeneficiaries');
    if (beneficiariesData) {
        const parsedData = JSON.parse(beneficiariesData);
        setFormRows(parsedData.beneficiaries);
    }
}, []);

const isDisabled = formRows.some(row => row.name === '' || row.relation === '');

// question popup page 

const signatureRef = useRef();
const [isSignatureEmpty, setIsSignatureEmpty] = useState(true);

const [questionsData, setQuestionsData] = useState([{
  english: '',
  arabic: '',
  key: '',
  accepted_answer: '',
  isChecked: '',
  isChecked_answer: '',
  details: ''
}]);

function handleRadioChange(index, isChecked) {
    const updatedQuestionsData = [...questionsData];
    updatedQuestionsData[index] = {
      ...updatedQuestionsData[index],
      isChecked,
      isChecked_answer: isChecked ? "Yes" : "No"
    };
    setQuestionsData(updatedQuestionsData);
  }

  function handleDetailsChange(index, details) {

    const updatedQuestionsData = [...questionsData];
    updatedQuestionsData[index] = {
      ...updatedQuestionsData[index],
      details
    };
    setQuestionsData(updatedQuestionsData);
  }

  const clearSignature = () => {
    signatureRef.current.clear();
    setIsSignatureEmpty(true); // Disable button after clearing
  };
  const handleSignature = () => {
    if (!signatureRef.current.isEmpty()) {
      setIsSignatureEmpty(false); // Enable button when signature is drawn
    }
  };

  const [isChecked1, setIsChecked1] = useState(false);
  const handleCheckboxChange1 = (event) => {
    setIsChecked1(event.target.checked);
  };








  return (
    <Fragment>

      <div className="header_section1" >

        <Navbar />


        <div className="container-fluid bg-breadcrumb">
          <div className="container text-center py-5">
            <h3 className="abt-txt my-5">{(lnstr === 'en') ? 'Medical Insurance Plan' : 'خطة التأمين الطبي\n'}</h3>
          </div>
        </div>


      </div>

      <div className="features_section" >
        <div className="container">

          <div className="row">

            <Col md={3}></Col>

            <Col md={6} className="myforms my-5">



              <h1><strong> Green Plan </strong></h1>

              <form>

                <div className="d-flex justify-content-between my-3 colmds-4 card m-0">
                  <div className="d-flex justify-content-between my-3">
                    <div>
                      <h4><strong>Plan Details</strong></h4>
                    </div>
                    <div>
                      <h3>{assuredvalue.assuredvalue} OMR</h3>
                    </div>

                  </div>

                  <div>
                    <ul className="list-unstyled">
                      <li>Annual Limit is {assuredvalue.annuallimit} OMR</li>
                      <li>Op deductible (seprately applied to both PC & DT) is {assuredvalue.outpatient} OMR</li>
                      <li>PM Co Pay {assuredvalue.copay}</li>
                    </ul>
                  </div>

                </div>

              </form>

              <div className="d-flex justify-content-between my-3 px-1">
                <button
                  type="button" 
                  // disabled={buttonDisabled} 
                  style={{ width: "auto", whiteSpace: "normal"}}
                  onClick={openModal}
                  className="btn btn-primary rounded-pill text-white my-1 mx-1 py-1 px-4">
                  {lnstr === 'en' ? 'Select Life Insurance Addon': 'اختر ملحق التأمين على الحياة'}
                </button>
                
                
                <button
                  type="submit"
                  // disabled={buttonDisabled} 
                  onClick={handleSubmit}
                  className="btn btn-primary rounded-pill text-white my-1 py-1 px-4">
                  {lnstr === 'en' ? 'Confirm Plan' : 'تأكيد الخطة'}
                </button>
                </div>


            </Col>

            <Col md={3}></Col>

          </div>

        </div>


        {/* popup 1 */}

        {showModal1 && (
                <div className="modal" style={{borderRadius:'50'}}>
                    <div className="modal-content" style={{width:1000 , height:600}}>

                      <h1>Enter Details</h1>

                    <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                     
                      <span className="input-group-text">{(lnstr === 'en') ? 'Height (in cms)' : 'الارتفاع (سم)'}<span style={{color:'red', marginLeft: '5px'}}>*</span> </span>
                    </div>
                    <input type="number" name="height" value={heightValue} maxLength={3} className="form-control" onBlur={handleHeightBlur} onChange={handleHeightChange} required />
                  </div>


                  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                      <span className="input-group-text">{(lnstr === 'en') ? 'Weight (in kgs)' : 'الوزن (بالكجم)'}<span style={{color:'red', marginLeft: '5px'}}>*</span></span>
                    </div>
                    <input type="number" name="weight" value={weightValue} maxLength={2} className="form-control" onBlur={handleWeightBlur} onChange={handleWeightChange} required />
                  </div>
                  {bmi != 0 && <p>Your Body Mass Index is {bmi}</p>}

                  <div style={{height:300}}></div>
                 
                  <div className="d-flex justify-content-between my-3 px-1">
                <button
                  type="button" 
                  // disabled={buttonDisabled} 
                  style={{ width: "auto", whiteSpace: "normal"}}
                  onClick={closeModal}
                  className="btn btn-primary rounded-pill text-white my-1 mx-1 py-1 px-4">
                  {lnstr === 'en' ? 'Cancal': 'كانكال'}
                </button>
                
                
                <button
                  type="submit"
                  disabled={buttonDisabled} 
                  onClick={openModal2} //style={{ width: "auto",height:"33px"}}
                  className="btn btn-primary rounded-pill text-white my-1 py-1 px-4">
                  {lnstr === 'en' ? 'Next' : 'التالي'}
                </button>
                </div>
                        
                       
                    </div>
                </div>
            )}

      {/* popup 2 */}

      {showModal2 && (
        <div className="modal">
          <div className="modal-content"  style={{width:1000 , height:600}}>

          <h4 className="px-0 py-2">{(lnstr === 'en') ? 'Choose your cover plan' : 'اختر خطة التغطية الخاصة بك\n'}</h4>

<div class="align-items-center travel-selection-option">
  {(lnstr === 'en') ? 'Personal Accident' : 'حادث شخصي\n'}
  <input type="radio" name="personalaccident" value="Personal Accident" className="d-inline-block option-circle ml-auto" onChange={handleOptionChange} />
</div>

<div class="align-items-center travel-selection-option my-3">
  {(lnstr === 'en') ? 'Term Plan' : 'خطة المدة\n'}
  <input type="radio" name="personalaccident" value="Term Plan" className="d-inline-block option-circle ml-auto" onChange={handleOptionChange} />
</div>

<div style={{height:300}}></div>

<div className="d-flex justify-content-between my-3 px-1">
                <button
                  type="button" 
                  // disabled={buttonDisabled} 
                  style={{ width: "auto", whiteSpace: "normal"}}
                  onClick={beforepopup}
                  className="btn btn-primary rounded-pill text-white my-1 mx-1 py-1 px-4">
                  {lnstr === 'en' ? 'Prev': 'السابق'}
                </button>
                
                
                <button
                  type="submit"
                  disabled={disablebutton2} 
                  onClick={openModal3} //style={{ width: "auto",height:"33px"}}
                  className="btn btn-primary rounded-pill text-white my-1 py-1 px-4">
                  {lnstr === 'en' ? 'Next' : 'التالي'}
                </button>
                </div>



          </div>
        </div>
      )}


  {/*assured popup */}

  {showModal3 &&(
    <div className="modal">
      <div className="modal-content"  style={{width:1000 , height:600}}>

        <h3 style={{textAlign:'left'}}>Choose sum assured</h3>
      <div className="px-0 py-1">
        <div className="card p-2" >
        <h1 className="text-bold" value={premium}> {premium==''?<strong></strong>:<strong><NumberFormatter value={premium} /></strong>} </h1>
        <h2 className="px-0 py-1" value={assure}>{'Sum Assured'} {assure}</h2>
        </div>
    
      <ul style={{ listStylePosition: 'inside', paddingLeft: '0', textAlign: 'left'}}>
        <li>2 Years Coverage</li>
        <li>Death due to an accident 3,000.000</li>
        <li>Permanent Total Disablement due to an accident 3,000.000</li>
        <li>Permanent Partial Disablement due to an accident (% of Sum Assured as certified by Govt Medical Board) 3,000.000 </li>
        <li>Death Due to any reason (Other than Accident) 1,000.000</li>
        <li>Medical Expenses due to an accident (Up to) 500.000</li>
        <li>Repatriation of Mortal Remains to home country (Up to) 400.000</li>
        <li>Air ticket expenses for medically unfit to work (Up to) 100.000</li>
      </ul>
    </div>

    <div style={{height:250}}></div>
    
<div className="d-flex justify-content-between my-3 px-1">
                <button
                  type="button" 
                  // disabled={buttonDisabled} 
                  style={{ width: "auto", whiteSpace: "normal"}}
                  onClick={beforepopup1}
                  className="btn btn-primary rounded-pill text-white my-1 mx-1 py-1 px-4">
                  {lnstr === 'en' ? 'Prev': 'السابق'}
                </button>
                
                
                <button
                  type="submit"
                  disabled={disablebutton2} 
                  onClick={openModal4} //style={{ width: "auto",height:"33px"}}
                  className="btn btn-primary rounded-pill text-white my-1 py-1 px-4">
                  {lnstr === 'en' ? 'Next' : 'التالي'}
                </button>
                </div>
      </div>
    </div>
  )
  }


  {/* beneficiary popup */}

  {showModal4 && (
    <div className="modal">
      <div className="modal-content" style={{width:1000 , height:600}}>
      <div>
<h3>Add Beneficiary</h3>


<form>
    {formRows.map((row, index) => (
        <div key={index}>
            <div  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}  >
            <h4>{(lnstr === 'en') ? 'Beneficiary ' : 'تفاصيل المسافر'}{index + 1}</h4>
            <p>{formRows.length !== 1 && (
<button type="button"
// disabled={formRows.name != ''}
style={{
backgroundColor: '#FF8488', border: 'none',padding: '0px 5px',borderRadius: '10px',cursor: 'pointer', marginTop: "10px" }}
onClick={() => handleRemoveRow(index)}>
{/* Remove */}<i   style={{ color: 'white' }}>X</i>
</button>
)}</p>
            </div>
            
            <div className="input-group mb-2 mt-2">
                <div className="input-group-prepend prepends">
                    <span className="input-group-text">{t('Name')} <span style={{color:'red', marginLeft: '5px'}}>*</span></span>
                </div>
                <input type="text" name="name" className="form-control" value={row.name}
                    onChange={(e) => handleInputChange(index, e, 'name')}   onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, '');
                      }}
                    required />
            </div>





            {formRows.length !== 1 && (<div className="input-group mb-2 mt-2">
                <div className="input-group-prepend prepends">
                    <span className="input-group-text">{(lnstr == 'en') ? 'Benefit Percentage' : 'نسبة الاستفادة'}</span>
                </div>
                <input type="number" name="benefit_percentage" min={0} max={100} inputMode="numeric" className="form-control" value={row.benefit_percentage} onChange={(e) => handleInputChange(index, e, 'benefit_percentage')}
                    required />
            </div>)}

            <div className="input-group mb-2 mt-2">
                <div className="input-group-prepend prepends">
                    <span className="input-group-text">{t('Relation')} <span style={{color:'red', marginLeft: '5px'}}>*</span></span>
                </div>
                <select name="relations" className="form-control" style={{ direction: 'ltr' }} value={row.relation}
                    onChange={(e) => handleInputChange(index, e, 'relation')}
                    required>

                    <option disabled selected >Select Relation</option>


                    {relations.map((relation, index) => (
                        <option key={index} value={relation}>
                            {relation.charAt(0).toUpperCase() + relation.slice(1)}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    ))}





</form>
{error && <span style={{color:"red"}}>{error}</span>}
{error1 && <span style={{color:"red"}}>{error1}</span> }


<div className="d-flex  my-3">
    <button type="button"
        className="btn btn-primary rounded-pill text-white my-3 py-1 px-4"
        onClick={handleAddRow}>
        Add Beneficiary
    </button>
</div>


</div>

<div style={{height:250}}></div>

<div className="d-flex justify-content-between my-3 px-1">
                <button
                  type="button" 
                  // disabled={buttonDisabled} 
                  style={{ width: "auto", whiteSpace: "normal"}}
                  onClick={beforepopup2}
                  className="btn btn-primary rounded-pill text-white my-1 mx-1 py-1 px-4">
                  {lnstr === 'en' ? 'Prev': 'السابق'}
                </button>
                
                
                <button
                  type="submit"
                  disabled={disablebutton2} 
                  onClick={async () => {


                    if (formRows.length == 1) {
                        formRows[0].benefit_percentage = '100';
                        setFormRows(formRows);
                    }
                    debugger
        
                    if (formRows.length > 0) {
                        debugger
                        if ((formRows.length === 1 && formRows[0].name === '') || (formRows.length === 1 && formRows[0].relation === '')) {
                            setbeneficiarycheck(true);
        
        
                        } else {
                            if (formRows.length > 1) {
                                for (let i = 0; i < formRows.length; i++) {
                                    if (formRows[i].name === '' || formRows[i].relation === '' || formRows[i].benefit_percentage === '') {
                                        setbeneficiarycheck(true);
                                        setErrorValue("please enter the right benefit percentage")
                                        return;
                                    }else{
                                        setErrorValue("")
                                    }
        
                                }
        
                            }
                            if ((totpercentage < 100 || totpercentage > 100) && formRows.length > 1) {
                                // alert("total percentage is invalid");
                                setError1Valule("total Benefit Percentage is invalid")
                                return;
                            }else{
                                setError1Valule("")
                            }
                        }
        
                    }
        
        
        
                    debugger
        
        
                    var data = {
                        'beneficiaries': formRows
                    }
        
                    debugger
        
                    sessionStorage.setItem("personalaccidentbeneficiaries", JSON.stringify(data))
                    setShowModal5(true);
                    setShowModal4(false);
        
                }} //style={{ width: "auto",height:"33px"}}
                  className="btn btn-primary rounded-pill text-white my-1 py-1 px-4">
                  {lnstr === 'en' ? 'Next' : 'التالي'}
                </button>
                </div>
      </div>
    </div>
  )}




{showModal5 && (
  <div className="modal">
    <div className="modal-content" style={{ width: 1000, maxHeight: '80vh', overflowY: 'auto', padding: '20px' }}>

      <h1>Please answer the following</h1>

      {questionsData.map((question, index) => (
        <div key={index}>
          <p>{question.english}</p>
          <div className="custom-radio" style={{ color: '#3fd2f2' }}>
            <input
              type="radio"
              id={`yes-${index}`}
              name={`option-${index}`}
              value="Yes"
              checked={question.isChecked === true}
              onChange={() => handleRadioChange(index, true)}
            />
            <label htmlFor={`yes-${index}`}>Yes</label>

            <input
              type="radio"
              id={`no-${index}`}
              name={`option-${index}`}
              value="No"
              checked={question.isChecked === false}
              onChange={() => handleRadioChange(index, false)}
            />
            <label htmlFor={`no-${index}`}>No</label>
          </div>
          <br />
          {question.isChecked_answer !== '' && question.accepted_answer !== question.isChecked_answer && (
            <div className='col-md-12'>
              <textarea
                style={{
                  height: '100px',
                  width: '100%',
                }}
                value={question.details || ''} // Ensure there's a default value
                onChange={(e) => handleDetailsChange(index, e.target.value)}
                placeholder="Please provide more details"
              />
            </div>
          )}
        </div>
      ))}

      <b>Please put your signature here</b><br />

      <SignatureCanvas
        ref={signatureRef}
        penColor="black"
        canvasProps={{ width: 340, height: 200, className: 'sigCanvas' }}
        style={{ border: '1px solid #ccc', backgroundColor: 'white' }} // Ensure background is white
        onEnd={handleSignature} // Detect when drawing ends
      />
      <div>
        <button
          type="button"
          style={{ backgroundColor: !isSignatureEmpty ? '#1D3561' : 'grey', borderColor: '#1D3561' }}
          className="btn btn-primary rounded-pill text-white my-3 py-1"
          onClick={async () => {
            clearSignature();
          }}
          disabled={isSignatureEmpty}
        >
          Clear Signature
        </button>
      </div>

      <p>
        <input
          type="checkbox"
          checked={isChecked1}
          onChange={handleCheckboxChange1}
          name="note"
        />
        {" I declare that the above answers are true to the best of my knowledge and belief, that I have disclosed all particulars affecting the assessment of the risk and that I am and always have been of temperate habits. I agree that this proposal and declaration shall be the basis of the contract between me and the Insurers."}
      </p>


      <div className="d-flex justify-content-between my-3 px-1">
                <button
                  type="button" 
                  style={{ width: "auto", whiteSpace: "normal"}}
                  onClick={beforepopup3}
                  className="btn btn-primary rounded-pill text-white my-1 mx-1 py-1 px-4">
                  {lnstr === 'en' ? 'Prev': 'السابق'}
                </button>
                
                
                <button
                  type="submit"
                  disabled={isChecked1} 
                  onClick={async () => {
                    var question_valid = !questionsData.some(q => q.isChecked_answer === '');
                    if (!question_valid) {
                      alert('Please ensure the question answer');
                    } else if (signatureRef.current.isEmpty()) {
                      alert('Please put signature');
                    } else if (!isChecked1) {
                      alert('Please ensure the declare');
                    } else {
                      setLoading(true);
                      setShowModal6(true);
                    }
                  }} 
                  className="btn btn-primary rounded-pill text-white my-1 py-1 px-4">
                  {lnstr === 'en' ? 'Next' : 'التالي'}
                </button>
                </div>

    </div>
  </div>
)}


{showModal6 && (
  <div className="modal">
    <div className="modal-content">
    <div>
            <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52" style={{width: '52px' , height: '52px;'}}><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"></circle><path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"></path></svg>
            </div>

            <div className="thanks-title text-center mt-4">
                <h4>{(lnstr === 'en') ? 'Thank you for contacting' : 'شكرا لاتصالك'}<br></br>

                {(lnstr === 'en') ? 'Dhofar Insurance Company' : 'شكرا لاتصالك'}
                </h4>
                <h6 className="thanks-title text-center mt-4">
                {(lnstr === 'en') ? 'We have received your request for' : 'لقد تلقينا طلبك ل'}<br></br>

                {"Token"}<br></br>

                {(lnstr === 'en') ? 'Please call us @ 8000 7777 for further assistance.' : 'يرجى الاتصال بنا على 80007777 لمزيد من المساعدة.'}



                </h6>

            </div>
            <br></br>

            <div className="d-flex justify-content-center">
            <button type="submit" className="btn btn-primary rounded-pill text-white " onClick={handleSubmit}>{(lnstr === 'en') ? 'Go To Home' : 'يتابع'}</button>
            </div>
    </div>
  </div>
)}



        
    
    
      </div>

      <Footer/>


      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}
    </Fragment>
    
  );
}

export default MedicalPlan;