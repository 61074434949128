import Cookies from 'js-cookie';

const MedicalindividualRemove = () => {

    sessionStorage.removeItem("mediindivialbasicinfo");
    sessionStorage.removeItem("premiumfullvalue");
    sessionStorage.removeItem("medicalpersonalinfopage");
    sessionStorage.removeItem("MedicalindividualPolicynumber");
    sessionStorage.removeItem("medicalamount");
  
};

export default MedicalindividualRemove ;