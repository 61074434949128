const MedicalAffordableRemove = () => {

    sessionStorage.removeItem("affordablebasicinfo");
    sessionStorage.removeItem("medicalpremiumcover");
    sessionStorage.removeItem("medicalaffordablepage");
    sessionStorage.removeItem("medicalaffordablePolicynumber");
    sessionStorage.removeItem("medicalamount");
  
};

export default MedicalAffordableRemove ;