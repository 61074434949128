import React, { Fragment, useState, useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { useTranslation } from 'react-i18next';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';
import AOS from 'aos';
import 'aos/dist/aos.css';
const { config } = require('../api/api');



function MotorInsuranceThirdParty() {
  const base_url = config.baseURL;

  const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';


  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    insurancetype: '',
  });

  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showSection, setShowSection] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered1, setIsHovered1] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);


  const handleOptionChange = (event) => {
    setSelected(event.target.value);
    debugger
    setFormData({ ...formData, 'insurancetype': event.target.value });

    if (event.target.value === ' ') {
      setShowSection(true);
      setButtonDisabled(true);
    }
    else {
      setShowSection(false);
      setButtonDisabled(false);
    }

  };


  useEffect(()=>{
    const planvalue = sessionStorage.getItem('seletedplan');
    if(planvalue != undefined) {
      const plan = JSON.parse(planvalue)
      setSelected(plan.plan)
    }
  },[])


  useEffect(()=>{
    AOS.init({duration:600});
  },[]);
  



  const handleSubmit = async (event) => {
    debugger
    if (selected === null) {
      alert('Please choose a plan');
    }else{
    var data ={
      'plan': selected
    }
      sessionStorage.setItem('seletedplan' , JSON.stringify(data))
      event.preventDefault();
    window.location.href ='/dhofar_motor_quote'

    }
  }



  const handleBack = async (event) => {

    debugger
      event.preventDefault();
    window.location.href ='/dhofar_motoroffers'
  }




  return (
    <Fragment>

      <div className="header_section1">

        <Navbar />


        <div className="container-fluid bg-breadcrumb">
          <div className="container text-center py-5">
            <h3 className="abt-txt my-3">{(lnstr === 'en') ? 'Third Party' : 'طرف ثالث\n'}</h3>
          </div>
        </div>


      </div>


      <div className="features_section">
        <div className="container">

          <div className="row">

            <Col md={3}></Col>

            <Col md={6} className="myforms my-5" data-aos='fade-up'>



              <h4 className="px-0 py-2">{(lnstr === 'en') ? 'Select plan' : 'اختر الخطة\n'}</h4>

              <form>
                <div class="align-items-center travel-selection-option">
                  {(lnstr === 'en') ? 'Basic + Driver + Family (PAB)' : 'أساسي + سائق + عائلة (PAB)\n'}
                  <input type="radio" name="personalaccident" value="Basic + Driver + Family (PAB)" className="d-inline-block option-circle ml-auto" onChange={handleOptionChange} />
                </div>

                <div class="align-items-center travel-selection-option my-3">
                  {(lnstr === 'en') ? 'Basic + Driver + Family (PAB) + UAE' : 'أساسي + سائق + عائلة (PAB) + الإمارات العربية المتحدة\n'}<input type="radio" name="personalaccident" value="Basic + Driver + Family (PAB) + UAE" className="d-inline-block option-circle ml-auto" onChange={handleOptionChange} />
                </div>
                <div class="align-items-center travel-selection-option my-3">
                  {(lnstr === 'en') ? 'Basic + Driver + Family (PAB) + RSA' : 'أساسي + سائق + عائلة (PAB) + RSA\n'}
                  <input type="radio" name="personalaccident" value="Basic + Driver + Family (PAB) + RSA" className="d-inline-block option-circle ml-auto" onChange={handleOptionChange} />
                </div>
                <div class="align-items-center travel-selection-option my-3">
                  {(lnstr === 'en') ? 'Basic + Driver + Family (PAB) + UAE + RSA' : 'أساسي + سائق + عائلأساسي + سائق + عائلة (PAB) + الإمارات العربية المتحدة + RSA\n'}
                  <input type="radio" name="personalaccident" value="Basic + Driver + Family (PAB) + UAE + RSA" className="d-inline-block option-circle ml-auto" onChange={handleOptionChange} />
                </div>
              </form>

              <div>
              <button type="submit" disabled={buttonDisabled} className="rounded-pill text-white my-1 py-1 px-4" style={{
            backgroundColor: isHovered ? '#0b8ba8' : '#33d0f2',
            transition: 'background-color 0.3s ease',
            position: 'absolute', 
            right: '10px',
          }} onClick={handleSubmit}  onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>{(lnstr === 'en' ? 'Get Quote' : 'الحصول على الاقتباس')}</button>

<button type="submit" disabled={buttonDisabled} className="rounded-pill text-white my-1 py-1 px-4" style={{
            backgroundColor: isHovered1 ? '#0b8ba8' : '#33d0f2',
            transition: 'background-color 0.3s ease',
          }} onClick={handleBack}  onMouseEnter={() => setIsHovered1(true)} onMouseLeave={() => setIsHovered1(false)}>{(lnstr === 'en' ? 'Back' : 'خلف')}</button>
              </div>


            </Col>

            <Col md={3}></Col>

          </div>

        </div>
      </div>

      <Footer />

      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}

    </Fragment>
  );
}

export default MotorInsuranceThirdParty;
